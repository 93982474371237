import {
  LOAD_ASSET,
  LOAD_ASSETS,
  LOAD_ALL_ASSETS,
  LOAD_ASSET_COUNT,
  LOAD_ASSETS_ON_MOVE_FROM_ASSET_GROUP_PROFILE,
  LOAD_ALL_ASSETS_LINKED_WITH_ASSET_GROUP,
  LOAD_ALL_ASSETS_LINKED_WITH_MAINTENANCE_SCHEDULE,
  SHOW_LOADER,
  HIDE_LOADER,
} from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  list: [],
  all: [],
  allByAssetGroup: [],
  allByMaintenanceSchedule: [],
  loading: false,
};

const asset = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_ASSET: {
      const item = action.payload;
      let { list, count, all } = state;
      if (item.name !== null) {
        if (all.find((element) => element.id === item.id) === undefined) {
          all.push(item);
        }
        if (list.find((element) => element.id === item.id) === undefined) {
          list.push(item);
        }

        list = list.map((listItem) => (item.id === listItem.id ? item : listItem));
        all = all.map((listItem) => (item.id === listItem.id ? item : listItem));
      } else {
        list = list.filter((listItem) => item.id !== listItem.id);
        all = all.filter((listItem) => item.id !== listItem.id);
        count -= 1;
      }
      return {
        ...state,
        item,
        list,
        count,
        all,
      };
    }
    case LOAD_ASSETS:
      return { ...state, list: action.payload };
    case LOAD_ALL_ASSETS:
      return { ...state, all: action.payload };
    case LOAD_ASSET_COUNT:
      return { ...state, count: action.payload };
    case LOAD_ASSETS_ON_MOVE_FROM_ASSET_GROUP_PROFILE:
      const item = action.payload;
      let { list, count } = state;
      list = list.filter((listItem) => item.id !== listItem.id);
      count = list.length;
      return {
        ...state,
        list,
        count,
      };
    case LOAD_ALL_ASSETS_LINKED_WITH_ASSET_GROUP:
      return { ...state, allByAssetGroup: action.payload };
    case LOAD_ALL_ASSETS_LINKED_WITH_MAINTENANCE_SCHEDULE:
      return { ...state, allByMaintenanceSchedule: action.payload };
    case SHOW_LOADER:
      return { ...state, loading: true };
    case HIDE_LOADER:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default asset;
