import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { Html5Qrcode, Html5QrcodeScanner } from 'html5-qrcode';

import { FormInput, FormDatePicker, SelectDropdown, CreatableSelectDropdown } from '../../form/parts';

const AssetEntryView = ({ t, args, onScanSuccess }) => {
  useEffect(() => {
    let config = {
      fps: 10,
      qrbox: { width: 100, height: 100 },
      rememberLastUsedCamera: true,
    };
    let html5QrcodeScanner = new Html5QrcodeScanner('reader', config, false);
    html5QrcodeScanner.render({ facingMode: 'environment' }, onScanSuccess);
  }, []);

  return (
    <div>
      <Field label={t('Asset Name *')} component={FormInput} name="name" type="text" placeholder={t('Asset Name')} />

      <Field
        label={t('Asset Number')}
        component={FormInput}
        name="reference"
        type="text"
        placeholder={t('Asset Number (optional)')}
      />

      <Field
        label={t('Description')}
        component={FormInput}
        name="description"
        type="text"
        placeholder={t('Description (optional)')}
      />

      {/* // if open from Sites > Asset (tab) > Add / Edit */}
      {!args.isAPTAddNewTask && (
        <div>
          <div className="form-group">
            <label className="col-sm-4 control-label">{t('Install Date')}</label>
            <div className="col-sm-8 full_datepicker">
              <Field name="installDate" component={FormDatePicker} placeholder={t('Install Date')} />
            </div>
          </div>

          <Field label={t('Quantity')} component={FormInput} name="quantity" type="text" placeholder={t('Quantity')} />

          <Field
            label={t('Serial Number')}
            component={FormInput}
            name="serialNumber"
            type="text"
            placeholder={t('Serial Number (optional)')}
          />

          <div className="form-group">
            <label className="col-sm-4 control-label">{t('Condition')}</label>

            <div className="col-sm-8">
              <Field
                name="condition"
                component={SelectDropdown}
                drpName="condition"
                options={args.conditionList}
                _optionLabel="lookupvalue"
                _optionValue="lookupvaluesid"
                placeholder={t('Condition')}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-4 control-label">{t('Location')}</label>

            <div className="col-sm-8">
              <Field
                name="locationId"
                component={SelectDropdown}
                drpName="locationId"
                options={args.locationList.map((data) => {
                  return { name: data.name, id: data.id, isDisabled: data.disabled };
                })}
                _optionLabel="name"
                _optionValue="id"
                placeholder={t('Location')}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-4 control-label">{t('Manufacturer')}</label>

            <div className="col-sm-8">
              <Field
                name="manufacturer"
                component={CreatableSelectDropdown}
                drpName="manufacturer"
                options={args.manufacturerList}
                _optionLabel="name"
                _optionValue="id"
                _noLabelValue={false}
                placeholder={t('Choose or create manufacturer')}
              />
            </div>
          </div>

          {args.showModels && (
            <div className="form-group">
              <label className="col-sm-4 control-label">{t('Model')}</label>

              <div className="col-sm-8">
                <Field
                  name="model"
                  component={CreatableSelectDropdown}
                  drpName="model"
                  options={args.modelList}
                  _optionLabel="name"
                  _optionValue="id"
                  _noLabelValue={false}
                  placeholder={t('Choose or create model')}
                />
              </div>
            </div>
          )}

          <Field
            label={t('Scanned Reference')}
            component={FormInput}
            name="tagResult"
            type="text"
            readOnly
            placeholder={t('Scanned Reference')}
          />

          <div className="form-group">
            <label className="col-sm-4 control-label">{t('Scan Barcode')}</label>
            <div className="col-sm-8">
              <div id="reader"></div>
            </div>
          </div>
        </div>
      )}

      {/* // if open from Add Planned Task > Advanced (radio) > Asset drop - Add New */}
      {/* // Simple (radio) > location dropdown */}
      {args.isAPTAddNewTask && args.isAssetSimpleView === 'true' && (
        <div>
          <div className="form-group">
            <label className="col-sm-4 control-label">{t('Location')}</label>

            <div className="col-sm-8">
              <Field
                name="location"
                component={SelectDropdown}
                drpName="location"
                options={args.locationList.map((data) => {
                  return { name: data.name, id: data.id, isDisabled: data.disabled };
                })}
                _optionLabel="name"
                _optionValue="id"
                placeholder={t('Location')}
              />
            </div>
          </div>
        </div>
      )}

      {/* // Advance (radio) > Qty, Install Date, Condition, Manufacturer, Model dropdown */}
      {args.isAPTAddNewTask && args.isAssetSimpleView === 'false' && (
        <div>
          <div className="form-group">
            <label className="col-sm-4 control-label">{t('Install Date')}</label>
            <div className="col-sm-8 full_datepicker">
              <Field name="installDate" component={FormDatePicker} placeholder={t('Install Date')} />
            </div>
          </div>

          <Field label={t('Quantity')} component={FormInput} name="quantity" type="text" placeholder={t('Quantity')} />

          <Field
            label={t('Serial Number')}
            component={FormInput}
            name="serialNumber"
            type="text"
            placeholder={t('Serial Number (optional)')}
          />

          <div className="form-group">
            <label className="col-sm-4 control-label">{t('Condition')}</label>

            <div className="col-sm-6">
              <Field
                name="condition"
                component={SelectDropdown}
                drpName="condition"
                options={args.conditionList}
                _optionLabel="lookupvalue"
                _optionValue="lookupvaluesid"
                placeholder={t('Condition')}
              />
            </div>
            <div className="col-sm-2">
              <button
                type="button"
                className="swal2-confirm swal2-styled margin_0 modal-button"
                onClick={args.onClickNewCondition}
              >
                {t('Add New')}
              </button>
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-4 control-label">{t('Manufacturer')}</label>

            <div className="col-sm-8">
              <Field
                name="manufacturer"
                component={CreatableSelectDropdown}
                drpName="manufacturer"
                options={args.manufacturerList}
                _optionLabel="name"
                _optionValue="id"
                _noLabelValue={false}
                placeholder={t('Choose or enter new manufacturer')}
              />
            </div>
          </div>

          {args.showModels && (
            <div className="form-group">
              <label className="col-sm-4 control-label">{t('Model')}</label>

              <div className="col-sm-8">
                <Field
                  name="model"
                  component={CreatableSelectDropdown}
                  drpName="model"
                  options={args.modelList}
                  _optionLabel="name"
                  _optionValue="id"
                  _noLabelValue={false}
                  placeholder={t('Choose or enter new model')}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const assetProps = PropTypes.shape({
  conditionList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  locationList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  manufacturerList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  modelList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showModels: PropTypes.bool.isRequired,
  isAPTAddNewTask: PropTypes.bool,
  isAssetSimpleView: PropTypes.string,
  onClickNewCondition: PropTypes.func,
}).isRequired;

AssetEntryView.propTypes = {
  args: assetProps,
  t: PropTypes.func.isRequired,
};

AssetEntryView.defaultProps = {
  args: {
    conditionList: [],
    locationList: [],
    manufacturerList: [],
    modelList: [],
    showModels: false,
    isAPTAddNewTask: false,
    isAssetSimpleView: 'true',
    onClickNewCondition: undefined,
  },
};

export default AssetEntryView;
