import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { showBodyScroll, hideBodyScroll } from '../../lib/bodyScrollToggle';
import { FormDatePicker, FormCheckbox } from '../form/parts';
import { updateBuilding } from '../../actions/bms/BuildingActions';
// import SimpleModal from './SimpleModal';
import { generateJob } from '../../actions/cafm/ScheduleActions';
import commonFunction from '../../lib/commonFunction';

class GenerateJobModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    handleOk: PropTypes.func.isRequired,
    onModalClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    isArchive: PropTypes.bool,
    warning: PropTypes.string,
    warningShow: PropTypes.bool,
    initialValues: PropTypes.shape().isRequired,
    updateBuilding: PropTypes.func,
    generateJob: PropTypes.func,
  };

  static defaultProps = {
    isArchive: false,
    warningShow: false,
    warning: '',
    updateBuilding: undefined,
    generateJob: undefined,
  };

  constructor(props) {
    super(props);
    hideBodyScroll();
    this.state = {
      display: true,
      isArchive: false,
      generateJobModal: {
        open: false,
        startDate: '',
        endDate: '',
        isSave: false,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      hideBodyScroll();
      this.setState({
        display: true,
      });
    }
  }

  onChangeArchive = (checked) => {
    this.setState({
      isArchive: checked,
    });
  };

  onOk = () => {
    this.closeModal();
    this.props.handleOk(this.state.isArchive);
  };

  closeModal = () => {
    this.setState({
      display: false,
      generateJobModal: {
        open: false,
        startDate: '',
        endDate: '',
        isSave: false,
      },
    });
    showBodyScroll();
    this.props.onModalClose();
  };

  handleGenerateJobs = (generateJob) => {
    const validPropsArray = [];
    Object.keys(generateJob).forEach((key) => {
      if (generateJob[key] !== '' && generateJob[key] !== null) {
        validPropsArray.push(key);
      }
    });
    const newGenerateJob = _.pick(generateJob, validPropsArray);
    newGenerateJob.startDate = commonFunction.getDateWithValidFormat(newGenerateJob.startDate);
    newGenerateJob.endDate = commonFunction.getDateWithValidFormat(newGenerateJob.endDate);
    // this.props
    //   .updateBuilding(newbuilding)
    //   .then(() => this.closeModal());
    this.props.generateJob(newGenerateJob);
    this.closeModal();
  };

  handleModalsClose = () => {
    this.setState({
      generateJobModal: {
        open: false,
        startDate: '',
        endDate: '',
        isSave: false,
      },
    });
  };

  render() {
    const { header, handleSubmit, text, t, isArchive, warning, warningShow, initialValues } = this.props;
    const { display, generateJobModal } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center form-horizontal swal2-fade swal2-shown over-y-auto
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show generate-job-modal "
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{header}</h2>

          {warningShow && (
            <div className="swal2-warning" style={{ display: 'block' }}>
              {warning}
            </div>
          )}

          <div className="swal2-content" style={{ display: 'block' }}>
            {text}
          </div>

          {isArchive && (
            <div className="swal2-control" style={{ display: 'block' }}>
              <input
                type="checkbox"
                name="Isarchive"
                onChange={(e) => {
                  this.onChangeArchive(e.target.checked);
                }}
              />
              <span>&nbsp;&nbsp;Archive Jobs with Notes and Service Sheets</span>
            </div>
          )}
          <form>
            <div className="form-group" />
            <div className="form-group">
              <label className="col-sm-4 control-label">{t('Start Date')}</label>
              <div className="col-sm-8 full_datepicker">
                <Field
                  name="startDate"
                  component={FormDatePicker}
                  placeholder={t('Choose Start Date')}
                  disabled
                  isClearable={false}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">{t('End Date')}</label>
              <div className="col-sm-8 full_datepicker">
                <Field name="endDate" component={FormDatePicker} placeholder={t('Choose End Date')} />
              </div>
            </div>
            <Field name="isSave" component={FormCheckbox} label={t('Save and generate')} />
          </form>
          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleGenerateJobs)}
            >
              {t('Generate')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Close')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateBuilding,
      generateJob,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'generateJobForm',
    enableReinitialize: true,
  })(GenerateJobModal),
);
