import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';

import { updateAsset } from '../../../actions/cafm/AssetActions';
import AssetPicturesEdit from '../../../containers/decorate/AssetPicturesEdit';
import { showBodyScroll, hideBodyScroll } from '../../../lib/bodyScrollToggle';
import { getLookupvalueList } from '../../../actions/cafm/LookupvaluesAction';
import { AssetEntryView } from '../../cafm/admin';
import commonFunction from '../../../lib/commonFunction';

class EditAssetModal extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    initialValues: PropTypes.shape().isRequired,
    createForm: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    locations: PropTypes.arrayOf(PropTypes.shape()),
    manufacturers: PropTypes.arrayOf(PropTypes.shape()),
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    updateAsset: PropTypes.func.isRequired,
    conditionlist: PropTypes.arrayOf(PropTypes.shape()),
    getLookupvalueList: PropTypes.func.isRequired,
    onUpdateCallback: PropTypes.func,
  };

  static defaultProps = {
    locations: [],
    manufacturers: [],
    createForm: {
      editAssetForm: {},
    },
    conditionlist: [],
    onUpdateCallback: () => {},
  };

  constructor(props) {
    super(props);

    const { locations } = props;
    const conditionNames = props.conditionlist;
    const manufacturerNames = props.manufacturers;
    let modelNames = [];
    if (props.initialValues.manufacturer) {
      const manufacturerValue = props.initialValues.manufacturer[0].value;
      const chosenManufacturer = _.find(props.manufacturers, ['id', manufacturerValue]);
      if (chosenManufacturer && chosenManufacturer.Model) {
        modelNames = chosenManufacturer.Model;
      }
    }

    this.state = {
      display: false,
      locations,
      manufacturerNames,
      modelNames,
      conditionNames,
    };
    props.getLookupvalueList({ lookupname: 'Condition' });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      hideBodyScroll();
      this.setState({
        display: true,
      });
    }
    if (nextProps.locations !== this.props.locations) {
      const { locations } = nextProps;
      this.setState({ locations });
    }
    if (nextProps.manufacturers !== this.props.manufacturers) {
      const manufacturerNames = nextProps.manufacturers;
      this.setState({
        manufacturerNames,
      });
    }
    const form = nextProps.createForm.editAssetForm;
    const prevForm = this.props.createForm.editAssetForm;
    if (
      form &&
      form.values &&
      form.values.manufacturer &&
      form.values.manufacturer[0] &&
      form.values.manufacturer[0] !==
        (prevForm && prevForm.values && prevForm.values.manufacturer && prevForm.values.manufacturer[0])
    ) {
      const { value } = form.values.manufacturer[0];
      const chosenManufacturer = _.find(nextProps.manufacturers, ['id', value]);
      if (chosenManufacturer && chosenManufacturer.Model) {
        const modelNames = chosenManufacturer.Model;
        if (prevForm && prevForm.values && form && form.values) {
          if (
            form.values.manufacturer &&
            prevForm.values.manufacturer &&
            form.values.manufacturer[0] != prevForm.values.manufacturer[0]
          ) {
            form.values.model = [];
          }
        }
        this.setState({
          modelNames,
        });
      } else {
        form.values.model = [];
        this.setState({
          modelNames: [],
        });
      }
    } else if (form.values && form.values.manufacturer && Object.keys(form.values.manufacturer).length <= 0) {
      // if manufacturer becomes null/cross/remove then clear model dropdown as it not clears model.
      // So selected value in model shows selected value of previous manufacturer if you select
      form.values.model = [];
      prevForm.values.model = [];
      this.setState({
        modelNames: [],
      });
    }
    const conditionNames = nextProps.conditionlist;
    this.setState({
      conditionNames,
    });
  }

  onScanSuccess = (decodedText, result) => {
    if (!result.errorMessage) this.props.change('tagResult', decodedText);
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
    showBodyScroll();
    this.props.onModalClose();
  };

  handleUpdateAsset = (asset) => {
    const { id } = this.props;
    const { locationId } = asset;

    const manufacturerLabel = asset.manufacturer && asset.manufacturer[0] && asset.manufacturer[0].label;
    const manufacturerValue = asset.manufacturer && asset.manufacturer[0] && asset.manufacturer[0].value;
    const modelLabel = asset.model && asset.model[0] && asset.model[0].label;
    const modelValue = asset.model && asset.model[0] && asset.model[0].value;
    const assetManufacturer = _.find(this.props.manufacturers, ['id', manufacturerValue]);
    const assetModel =
      assetManufacturer && assetManufacturer.Model ? _.find(assetManufacturer.Model, ['id', modelValue]) : null;
    const newAsset = {
      id,
      name: asset.name,
      quantity: +asset.quantity,
      locationId: locationId && locationId.value,
      installDate:
        asset.installDate === null || asset.installDate === ''
          ? null
          : commonFunction.getDateWithValidFormat(asset.installDate),
      conditionId: asset.condition && asset.condition.value,
      reference: asset.reference,
      serialNumber: asset.serialNumber,
    };
    if (assetModel && assetModel.uuid) {
      newAsset.modelUUID = assetModel.uuid;
    } else if (modelLabel && assetManufacturer) {
      newAsset.modelCreator = {
        modelName: modelLabel,
        manufacturerUUID: assetManufacturer.uuid,
      };
    } else if (manufacturerLabel && modelLabel) {
      newAsset.modelCreator = {
        modelName: modelLabel,
        manufacturerName: manufacturerLabel,
      };
    }
    newAsset.tagResult = asset.tagResult;
    this.props.updateAsset(newAsset).then(() => {
      this.closeModal();
      this.props?.onUpdateCallback();
    });
  };

  render() {
    const {
      initialValues: { assetPictures },
      handleSubmit,
      t,
    } = this.props;
    const { display, locations, manufacturerNames, modelNames, conditionNames } = this.state;
    const pictures = assetPictures || [];
    return (
      <div
        tabIndex={0}
        role="button"
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show modal-template large-modal form-horizontal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{t('Update asset')}</h2>

          <form>
            <div className="row row-eq-height">
              <div className="col col-md-8">
                <AssetEntryView
                  t={t}
                  args={{
                    isAPTAddNewTask: false,
                    conditionList: conditionNames,
                    locationList: locations,
                    manufacturerList: manufacturerNames,
                    modelList: modelNames,
                    showModels: true,
                  }}
                  onScanSuccess={this.onScanSuccess}
                />
              </div>
              <div className="col col-md-4">
                <AssetPicturesEdit initialPictures={pictures} t={t} />
              </div>
            </div>
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleUpdateAsset)}
            >
              {t('Update')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues,
  createForm: state.form,
  conditionlist: state.lookupvalues.list,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateAsset,
      getLookupvalueList,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'editAssetForm',
    enableReinitialize: true,
  })(EditAssetModal),
);
