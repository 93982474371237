import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';

import { updateLocation, getLocationFullList } from '../../../actions/cafm/LocationActions';
import { showBodyScroll, hideBodyScroll } from '../../../lib/bodyScrollToggle';
import { getFloorOptions } from '../../../constants/options';
import { LocationEntryView } from '../../cafm/admin';

class EditLocationModal extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    initialValues: PropTypes.shape().isRequired,
    siteId: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    updateLocation: PropTypes.func.isRequired,
    getLocationFullList: PropTypes.func.isRequired,
    locations: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    locations: [],
  };

  constructor(props) {
    super(props);
    const floorOptions = getFloorOptions(props.t);
    this.state = {
      display: false,
      floorOptions,
    };
    props.getLocationFullList({ siteId: props.siteId, isOnlyParent: true, notInIds: props.id });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      hideBodyScroll();
      this.setState({
        display: true,
      });
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    showBodyScroll();
    this.props.onModalClose();
  };

  handleUpdateLocation = (location) => {
    const { id, initialValues } = this.props;
    const fields = ['name'];
    const newLocation = _.pick(location, fields);
    // if (location.parent !== 'none') {
    //   const parentLocation = _.find(this.props.parentLocations, ['name', location.parent]);
    //   newLocation.locationId = parentLocation.id;
    // }
    newLocation.siteId = this.props.siteId;
    newLocation.id = id;
    newLocation.type = location.type && location.type.value;
    newLocation.locationId = location.parent && location.parent.value;
    newLocation.disabled = location.disabled;
    newLocation.isChildUpdate = initialValues.isChildUpdate || undefined;
    newLocation.urlPath = initialValues.urlPath || undefined;
    this.props.updateLocation(newLocation);
    this.closeModal();
  };

  render() {
    const { handleSubmit, t, initialValues, locations } = this.props;
    const { floorOptions } = this.state;
    const { display } = this.state;

    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show modal-template form-horizontal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{t('Update location')}</h2>

          <form>
            <LocationEntryView
              t={t}
              args={{
                floorList: floorOptions,
                mode: 'edit',
                parentLocationList: locations || [],
                isParentDropdownShow: true,
              }}
            />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleUpdateLocation)}
            >
              {t('Update')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues,
  locations: state.location.onlyParent || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateLocation,
      getLocationFullList,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'editCategoryGroupForm',
    enableReinitialize: true,
  })(EditLocationModal),
);
