//1446/1453
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInput, FormDatePicker, FormCheckbox, SelectDropdown, TagsDropdown } from '../../form/parts';

const AssetSurveyEntryView = ({ t, args }) => (
  <div>
    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Site *')}</label>

      <div className="col-sm-8">
        <Field
          name="Site"
          component={SelectDropdown}
          drpName="Site"
          options={args.siteList}
          placeholder={t('Site')}
          onSelect={args.onChangeSite}
        />
      </div>
    </div>

    <Field
      label={t('Est No. Days')}
      component={FormInput}
      name="estimateNoOfDay"
      type="text"
      placeholder={t('Estimate no. of days')}
    />

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Start Date *')}</label>
      <div className="col-sm-8 full_datepicker">
        <Field
          name="startDate"
          component={FormDatePicker}
          dateFormat="YYYY-MM-DD"
          placeholder={t('Start Date')}
          disabled={!args.siteId || args.siteId == 0}
        />
      </div>
    </div>

    <Field
      label={t('Est no. Assets')}
      component={FormInput}
      name="estimateNoOfAsset"
      type="text"
      placeholder={t('Estimate no. of assets')}
    />

    <Field label={t('No. Floors')} component={FormInput} name="floor" type="text" placeholder={t('No. floor')} />

    <Field
      name="hasFloorPlan"
      component={FormCheckbox}
      label={t('Floor Plans')}
      disabled={!args.siteId || args.siteId == 0}
    />

    <div className="form-group">
      <div className="col-sm-6 col-sm-offset-4 text-left">
        <span>
          {t('Choose an internal user for this work or select which subcontractor the work will be carried out by')}
        </span>
      </div>
    </div>

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('User Assignee')}</label>

      <div className={args.canAddNewUser ? 'col-sm-6' : 'col-sm-8'}>
        <Field
          name="userAssignee"
          component={SelectDropdown}
          drpName="userAssignee"
          options={args.userList}
          _optionLabel="fullName"
          placeholder={t('Choose User Assignee')}
          isDisabled={(args.isSubContract !== null && args.isSubContract) || !args.siteId || args.siteId == 0}
        />
      </div>
      {args.canAddNewUser && (
        <div className="col-sm-2">
          <button
            type="button"
            className="swal2-confirm swal2-styled margin_0 modal-button"
            onClick={args.onClickNewUser}
            disabled={(args.isSubContract !== null && args.isSubContract) || !args.siteId || args.siteId == 0}
          >
            {t('Add New')}
          </button>
        </div>
      )}
    </div>

    <div className="form-group">
      <div className="col-sm-6 col-sm-offset-4 text-left">
        <span>{t('or')}</span>
      </div>
    </div>

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Carried out by')}</label>

      <div className={args.canAddNewCompany ? 'col-sm-6' : 'col-sm-8'}>
        <Field
          name="company"
          component={SelectDropdown}
          drpName="company"
          options={args.companyList}
          placeholder={t('Choose Subcontractor')}
          isDisabled={(args.isSubContract !== null && !args.isSubContract) || !args.siteId || args.siteId == 0}
        />
      </div>
      {args.canAddNewCompany && (
        <div className="col-sm-2">
          <button
            type="button"
            className="swal2-confirm swal2-styled margin_0 modal-button"
            onClick={args.onClickNewInternalCompany}
            disabled={(args.isSubContract !== null && !args.isSubContract) || !args.siteId || args.siteId == 0}
          >
            {t('Add New')}
          </button>
        </div>
      )}
    </div>

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Contact')}</label>

      <div className="col-sm-8">
        <Field
          name="contact"
          component={SelectDropdown}
          drpName="contact"
          options={args.contactList}
          _optionLabel="fullName"
          placeholder={t('Choose Subcontractor Contact')}
          isDisabled={(args.isSubContract !== null && !args.isSubContract) || !args.siteId || args.siteId == 0}
        />
      </div>
    </div>

    <Field
      label={t('Admin Email')}
      component={FormInput}
      name="adminEmail"
      type="text"
      placeholder={t('Admin email')}
      maxLength={255}
    />

    <Field
      name="notifyAdmin"
      component={FormCheckbox}
      label={t('Notifications to Admin')}
      disabled={!args.siteId || args.siteId == 0}
    />
  </div>
);

const maintenanceScheduleProps = PropTypes.shape({
  floorList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  hasFloorPlan: PropTypes.bool.isRequired,
  userList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClickNewUser: PropTypes.func,
  companyList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClickNewInternalCompany: PropTypes.func,
  contactList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  siteList: PropTypes.arrayOf(PropTypes.shape()),
  onChangeSite: PropTypes.func,
  siteId: PropTypes.number,
  canAddNewUser: PropTypes.bool,
  canAddNewCompany: PropTypes.bool,
  isSubContract: PropTypes.bool,
}).isRequired;

AssetSurveyEntryView.propTypes = {
  args: maintenanceScheduleProps,
  t: PropTypes.func.isRequired,
};

AssetSurveyEntryView.defaultProps = {
  args: {
    floorList: [],
    hasFloorPlan: false,
    userList: [],
    companyList: [],
    contactList: [],
    siteList: [],
    onChangeSite: undefined,
    siteId: 0,
    canAddNewUser: false,
    canAddNewCompany: false,
    isSubContract: false,
  },
};

export default AssetSurveyEntryView;
