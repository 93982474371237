import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { SideMenuView } from '../../components/decorate';
import { Router } from '../../../common/routes';

import { getCurrentUser, getCurrentUserPermissions } from '../../actions/UserActions';

class SideMenu extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    currentSystem: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    currentuser: PropTypes.shape(),
    userCheckRights: PropTypes.shape(),
    accountUUID: PropTypes.string.isRequired,
    activeSiteId: PropTypes.number,
    getCurrentUserPermissions: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    props.getCurrentUserPermissions();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentSystem !== this.props.currentSystem) {
      switch (nextProps.currentSystem) {
        case 'bms':
          Router.pushRoute('bms/buildings');
          break;
        // case 'cafm':
        //   Router.pushRoute('cafm/admin/sites');
        //   break;
        case 'cafm-admin':
          Router.pushRoute('cafm/admin/sites');
          break;
        default:
          Router.pushRoute('main');
      }
    }
  }

  render() {
    return <SideMenuView {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  const {
    tokens: { accessToken },
    user: {
      user,
      userCheckRights,
      currentuser,
      currentuserpermissions,
      currentSystem,
      currentActiveAccount,
      activeSiteId,
      activeSite,
    },
    ppmJob: { item },
  } = state;
  return {
    accessToken,
    currentSystem,
    currentuser,
    userCheckRights,
    accountUUID: (currentActiveAccount && currentActiveAccount.uuid) || '',
    activeSiteId,
    activeSite,
    userPermissions: currentuserpermissions,
    jobItem: item || {},
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCurrentUserPermissions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
