import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Head from 'next/head';

const SliderPictures = ({ pictures }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 250,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <Head>
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="/static/react-slick-1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="/static/react-slick-1.6.0/slick-theme.min.css" />
      </Head>
      <Slider {...settings} className="asset-pictures-slider">
        {pictures &&
          pictures.map((picture, index) => (
            <div className="slider-item" key={index}>
              <img src={picture.url} />
            </div>
          ))}
      </Slider>
    </div>
  );
};

const SinglePicture = ({ pictures }) => (
  <div>
    <img src={pictures[0].url} height="120" />
  </div>
);

class PictureView extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    pictures: PropTypes.arrayOf(PropTypes.object),
    notAbsolute: PropTypes.bool,
  };

  render() {
    const { pictures, notAbsolute } = this.props;

    return (
      <div className={notAbsolute ? 'asset-picture-view-flexible' : 'asset-picture-view'}>
        {pictures && pictures.length > 1 && <SliderPictures pictures={pictures} />}
        {pictures && pictures.length === 1 && <SinglePicture pictures={pictures} />}
      </div>
    );
  }
}

export default PictureView;
