import { LOAD_CUSTOMER_ADDRESS_LIST } from '../../actions/types';

const INITIAL_STATE = {
  customerAddressList: [],
};

const internalAddress = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_CUSTOMER_ADDRESS_LIST:
      return { ...state, customerAddressList: action.payload };

    default:
      return state;
  }
};

export default internalAddress;
