import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import UppyFileUploader from '../../components/form/parts/UppyFileUploader';
import { setPictures, setRemoved, setCustomPictures } from '../../actions/cafm/AttachmentActions'; //1449

export class CustomFileUploader extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    initialPictures: PropTypes.arrayOf(PropTypes.shape()),
    pictures: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    removed: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    setPictures: PropTypes.func.isRequired,
    setRemoved: PropTypes.func.isRequired,
    name: PropTypes.string,
    maxNumberOfFiles: PropTypes.number,
  };

  constructor(props) {
    super(props);
    const { setPictures, setRemoved, initialPictures, setCustomPictures } = this.props;

    let pictures;

    if (!initialPictures || initialPictures.length == 0) {
      pictures = [];
    } else {
      pictures = initialPictures.map((obj) => ({
        type: 'preview',
        data: obj,
        isDefault: true,
      }));
    }
    setCustomPictures([], true);
    setRemoved([]);
  }

  static defaultProps = {
    initialPictures: [],
    name: 'imageLarge',
    maxNumberOfFiles: 1,
  };

  onAdd = (index, accepted) => {
    //once file selected and added in uppy
    if (!accepted) {
      return;
    }
    const { setCustomPictures } = this.props;

    let newParams = { ...accepted };
    delete accepted.uppyObject;

    const pictures = [
      {
        type: 'file',
        data: accepted,
        isDefault: true,
        uppyObject: newParams.uppyObject,
      },
    ];
    setCustomPictures(pictures);
  };

  onRemove = (index, id) => {
    let { pictures } = this.props;
    const { setCustomPictures } = this.props;
    pictures = pictures.filter((item, key) => item && item.data.id != id);
    setCustomPictures(pictures, true);
  };

  render() {
    const { t, pictures, name, maxNumberOfFiles, acceptedFileType } = this.props;
    return (
      <UppyFileUploader
        t={t}
        key={0}
        index={0}
        name={name} //"imageLarge"
        isDefault={false}
        onAdd={(index, accepted) => this.onAdd(index, accepted)}
        onRemove={(index, id) => this.onRemove(index, id)}
        preview={pictures && pictures.length > 0 && pictures[0].type == 'preview' ? pictures[0].data.imageLarge : ''}
        removeButton={pictures && pictures.length > 0 && pictures[0].type == 'preview' ? true : false}
        previewWidth={150}
        maxNumberOfFiles={maxNumberOfFiles}
        accept={'all'}
      />
    );
  }
}

const mapStateToProps = ({ attachmentUpload: { pictures, removed } }) => {
  return {
    pictures,
    removed,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPictures,
      setRemoved,
      setCustomPictures,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CustomFileUploader);
