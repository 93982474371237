import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { getSiteFullList } from '../../actions/cafm/SiteActions';
import { SelectDropdown } from '../form/parts';
import { getRolesListUserNotAdded } from '../../actions/account/RoleActions';

class AddUserToRoleModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleAddUser: PropTypes.func.isRequired,
    onModalClose: PropTypes.func.isRequired,
    canAddCafmRoles: PropTypes.bool.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    roles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    t: PropTypes.func.isRequired,
    getRolesListUserNotAdded: PropTypes.func,
    createForm: PropTypes.shape(),
  };

  static defaultProps = {
    createForm: {
      frmAddUserToRoleModal: {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      display: true,
      chosenName: null,
      chosenRole: null,
      chosenSite: null,
    };
  }

  componentDidMount() {
    if (this.props.canAddCafmRoles) {
      this.props.getSiteFullList();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
    const form = nextProps.createForm.frmAddUserToRoleModal;
    const prevForm = this.props.createForm.frmAddUserToRoleModal;
    if (prevForm && prevForm.values && form && form.values) {
      if (
        (prevForm.values.user && prevForm.values.user !== form.values.user) ||
        (!prevForm.values.user && form.values.user)
      ) {
        this.props.change('role', null);
      }
    }
  }

  onSelectUser = (option) => {
    const userId = option && option.value;
    if (userId) {
      this.props.getRolesListUserNotAdded(userId);
      this.setState({
        chosenName: option,
      });
    }
  };

  onSelectRole = (option) => {
    this.setState({
      chosenRole: option,
    });
  };

  onSelectSite = (option) => {
    this.setState({
      chosenSite: option,
    });
  };

  onUserAdd = () => {
    const { chosenName, chosenRole, chosenSite } = this.state;
    const userId = chosenName && chosenName.value;
    const roleId = chosenRole && chosenRole.value;
    const siteId = chosenSite && chosenSite.value;

    this.closeModal();
    if (userId && roleId) this.props.handleAddUser(userId, roleId, siteId);
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
    this.props.onModalClose();
  };

  render() {
    const { t, users, roles, sites, canAddCafmRoles, rolesListUserNotAdded } = this.props;
    const { display, chosenName } = this.state;
    let roleList = roles;
    if (chosenName && chosenName.value && rolesListUserNotAdded) roleList = rolesListUserNotAdded;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown over-y-auto
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show profile-modal add-user-modal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{t('Add user to role')}</h2>

          <div className="form-group">
            <label className="col-sm-4 control-label">{t('Choose user')}</label>

            <div className="col-sm-8">
              {users && users.map && (
                <Field
                  name="user"
                  component={SelectDropdown}
                  drpName="user"
                  options={users}
                  _optionLabel="fullName"
                  placeholder={t('Choose user')}
                  onChange={this.onSelectUser}
                />
              )}
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-4 control-label">{t('Choose role')}</label>

            <div className="col-sm-8">
              {roleList && roleList.map && (
                <Field
                  name="role"
                  component={SelectDropdown}
                  drpName="role"
                  options={roleList}
                  placeholder={t('Choose role')}
                  onChange={this.onSelectRole}
                />
              )}
            </div>
          </div>
          {canAddCafmRoles && (
            <div className="form-group">
              <label className="col-sm-4 control-label">{t('Choose site')}</label>

              <div className="col-sm-8">
                {roles && roles.map && (
                  <Field
                    name="site"
                    component={SelectDropdown}
                    drpName="site"
                    options={sites}
                    placeholder={t('Choose site')}
                    onChange={this.onSelectSite}
                  />
                )}
              </div>
            </div>
          )}

          <div className="swal2-buttonswrapper display-block">
            <button type="submit" className="swal2-confirm swal2-styled btn-primary" onClick={this.onUserAdd}>
              {t('Add User')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    site: { list },
    roles: { rolesListUserNotAdded },
    form,
  } = state;

  return {
    sites: list,
    rolesListUserNotAdded,
    createForm: form,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSiteFullList,
      getRolesListUserNotAdded,
    },
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'frmAddUserToRoleModal',
  })(AddUserToRoleModal),
);
