import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Table } from '../table';
import PictureView from './PictureView';
import { EditAssetModal } from '../../components/modals/cafm';

import { getManufacturerFullList } from '../../actions/cafm/ManufacturerActions';
import { getLocationFullList } from '../../actions/cafm/LocationActions';

const AssetSurveyReviewContainer = ({
  type,
  selectedAsset,
  getSelectedAsset,
  HEADERS,
  list,
  t,
  responseSurveyAsset,
  setSelectedAsset,
  assetsReviewList,
  manufacturers,
  locations,
  getManufacturerFullList,
  getLocationFullList,
  refreshCallback,
  onFeedbackCallback,
  readOnly,
}) => {
  const [comment, setComment] = useState('');
  const [updateModal, setUpdateModal] = useState({
    open: false,
    initialValues: {},
  });

  useEffect(() => {
    getManufacturerFullList();
    getLocationFullList({ siteId: selectedAsset?.Asset.siteId });
  }, [selectedAsset]);

  useEffect(() => {
    setComment('');
  }, [assetsReviewList]);

  const onApprove = async () => {
    await responseSurveyAsset({
      id: selectedAsset.id,
      review: 'approved',
    });
    setSelectedAsset(null);
    onFeedbackCallback();
  };

  const onReject = async () => {
    await responseSurveyAsset({
      id: selectedAsset.id,
      review: 'reviewed',
      comment,
    });
    setSelectedAsset(null);
    onFeedbackCallback();
  };

  const onIgnore = async () => {
    await responseSurveyAsset({
      id: selectedAsset.id,
      review: 'ignored',
      comment,
    });
    setSelectedAsset(null);
    onFeedbackCallback();
  };

  const openUpdateModal = () => {
    const formattedData = formatData(selectedAsset.Asset);
    setUpdateModal({
      open: true,
      initialValues: formattedData,
    });
  };

  const handleModalsClose = () => {
    setUpdateModal({
      open: false,
      initialValues: {},
    });
  };

  const formatData = (data) => {
    return {
      ...data,
      assetPictures: data.AssetPictures,
      locationId: {
        label: data?.Location?.name,
        value: data?.Location?.id,
      },
      condition: {
        label: data?.lookupvalues?.lookupvalue,
        value: data?.lookupvalues?.lookupvaluesid,
      },
      manufacturer: [
        {
          label: data?.Model?.Manufacturer?.name,
          value: data?.Model?.Manufacturer?.id,
        },
      ],
      model: [
        {
          label: data?.Model?.name,
          value: data?.Model?.id,
        },
      ],
    };
  };

  return (
    <>
      <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
        <Table
          headers={HEADERS}
          rows={list}
          showIntable={false}
          t={t}
          paginate={false}
          isCheckBoxOnEachRow
          getSelectedValue={getSelectedAsset}
        />
      </div>
      {selectedAsset != null && (
        <div className="row" style={{ marginLeft: '14px', marginTop: '20px' }}>
          <div className="panel panel-default col-lg-8">
            <div className="panel-body">
              <div className="row form-group">
                <div className="col-lg-11 col-md-11 col-sm-11">
                  <h3 className="box-title">General Info</h3>
                  <div className="table-responsive">
                    <table className="table table-striped table-product">
                      <tbody>
                        <tr>
                          <td width="390">Name</td>
                          <td>{selectedAsset.Asset.name}</td>
                        </tr>
                        <tr>
                          <td>Asset Number</td>
                          <td>Knock Down</td>
                        </tr>
                        <tr>
                          <td>Description</td>
                          <td>{selectedAsset.Asset.description ?? '-'}</td>
                        </tr>
                        <tr>
                          <td>Install Date</td>
                          <td>{selectedAsset.Asset.installDate}</td>
                        </tr>
                        <tr>
                          <td>Quantity</td>
                          <td>{selectedAsset.Asset.quantity}</td>
                        </tr>
                        <tr>
                          <td>Serial Number</td>
                          <td>{selectedAsset.Asset.serialNumber ?? '-'}</td>
                        </tr>
                        <tr>
                          <td>Condition</td>
                          <td>
                            {selectedAsset.Asset.lookupvalues != null
                              ? selectedAsset.Asset.lookupvalues.lookupvalue
                              : '-'}
                          </td>
                        </tr>
                        <tr>
                          <td>Location</td>
                          <td>{selectedAsset.locationName ?? '-'}</td>
                        </tr>
                        <tr>
                          <td>Manufacturer</td>
                          <td>{selectedAsset.manufacturerName ?? '-'}</td>
                        </tr>
                        <tr>
                          <td>Model</td>
                          <td>{selectedAsset.modelName ?? '-'}</td>
                        </tr>
                        <tr>
                          <td>Scanned Reference</td>
                          <td>{selectedAsset.Asset.tagResult ?? '-'}</td>
                        </tr>
                        {selectedAsset.Asset.AssetPictures.length != 0 && (
                          <tr>
                            <td>Images</td>
                            <td>
                              <PictureView pictures={selectedAsset.Asset.AssetPictures} t={t} notAbsolute={true} />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!readOnly && (
                  <div className="col-lg-1 col-md-1 col-sm-1">
                    <a className="btn btn-default" onClick={openUpdateModal}>
                      <i className="icon-pencil"></i> Edit
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
          {!readOnly && (
            <div className="col-lg-3" style={{ marginLeft: '6px' }}>
              <div className="form-group" style={{ marginTop: '16px' }}>
                <label htmlFor="commentInput">Additional Comment</label>
                <input
                  type="email"
                  className="form-control"
                  id="commentInput"
                  placeholder="Enter comment"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
              <button onClick={onApprove} type="button" className="btn btn-success">
                Approve
              </button>
              <button onClick={onReject} type="button" className="btn btn-warning" style={{ marginLeft: '10px' }}>
                Ask for Review
              </button>
              <button onClick={onIgnore} type="button" className="btn btn-warning" style={{ marginLeft: '10px' }}>
                Ignore
              </button>
            </div>
          )}
          {updateModal.open && (
            <EditAssetModal
              open={updateModal.open}
              initialValues={updateModal.initialValues}
              id={updateModal.initialValues.id}
              manufacturers={manufacturers}
              locations={locations}
              onModalClose={handleModalsClose}
              onUpdateCallback={refreshCallback}
              t={t}
            />
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    assetSurvey,
    manufacturer: { all },
    location,
  } = state;

  return {
    assetsReviewList: assetSurvey.assetsReviewList,
    manufacturers: all,
    locations: location.parent,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getManufacturerFullList,
      getLocationFullList,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AssetSurveyReviewContainer);
