import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';

import { updateTask } from '../../../actions/cafm/TaskActions';
import { showBodyScroll, hideBodyScroll } from '../../../lib/bodyScrollToggle';
import {
  getRepeatOptions,
  getPeriodOptions,
  getCustomDateList,
  getRepeatOtherOptions,
} from '../../../constants/options';
import { getTaskCategoryFullList } from '../../../actions/cafm/TaskCategoryActions';
import AddNewTaskCategoryModal from '../AddNewTaskCategoryModal';
import { TaskLibraryEntryView } from '../../cafm/admin';
import { getCustomizeParentChildFullList } from '../../../actions/cafm/TaskComplianceActions';

class EditTasksModal extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    initialValues: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    updateTask: PropTypes.func.isRequired,
    getTaskCategoryFullList: PropTypes.func.isRequired,
    getCustomizeParentChildFullList: PropTypes.func.isRequired,
  };

  static defaultProps = {
    taskCategories: [],
    taskCompliances: [],
  };

  constructor(props) {
    super(props);
    const taskCategoryNames = props.taskCategories;
    const repeatOptions = getRepeatOptions(props.t);
    const periodOptions = getPeriodOptions(props.t);

    const customDateList = getCustomDateList(props.t);
    const repeatOtherOptions = getRepeatOtherOptions(props.t);
    const { initialValues } = props;

    const repeatPeriodValue = initialValues?.repeatPeriod?.value ?? null;
    const isOtherRepeatPeriod =
      repeatPeriodValue != null && repeatOtherOptions.includes(repeatPeriodValue) ? true : false;
    const isCustomRepeatPeriod = repeatPeriodValue != null && repeatPeriodValue === 'Custom' ? true : false;

    this.state = {
      display: false,
      repeatOptions,
      periodOptions,
      taskCategoryNames,
      addNewTaskCategoryModal: {
        open: false,
        initialValues: {},
      },
      customDateList,
      repeatOtherOptions,
      isOtherRepeatPeriod: isOtherRepeatPeriod,
      isCustomRepeatPeriod: isCustomRepeatPeriod,
      customRepeatList: initialValues?.customRepeatList || [],
      oldCustomRepeatList: _.clone(initialValues.customRepeatList),
      isRPChange: false,
    };
    props.getTaskCategoryFullList();
    props.getCustomizeParentChildFullList();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      hideBodyScroll();
      this.setState({ display: true });
    }
    const taskCategoryNames = nextProps.taskCategories;
    this.setState({ taskCategoryNames });
  }

  closeModal = () => {
    this.setState({
      display: false,
      addNewTaskCategoryModal: {
        open: false,
      },
    });
    showBodyScroll();
    this.props.onModalClose();
  };

  TaskCategorycloseModal = () => {
    this.props.getTaskCategoryFullList();
    this.setState({
      // display: false,
      addNewTaskCategoryModal: {
        open: false,
        initialValues: {
          siteId: null,
        },
      },
    });
  };

  handleUpdateTask = (task) => {
    const { id } = this.props;

    const contentOnly = task && task.description ? task.description.replace(/<(?:.|\n)*?>/gm, '') : '';
    if (contentOnly != undefined && contentOnly.trim() == '') {
      task.description = '';
    }

    const newTask = _.pick(task, [
      'name',
      'description',
      'repeatEvery',
      'labourRequired',
      'isRegulatory',
      'majorSfg20',
      'minorSfg20',
    ]);
    newTask.id = id;
    const { repeatPeriod, labourPeriod, category, compliance } = task;
    newTask.customRepeatList = this.state.customRepeatList;
    newTask.repeatPeriod = (repeatPeriod && repeatPeriod.value) || '';
    newTask.labourPeriod = (labourPeriod && labourPeriod.value) || null;
    newTask.taskCategoryId = (category && category.value) || '';
    newTask.taskComplianceId = (compliance && compliance.value) || null;
    this.props.updateTask(newTask);
    this.closeModal();
  };

  handleAddNewTaskCategory = () => {
    this.setState({
      addNewTaskCategoryModal: {
        open: true,
      },
    });
  };

  handleCustomRepeatList = (value) => {
    const checkIfSelected = this.state.customRepeatList.filter((e) => e.id === value.id);
    const newRPList =
      checkIfSelected.length > 0
        ? this.state.customRepeatList.filter((e) => e.id !== value.id)
        : this.state.customRepeatList.concat(value);
    this.setState({
      customRepeatList: newRPList,
    });
  };

  handleRepeatPeriod = (e) => {
    let isRPChange = false;
    if (e.value != this.state.oldRpValue) {
      isRPChange = true;
    }
    this.setState({
      isOtherRepeatPeriod: this.state.repeatOtherOptions.includes(e.value) ? true : false,
      isCustomRepeatPeriod: e.value === 'Custom' ? true : false,
      isRPChange,
    });
  };

  render() {
    const { handleSubmit, t, taskCompliances } = this.props;
    const {
      display,
      repeatOptions,
      periodOptions,
      taskCategoryNames,
      addNewTaskCategoryModal,
      customDateList,
      repeatOtherOptions,
      isCustomRepeatPeriod,
      customRepeatList,
      isOtherRepeatPeriod,
    } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                  ${!display && 'display-none'}`}
        // onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show modal-template form-horizontal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{t('Update task')}</h2>

          <form>
            <TaskLibraryEntryView
              t={t}
              args={{
                isAPTAddNewTask: false,
                repeatOptionList: repeatOptions,
                periodOptionList: periodOptions,
                isTaskCategoryShow: true,
                taskCategoryList: taskCategoryNames,
                onClickAddNewTaskCategory: this.handleAddNewTaskCategory,
                taskCompliancesList: taskCompliances,
                customDateList,
                repeatOtherOptions,
                isCustomRepeatPeriod,
                customRepeatList,
                isOtherRepeatPeriod,
                handleCustomRepeatList: this.handleCustomRepeatList,
                handleRepeatPeriod: this.handleRepeatPeriod,
              }}
            />

            <div className="form-group">
              <div className="swal2-buttonswrapper display-block">
                <button
                  type="submit"
                  className="swal2-confirm swal2-styled btn-primary"
                  onClick={handleSubmit(this.handleUpdateTask)}
                >
                  {t('Update')}
                </button>
                &nbsp;
                <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
                  {t('Cancel')}
                </button>
              </div>
            </div>
          </form>
        </div>
        {addNewTaskCategoryModal.open && (
          <AddNewTaskCategoryModal
            open={addNewTaskCategoryModal.open}
            onModalClose={this.TaskCategorycloseModal}
            initialValues={addNewTaskCategoryModal.initialValues}
            t={t}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues,
  taskCategories: state.taskCategory && state.taskCategory.all,
  taskCompliances: (state.taskCompliance && state.taskCompliance.allCustomizeParentChild) || [], //parent disabled && child enable for add/edit page dropdown
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateTask,
      getTaskCategoryFullList,
      getCustomizeParentChildFullList,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'editCategoryGroupForm',
    enableReinitialize: true,
  })(EditTasksModal),
);
