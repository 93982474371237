import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import PickerView from './PickerView';
import DateInput from '../input/DateInput';

import { MONTH_LIST } from '../../constants/pickerdata';

class MonthPicker extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    changeDate: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      date: PropTypes.string,
    }),
  };

  static defaultProps = {
    placeholder: 'Click here to pick date',
    initialValues: null,
  };

  constructor(props) {
    super(props);

    const initialData = this.getInitialData(props.initialValues);

    const initialState = {
      ...initialData,
      isShown: false,
    };
    this.state = initialState;

    moment.locale('en');
  }

  getInitialData = (initialValues) => {
    let initialData = {};
    if (initialValues && initialValues.date) {
      const { date } = initialValues;
      const chosenDate = moment(date, 'YYYY-MM-DD');
      initialData = {
        chosen: {
          year: chosenDate.year(),
          month: MONTH_LIST[chosenDate.month()],
          mNumber: chosenDate.month(),
        },
        date,
      };
    } else {
      initialData = {
        chosen: {
          year: null,
          month: null,
          mNumber: null,
        },
        date: null,
      };
    }
    return initialData;
  };

  handleMonthClick = (year, month, mNumber) => {
    const date = moment({ year, month: mNumber });
    const strDate = date.format('YYYY-MM-DD');

    this.setState({
      chosen: {
        year,
        month,
        mNumber,
      },
      date: strDate,
      isShown: false,
    });

    this.props.changeDate(strDate);
  };

  openPicker = () => {
    this.setState({ isShown: true });
  };

  handleOnMouseLeave = () => {
    this.setState({ isShown: false });
  };

  render() {
    const { chosen, date, isShown } = this.state;
    const startDate = {
      year: 2018,
      mNumber: 3,
    };

    return (
      <div className="single-date-picker" onMouseLeave={this.handleOnMouseLeave}>
        <DateInput placeholder={this.props.placeholder} value={date} onClick={this.openPicker} />

        {isShown && <PickerView chosen={chosen} startDate={startDate} onMonthClick={this.handleMonthClick} />}
      </div>
    );
  }
}

export default MonthPicker;
