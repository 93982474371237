import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ApproverForm from '../form/cafm/ApproverForm';

import { bulkUpdateSiteData } from '../../actions/bms/BuildingActions';
import { getTaskCommentApproverUserListbyAccountId } from '../../actions/UserActions';

class BulkDataUpdateModal extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    createForm: PropTypes.shape(),
    initialValues: PropTypes.shape().isRequired,
    onModalClose: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape()),
    getTaskCommentApproverUserListbyAccountId: PropTypes.func,
    bulkUpdateSiteData: PropTypes.func,
  };

  static defaultProps = {
    createForm: {
      bulkDataUpdateForm: {},
      users: [],
      getTaskCommentApproverUserListbyAccountId: undefined,
      bulkUpdateSiteData: undefined,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
    if (props.accessToken) {
      props.getTaskCommentApproverUserListbyAccountId();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
    const form = nextProps.createForm.bulkDataUpdateForm;
    if (form.values) {
      if (form.submitSucceeded) {
        this.props.onModalClose();
      }
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    this.props.onModalClose();
  };

  handleFormSubmit = (values) => {
    const { approver } = values;
    const { initialValues, currentUser } = this.props;
    const { flag, siteIds } = initialValues;
    let tmpParams;
    const tmpJsonData = [];

    if (flag === 'site') {
      if (approver) {
        siteIds.map((id) => {
          approver.map((data) => {
            tmpJsonData.push({
              siteId: id,
              approverId: data.value, // approverId,
              createdBy: currentUser.id,
              updatedBy: currentUser.id,
            });
          });
        });
      }
      tmpParams = {
        entity: flag,
        ids: siteIds,
        bulkData: [{ key: 'approver', value: tmpJsonData }],
      };
      this.props.bulkUpdateSiteData(tmpParams);
    }
  };

  render() {
    const { handleSubmit, t, title, users, initialValues } = this.props;
    const { display } = this.state;
    const { flag } = initialValues;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                        ${!display && 'display-none'}`}
      >
        <div aria-modal="true" className="swal2-modal swal2-show modal-template form-horizontal">
          <h2 className="swal2-title">{title}</h2>

          <form>{flag === 'site' && <ApproverForm t={t} approverUsersList={users} approver={[]} />}</form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleFormSubmit)}
            >
              {t('Save')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    tokens: { accessToken },
    form,
    user,
  } = state;

  return {
    accessToken,
    createForm: form,
    initialValues: props.initialValues,
    users: user.list,
    currentUser: user.currentuser,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTaskCommentApproverUserListbyAccountId,
      bulkUpdateSiteData,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'bulkDataUpdateForm',
  })(BulkDataUpdateModal),
);
