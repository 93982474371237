import { SET_PICTURES_ASSET_PICTURES, SET_REMOVED_ASSET_PICTURES } from '../../actions/types';

const INITIAL_STATE = {
  pictures: [],
  removed: [],
  assetId: null,
};

const assetPictures = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PICTURES_ASSET_PICTURES:
    case SET_REMOVED_ASSET_PICTURES:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default assetPictures;
