import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import ErrorModal from '../../components/modals/ErrorModal';
import commonFunction from '../../lib/commonFunction';

import {
  hideErrorModal,
  deleteTaskCategoryWithUpdateTasksLibrary,
  deleteParentChildData,
} from '../../actions/ErrorActions';
import { showBodyScroll, hideBodyScroll } from '../../lib/bodyScrollToggle';
import * as Constants from '../../constants/tabledata/error/cafm';
import { Router } from '../../../common/routes';

class ErrorModalContainer extends React.PureComponent {
  static propTypes = {
    isShown: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    errorList: PropTypes.arrayOf(
      PropTypes.shape({
        param: PropTypes.string,
        message: PropTypes.string,
        entityType: PropTypes.string,
        entityList: PropTypes.arrayOf(PropTypes.shape()),
        selectionList: PropTypes.arrayOf(PropTypes.shape()),
        isShowOkCancel: PropTypes.bool,
      }),
    ),
    hideErrorModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    deleteTaskCategoryWithUpdateTasksLibrary: PropTypes.func,
    deleteParentChildData: PropTypes.func,
  };

  static defaultProps = {
    errorList: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      tableScale: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isShown) {
      hideBodyScroll();
    }

    const showEntityList = this.isNeedToShowEntityList(nextProps.errorList);
    if (showEntityList) {
      const tableData = this.getErrorTableData(nextProps.errorList);
      const isDropdownToShow = this.isNeedToShowDropdownList(nextProps.errorList);
      this.setState({
        tableData,
        tableScale: true,
        selectionData: isDropdownToShow ? this.getSelectionDataForDropdown(nextProps.errorList) : [],
        isDropdownToShow,

        isShowOkCancel: this.isOkCancelToShow(nextProps.errorList),
      });
    } else {
      this.setState({
        tableData: [],
        tableScale: false,
        selectionData: [],
        isDropdownToShow: false,
        isShowOkCancel: false,
      });
    }
  }

  getErrorTableData = (errorList) => {
    const tableData = [];
    const tmpJobs = [];
    const tmpScheduleTasks = [];
    errorList.forEach &&
      errorList.forEach((item) => {
        const type = item.entityType;
        if (type) {
          switch (type) {
            case 'maintenanceSchedule':
              _.map(item.entityList, (data) => {
                tmpJobs.push({
                  id: data.maintenanceScheduleId,
                  name: data.MaintenanceSchedule.name,
                  assetName: data.Asset && data.Asset.length > 0 ? data.Asset[0].name : '',
                  taskLibraryName: data.TaskLibrary.name,
                  supplierCompanyName:
                    data.SupplierCompany && data.SupplierCompany.length > 0 ? data.SupplierCompany[0].name : '',
                  supplierContactName:
                    data.SupplierContact && data.SupplierContact.length > 0
                      ? data.SupplierContact[0].fullName
                      : data.userAssignee && data.userAssignee.length > 0
                      ? data.userAssignee[0].fullName
                      : '',
                });
              });

              tableData.push({
                headers: Constants.MAINTENANCE_SCHEDULE,
                rows: tmpJobs,
              });
              break;
            case 'asset':
              tableData.push({
                headers: Constants.ASSET,
                rows: item.entityList.map((entity) => _.pick(entity, ['id', 'name', 'description'])),
              });
              break;
            case 'taskCategory':
              tableData.push({
                headers: Constants.TASK_CATEGORY,
                rows: item.entityList.map((entity) => _.pick(entity, ['id', 'name', 'description'])),
              });
              break;
            case 'location':
              tableData.push({
                headers: Constants.LOCATION,
                rows: item.entityList.map((entity) => _.pick(entity, ['id', 'name', 'type', 'ParentLocation.name'])),
              });
              break;
            case 'taskLibrary':
              tableData.push({
                headers: Constants.TASK,
                rows: item.entityList.map((entity) => _.pick(entity, ['id', 'name', 'description'])),
              });
              break;
            case 'job':
            case 'scheduleTaskJob':
              _.map(item.entityList, (data) => {
                tmpJobs.push({
                  id: data.id,
                  dueDate: data.dueDate,
                  visitDate: data.visitDate,
                  statusName: data.Status.name,
                });
              });

              tableData.push({
                headers: Constants.PPM_JOB,
                rows: tmpJobs,
              });
              break;
            case 'model':
              tableData.push({
                headers: Constants.MODEL,
                rows: item.entityList.map((entity) => _.pick(entity, ['id', 'name', 'description'])),
              });
              break;
            case 'problemTopic':
              tableData.push({
                headers: Constants.PROBLEM_TOPIC,
                rows: item.entityList.map((entity) => _.pick(entity, ['id', 'name', 'description'])),
              });
              break;
            case 'taskLibraryWithMaintenance':
              tableData.push({
                headers: Constants.TASK_LINKED_MAINTENANCE,
                rows: item.entityList.map((entity) =>
                  _.pick(entity, [
                    'id',
                    'name',
                    'repeatEveryPeriod',
                    'assetName',
                    'locationName',
                    'supplierCompanyName',
                    'supplierContactName',
                    'active',
                    'firstTaskDate',
                    'lastTaskDate',
                  ]),
                ),
              });
              break;
            case 'childLocation':
              _.map(item.entityList, (data) => {
                tmpJobs.push({
                  id: data.id,
                  name: data.name,
                  type: data.type === 1000 ? 'Roof' : data.type,
                  locationName: data.ParentLocation && data.ParentLocation.name,
                  inactiveLocation: data.disabled ? 'yes' : 'no',
                });
              });
              tableData.push({
                headers: Constants.LOCATION,
                rows: tmpJobs,
              });
              break;
            case 'childTaskCompliance':
              _.map(item.entityList, (data) => {
                tmpJobs.push({
                  id: data.id,
                  name: data.name,
                  parentCompliance: data.ParentTaskCompliance && data.ParentTaskCompliance.name,
                });
              });
              tableData.push({
                headers: Constants.TASK_COMPLIANCES,
                rows: tmpJobs,
              });
              break;
            case 'complianceLinkedTasks':
              _.map(item.entityList, (data) => {
                tmpJobs.push({
                  id: data.id,
                  name: data.name,
                  description: data.description,
                  sfg20Title: data.majorSfg20 ? `${data.majorSfg20}-${data.minorSfg20}` : undefined,
                });
              });
              tableData.push({
                headers: Constants.TASK,
                rows: tmpJobs,
              });
              break;
            case 'maintenanceLinkedScheduleTasks':
              _.map(item.entityList, (data) => {
                tmpScheduleTasks.push({
                  id: data.id,
                  taskLibraryName: data.TaskLibrary.name,
                  repeat: data.overrideRepeatFrequency
                    ? `${data.repeatEvery} ${data.repeatPeriod}`
                    : data.TaskLibrary
                    ? `${data.TaskLibrary.repeatEvery} ${data.TaskLibrary.repeatPeriod}`
                    : '',
                  labour:
                    data.overrideLabourFrequency && data.labourRequired
                      ? `${data.labourRequired} ${data.labourPeriod}`.labourRequired
                      : data.TaskLibrary && data.TaskLibrary.labourRequired
                      ? `${data.TaskLibrary.labourRequired} ${data.TaskLibrary.labourPeriod}`
                      : '',
                  supplierCompanyName:
                    data.SupplierCompany && data.SupplierCompany.length > 0 ? data.SupplierCompany[0].name : '',
                  supplierContactName:
                    data.SupplierContact && data.SupplierContact.length > 0
                      ? data.SupplierContact[0].fullName
                      : data.userAssignee && data.userAssignee.length > 0
                      ? data.userAssignee[0].fullName
                      : '',
                  haveSubcontract: data.subcontract ? 'yes' : 'no',
                  active: data.enabled ? 'yes' : 'no',
                  lastTaskDate: commonFunction.displayDateWithValidFormat(data.lastTaskDate),
                });
              });

              tableData.push({
                headers: Constants.MAINTENANCE_LINKED_SCHEDULE_TASKS,
                rows: tmpScheduleTasks,
              });
              break;
            case 'taskLibraryLinkedScheduleTasks':
              _.map(item.entityList, (data) => {
                tmpScheduleTasks.push({
                  id: data.id,
                  name: data.MaintenanceSchedule.name, // core.maintenanceScheduleName
                  siteName: data.Site.name, // core.site.name
                  assetName: data.Asset && data.Asset.length > 0 ? data.Asset[0].name : '',
                  locationName:
                    data.Asset && data.Asset.length > 0 && data.Asset[0].Location
                      ? data.Asset[0].Location.name
                      : data.location
                      ? data.location.name
                      : '',
                  taskLibraryName: data.TaskLibrary.name,
                  supplierCompanyName:
                    data.SupplierCompany && data.SupplierCompany.length > 0 ? data.SupplierCompany[0].name : '',
                  supplierContactName:
                    data.SupplierContact && data.SupplierContact.length > 0
                      ? data.SupplierContact[0].fullName
                      : data.userAssignee && data.userAssignee.length > 0
                      ? data.userAssignee[0].fullName
                      : '',
                });
              });

              tableData.push({
                headers: Constants.TASK_LIBRARY_LINKED_SCHEDULE_TASKS,
                rows: tmpScheduleTasks,
              });
              break;
            default:
              tableData.push({});
          }
        } else {
          tableData.push({});
        }
      });
    return tableData;
  };

  isNeedToShowEntityList = (errorList) => {
    let show = false;
    errorList &&
      errorList.forEach((item) => {
        if (item.entityType && item.entityList) {
          show = true;
        }
      });
    return show;
  };

  isNeedToShowDropdownList = (errorList) => {
    let show = false;
    errorList &&
      errorList.forEach((item) => {
        if (item.entityType && item.entityList && item.selectionList) {
          show = true;
        }
      });
    return show;
  };

  getSelectionDataForDropdown = (errorList) => {
    const selectionData = [];
    errorList.forEach &&
      errorList.forEach((item) => {
        if (item.entityType && item.entityList && item.selectionList) {
          selectionData.push({
            rows: item.selectionList.map((data) => ({
              id: data.id,
              name: data.name,
              isDisabled: data.disabled,
            })),
          });
        }
      });
    return selectionData;
  };

  handleSubmitClick = (values) => {
    showBodyScroll();
    const { errorList } = this.props;
    const tmpEntityIds = [];
    const params = {
      reassignId: values && values.id ? values.id.value : 0, // its now react-select dropdown. 'id' dropdown name
    };
    errorList.forEach((item) => {
      if (item.entityType) {
        params.sourceId = item.sourceId;
        params.sourceUuid = item.sourceUuid;
        params.entityType = item.entityType;
        if (item.entityList) {
          item.entityList.map((data) => {
            tmpEntityIds.push(data.id);
          });
        }
        params.tmpEntityIds = tmpEntityIds;
        switch (item.entityType) {
          case 'taskLibraryWithMaintenance':
            this.props.deleteTaskCategoryWithUpdateTasksLibrary(params);
            break;
          case 'model':
          case 'job':
          case 'childLocation':
          case 'childTaskCompliance':
          case 'complianceLinkedTasks':
          case 'scheduleTaskJob':
            this.props.deleteParentChildData(params);
            break;
          default:
            break;
        }
      }
    });
    this.props.hideErrorModal();
  };

  handleClose = () => {
    showBodyScroll();
    this.props.hideErrorModal();
    const { errorList } = this.props;
    errorList.forEach((item) => {
      if ((item.param === 'id' || item.param === 'uuid') && item.message.slice(-9) === 'not found') {
        Router.pushRoute('main');
      }
    });
  };

  isOkCancelToShow = (errorList) => {
    let show = false;
    errorList &&
      errorList.forEach((item) => {
        if (item.entityType && item.entityList && item.isShowOkCancel) {
          show = true;
        }
      });
    return show;
  };

  render() {
    const { errorList, isShown, t, handleSubmit, title } = this.props;
    const { tableData, tableScale, selectionData, isDropdownToShow, isShowOkCancel } = this.state;
    return (
      <ErrorModal
        errorList={errorList}
        isShown={isShown}
        closeModal={this.handleClose}
        tableData={tableData}
        tableScale={tableScale}
        t={t}
        isDropdownToShow={isDropdownToShow}
        selectionData={selectionData}
        onSubmitClick={handleSubmit(this.handleSubmitClick)}
        isShowOkCancel={isShowOkCancel}
        title={title}
      />
    );
  }
}

const mapStateToProps = ({ errors: { isShown, errorList, title } }) => ({ isShown, errorList, title });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideErrorModal,
      deleteTaskCategoryWithUpdateTasksLibrary,
      deleteParentChildData,
    },
    dispatch,
  );

// export default connect(mapStateToProps, mapDispatchToProps)(ErrorModalContainer);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'errorModalContainerFrom',
  })(ErrorModalContainer),
);
