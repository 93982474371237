import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { updateThreshold } from '../../actions/bms/ThresholdActions';
import { FormInput, ReduxFormSelect } from '../form/parts';

const AMOUNT_TYPES = ['amount', 'percentage'];

class EditThresholdModal extends Component {
  static propTypes = {
    uuid: PropTypes.string.isRequired,
    initialValues: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    updateThreshold: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const TRANSLATED_TYPES = AMOUNT_TYPES.map((type) => props.t(`${type}`));
    this.state = {
      display: false,
      translatedTypes: TRANSLATED_TYPES,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    this.props.onModalClose();
  };

  handleUpdateThreshold = (threshold) => {
    const { uuid } = this.props;
    const newthreshold = {
      uuid,
      amountSize: threshold.amountSize,
      amountType: threshold.amountType === 'amount',
    };
    this.props.updateThreshold(newthreshold);
    this.closeModal();
  };

  render() {
    const { handleSubmit, t } = this.props;
    const { display, translatedTypes } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown over-y-auto
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show profile-modal edit-threshold-modal form-horizontal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{t('Update threshold')}</h2>

          <form>
            <div className="form-group">
              <label className="col-sm-4 control-label">{t('Amount type')}</label>

              <div className="col-sm-8">
                <Field
                  name="amountType"
                  component={ReduxFormSelect}
                  options={translatedTypes}
                  placeholder={t('Choose amount type')}
                />
              </div>
            </div>

            <Field
              label={t('Amount size')}
              component={FormInput}
              name="amountSize"
              type="text"
              placeholder={t('Enter amount size')}
            />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleUpdateThreshold)}
            >
              {t('Update')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateThreshold,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'editThresholdForm',
    enableReinitialize: true,
  })(EditThresholdModal),
);
