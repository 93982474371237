import {
  LOAD_ACCOUNT,
  LOAD_ALL_EMAIL_TEMPLATES,
  LOAD_EMAIL_TEMPLATE,
  LOAD_EMAIL_TEMPLATES_COUNT,
} from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  list: [],
  emailTemplateItem: null,
};

const account = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_ACCOUNT:
      return { ...state, item: action.payload };
    case LOAD_ALL_EMAIL_TEMPLATES:
      return { ...state, list: action.payload };
    case LOAD_EMAIL_TEMPLATES_COUNT:
      return { ...state, count: action.payload };
    case LOAD_EMAIL_TEMPLATE:
      return { ...state, emailTemplateItem: action.payload };

    default:
      return state;
  }
};

export default account;
