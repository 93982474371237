import {
  LOAD_SCHEDULE_ASSET_GROUP,
  LOAD_SCHEDULE_ASSET_GROUPS,
  LOAD_ALL_SCHEDULE_ASSET_GROUPS,
  LOAD_SCHEDULE_ASSET_GROUP_COUNT,
  SHOW_LOADER,
  HIDE_LOADER,
} from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/scheduleAssetGroup';

const adjustScheduleAssetGroup = (assetGroup) => {
  if (assetGroup.AssetGroup) {
    assetGroup.assetGroupName = assetGroup.AssetGroup.name;
  } else {
    assetGroup.assetGroupName = null;
  }
};

export const loadScheduleAssetGroup = (item) => ({
  type: LOAD_SCHEDULE_ASSET_GROUP,
  payload: item,
});

export const loadScheduleAssetGroups = (items) => ({
  type: LOAD_SCHEDULE_ASSET_GROUPS,
  payload: items,
});

export const loadAllScheduleAssetGroups = (items) => ({
  type: LOAD_ALL_SCHEDULE_ASSET_GROUPS,
  payload: items,
});

export const loadCount = (count) => ({
  type: LOAD_SCHEDULE_ASSET_GROUP_COUNT,
  payload: count,
});

export const bulkCreateScheduleAssetGroup = (body) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  return api
    .post(`${routePath}/bulk-create`, body)
    .then((response) => {
      const result = response.data;
      result.forEach(adjustScheduleAssetGroup);
      dispatch(loadScheduleAssetGroups(result));
      dispatch(loadCount(response.data.length));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, bulkCreateScheduleAssetGroup, body);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const createScheduleAssetGroup = (body) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  return api
    .post(`${routePath}/create`, body)
    .then((response) => {
      adjustScheduleAssetGroup(response.data);
      dispatch(loadScheduleAssetGroup(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createScheduleAssetGroup, body);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateScheduleAssetGroup = (body) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  return api
    .put(`${routePath}/update`, body)
    .then((response) => {
      const result = response.data;
      adjustScheduleAssetGroup(result);
      dispatch(loadScheduleAssetGroup(result));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateScheduleAssetGroup, body);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getScheduleAssetGroup = (id) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-item/${id}`)
    .then((response) => {
      const result = response.data;
      adjustScheduleAssetGroup(result);
      dispatch(loadScheduleAssetGroup(result));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getScheduleAssetGroup, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteScheduleAssetGroup = (id, path) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${id}`)
    .then(() => {
      const item = {
        id,
        assetGroupName: null,
      };
      dispatch(loadScheduleAssetGroup(item));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteScheduleAssetGroup, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getScheduleAssetGroupList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      const result = response.data;
      result.forEach(adjustScheduleAssetGroup);
      dispatch(loadScheduleAssetGroups(result));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getScheduleAssetGroupList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getScheduleAssetGroupFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-full-list`, { params })
    .then((response) => {
      const result = response.data;
      result.forEach(adjustScheduleAssetGroup);
      dispatch(loadAllScheduleAssetGroups(result));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getScheduleAssetGroupFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getScheduleAssetGroupListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list-with-size`, { params })
    .then((response) => {
      const result = response.data.rows;
      result.forEach(adjustScheduleAssetGroup);
      dispatch(loadScheduleAssetGroups(result));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getScheduleAssetGroupListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
