import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';

// import { updateBuilding } from '../../actions/bms/BuildingActions';
// import QuickAddNewPPM from './';
import AddNewLocationModal from './AddNewLocationModal';
// import addNewTaskCategoryModal from './AddNewTaskCategoryModal';
import AddNewAssetModal from './AddNewAssetModal';
import AddNewTaskModal from './AddNewTaskModal';
import { getLocationFullList } from '../../actions/cafm/LocationActions';
import { getTaskCategoryFullList } from '../../actions/cafm/TaskCategoryActions';
import { getAssetFullList } from '../../actions/cafm/AssetActions';
import { getTaskFullList } from '../../actions/cafm/TaskActions';
import { getInternalCompanyFullList } from '../../actions/cafm/InternalCompanyActions';
import { getSiteFullList } from '../../actions/cafm/SiteActions';
import { getCompanyContactList } from '../../actions/oms/InternalContactActions';
import { getRepeatOptions, getPeriodOptions, getRepeatOtherOptions, getCustomDateList } from '../../constants/options';
import { createSchedule } from '../../actions/cafm/ScheduleActions';
import AddNewTaskCategoryModal from './AddNewTaskCategoryModal';
import { getFullUserListbyAccountId } from '../../actions/UserActions';
import MaintenanceScheduleEntryView from '../cafm/admin/MaintenanceScheduleEntryView';
import commonFunction from '../../lib/commonFunction';

class QuickAddNewPPMModal extends Component {
  static propTypes = {
    initialValues: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    createSchedule: PropTypes.func.isRequired,
    siteList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    locations: PropTypes.arrayOf(PropTypes.shape()),
    // location: PropTypes.arrayOf(PropTypes.shape()),
    taskCategories: PropTypes.arrayOf(PropTypes.shape()),
    assets: PropTypes.arrayOf(PropTypes.shape()),
    tasks: PropTypes.arrayOf(PropTypes.shape()),
    internalCompanies: PropTypes.arrayOf(PropTypes.shape()),
    internalContact: PropTypes.arrayOf(PropTypes.shape()),
    createForm: PropTypes.shape(),
    getFullUserListbyAccountId: PropTypes.func,
    users: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    updateBuilding: undefined,
    siteList: [],
    locations: [],
    taskCategories: [],
    assets: [],
    tasks: [],
    internalCompanies: [],
    internalContact: [],
    createForm: {
      quickAddNewPPMForm: {},
    },
    getFullUserListbyAccountId: undefined,
    users: [],
  };

  constructor(props) {
    super(props);
    const repeatOptions = getRepeatOptions(props.t);
    const periodOptions = getPeriodOptions(props.t);
    const repeatOtherOptions = getRepeatOtherOptions(props.t);
    const customDateList = getCustomDateList(props.t);

    this.state = {
      isSiteChangeExecute: true,
      display: false,
      repeatOptions,
      contacts: [],
      isSimpleView: 'true',
      repeatEvery: '',
      repeatPeriod: '',
      isOtherRepeatPeriod: false,
      isCustomRepeatPeriod: false,
      customRepeatList: [],
      repeatOtherOptions,
      customDateList,
      siteId: props.initialValues && props.initialValues.Site ? 1 : 0,
      categoryId: 0,
      taskId: 0,
      assetId: 0,
      locationId: 0,
      overrideRepeatFrequency: false,
      addNewLocationModal: {
        open: false,
        initialValues: {},
      },
      addNewTaskCategoryModal: {
        open: false,
        initialValues: {},
      },
      addNewAssetModal: {
        open: false,
        initialValues: {},
      },
      addNewTaskModal: {
        open: false,
        initialValues: {},
      },
      isLastTaskDisable: !!(props.initialValues && props.initialValues.lastTask && props.initialValues.lastTask != ''),
      periodOptions,
      overrideLabourFrequency: false,
      labourRequired: '',
      labourPeriod: '',
      overrideTaskDescription: false,
    };
    props.getTaskCategoryFullList();
    this.props.getInternalCompanyFullList();
    props.getSiteFullList();
    props.getFullUserListbyAccountId();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
    const { isSiteChangeExecute } = this.state;
    let chosenCategorieid = 0;
    const form = nextProps.createForm.quickAddNewPPMForm;
    const prevForm = this.props.createForm.quickAddNewPPMForm;
    // Site Profile > Add Planned Task (to execute asset/location dropdownbind)
    if (prevForm && prevForm.values && form && form.values) {
      if (
        isSiteChangeExecute &&
        form.values.Site == prevForm.values.Site &&
        nextProps.siteList &&
        Object.keys(nextProps.siteList).length > 0
      ) {
        this.handleChangeSite(form.values.Site);
      }
    }
    if (form.values && form.values.Category != undefined && form.values.Category != '') {
      chosenCategorieid = form.values.Category.value;
      this.setState({
        categoryId: chosenCategorieid == undefined ? 0 : chosenCategorieid,
      });
      if (
        (prevForm.values.Category && prevForm.values.Category !== form.values.Category) ||
        (!prevForm.values.Category && form.values.Category)
      ) {
        form.values.TaskName = '';
        this.setState({
          repeatEvery: undefined,
          repeatPeriod: undefined,
          taskId: 0,
          labourRequired: undefined,
          labourPeriod: undefined,
        });
      } else if (
        nextProps.tasks &&
        Object.keys(nextProps.tasks).length > 0 &&
        form.values.Category.value !== nextProps.tasks[0].taskCategoryId
      ) {
        form.values.TaskName = '';
        this.setState({
          repeatEvery: undefined,
          repeatPeriod: undefined,
          labourRequired: undefined,
          labourPeriod: undefined,
          taskId: 0,
        });
        this.handleChangeToBindAsset(form.values.Category);
      }
      // this.handleChangeToBindAsset();
    } else {
      form.values.TaskName = '';
      this.setState({
        repeatEvery: undefined,
        repeatPeriod: undefined,
        labourRequired: undefined,
        labourPeriod: undefined,
        taskId: 0,
      });
    }

    if (form.values && form.values.TaskName != undefined && form.values.TaskName != '') {
      const chosenTask = this.props.tasks.find((task) => task.id === form.values.TaskName.value);
      // this.props.createForm.quickAddNewPPMForm.values['repeatEvery']=chosenTask && chosenTask.repeatEvery;
      // this.props.createForm.quickAddNewPPMForm.values['repeatPeriod']=chosenTask && chosenTask.repeatPeriod;
      this.setState({
        repeatEvery: chosenTask && chosenTask.repeatEvery,
        repeatPeriod: chosenTask && chosenTask.repeatPeriod,
        taskId: chosenTask && chosenTask.id,
        locationId: 0,
        assetId: 0,
        labourRequired: chosenTask && chosenTask.labourRequired,
        labourPeriod: chosenTask && chosenTask.labourPeriod,
      });

      if (
        prevForm &&
        prevForm.values &&
        (prevForm.values?.TaskName?.value !== form.values?.TaskName?.value ||
          (!prevForm.values.TaskName && form.values.TaskName))
      ) {
        form.values.taskDescription = chosenTask && chosenTask.description;
      }
    }
    if (
      form.values &&
      ((form.values.location != undefined && form.values.location != '') ||
        (form.values.asset != undefined && form.values.asset != ''))
    ) {
      this.handleChangeAssetLocation();
    }
    let subcontract = null;
    if (prevForm && prevForm.values && form && form.values) {
      if (
        (prevForm.values.company && prevForm.values.company !== form.values.company) ||
        (!prevForm.values.company && form.values.company)
      ) {
        if (form.values.company) {
          const chosenCompany = _.find(this.props.internalCompanies, ['id', form.values.company.value]);
          if (chosenCompany && chosenCompany.InternalContact) {
            const contacts = chosenCompany.InternalContact;
            this.props.change('contact', null);
            this.setState({
              contacts,
            });
          }
        } else {
          this.props.change('contact', null);
          this.setState({
            contacts: [],
          });
        }
      }
    }

    if (form && form.values && (form.values.company || form.values.userAssignee)) {
      if (form.values.company && !form.values.userAssignee) subcontract = true;
      else if (!form.values.company && form.values.userAssignee) subcontract = false;
    }
    if (!subcontract) {
      this.props.change('notifySubcontractor', false);
    }
    let overrideRepeatFrequency = false;
    if (form.values && form.values.overrideRepeatFrequency) {
      overrideRepeatFrequency = true;
    }
    let overrideLabourFrequency = false;
    if (form.values && form.values.overrideLabourFrequency) {
      overrideLabourFrequency = true;
    }
    let overrideTaskDescription = false;
    if (form.values && form.values.overrideTaskDescription) {
      overrideTaskDescription = true;
    }
    this.setState({
      overrideRepeatFrequency,
      subcontract,
      overrideLabourFrequency,
      overrideTaskDescription,
    });
  }

  handleAddNewLocation = () => {
    let chosenSiteid = 0;
    const form = this.props.createForm.quickAddNewPPMForm.values;
    if (form.Site != undefined) {
      chosenSiteid = form.Site.value;
    }
    this.setState({
      addNewLocationModal: {
        open: true,
        initialValues: {
          siteId: chosenSiteid,
        },
      },
    });
  };

  handleAddNewTaskCategory = () => {
    this.setState({
      addNewTaskCategoryModal: {
        open: true,
      },
    });
  };

  handleAddNewAsset = () => {
    let chosenSiteid = 0;
    const form = this.props.createForm.quickAddNewPPMForm.values;
    if (form.Site != undefined && form.Site != '') {
      chosenSiteid = form.Site.value;
    }

    this.setState({
      addNewAssetModal: {
        open: true,
        initialValues: {
          siteId: chosenSiteid,
          location: form.Location || null,
        },
      },
    });
  };

  handleAddNewTask = () => {
    let chosenTaskCategoryid = 0;
    const form = this.props.createForm.quickAddNewPPMForm.values;
    if (form.Category != undefined) {
      chosenTaskCategoryid = form.Category.value;
    }
    this.setState({
      addNewTaskModal: {
        open: true,
        initialValues: {
          isSimpleView: this.state.isSimpleView,
          taskCategoryId: chosenTaskCategoryid,
        },
      },
    });
  };

  handleRepeatPeriod = (e) => {
    this.setState({
      isOtherRepeatPeriod: this.state.repeatOtherOptions.includes(e.value) ? true : false,
      isCustomRepeatPeriod: e.value === 'Custom' ? true : false,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      addNewLocationModal: {
        open: false,
        initialValues: {
          siteId: null,
        },
      },
      addNewTaskCategoryModal: {
        open: false,
      },
      isLastTaskDisable: true,
    });
    this.props.onModalClose();
  };

  LocationcloseModal = () => {
    this.setState({
      display: false,
      addNewLocationModal: {
        open: false,
        initialValues: {
          siteId: null,
        },
      },
    });
  };

  TaskCategorycloseModal = () => {
    this.setState({
      display: false,
      addNewTaskCategoryModal: {
        open: false,
        initialValues: {
          siteId: null,
        },
      },
    });
  };

  AssetcloseModal = () => {
    this.setState({
      display: false,
      addNewAssetModal: {
        open: false,
        initialValues: {
          siteId: null,
        },
      },
    });
  };

  TaskcloseModal = () => {
    this.setState({
      display: false,
      addNewTaskModal: {
        open: false,
        initialValues: {
          siteId: null,
        },
      },
    });
  };

  handleChangeToBindAsset = (name) => {
    this.props.createForm.quickAddNewPPMForm.values.asset = '';
    let chosenSiteid = 0;
    // var chosenLocationid=0;
    let chosenCategorieid = 0;
    // var chosenAssetid=0;
    const form = this.props.createForm.quickAddNewPPMForm.values;
    if (form.Site != undefined && form.Site != '') {
      chosenSiteid = form.Site.value;
    }

    if (form.Category != undefined && form.Category != '') {
      chosenCategorieid = form.Category.value;
      this.setState({
        categoryId: chosenCategorieid == undefined ? 0 : chosenCategorieid,
      });
    }

    this.props.getTaskFullList({ taskCategoryId: chosenSiteid != 0 && chosenCategorieid != 0 ? chosenCategorieid : 0 });
  };

  handleChangeAssetLocation = () => {
    const form = this.props.createForm.quickAddNewPPMForm.values;
    this.setState({
      locationId:
        (form &&
          form.location &&
          form.location != '' &&
          Object.keys(form.location).length > 0 &&
          form.location.value) ||
        0,
    });
    this.setState({
      assetId: (form && form.asset && form.asset != '' && Object.keys(form.asset).length > 0 && form.asset.value) || 0,
    });
  };

  handleChangeSite = (name) => {
    const { isSiteChangeExecute } = this.state;
    this.props.createForm.quickAddNewPPMForm.values.repeatEvery = '';
    this.props.createForm.quickAddNewPPMForm.values.repeatPeriod = '';
    this.props.createForm.quickAddNewPPMForm.values.TaskName = '';
    this.props.createForm.quickAddNewPPMForm.values.location = '';
    this.props.createForm.quickAddNewPPMForm.values.asset = '';
    this.props.createForm.quickAddNewPPMForm.values.labourRequired = '';
    this.props.createForm.quickAddNewPPMForm.values.labourPeriod = '';
    if (!isSiteChangeExecute) {
      this.props.createForm.quickAddNewPPMForm.values.lastTask = '';
    }
    // // const chosenSite = this.props.siteList.find(building => building.name === name);
    const chosenSite = this.props.siteList.find((building) => building.id === (name && name.value ? name.value : 0));

    if (chosenSite) {
      this.props.createForm.quickAddNewPPMForm.values.lastTask = chosenSite.endDate || '';
      this.setState({
        isLastTaskDisable: !!(chosenSite && chosenSite.endDate && chosenSite.endDate != ''),
      });
    }

    const tmpSiteId = (chosenSite && chosenSite.id) || (name && name.value); // chosenSite.id
    this.setState({
      siteId: tmpSiteId,
      isSiteChangeExecute: false,
    });
    this.handleChangeTask('');
    this.props.getAssetFullList({ siteId: tmpSiteId });
    this.props.getLocationFullList({ siteId: tmpSiteId });
  };

  handleChangeTask = (name) => {
    // const chosenTask = this.props.tasks.find(task => task.name === name);
    // this.props.createForm.quickAddNewPPMForm.values['repeatEvery']=chosenTask && chosenTask.repeatEvery;
    // this.props.createForm.quickAddNewPPMForm.values['repeatPeriod']=chosenTask && chosenTask.repeatPeriod;
    // this.setState({
    //   repeatEvery:chosenTask && chosenTask.repeatEvery,
    //   repeatPeriod:chosenTask && chosenTask.repeatPeriod,
    //   taskId:chosenTask && chosenTask.id,
    //   locationId:0,
    //   assetId:0
    // });
  };

  handleChangeCompany = (name) => {
    this.props.createForm.quickAddNewPPMForm.values.Contact = '';
    const chosenCompany = this.props.internalCompanies.find((building) => building.name === name);
    this.props.getCompanyContactList(chosenCompany.uuid);
  };

  handleChangeSimpleandAdvanced = (value) => {
    this.setState({
      isSimpleView: value,
    });
  };

  handleCustomRepeatList = (value) => {
    const checkIfSelected = this.state.customRepeatList.filter((e) => e.id === value.id);
    this.setState({
      customRepeatList:
        checkIfSelected.length > 0
          ? this.state.customRepeatList.filter((e) => e.id !== value.id)
          : this.state.customRepeatList.concat(value),
    });
  };

  handleFormSubmit = (values) => {
    const {
      name,
      Site,
      Category,
      TaskName,
      overrideRepeatFrequency,
      repeatEvery,
      repeatPeriod,
      firstTask,
      lastTask,
      userAssignee,
      company,
      contact,
      subcontract,
      notifySubcontractor,
      location,
      asset,
      tags,
      overrideLabourFrequency,
      labourRequired,
      labourPeriod,
      overrideTaskDescription,
    } = values;

    const contentOnly = values && values.taskDescription ? values.taskDescription.replace(/<(?:.|\n)*?>/gm, '') : '';
    if (contentOnly !== undefined && contentOnly.trim() === '') {
      values.taskDescription = '';
    }
    const newCreatedLookup = _.filter(
      tags,
      (item) => item.value === item.label, // react-select/creatable, new has label/value same
    );
    const selectedLookup = _.filter(tags, (item) => item.value !== undefined && item.value !== item.label);
    const Createlookupvalue = _.map(newCreatedLookup, (lookup, key) => ({ lookupvalue: lookup.value }));
    const selectedLookupValues = selectedLookup.map((lookup) => lookup.value);
    let repeatEveryValue = undefined;
    if (overrideRepeatFrequency) {
      if (this.state.repeatOptions.includes(repeatPeriod.value)) {
        repeatEveryValue = 1;
      } else {
        repeatEveryValue = repeatEvery;
      }
    }
    const newSchedule = {
      name,
      siteId: typeof Site === 'object' ? Site?.value : null, // siteList && siteList.id,
      assetId: typeof asset === 'object' ? asset?.value : null,
      taskLibraryId: typeof TaskName === 'object' ? TaskName?.value : null, // tasks && tasks.id
      subcontract,
      enabled: 1,
      firstTaskDate: commonFunction.getDateWithValidFormat(firstTask),
      lastTaskDate: commonFunction.getDateWithValidFormat(lastTask),
      supplierCompanyId: typeof company === 'object' ? company?.value : null, // company
      supplierContactId: typeof contact === 'object' ? contact?.value : null, // contact
      locationId: typeof location === 'object' ? location?.value : null, // location
      categoryId: typeof Category === 'object' ? Category?.value : null, // // categoryList && categoryList[0],
      repeatEvery: repeatEveryValue,
      repeatPeriod: overrideRepeatFrequency ? repeatPeriod.value : undefined,
      overrideRepeatFrequency,
      notifySubcontractor,
      userAssigneeId: userAssignee && userAssignee.value, // // userAssignee
      selectedLookupValues,
      Createlookupvalue,
      labourRequired: overrideLabourFrequency ? labourRequired : undefined,
      labourPeriod: overrideLabourFrequency ? labourPeriod?.value : undefined,
      overrideLabourFrequency,
      taskRepeatEvery: this.state.repeatEvery,
      taskRepeatPeriod: this.state.repeatPeriod,
      overrideTaskDescription,
      taskDescription: overrideTaskDescription ? values.taskDescription : undefined,
      customRepeatList: this.state.customRepeatList,
    };
    this.props.createSchedule(newSchedule);
  };

  render() {
    const {
      handleSubmit,
      t,
      siteList,
      locations,
      taskCategories,
      assets,
      tasks,
      internalCompanies,
      // createForm,
      internalContact,
      // initialValues,
      users,
    } = this.props;
    const { display } = this.state;
    // // const siteNames = siteList.map(site => site.name);
    // // const categoryNames = taskCategories.map(category => category.name);
    // // const taskNames = tasks.map(task => task.name);
    const siteNames = siteList; /* .map(site => ({
      label: site.name,
      value: site.id,
    })); */
    const categoryNames = taskCategories; /* .map(category => ({
      label: category.name,
      value: category.id,
    })); */
    const taskNames = tasks; /* .map(task => ({
      label: task.name,
      value: task.id,
    })); */

    const {
      repeatOptions,
      addNewLocationModal,
      addNewTaskCategoryModal,
      addNewAssetModal,
      addNewTaskModal,
      isSimpleView,
      repeatEvery,
      repeatPeriod,
      name,
      siteId,
      categoryId,
      taskId,
      assetId,
      locationId,
      overrideRepeatFrequency,
      userAssigneeId,
      notifySubcontractor,
      contacts,
      subcontract,
      isLastTaskDisable,
      periodOptions,
      overrideLabourFrequency,
      labourRequired,
      labourPeriod,
      overrideTaskDescription,
      isOtherRepeatPeriod,
      isCustomRepeatPeriod,
      customRepeatList,
      customDateList,
    } = this.state;

    const showContacts = contacts && contacts.length > 0;

    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown over-y-auto
                  ${!display && 'display-none'}`}
        // onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show profile-modal quick-addnew-ppm-modal form-horizontal"
          // onClick={(e) => {
          //   e.stopPropagation();
          // }}
        >
          <div className="col-sm-12 title_with_filter">
            <h2 className="swal2-title text-left col-sm-offset-3 col-sm-9">
              {t('Add Planned Task')}
              <div className="switch_field">
                <input
                  type="radio"
                  name="SimpleandAdvanced"
                  checked={isSimpleView === 'false'}
                  onChange={(e) => this.handleChangeSimpleandAdvanced(e.target.value)}
                  id="RBAdvanced"
                  value="false"
                />{' '}
                <label className="radio_label" htmlFor="RBAdvanced">
                  {t('Advanced')}
                </label>
                <input
                  type="radio"
                  name="SimpleandAdvanced"
                  checked={isSimpleView === 'true'}
                  onChange={(e) => this.handleChangeSimpleandAdvanced(e.target.value)}
                  id="RBSimple"
                  value="true"
                />{' '}
                <label className="radio_label" htmlFor="RBSimple">
                  {t('Simple')}
                </label>
              </div>
            </h2>
          </div>
          <form>
            <MaintenanceScheduleEntryView
              t={t}
              args={{
                isAddPlannedTask: true,
                siteList: siteNames,
                onChangeSite: this.handleChangeSite,
                siteId,
                categoryList: categoryNames,
                categoryId,
                onChangeCategory: this.handleChangeToBindAsset,
                onClickNewTaskCategory: this.handleAddNewTaskCategory,
                taskList: taskNames,
                onChangeTask: this.handleChangeTask,
                onClickNewTask: this.handleAddNewTask,
                name,
                taskId,
                repeatEvery,
                repeatPeriod,
                onRepeatPeriod: this.handleRepeatPeriod,
                isOtherRepeatPeriod,
                isCustomRepeatPeriod,
                customDateList,
                overrideRepeatFrequency,
                repeatOptionList: repeatOptions,
                isLastTaskDisable,
                userList: users,
                companyList: internalCompanies,
                showContacts,
                contactList: contacts,
                subcontract,
                isSimpleView,
                assetList: assets,
                assetId,
                onClickNewAsset: this.handleAddNewAsset,
                locationList: locations,
                locationId,
                onClickNewLocation: this.handleAddNewLocation,
                onChangeAssetLocation: this.handleChangeAssetLocation,
                overrideLabourFrequency,
                periodOptionList: periodOptions,
                labourRequired,
                labourPeriod,
                overrideTaskDescription,
                customRepeatList,
                handleCustomRepeatList: this.handleCustomRepeatList,
              }}
            />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleFormSubmit)}
            >
              {t('Save')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
        {addNewLocationModal.open && (
          <AddNewLocationModal
            open={addNewLocationModal.open}
            onModalClose={this.LocationcloseModal}
            initialValues={addNewLocationModal.initialValues}
            t={t}
          />
        )}
        {addNewTaskCategoryModal.open && (
          <AddNewTaskCategoryModal
            open={addNewTaskCategoryModal.open}
            onModalClose={this.TaskCategorycloseModal}
            initialValues={addNewTaskCategoryModal.initialValues}
            t={t}
          />
        )}
        {addNewAssetModal.open && (
          <AddNewAssetModal
            open={addNewAssetModal.open}
            onModalClose={this.AssetcloseModal}
            initialValues={addNewAssetModal.initialValues}
            t={t}
          />
        )}
        {addNewTaskModal.open && (
          <AddNewTaskModal
            open={addNewTaskModal.open}
            onModalClose={this.TaskcloseModal}
            initialValues={addNewTaskModal.initialValues}
            t={t}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    tokens: { accessToken },
    // building: { list, count },
    site: { list, count },
    location: { parent, item },
    taskCategory: { all },
    asset,
    task,
    internalCompany,
    form,
    omsInternalContact,
    user,
  } = state;

  return {
    siteList: list,
    initialValues: props.initialValues,
    locations: parent,
    location: item,
    taskCategories: all,
    assets: asset.all, // asset.list,
    tasks: task.all,
    internalCompanies: internalCompany.all,
    createForm: form,
    internalContact: omsInternalContact.customerContactList,
    users: user.list,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createSchedule,
      getLocationFullList,
      getTaskCategoryFullList,
      getAssetFullList,
      getTaskFullList,
      getInternalCompanyFullList,
      getRepeatOptions,
      getCompanyContactList,
      getSiteFullList,
      getFullUserListbyAccountId,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'quickAddNewPPMForm',
    enableReinitialize: true,
  })(QuickAddNewPPMModal),
);
