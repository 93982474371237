import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import { SelectDropdown } from '../form/parts';

import { getAuditLogList, getEmailDeliveryReport } from '../../actions/audit/Log';

class Activity extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    activityLogs: PropTypes.arrayOf(PropTypes.shape()),
    getAuditLogList: PropTypes.func.isRequired,
    rowId: PropTypes.string.isRequired,
    entityType: PropTypes.string.isRequired,
    showActivitySelection: PropTypes.bool.isRequired,
    parentAssociatedId: PropTypes.string,
    profileLinkPermissions: PropTypes.shape(),
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activityLogs: [],
    parentAssociatedId: '0',
    profileLinkPermissions: {},
  };

  constructor(props) {
    super(props);

    const { rowId, entityType, parentAssociatedId } = props;
    this.state = {
      activityFor: {
        value: 'all',
        label: 'Show All',
      },
      emailModal: false,
      logId: 0,
    };
    this.props.getAuditLogList({
      rowId,
      entityType,
      showForSpecificEntity: false,
      parentAssociatedId,
    });
  }

  getMessageDeliveryReport = (id) => {
    this.setState({ emailModal: true, logId: id });
    this.props.getEmailDeliveryReport(id);
  };

  onChangeActivityDropdown = (key) => {
    const { rowId, entityType, parentAssociatedId } = this.props;
    this.setState({
      activityFor: {
        value: key.value,
        label: key.label,
      },
    });
    if (key.value === 'all') {
      // means activity of master with all child activity
      this.props.getAuditLogList({
        rowId,
        entityType,
        showForSpecificEntity: false,
        parentAssociatedId,
      });
    } else {
      // means masters > specific child activity
      this.props.getAuditLogList({
        rowId,
        entityType: key.value, // key,
        showForSpecificEntity: true,
        parentAssociatedId,
      });
    }
  };

  render() {
    const { activityLogs, showActivitySelection, entityType, profileLinkPermissions, t, deliveryList } = this.props;
    const { activityFor } = this.state;
    const options = [{ key: 'all', value: 'Show All' }];
    if (entityType === 'manufacturer') {
      options.push({ key: 'manufacturer', value: 'Show Manufacturer Profile Activity' });
      options.push({ key: 'model', value: 'Show All Model Activity' });
    } else if (entityType === 'taskcategory') {
      options.push({ key: 'taskcategory', value: 'Show Task Category Profile Activity' });
      options.push({ key: 'tasklibrary', value: 'Show All Task Activity' });
    } else if (entityType === 'helpdeskcategory') {
      options.push({ key: 'helpdeskcategory', value: 'Show Issue Category Profile Activity' });
      options.push({ key: 'helpdesktopic', value: 'Show All Topic Activity' });
    } else if (entityType === 'site') {
      options.push({ key: 'site', value: 'Show Site Profile Activity' });
      options.push({ key: 'asset', value: 'Show All Asset Activity' });
      options.push({ key: 'location', value: 'Show All Location Activity' });
      options.push({ key: 'maintenanceschedule', value: 'Show All Maintenance Schedule Activity' });
      options.push({ key: 'sitenotification', value: 'Show All Notification Activity' });
    } else if (entityType === 'maintenanceschedule') {
      options.push({ key: 'maintenancescheduleprofile', value: 'Show Maintenance Schedule Profile Activity' });
      options.push({ key: 'ppmjob', value: 'Show All PPM Job Activity' });
      options.push({ key: 'scheduletask', value: 'Show All Schedule Task Activity' });
    }

    return (
      <div className="container-fluid no-padding-h zero-side-paddings">
        {this.state.emailModal && (
          <div
            tabIndex={0}
            role="button"
            className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow`}
            onClick={this.closeModal}
          >
            <div
              aria-modal="true"
              className="swal2-modal swal2-show modal-template large-modal form-horizontal"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p>
                E-Mail Delivery Information is available for emails sent after August 2019, information may take up to 5
                minutes to appear
              </p>

              <div className="row ">
                <table className="table table-bordered mx-2">
                  <thead>
                    <tr>
                      <th scope="col">Email</th>
                      <th scope="col">Status</th>
                      <th scope="col">Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deliveryList.map((i) => (
                      <tr>
                        <td scope="row">{i.email}</td>
                        <td>{i.status}</td>
                        <td>{i.reason}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="swal2-buttonswrapper display-block">
                &nbsp;
                <button
                  type="button"
                  className="swal2-confirm swal2-styled btn-default"
                  onClick={() => this.setState({ emailModal: false })}
                >
                  {t('Close')}
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="wrap-for-max-width">
          {showActivitySelection && (
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-sm-1 control-label">Activity</label>{' '}
                <div className="col-sm-5">
                  <Field
                    name="drpActivity"
                    component={SelectDropdown}
                    drpName="drpActivity"
                    options={options}
                    _optionLabel="value"
                    _optionValue="key"
                    placeholder="Activity"
                    isClearable={false}
                    onChange={this.onChangeActivityDropdown}
                    defaultValue={activityFor}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="col-lg-12">
            <div className="activity-stream">
              {activityLogs &&
                activityLogs.map((data) => (
                  <div className="stream">
                    <div className="stream-badge">
                      {data.action === 'create' && <i className="fa fa-plus" />}
                      {data.action === 'update' && <i className="fa fa-pencil" />}
                      {data.action === 'delete' && <i className="fa fa-trash" />}
                      {data.action === 'lost' && <i className="fa fa-thumbs-down" />}
                      {data.action === 'approve' && <i className="fa fa-thumbs-up" />}
                      {data.action === 'send' && <i className="fa fa-envelope" />}
                    </div>
                    <div className="stream-panel ">
                      <div className="stream-info">
                        <a href="#">
                          {data.CreatedBy && (
                            <span className="createdBy">{data.CreatedBy.fullName || 'Unknown Logged in User'}</span>
                          )}
                          {!data.CreatedBy && data.fullName && (
                            <span className="createdBy">{data.fullName || 'Unknown Logged in User'}</span>
                          )}
                          {!data.CreatedBy && !data.fullName && (
                            <span className="createdBy">{data.fullName || 'Public User'}</span>
                          )}
                          <span className="createdAt date m-w-xxs">
                            {moment(data.createdAt).calendar(null, { sameElse: 'DD MMM YYYY - HH:mm A' })}
                          </span>
                        </a>
                      </div>
                      <div>
                        {entityType === 'site' && data.action !== 'delete' ? (
                          <span className="content " style={{ display: 'inline-block' }}>
                            {data.associatedType === 14 &&
                              (profileLinkPermissions.canViewLocations ? (
                                <a href={`/cafm/admin/location/${data.childAssociatedId}/profile`}>
                                  {data.description}
                                </a>
                              ) : (
                                data.description
                              ))}
                            {data.associatedType === 15 &&
                              (profileLinkPermissions.canViewAssets ? (
                                <a href={`/cafm/admin/asset/${data.childAssociatedId}/profile`}>{data.description}</a>
                              ) : (
                                data.description
                              ))}
                            {data.associatedType === 16 &&
                              (profileLinkPermissions.canViewSchedule ? (
                                <a href={`/cafm/admin/maintenance-schedule/${data.childAssociatedId}/profile`}>
                                  {data.description}
                                </a>
                              ) : (
                                data.description
                              ))}
                            {data.associatedType !== 14 &&
                              data.associatedType !== 15 &&
                              data.associatedType !== 16 &&
                              data.description}
                            <br />
                          </span>
                        ) : (
                          <>
                            <span className="content" style={{ display: 'inline-block' }}>
                              {data.description}
                            </span>
                            <br />
                          </>
                        )}
                        {data.LogDetail &&
                          data.LogDetail.map((detail) => (
                            <>
                              <span className="content" style={{ display: 'inline-block' }}>
                                {detail.description}
                              </span>
                              <br />
                            </>
                          ))}
                        {data.action === 'send' && (
                          <a
                            title="View email delivery status"
                            class="wrap-btn-round ml-2"
                            onClick={() => this.getMessageDeliveryReport(data.id)}
                          >
                            <i class="fas fa-info-circle" />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    tokens: { accessToken },
    auditLogs: { list, count, deliveryList },
    user,
  } = state;

  return {
    accessToken,
    activityLogs: list,
    deliveryList,
    count,
    userPermissions: user.currentuserpermissions,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAuditLogList,
      getEmailDeliveryReport,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'activityForm',
  })(Activity),
);
