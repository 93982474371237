import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { isArray } from 'lodash';
import { SHOW_LOADER, HIDE_LOADER, LOAD_CALENDAR } from '../types';
import { api } from '../../lib/api';
import { Router } from '../../../common/routes';
import { handleErrors } from '../TokenActions';
import { showErrorModal, showSuccessModal } from '../ErrorActions';
import { updateJobVisitTimeEntry, updateMultipleJob } from './JobActions';

const routePath = '/v2/ppmJob';

export const loadCalendar = (params) => ({
  type: LOAD_CALENDAR,
  payload: params,
});

export const getJobCalendarData =
  (selectedStartDate = null, selectedEndDate = null) =>
  async (dispatch, getState) => {
    dispatch({ type: SHOW_LOADER });
    const { startDate, endDate } = await getState().calendar;

    const params = {
      startDate: selectedStartDate ? selectedStartDate : startDate,
      endDate: selectedEndDate ? selectedEndDate : endDate,
    };
    
    api
      .get(`v2/ppmJob/get-job-schedule-task-list`, { params })
      .then((response) => {
        const data = response.data;
        dispatch(loadCalendar({ listData: data, ...params }));
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response?.data || []));
        handleErrors(error, dispatch, getJobCalendarData, startDate);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const updateJobCalendar = (params) => (dispatch, getState) => {
  dispatch(updateMultipleJob(params, null, true));
};

export const updateJobItemCalendar = (params) => (dispatch, getState) => {
  const { listData } = getState().calendar;
  const listIndex = listData.findIndex((e) => e.id == params.id);
  listData[listIndex].UserAssigneeId = params.userAssigneeId;
  listData[listIndex].StartTime = moment(params.visitDate).toISOString(true);
  listData[listIndex].EndTime = moment(params.visitDate).add(1, 'hours').toISOString(true);
  listData[listIndex].oldStartTime = moment(params.visitDate).toISOString(true);
  listData[listIndex].oldEndTime = moment(params.visitDate).add(1, 'hours').toISOString(true);

  dispatch(loadCalendar({ listData: listData }));
};

export const getJobTimeEntryCalendarData =
  (selectedStartDate = null, selectedEndDate = null, selectedUser = null) =>
  async (dispatch, getState) => {
    dispatch({ type: SHOW_LOADER });
    const { startDate, endDate } = await getState().calendar;

    const params = {
      startDate: selectedStartDate ? selectedStartDate : startDate,
      endDate: selectedEndDate ? selectedEndDate : endDate,
      ...(selectedUser && isArray(selectedUser) && selectedUser.length > 0 ? { userAssignee: selectedUser } : {}),
    };

    api
      .get(`v2/ppmJob/get-job-schedule-task-activity-list`, { params })
      .then((response) => {
        const data = response.data;
        dispatch(loadCalendar({ listData: data, ...params }));
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response?.data || []));
        handleErrors(error, dispatch, getJobCalendarData, startDate);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const updateJobActivityCalendar = (params) => (dispatch, getState) => {
  dispatch(updateJobVisitTimeEntry(params, null, true));
};

export const updateJobActivityItemCalendar = (params) => (dispatch, getState) => {
  const { listData } = getState().calendar;
  const listIndex = listData.findIndex((e) => e.id == params.id);
  listData[listIndex].UserAssigneeId = params.userAssigneeId;
  listData[listIndex].StartTime = moment(params.startAt).toISOString(true);
  listData[listIndex].EndTime = moment(params.departureAt).toISOString(true);
  listData[listIndex].oldEndTime = moment(params.oldEndTime).toISOString(true);
  listData[listIndex].oldStartTime = moment(params.oldStartTime).toISOString(true);
  dispatch(loadCalendar({ listData: listData }));
};
