import { LOAD_BUILDING_BUDGET, LOAD_TASK_BUDGET, LOAD_BUILDING_BUDGETS, LOAD_COUNT } from '../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  list: [],
};

const buildingBudget = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_BUILDING_BUDGET:
      return { ...state, item: action.payload };
    case LOAD_TASK_BUDGET: {
      const { item } = state;
      const { id, taskId, amount } = action.payload;
      const taskBudget = item.BuildingBudget[0].TaskBudget.find((budget) => budget.id === taskId);
      const monthBudget = taskBudget.TaskBudgetMonth.find((month) => month.id === id);
      monthBudget.amount = amount;
      const newItem = {
        ...item,
      };
      return { ...state, item: newItem };
    }
    case LOAD_BUILDING_BUDGETS: {
      return { ...state, list: action.payload };
    }
    case LOAD_COUNT:
      return { ...state, count: action.payload };
    default:
      return state;
  }
};

export default buildingBudget;
