import React from 'react';
import PropTypes from 'prop-types';

import { MonthPicker } from '../../picker';

class FormMonthPicker extends React.Component {
  static propTypes = {
    input: PropTypes.shape().isRequired,
    placeholder: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        date: props.input.value || null,
      },
    };
  }

  changeDate = (date) => {
    this.props.input.onChange(date);
  };

  render() {
    const { placeholder } = this.props;
    const { initialValues } = this.state;
    return <MonthPicker placeholder={placeholder} changeDate={this.changeDate} initialValues={initialValues} />;
  }
}

export default FormMonthPicker;
