import React from 'react';
import PropTypes from 'prop-types';

import { YEAR_LIST, MONTH_LIST } from '../../constants/pickerdata';

const PickerView = ({ chosen, chosenStart, onMonthClick, isEnd, isStart }) => {
  const className = (year, month, mNumber) => {
    const condition =
      chosenStart && (chosenStart.year > year || (chosenStart.year === year && chosenStart.mNumber >= mNumber + 1));

    const isChosen = chosen.year === year && chosen.month === month ? 'chosen' : '';

    return condition ? 'not-allowed' : isChosen;
  };

  return (
    <div className={`picker-wrapper is-modal${isEnd ? ' is-end' : ''}${isStart ? ' is-start' : ''}`}>
      {YEAR_LIST.map((year) => (
        <div key={year} className="year-wrapper">
          <label>
            <span>{year}</span>
          </label>

          <ol>
            {MONTH_LIST.map((month, mNumber) => (
              <li
                key={month}
                className={className(year, month, mNumber)}
                onClick={() => {
                  onMonthClick(year, month, mNumber);
                }}
              >
                {month}
              </li>
            ))}
          </ol>
        </div>
      ))}
    </div>
  );
};

PickerView.propTypes = {
  chosen: PropTypes.shape({}).isRequired,

  onMonthClick: PropTypes.func.isRequired,

  isEnd: PropTypes.bool,
  isStart: PropTypes.bool,
  chosenStart: PropTypes.shape({}),
};

PickerView.defaultProps = {
  isEnd: null,
  isStart: null,
  chosenStart: null,
};

export default PickerView;
