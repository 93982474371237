import {
  LOAD_INTERNAL_COMPANY,
  LOAD_INTERNAL_COMPANIES,
  LOAD_ALL_INTERNAL_COMPANIES,
  LOAD_ALL_INTERNAL_COMPANIE_CONTACTS,
  LOAD_ALL_INTERNAL_COMPANIE_CONTACTS_BY_ID,
  LOAD_ALL_INTERNAL_AND_EXTERNAL,
  LOAD_INTERNAL_COMPANY_COUNT,
  SHOW_LOADER,
  HIDE_LOADER,
} from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/internalCompany';
const legacyRoutePath = 'core/maintenance-schedule';

export const loadInternalCompany = (internalCompany) => ({
  type: LOAD_INTERNAL_COMPANY,
  payload: internalCompany,
});

export const loadInternalCompanies = (internalCompanies) => ({
  type: LOAD_INTERNAL_COMPANIES,
  payload: internalCompanies,
});

export const formatInternalCompanies = (internalCompanies, enableAllOptions = false) => {
  return internalCompanies.map((e) => {
    const InternalContact = e.InternalContact.map((i) => ({ ...i, name: i.fullName }));
    return {
      ...e,
      InternalContact,
      ...(enableAllOptions ? { disabled: false } : {}),
    };
  });
};

export const formatAllUsers = (data) => {
  return [
    {
      label: 'Internal Users',
      items: data.users.map((e) => ({ label: e.fullName, value: e.id })),
    },
    {
      label: 'External Users',
      items: data.contacts.map((e) => ({ label: e.fullName, value: e.id })),
    },
  ];
};

export const loadAllInternalCompanies = (internalCompanies, enableAllOptions = false) => ({
  type: LOAD_ALL_INTERNAL_COMPANIES,
  payload: formatInternalCompanies(internalCompanies, enableAllOptions),
});

export const loadInternalCompanieAllContact = (internalContacts) => ({
  type: LOAD_ALL_INTERNAL_COMPANIE_CONTACTS,
  payload: internalContacts,
});

export const loadInternalCompanyContactsById = (data) => ({
  type: LOAD_ALL_INTERNAL_COMPANIE_CONTACTS_BY_ID,
  payload: data,
});

export const loadAllUsers = (data) => ({
  type: LOAD_ALL_INTERNAL_AND_EXTERNAL,
  payload: formatAllUsers(data),
});

export const loadCount = (count) => ({
  type: LOAD_INTERNAL_COMPANY_COUNT,
  payload: count,
});

export const createInternalCompany = (internalCompany) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/create`, internalCompany)
    .then((response) => {
      dispatch(loadInternalCompany(response.data));
      Router.pushRoute('cafm/admin/categories');
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createInternalCompany, internalCompany);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateInternalCompany = (internalCompany) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .put(`${routePath}/update`, internalCompany)
    .then((response) => {
      dispatch(loadInternalCompany(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateInternalCompany, internalCompany);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getInternalCompany = (uuid) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-item/${uuid}`)
    .then((response) => {
      dispatch(loadInternalCompany(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getInternalCompany, uuid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteInternalCompany = (uuid) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${uuid}`)
    .then(() => {
      const item = {
        uuid,
        name: null,
      };
      dispatch(loadInternalCompany(item));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteInternalCompany, uuid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getInternalCompanyList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      dispatch(loadInternalCompanies(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getInternalCompanyList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getInternalCompanyFullList =
  (params, enableAllOptions = false) =>
  (dispatch) => {
    dispatch({ type: SHOW_LOADER });

    api
      .get(`${routePath}/get-full-list/supplier`, { params })
      .then((response) => {
        dispatch(loadAllInternalCompanies(response.data, enableAllOptions));
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response?.data));
        handleErrors(error, dispatch, getInternalCompanyFullList, params);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const getInternalCompanyContactList = (companyId, params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`/oms/companies/${companyId}/contacts/select`, { params })
    .then((response) => {
      dispatch(loadInternalCompanieAllContact(response.data.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error?.response?.data));
      handleErrors(error, dispatch, getInternalCompanyContactList, companyId);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getInternalCompanyContactsById = (companyId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`/oms/companies/${companyId}/contacts`)
    .then((response) => {
      dispatch(loadInternalCompanyContactsById(response.data.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getInternalCompanyContactList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getInternalAndExternalUser = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${legacyRoutePath}/wallchart/getContact`, { params })
    .then((response) => {
      dispatch(loadAllUsers(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getInternalAndExternalUser, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getInternalCompanyListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`${routePath}/get-list-with-size`, { params })
    .then((response) => {
      dispatch(loadInternalCompanies(response.data.rows));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getInternalCompanyListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const quickCreateInternalCompany =
  (params, type = null, cb = () => {}) =>
  (dispatch) => {
    dispatch({ type: SHOW_LOADER });

    api
      .post(`${routePath}/quick-create${type === 'postroom' ? '?type=postroom' : ''}`, params)
      .then((response) => {
        if (type === 'postroom') {
          cb();
        } else {
          dispatch(loadInternalCompany(response.data));
        }
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response.data));
        handleErrors(error, dispatch, quickCreateInternalCompany, params);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const createInternalCompanyContact =
  (companyId, params, type = null, cb = () => {}) =>
  (dispatch) => {
    dispatch({ type: SHOW_LOADER });
    api
      .post(`/oms/companies/${companyId}/contacts${type === 'postroom' ? '?type=postroom' : ''}`, params)
      .then((response) => {
        if (type === 'postroom') {
          cb();
        } else {
          dispatch(getInternalCompanyContactList(companyId));
        }
      })
      .catch((error) => {
        dispatch(showErrorModal(error?.response?.data));
        handleErrors(error, dispatch, createInternalCompanyContact, companyId);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };
