import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import CreatableSelectDropdown from './CreatableSelectDropdown';
import SelectDropdown from './SelectDropdown';

import { getLookupvalueList } from '../../../actions/cafm/LookupvaluesAction';
import { permissions } from '../../../constants/permissions';

class TagsDropdown extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    getLookupvalueList: PropTypes.func,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    tagslist: PropTypes.arrayOf(PropTypes.shape()),
    labelClass: PropTypes.string,
    divClass: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    name: '',
    label: '',
    placeholder: '',
    tagslist: [],
    labelClass: 'col-xs-12 col-sm-4 control-label',
    divClass: 'col-xs-12 col-sm-8',
    disabled: false,
  };

  constructor(props) {
    super(props);
    const { accessToken } = props;

    props.getLookupvalueList({ lookupname: 'Tags', tagsof: '1' });
  }

  render() {
    const {
      // t,
      name,
      label,
      placeholder,
      tagslist,
      labelClass,
      divClass,
      disabled,
      userPermissions,
    } = this.props;

    const sitePermissions =
      userPermissions && userPermissions.sitePermissions.map(({ permissions }) => permissions).toString();
    const canCreateTags =
      (userPermissions && userPermissions.permissions.includes(permissions.tags.Create)) ||
      (sitePermissions && sitePermissions.includes(permissions.tags.Create));

    return (
      <div className="form-group">
        <label className={labelClass} htmlFor={name}>
          {label}
        </label>

        <div className={divClass}>
          {canCreateTags && (
            <Field
              name={name}
              component={CreatableSelectDropdown}
              options={tagslist}
              _optionLabel="lookupvalue"
              _optionValue="lookupvaluesid"
              drpName={name}
              placeholder={placeholder}
              isDisabled={disabled}
              isMulti
            />
          )}

          {!canCreateTags && (
            <Field
              name={name}
              component={SelectDropdown}
              options={tagslist}
              _optionLabel="lookupvalue"
              _optionValue="lookupvaluesid"
              drpName={name}
              placeholder={placeholder}
              isMulti
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    tokens: { accessToken },
    lookupvalues,
    user,
  } = state;

  return {
    accessToken,
    tagslist: lookupvalues.tagsList, // lookupvalues.list,
    userPermissions: user.currentuserpermissions,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getLookupvalueList,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TagsDropdown);
