import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { PanelWrapper } from '../styled';

class Panel extends Component {
  static propTypes = {
    initialValues: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    components: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    header: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
  };

  static defaultProps = {
    initialValues: {},
    width: '600px',
    height: '600px',
  };

  constructor(props) {
    super(props);
    this.state = {
      display: false,
      data: {},
      currentComponent: 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
  }

  getRenderedComponent = () => {
    const { components, handleSubmit, t } = this.props;
    const { data, currentComponent } = this.state;
    const RenderedComponent = components[currentComponent];
    return React.cloneElement(RenderedComponent, {
      data,
      handleSubmit,
      t,
      changeData: this.changeData,
      closeModal: this.closeModal,
      showNewComponent: this.showNewComponent,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
    this.props.onModalClose();
  };

  changeData = (newData) => {
    this.setState({
      data: newData,
    });
  };

  showNewComponent = (newComponent) => {
    this.setState({
      currentComponent: newComponent,
    });
  };

  render() {
    const { header, width, height, t } = this.props;
    const { display } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show modal-overflow form-horizontal display-block"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <PanelWrapper width={width} height={height}>
            <h2 className="swal2-title">{header}</h2>

            <form>{this.getRenderedComponent()}</form>

            <div className="swal2-buttonswrapper display-block">
              <button type="button" className="swal2-confirm swal2-styled btn-primary" onClick={this.closeModal}>
                {t('Close')}
              </button>
            </div>
          </PanelWrapper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues,
});

export default connect(
  mapStateToProps,
  null,
)(
  reduxForm({
    form: 'panelForm',
    enableReinitialize: true,
  })(Panel),
);
