import _ from 'lodash';
import { applicationFeature } from '../constants/applicationFeature';
import moment from 'moment';

const commonFunction = {
  goToSiteDashboard(siteId) {
    window.location = `/cafm/site/select/${siteId}`;
  },
  getEntitledAccountApplicationFeature: (args) => {
    const featureInfo = {};
    if (Object.keys(args.entitledApplicationFeatureList).length > 0) {
      const tmpFeatureData = args.entitledApplicationFeatureList.filter(
        (x) => x.name.toLowerCase() === args.application,
      );
      if (Object.keys(tmpFeatureData).length > 0 && tmpFeatureData[0].ApplicationAccountApplicationFeature) {
        tmpFeatureData[0].ApplicationAccountApplicationFeature.map((data) => {
          if (args.application === 'alp') {
            if (data.featureId === applicationFeature.ALP.TIMEOFF) featureInfo.canSeeTimeOff = true;
            else if (data.featureId === applicationFeature.ALP.SETTINGS) featureInfo.canSeeSetting = true;
            else if (data.featureId === applicationFeature.ALP.EMPLOYEE) featureInfo.canSeeEmployee = true;
            else if (data.featureId === applicationFeature.ALP.TEAM) featureInfo.canSeeTeam = true;
          } else if (args.application === 'jms') {
            if (data.featureId === applicationFeature.JMS.QUOTE) featureInfo.canSeeQuote = true;
            else if (data.featureId === applicationFeature.JMS.JOB) featureInfo.canSeeJob = true;
            else if (data.featureId === applicationFeature.JMS.PURCHASEORDER) featureInfo.canSeePurchaseOrder = true;
            else if (data.featureId === applicationFeature.JMS.COMPANIES) featureInfo.canSeeCompanies = true;
            else if (data.featureId === applicationFeature.JMS.JOBVISIT) featureInfo.canSeeJobVisit = true;
            else if (data.featureId === applicationFeature.JMS.JOBVISITTIMELOG)
              featureInfo.canSeeJobVisitTimelog = true;
            else if (data.featureId === applicationFeature.JMS.INVOICE) featureInfo.canSeeInvoices = true;
            else if (data.featureId === applicationFeature.JMS.ADVANCEDINVOICE)
              featureInfo.canSeeAdvancedInvoices = true;
          } else if (args.application === 'cafm') {
            if (data.featureId === applicationFeature.CAFM.SITEDASHBOARD) featureInfo.canSeeSiteDashboard = true;
            else if (data.featureId === applicationFeature.CAFM.SITEADMIN) featureInfo.canSeeSiteAdmin = true;
            else if (data.featureId === applicationFeature.CAFM.PPMCALENDAR) featureInfo.canSeePPMCalendar = true;
            else if (data.featureId === applicationFeature.CAFM.HELPDESK) featureInfo.canSeeHelpdesk = true;
            else if (data.featureId === applicationFeature.CAFM.ADVANCEDTICKETING)
              featureInfo.canSeeAdvancedTicketing = true;
            else if (data.featureId === applicationFeature.CAFM.DOCUMENTS) featureInfo.canSeeDocuments = true;
            else if (data.featureId === applicationFeature.CAFM.INDUCTION) featureInfo.canSeeInduction = true;
            else if (data.featureId === applicationFeature.CAFM.COMPANIES) featureInfo.canSeeCafmCompanies = true;
            else if (data.featureId === applicationFeature.CAFM.RISK) featureInfo.canSeeRisk = true;
          }
        });
        return featureInfo;
      }
    }
    return {};
  },
  getDateWithValidFormat: (inputValue) => {
    let resultValue = null;
    let tempMoment;
    let datePickerFormat = 'DD/MM/YYYY';
    let dbMomentFormat = 'YYYY-MM-DD';
    if (inputValue && inputValue !== '') {
      // if mainly in Edit modal etc if date is not changed then it return string
      // if changed then it retrun javascript date object
      // 01/01/2021, 13/01/2021
      if (inputValue.length > 10) {
        datePickerFormat = 'DD/MM/YYYY HH:mm';
        dbMomentFormat = 'YYYY-MM-DD HH:mm';
      } else if (inputValue.length == 10) {
        // nothing to do as it's date only
      } else if (
        isNaN(new Date(inputValue).getTime()) == false &&
        (new Date(inputValue).getHours > 0 || new Date(inputValue).getMinutes() > 0)
      ) {
        datePickerFormat = 'DD/MM/YYYY HH:mm';
        dbMomentFormat = 'YYYY-MM-DD HH:mm:ss';
      }
      tempMoment = moment(inputValue, datePickerFormat, true);
      if (tempMoment.isValid()) resultValue = tempMoment.format(dbMomentFormat);
      else if (moment(inputValue).isValid()) resultValue = moment(inputValue).format('YYYY-MM-DD HH:mm:ss');
    }
    return resultValue;
  },
  // isOnlyDate = false mean it has time
  displayDateWithValidFormat: (inputValue, isOnlyDate = true) => {
    let resultValue = null;
    let tempMoment;
    let displayFormat = 'DD/MM/YYYY';
    if (inputValue && inputValue !== null && inputValue !== '') {
      if (!isOnlyDate) displayFormat = `${displayFormat} HH:mm`;
      tempMoment = moment(inputValue);
      resultValue = tempMoment.isValid() ? tempMoment.format(displayFormat) : inputValue;
    }
    return resultValue;
  },
  getInternalExternamAssignee: (args) => {
    const assignee = [];
    // internal Assignee
    if (args.UserAssignee && args.UserAssignee.length > 0) {
      _.map(args.UserAssignee, (data) => {
        if (data && !_.isUndefined(data.fullName) && !_.isNull(data.fullName)) {
          assignee.push(data.fullName);
        }
      });
    }
    // external Assignee
    if (args.SupplierCompany && args.SupplierCompany.length > 0) {
      _.map(args.SupplierCompany, (data) => {
        if (data && !_.isUndefined(data.name) && !_.isNull(data.name)) {
          assignee.push(data.name);
        }
      });
    }
    if (args.SupplierContact && args.SupplierContact.length > 0) {
      _.map(args.SupplierContact, (data) => {
        if (data && !_.isUndefined(data.fullName) && !_.isNull(data.fullName)) {
          assignee.push(data.fullName);
        }
      });
    }
    return assignee;
  },
  getAssetName: (args) => {
    const tempData = [];
    if (args.Asset && args.Asset.length > 0) {
      _.map(args.Asset, (data) => {
        tempData.push(data.name);
      });
    }
    return tempData;
  },
};

export default commonFunction;
