import { LOAD_TAGS } from '../../actions/types';

const INITIAL_STATE = {
  list: [],
};

const tags = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_TAGS:
      return { ...state, list: action.payload };
    default:
      return state;
  }
};

export default tags;
