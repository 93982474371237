import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';

import { updateBuilding } from '../../actions/bms/BuildingActions';
import SitePicturesEdit from '../../containers/decorate/SitePicturesEdit';
import { getSiteTypeOptions } from '../../constants/options';
import SiteEntryView from '../cafm/admin/SiteEntryView';
import commonFunction from '../../lib/commonFunction';

class EditBuildingModal extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    initialValues: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    updateBuilding: PropTypes.func,
  };

  static defaultProps = {
    updateBuilding: undefined,
  };

  constructor(props) {
    super(props);
    const siteTypeOptions = getSiteTypeOptions(props.t);
    this.state = {
      display: false,
      siteTypeOptions,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    this.props.onModalClose();
  };

  handleUpdateBuilding = (building) => {
    const { id } = this.props;
    const validPropsArray = [];
    Object.keys(building).forEach((key) => {
      if (building[key] !== '' && building[key] !== null) {
        validPropsArray.push(key);
      }
    });
    const newbuilding = _.pick(building, validPropsArray);
    newbuilding.id = id;
    newbuilding.startDate = commonFunction.getDateWithValidFormat(newbuilding.startDate);
    newbuilding.endDate = commonFunction.getDateWithValidFormat(newbuilding.endDate);

    if (building.type && building.type.value) {
      newbuilding.type = newbuilding.type.value;
    } else if (building.type && building.type.length > 0) {
      const siteType = this.state.siteTypeOptions.filter((e) => e.label === building.type);
      newbuilding.type = siteType.length > 0 ? siteType[0].value : null;
    } else {
      newbuilding.type = null;
    }
    this.props.updateBuilding(newbuilding).then(() => this.closeModal());
  };

  render() {
    const {
      handleSubmit,
      t,
      initialValues: { SitePictures },
    } = this.props;
    const { display, siteTypeOptions } = this.state;
    const pictures = SitePictures || [];
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown over-y-auto
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show profile-modal large-modal edit-building-modal form-horizontal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{t('Update building')}</h2>

          <form>
            <div className="row row-eq-height">
              <div className="col-md-8">
                <SiteEntryView
                  t={t}
                  args={{
                    siteTypeList: siteTypeOptions,
                    isEditMode: true,
                  }}
                />
              </div>
              <div className="col-md-4">
                <SitePicturesEdit initialPictures={pictures} t={t} />
              </div>
            </div>
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleUpdateBuilding)}
            >
              {t('Update')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    initialValues: props.initialValues,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateBuilding,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'editBuildingForm',
    enableReinitialize: true,
  })(EditBuildingModal),
);
