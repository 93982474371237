//1446/1453
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import { FormInput, FormCheckbox } from '../form/parts';

import { quickCreateInternalCompany } from '../../actions/cafm/InternalCompanyActions';

class AddInternalCompanyModal extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    quickCreateInternalCompany: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    createForm: PropTypes.shape(),
    initialValues: PropTypes.shape().isRequired,
    onModalClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    createForm: {
      addInternalCompanyForm: {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
    const form = nextProps.createForm.addInternalCompanyForm;
    if (form.values && nextProps.internalCompany) {
      if (nextProps.internalCompany.name === form.values.name && form.submitSucceeded) {
        if (nextProps.createForm.assetSurveyForm) {
          nextProps.createForm.assetSurveyForm.values.company = {
            label: nextProps.internalCompany.name,
            value: nextProps.internalCompany.id,
          };

          nextProps.createForm.assetSurveyForm.values.contact = {
            label: nextProps.internalCompany.InternalContact[0].fullName,
            value: nextProps.internalCompany.InternalContact[0].id,
          };
        }
        this.props.onModalClose();
      }
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    this.props.onModalClose();
  };

  handleFormSubmit = (values) => {
    const { name, externalReference, firstName, lastName, contactNumber, emailAddress, forBilling } = values;

    const data = {
      name,
      externalReference,
      firstName,
      lastName,
      contactNumber,
      emailAddress,
      forBilling,
      type: '2', //supplier
    };
    this.props.quickCreateInternalCompany(data);
  };

  render() {
    const { handleSubmit, t, initialValues, title } = this.props;
    const { display } = this.state;

    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                        ${!display && 'display-none'}`}
      >
        <div aria-modal="true" className="swal2-modal swal2-show modal-template form-horizontal">
          <h2 className="swal2-title">Add New Company</h2>

          <form>
            <Field
              label={t('Company Name *')}
              component={FormInput}
              name="name"
              type="text"
              placeholder={t('Company Name')}
            />

            <Field
              label={t('External Reference')}
              component={FormInput}
              name="externalReference"
              type="text"
              placeholder={t('External Reference')}
            />

            <Field
              label={t('First Name *')}
              component={FormInput}
              name="firstName"
              type="text"
              placeholder={t('First Name')}
            />
            <Field
              label={t('Last Name *')}
              component={FormInput}
              name="lastName"
              type="text"
              placeholder={t('Last Name')}
            />
            <Field
              label={t('Contact Number *')}
              component={FormInput}
              name="contactNumber"
              type="text"
              placeholder={t('Contact Number')}
            />
            <Field
              label={t('Contact Email *')}
              component={FormInput}
              name="emailAddress"
              type="text"
              placeholder={t('Contact Email')}
            />

            <Field name="forBilling" component={FormCheckbox} label={t('Use for Billing')} />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleFormSubmit)}
            >
              {t('Save')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    tokens: { accessToken },
    form,
    internalCompany: { item },
  } = state;
  return {
    accessToken,
    createForm: form,
    initialValues: props.initialValues,
    internalCompany: item,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      quickCreateInternalCompany,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'addInternalCompanyForm',
  })(AddInternalCompanyModal),
);
