import React from 'react';

const ProductListView = ({ userProduct, onFindOutMoreClick }) =>
  userProduct && (
    <div className="col-sm-3 pricing product">
      {userProduct.id == 0 && (
        <div className="panel panel-default gray-bg">
          <div className="img-responsive p-m">
            <img src={userProduct.logoUrl} alt={userProduct.name} />
          </div>
          <ul className="product-detail">
            <li className="li-has-content" />
          </ul>
          <div>
            <a className="btn btn-primary btn-lg mar_btm_10" href="/setup/subscription">
              Buy Now
            </a>
          </div>
          <div>
            <a className="btn btn-primary btn-lg" onClick={onFindOutMoreClick}>
              Find Out More
            </a>
          </div>
        </div>
      )}
      {userProduct.id > 0 && (
        <div className="panel panel-default">
          <div className="img-responsive p-m">
            <img src={userProduct.logoUrl} alt={userProduct.name} />
          </div>
          <ul className="product-detail">
            <li className="li-has-content" />
          </ul>
          <div className="subscribe-blank-div" />
          <div className="gray-bg">
            <a className="btn btn-primary btn-lg" href={userProduct.url}>
              Open
            </a>
          </div>
        </div>
      )}
    </div>
  );

ProductListView.propTypes = {};

export default ProductListView;
