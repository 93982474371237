import _ from 'lodash';
import {
  LOAD_SCHEDULE_TASK,
  LOAD_SCHEDULE_TASKS,
  LOAD_ALL_SCHEDULE_TASKS,
  LOAD_SCHEDULE_TASK_COUNT,
  SHOW_LOADER,
  HIDE_LOADER,
  LOAD_BUILDING,
} from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';
import commonFunction from '../../lib/commonFunction';

const routePath = '/v2/scheduleTask';

const adjustScheduleTasks = (item) => {
  if (!item.taskLibraryId || !item.TaskLibrary) {
    item.taskLibraryName = null;
  } else {
    item.taskLibraryName = item.TaskLibrary.name;
  }

  if (item.SupplierCompany && item.SupplierCompany.length > 0) {
    const tempCompanyAssignee = commonFunction.getInternalExternamAssignee({ SupplierCompany: item.SupplierCompany });
    item.supplierCompanyName = tempCompanyAssignee && tempCompanyAssignee.length > 0 && tempCompanyAssignee.toString();
  } else {
    item.supplierCompanyName = null;
  }

  if (item.userAssignee && item.userAssignee.length > 0) {
    const tempUserAssignee = commonFunction.getInternalExternamAssignee({ UserAssignee: item.userAssignee });
    item.supplierContactName = tempUserAssignee && tempUserAssignee.length > 0 && tempUserAssignee.toString();
  } else if (item.SupplierContact && item.SupplierContact.length > 0) {
    const tempContactAssignee = commonFunction.getInternalExternamAssignee({ SupplierContact: item.SupplierContact });
    item.supplierContactName = tempContactAssignee && tempContactAssignee.length > 0 && tempContactAssignee.toString();
  } else {
    item.supplierContactName = null;
  }

  item.haveSubcontract = item.subcontract ? 'yes' : 'no';
  item.sendNotifications = item.notifySubcontractor ? 'yes' : 'no';
  item.active = item.enabled ? 'yes' : 'no';
  item.overrideRepeatFreq = item.overrideRepeatFrequency ? 'yes' : 'no';

  if (item.overrideRepeatFrequency) {
    item.repeat = `${item.repeatEvery} ${item.repeatPeriod}`;
  } else if (item.TaskLibrary) {
    item.repeat = `${item.TaskLibrary.repeatEvery} ${item.TaskLibrary.repeatPeriod}`;
  } else {
    item.repeat = null;
  }
  item.overrideLabourFreq = item.overrideLabourFrequency ? 'yes' : 'no';
  if (item.overrideLabourFrequency && item.labourRequired) {
    item.labour = `${item.labourRequired} ${item.labourPeriod}`;
  } else if (item.TaskLibrary && item.TaskLibrary.labourRequired) {
    item.labour = `${item.TaskLibrary.labourRequired} ${item.TaskLibrary.labourPeriod}`;
  } else {
    item.labour = null;
  }
  item.firstTaskDate = commonFunction.displayDateWithValidFormat(item.firstTaskDate);
  item.lastTaskDate = commonFunction.displayDateWithValidFormat(item.lastTaskDate);
  item.nextTaskDate = commonFunction.displayDateWithValidFormat(item.nextTaskDate);
};

export const loadBuilding = (building) => ({
  type: LOAD_BUILDING,
  payload: building,
});

export const loadScheduleTask = (schedule) => ({
  type: LOAD_SCHEDULE_TASK,
  payload: schedule,
});

export const loadScheduleTasks = (schedules) => ({
  type: LOAD_SCHEDULE_TASKS,
  payload: schedules,
});

export const loadAllScheduleTasks = (schedules) => ({
  type: LOAD_ALL_SCHEDULE_TASKS,
  payload: schedules,
});

export const loadCount = (count) => ({
  type: LOAD_SCHEDULE_TASK_COUNT,
  payload: count,
});

export const createScheduleTask = (body) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .post(`${routePath}/create`, body)
    .then((response) => {
      adjustScheduleTasks(response.data);
      dispatch(loadScheduleTask(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createScheduleTask, body);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateScheduleTask = (body) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .put(`${routePath}/update`, body)
    .then((response) => {
      const result = response.data;
      adjustScheduleTasks(result);
      dispatch(loadScheduleTask(result));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateScheduleTask, body);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const disableScheduleTask = (body) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .put(`${routePath}/enable-disable`, {
      id: body.id,
      enabled: !body.enabled,
    })
    .then((response) => {
      const result = response.data;
      adjustScheduleTasks(result);
      dispatch(loadScheduleTask(result));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, disableScheduleTask, body);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getScheduleTask = (id) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`${routePath}/get-item/${id}`)
    .then((response) => {
      const result = response.data;
      adjustScheduleTasks(result);
      dispatch(loadScheduleTask(result));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getScheduleTask, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteScheduleTask = (id, siteId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .delete(`${routePath}/delete/${id}`)
    .then(() => {
      const item = {
        id,
        name: null,
      };
      dispatch(loadScheduleTask(item));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteScheduleTask, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const scheduleTaskArchived = (id, siteId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .put(`${routePath}/scheduleArchived`, {
      id,
      archived: 1,
    })
    .then(() => {
      const item = {
        id,
        name: null,
      };
      dispatch(loadScheduleTask(item));
      if (siteId) {
        Router.pushRoute('cafm/admin/site_profile', { sid: siteId });
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, scheduleTaskArchived, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getScheduleTaskList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      const result = response.data;
      result.forEach(adjustScheduleTasks);
      dispatch(loadScheduleTasks(result));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getScheduleTaskList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getScheduleTaskFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`${routePath}/get-full-list`, { params })
    .then((response) => {
      dispatch(loadAllScheduleTasks(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getScheduleTaskFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getScheduleTaskListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`${routePath}/get-list-with-size`, { params })
    .then((response) => {
      const result = response.data.rows;
      result.forEach(adjustScheduleTasks);
      dispatch(loadScheduleTasks(result));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getScheduleTaskListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const generateJob = (schedule) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .post(`${routePath}/generateJob`, schedule)
    .then((response) => {
      if (schedule.isSite == false) {
        window.location.href = window.location.href;
      } else {
        const newSchedule = response.data;
        _.each(newSchedule, (value, key) => {
          adjustScheduleTasks(value);
          dispatch(loadScheduleTask(value));
        });

        if (schedule.isSite && schedule.isSave) {
          schedule.startDate = commonFunction.displayDateWithValidFormat(schedule.startDate);
          schedule.endDate = commonFunction.displayDateWithValidFormat(schedule.endDate);
          dispatch(loadBuilding(schedule));
        }
      }

      // dispatch(loadSchedule(response.data));
      // Router.pushRoute('cafm/admin/schedule_profile', { schedule_id: response.data.id });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, generateJob, schedule);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
