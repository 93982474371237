import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { logoutUser, myProfile, loadCurrentSystem, getCurrentUserPermissions } from '../../actions/UserActions';
import { permissions } from '../../constants/permissions';

class HamburgerMenu extends Component {
  static propTypes = {
    logoutUser: PropTypes.func.isRequired,
    myProfile: PropTypes.func.isRequired,
    loadCurrentSystem: PropTypes.func.isRequired,
    refreshToken: PropTypes.string.isRequired,
    currentSystem: PropTypes.string.isRequired,
    userPermissions: PropTypes.shape().isRequired,

    accountUUID: PropTypes.string.isRequired,

    getCurrentUserPermissions: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    props.getCurrentUserPermissions();
  }

  closeMenu = () => {
    const menuRight = document.getElementById('menu-right');
    if (menuRight.className.indexOf('hamburger-open') !== -1) {
      menuRight.className = menuRight.className.replace(' hamburger-open', '');
    }
  };

  render() {
    const { refreshToken, currentSystem, userPermissions, accountUUID } = this.props;
    const sitePermissions =
      userPermissions &&
      userPermissions.sitePermissions &&
      userPermissions.sitePermissions.map(({ permissions }) => permissions).toString();
    const cafmAccess =
      (userPermissions &&
        userPermissions.permissions &&
        userPermissions.permissions.includes(permissions.subscriptions.Cafm)) ||
      (sitePermissions && sitePermissions.includes(permissions.subscriptions.Cafm));
    const alpAccess =
      (userPermissions &&
        userPermissions.permissions &&
        userPermissions.permissions.includes(permissions.subscriptions.Alp)) ||
      (sitePermissions && sitePermissions.includes(permissions.subscriptions.Alp));
    const jmsAccess =
      (userPermissions &&
        userPermissions.permissions &&
        userPermissions.permissions.includes(permissions.subscriptions.Jms)) ||
      (sitePermissions && sitePermissions.includes(permissions.subscriptions.Jms));

    const canManageAccount =
      userPermissions && userPermissions.permissions && userPermissions.permissions.includes(permissions.accounts.Edit);
    return (
      <div className="hamburger-container" id="menu-right" onClick={this.closeMenu}>
        <nav
          className="navbar-default keep-background"
          id="right-nav"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ul className="nav metismenu">
            <li>
              <span>for you</span>
            </li>
            <li>
              <a
                onClick={() => {
                  this.props.myProfile();
                }}
                href="#"
              >
                my profile
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  this.props.logoutUser(refreshToken);
                }}
                href="#"
              >
                logout
              </a>
            </li>
            <li>
              <span>Applications</span>
            </li>
            {cafmAccess && (
              <li className={currentSystem === 'cafm' ? 'active' : undefined}>
                <a href="/cafm">CAFM</a>
              </li>
            )}
            {alpAccess && (
              <li className={currentSystem === 'alp' ? 'active' : undefined}>
                <a href="/hr">ALP</a>
              </li>
            )}
            {jmsAccess && (
              <li className={currentSystem === 'jms' ? 'active' : undefined}>
                <a href="/oms">JMS</a>
              </li>
            )}

            {accountUUID && canManageAccount && (
              <li className={currentSystem === '' ? 'active' : undefined}>
                <a href={`/account/${accountUUID}`}>MANAGE ACCOUNT</a>
              </li>
            )}

            {/* <li>
              <a href="/bms/buildings">BMS</a>
            </li> */}
            <li>
              <a href="https://help.octanise.com">HELP</a>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = ({
  tokens: { refreshToken },
  user: { currentSystem, currentActiveAccount, currentuserpermissions },
}) => ({
  refreshToken,
  currentSystem,
  accountUUID: (currentActiveAccount && currentActiveAccount.uuid) || '',
  userPermissions: currentuserpermissions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutUser,
      myProfile,
      loadCurrentSystem,
      getCurrentUserPermissions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(HamburgerMenu);
