import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reduxForm, Field, reset } from 'redux-form';

import FilterButton from './FilterButton';
import DropFilterButton from './DropFilterButton';
import FilterInput from '../input/FilterInput';
import MonthPicker from '../picker/MonthPicker';
import { SelectDropdown, FormDatePicker, SearchableSelectBox } from '../form/parts';

import { COMPARISON } from '../../constants/filterdata';
import userSelectedPreference from '../../lib/userSelectedPreference';

class SmartFilter extends React.Component {
  static propTypes = {
    onFilter: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    filterFields: PropTypes.shape().isRequired,
    filterValues: PropTypes.shape().isRequired,
    formWrapper: PropTypes.shape().isRequired,
    store: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    budgetPeriodFilter: PropTypes.bool,
    categoryGroupFilter: PropTypes.bool,
    categoryGroups: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    budgetPeriodFilter: false,
    categoryGroupFilter: false,
    categoryGroups: [],
  };

  constructor(props) {
    super(props);
    let stateParams = {
      chosenField: null,
      chosenComparison: 'equal',
      chosenBool: null,
      chosenArray: [],
      nestedArray: [],
      nestedFilter: false,
      boolOptions: [],
      boolValues: {},
      strDate: null,
      startDate: null,
      endDate: null,
      chosenArrayInfo: null,
      nestedArrayInfo: null,
      value: null,
    };
    const { user } = props;
    const tempFilterData = userSelectedPreference.getSpecificTableFilterData({
      currentPageUrl: typeof window !== 'undefined' ? window.location.pathname : '',
      userId: user && user.id,
    });
    if (tempFilterData && tempFilterData.filterFieldInfo) {
      stateParams = tempFilterData.filterFieldInfo;
    }
    this.state = stateParams;
  }

  componentWillReceiveProps(nextProps) {
    const { filterFields, formWrapper } = nextProps;
    const prevForm = this.props.formWrapper.filterForm;
    const values = formWrapper && formWrapper.filterForm && formWrapper.filterForm.values;
    if (values && values.nestedValue) {
      // // && values.nestedValue[0]
      const { chosenField } = this.state;
      const nestedValue = values.nestedValue.info; // // values.nestedValue[0]
      const nestedArrayName = filterFields[chosenField].nestedArray;
      const nestedArray = nestedValue[nestedArrayName];
      if (prevForm && prevForm.values && values) {
        if (prevForm.values.nestedValue != values.nestedValue) {
          formWrapper.filterForm.values.value = '';
        }
      }
      this.setState({
        nestedArray,
      });
    }
  }

  onFieldSelectChange = (option) => {
    if (option && option.hasOwnProperty('label')) {
      option = option.value;
    } else {
      option = undefined;
    }
    let { strDate } = this.state;
    const { filterFields, filterValues, store, t } = this.props;
    if (option !== 'startDate' || option !== 'endDate') {
      strDate = null;
    }
    let chosenArray = [];
    const arrayName = option && filterFields[option].array;
    const nestedArrayName = option && filterFields[option].nestedArray;
    const typeName = option && filterFields[option].type;

    if (arrayName) {
      store.dispatch(reset('filterForm'));
      chosenArray = filterValues[arrayName];
    }

    let nestedFilter = false;
    if (nestedArrayName) {
      nestedFilter = true;
    }

    let boolOptions = [];
    let boolValues = {};
    let chosenBool = null;
    if (typeName === 'boolean') {
      store.dispatch(reset('filterForm'));
      boolOptions = option && filterFields[option].boolArray.map((item) => t(`${item}`));
      boolValues = {
        [boolOptions[0]]: true,
        [boolOptions[1]]: false,
      };
      chosenBool = boolOptions[0];
    }

    this.setState({
      chosenField: option,
      strDate,
      chosenArray,
      chosenBool,
      nestedFilter,
      boolOptions,
      boolValues,
    });
  };

  onComparisonSelectChange = (option) => {
    if (option.hasOwnProperty('label')) {
      option = option.value;
    } else {
      option = undefined;
    }
    this.setState({ chosenComparison: option });
  };

  onBoolSelectChange = (option) => {
    if (option.hasOwnProperty('label')) {
      option = option.value;
    } else {
      option = undefined;
    }
    this.setState({ chosenBool: option });
  };

  handleFilter = (filterData) => {
    const {
      chosenField,
      chosenComparison,
      chosenBool,
      strDate,
      boolValues,
      startDate,
      endDate,
      nestedArray,
      nestedFilter,
      boolOptions,
      chosenArray,
    } = this.state;
    let { url, user } = this.props;
    if (!url || url === '') {
      url = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    }
    let filterParams = {};
    let chosenOption; // // = filterData.value && filterData.value[0];
    const chosenArrayInfo = {};
    const nestedArrayInfo = {};
    if (!chosenField) {
      const params = {
        startDate,
        endDate,
      };
      filterParams = _.pickBy(params, (property) => property);
    } else {
      let { type, value } = this.props.filterFields[chosenField];
      let filterValue = null;
      if (filterData.value && filterData.value != '') {
        if (typeof filterData.value === 'object') {
          chosenOption = filterData.value.info;
          chosenArrayInfo.label = filterData.value.label;
          chosenArrayInfo.value = filterData.value.value;
          // if Contact then it has supplierCompany && supplierContact dropdown
          if (filterData.nestedValue && Object.keys(filterData.nestedValue).length > 0) {
            chosenArrayInfo.label = filterData.nestedValue.label; // suppier company
            chosenArrayInfo.value = filterData.nestedValue.value;
            nestedArrayInfo.label = filterData.value.label; // suppier contact
            nestedArrayInfo.value = filterData.value.value;
          }
        } else {
          chosenOption = filterData.value;
        }
      } else if (filterData.nestedValue && filterData.nestedValue != '') {
        if (chosenField === 'contact') {
          value = 'supplierCompanyId';
        } else if (chosenField === 'model') {
          // check for pass multipleValue (manufacturer > Model(allValue)) as we don't have manufacturerId in asset table
          value = 'manufacturerId';
        }
        if (typeof filterData.nestedValue === 'object') {
          chosenOption = filterData.nestedValue.info;
        } else {
          chosenOption = filterData.nestedValue;
        }
      }

      switch (type) {
        case 'date':
          filterValue = strDate;
          break;
        case 'boolean':
          filterValue = boolValues[chosenBool];
          break;
        case 'number':
          filterValue = filterData.value;
          break;
        case 'like':
          filterValue = filterData.value;
          break;
        default:
          filterValue = chosenOption[value] || chosenOption.id;
      }
      filterParams = {
        filterField: value,
        fieldComparisonType: type === 'like' ? 'like' : COMPARISON[chosenComparison],
        filterValue,
        startDate,
        endDate,
      };
      filterParams = _.pickBy(filterParams, (property) => property !== undefined && property !== null);
    }
    if (filterData.group) {
      // const categoryGroup = _.find(categoryGroups, group => filterData.group === group.name);
      filterParams.budgetCategoryGroupUUID = filterData.group.value; // categoryGroup.uuid;
    }
    userSelectedPreference.setUserSelectedPreference({
      currentPageUrl: url.asPath,
      userId: user && user.id,
      tableFilterPreference: {
        filterFieldInfo: {
          chosenField,
          strDate,
          chosenArray,
          chosenBool,
          nestedArray,
          nestedFilter,
          boolOptions,
          boolValues,
          startDate,
          endDate,
          chosenComparison,
          chosenArrayInfo,
          nestedArrayInfo,
          value: chosenOption,
        },
      },
    });
    this.props.onFilter(filterParams);
  };

  handleDropFilter = () => {
    this.setState({
      chosenField: null,
      chosenComparison: 'equal',
      chosenBool: null,
      startDate: null,
      endDate: null,
      nestedFilter: false,
    });
    let { url, user } = this.props;
    if (!url || url === '') {
      url = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    }
    userSelectedPreference.setUserSelectedPreference({
      currentPageUrl: url.asPath,
      userId: user && user.id,
      isFilterResetOnDropFilter: true,
    });
    this.props.store.dispatch(reset('filterForm'));
    this.props.onFilter({});
  };

  handleDate = (strDate) => {
    this.setState({ strDate });
  };

  handleBudgetStart = (startDate) => {
    this.setState({ startDate });
  };

  handleBudgetEnd = (endDate) => {
    this.setState({ endDate });
  };

  render() {
    const { handleSubmit, filterFields, budgetPeriodFilter, categoryGroupFilter, categoryGroups, formWrapper, t } =
      this.props;
    const {
      chosenField,
      chosenComparison,
      chosenBool,
      chosenArray,
      nestedArray,
      nestedFilter,
      boolOptions,
      startDate,
      endDate,
      chosenArrayInfo,
      nestedArrayInfo,
      value,
      strDate,
    } = this.state;
    const { type } = chosenField ? filterFields[chosenField] : {};
    const showComparisonSelect = chosenField && type !== 'string' && type !== 'boolean' && type !== 'like';
    const showBoolSelect = chosenField && type === 'boolean';
    const showFilterInput = chosenField && type === 'string' && !nestedFilter;
    const showSearchInput = chosenField && type === 'like';
    const showNumberInput = chosenField && type === 'number';
    const showMonthPicker = chosenField && type === 'date';
    const categoryGroupNames = categoryGroups; // .map(group => group.name);
    const areValuesExist = formWrapper.filterForm && formWrapper.filterForm.values;
    const isCategoryGroupChosen = areValuesExist && formWrapper.filterForm.values.group;
    const field = filterFields[chosenField];
    const useNameAsLabel = field && field.value.slice(-2) === 'Id';

    return (
      <form onSubmit={handleSubmit(this.handleFilter)}>
        <div className="filter-wrapper p-4 justify-content-between">
          <Field
            name="filterFields"
            component={SelectDropdown}
            drpName="filterFields"
            options={Object.keys(filterFields)}
            _noLabelValue
            placeholder={t('Choose a field')}
            onChange={this.onFieldSelectChange}
            width="180px"
            defaultValue={{
              label: chosenField,
              value: chosenField,
            }}
          />

          {showBoolSelect && (
            <div>
              <Field
                name="boolOptions"
                component={SelectDropdown}
                drpName="boolOptions"
                options={boolOptions}
                _noLabelValue
                placeholder={t('Select value')}
                onChange={this.onBoolSelectChange}
                width="210px"
                isClearable={false}
                defaultValue={{
                  label: chosenBool,
                  value: chosenBool,
                }}
              />
            </div>
          )}

          {showComparisonSelect && (
            <div>
              <Field
                name="comparisonOptions"
                component={SelectDropdown}
                drpName="comparisonOptions"
                options={Object.keys(COMPARISON)}
                _noLabelValue
                placeholder={t('operation')}
                onChange={this.onComparisonSelectChange}
                width="210px"
                isClearable={false}
                defaultValue={{
                  label: chosenComparison,
                  value: chosenComparison,
                }}
              />
            </div>
          )}

          {!showComparisonSelect && !showBoolSelect && <div className="comparison-gap" />}

          {(!chosenField || showBoolSelect || nestedFilter) && <div className="value-gap" />}

          {showFilterInput && (
            <div className="multi-select-filter-wrapper">
              <Field
                name="value"
                component={SelectDropdown}
                drpName="value"
                options={chosenArray}
                _optionLabel={field.label || (useNameAsLabel ? 'name' : field.value)}
                _optionValue="id"
                isOptionFullInfoRequired
                placeholder={t('Enter value')}
                defaultValue={
                  chosenArrayInfo && {
                    label: chosenArrayInfo.label,
                    value: chosenArrayInfo.value,
                  }
                }
              />
            </div>
          )}

          {showSearchInput && (
            <div className="multi-select-filter-wrapper">
              <Field
                name="value"
                component={SearchableSelectBox}
                drpName="value"
                options={chosenArray}
                _optionLabel={field.label || (useNameAsLabel ? 'name' : field.value)}
                _optionValue="id"
                isOptionFullInfoRequired
                placeholder={t('Enter value')}
                inputSearchString={value || ''}
                defaultValue={
                  chosenArrayInfo && {
                    label: chosenArrayInfo.label,
                    value: chosenArrayInfo.value,
                  }
                }
              />
            </div>
          )}

          {showNumberInput && (
            <Field label={t('Enter value')} component={FilterInput} name="value" type="text" selectedValue={value} />
          )}

          {showMonthPicker && (
            <div className="navbar-form">
              <Field
                name="firstTask"
                component={FormDatePicker}
                placeholder={t('Select Date')}
                onChangeExternal={this.handleDate}
                input={{
                  value: strDate,
                  onChange: () => {},
                }}
              />
            </div>
          )}

          <FilterButton
            width="90px"
            onSubmit={handleSubmit(this.handleFilter)}
            disabled={!chosenField && !startDate && !endDate && !isCategoryGroupChosen}
            t={t}
          />

          <DropFilterButton width="120px" onDrop={this.handleDropFilter} t={t} />
        </div>

        {budgetPeriodFilter && (
          <div className="filter-wrapper">
            <MonthPicker changeDate={this.handleBudgetStart} placeholder={t('Budget period from')} />

            <MonthPicker changeDate={this.handleBudgetEnd} placeholder={t('Budget period to')} />
          </div>
        )}

        {categoryGroupFilter && (
          <div className="filter-wrapper">
            <Field
              name="group"
              component={SelectDropdown}
              drpName="group"
              options={categoryGroupNames}
              _optionLabel="name"
              _optionValue="uuid"
              isOptionFullInfoRequired
              placeholder={this.props.t('Category group')}
              width="180px"
            />
          </div>
        )}
        {nestedFilter && (
          <div className="filter-wrapper">
            <div className="multi-select-filter-wrapper">
              <Field
                name="nestedValue"
                component={SelectDropdown}
                drpName="nestedValue"
                options={chosenArray}
                _optionLabel={field.label || (useNameAsLabel ? 'name' : field.value)}
                _optionValue="id"
                isOptionFullInfoRequired
                placeholder={t('Enter value')}
                defaultValue={
                  chosenArrayInfo && {
                    label: chosenArrayInfo.label,
                    value: chosenArrayInfo.value,
                  }
                }
              />
            </div>
            {nestedArray.length > 0 && (
              <div className="multi-select-filter-wrapper">
                <Field
                  name="value"
                  component={SelectDropdown}
                  drpName="value"
                  options={nestedArray}
                  _optionLabel={
                    nestedArray && nestedArray[0].hasOwnProperty('fullName')
                      ? 'fullName'
                      : field.label || (useNameAsLabel ? 'name' : field.value)
                  }
                  _optionValue="id"
                  isOptionFullInfoRequired
                  placeholder={t('Enter value')}
                  defaultValue={
                    nestedArrayInfo && {
                      label: nestedArrayInfo.label,
                      value: nestedArrayInfo.value,
                    }
                  }
                />
              </div>
            )}
          </div>
        )}
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  const { form, user } = state;

  return {
    formWrapper: form,
    user: user.currentuser,
  };
};

export default connect(
  mapStateToProps,
  null,
)(
  reduxForm({
    form: 'filterForm',
  })(SmartFilter),
);
