import React from 'react';
import PropTypes from 'prop-types';

const displayArrows = (order) => {
  switch (order) {
    case 'NO':
      return 'sorting';
    case 'ASC':
      return 'sorting_asc';
    case 'DESC':
      return 'sorting_desc';
    case 'DISABLED':
      return 'sorting_disabled';
    default:
      return 'sorting_disabled';
  }
};

const TableHeader = ({ headers, onSort, extended, showIntable, t, multiDeleteMode, isCheckBoxOnEachRow }) => (
  <div className="dataTables_scrollHead table_custom_header">
    <div className="dataTables_scrollHeadInner table_custom_headInner">
      <table className="display dataTable no-footer" role="grid" style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr role="row">
            {headers.map((header) =>
              !(multiDeleteMode || isCheckBoxOnEachRow) && header.type == 'checkbox' ? (
                ''
              ) : (
                <th
                  style={
                    extended ? { minWidth: header.width, outline: 'none' } : { width: header.width, outline: 'none' }
                  }
                  key={header.name}
                  className={displayArrows(header.order)}
                  onClick={() => {
                    onSort && onSort(header);
                  }}
                >
                  {/\d{4}/gm.test(header.label) || header.doNotTranslate ? header.label : t(`${header.label}`)}
                </th>
              ),
            )}
            {showIntable && (
              <th className="action-column sorting_disabled" style={{ width: '4%' }}>
                &nbsp;
              </th>
            )}
          </tr>
        </thead>
      </table>
    </div>
  </div>
);

TableHeader.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showIntable: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onSort: PropTypes.func,
  extended: PropTypes.bool,
  multiDeleteMode: PropTypes.bool,
  isCheckBoxOnEachRow: PropTypes.bool,
};

TableHeader.defaultProps = {
  onSort: undefined,
  extended: false,
};

export default TableHeader;
