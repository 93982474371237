import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInput, SelectDropdown } from '../../form/parts';
import { getEnabledOptions } from '../../../constants/options';
import HelpdeskCategoryTopicPicture from '../../../containers/decorate/HelpdeskCategoryTopicPicture';

const ProblemCategoryEntryView = ({ t, args }) => (
  <div>
    <Field label={t('Name *')} component={FormInput} name="name" type="text" placeholder={t('Enter name')} />

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Enabled')}</label>

      <div className="col-sm-8">
        <Field
          name="enabled"
          component={SelectDropdown}
          options={getEnabledOptions(t)}
          _noLabelValue
          drpName="enabled"
          placeholder={t('Enabled (optional)')}
        />
      </div>
    </div>

    <Field label={t('Order')} component={FormInput} name="order" type="number" placeholder={t('Order (optional)')} />

    {/* TODO: Uncomment with app release */}

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Image')}</label>

      <div className="col-sm-8">
        <HelpdeskCategoryTopicPicture
          t={t}
          initialPictures={args.imageLarge ? [{ imageLarge: `${args.imageLarge}` }] : []}
        />
      </div>
    </div>
  </div>
);

const problemTopicProps = PropTypes.shape({
  imageLarge: PropTypes.string,
  imageSmall: PropTypes.string,
}).isRequired;

ProblemCategoryEntryView.propTypes = {
  args: problemTopicProps,
  t: PropTypes.func.isRequired,
};

ProblemCategoryEntryView.defaultProps = {
  args: {
    imageLarge: undefined,
    imageSmall: undefined,
  },
};

export default ProblemCategoryEntryView;
