import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

class FormDatePicker extends React.Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.bool,
    }),
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    isClearable: PropTypes.bool,
    showTimeSelect: PropTypes.bool,
    showTimeInput: PropTypes.bool,
    timeInputLabel: PropTypes.string,
    showWeekNumbers: PropTypes.bool,
    onChangeExternal: PropTypes.func,
  };

  static defaultProps = {
    placeholder: '',
    disabled: false,
    isClearable: true,
    showTimeSelect: false,
    showTimeInput: false,
    timeInputLabel: 'Time:',
    showWeekNumbers: false,
    onChangeExternal: undefined,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (date) => {
    const { onChangeExternal, showTimeInput, showTimeSelect } = this.props;
    let momentFormat = 'YYYY-MM-DD';
    if (onChangeExternal) {
      if (showTimeInput || showTimeSelect) momentFormat = `${momentFormat} HH:mm`;
    }
    if (!date) {
      this.props.input.onChange('');
      if (onChangeExternal) onChangeExternal(null);
    } else {
      this.props.input.onChange(date);
      if (onChangeExternal) onChangeExternal(moment(date).format(momentFormat));
    }
  };

  render() {
    const {
      input,
      placeholder,
      meta: { touched, error },
      disabled,
      isClearable,
      showTimeSelect,
      showTimeInput,
      timeInputLabel,
      showWeekNumbers,
    } = this.props;
    let selectedValue;
    let datePickerFormat = 'dd/MM/yyyy';
    let momentFormat = 'DD/MM/YYYY';
    if (showTimeInput || showTimeSelect) {
      datePickerFormat = `${datePickerFormat} HH:mm`;
      momentFormat = input.value && input.value.length > 10 ? `${momentFormat} HH:mm` : momentFormat;
    }
    const tempMoment = input.value && moment(input.value, momentFormat, true);
    if (tempMoment) {
      if (tempMoment.isValid()) selectedValue = new Date(tempMoment);
      else if (moment(input.value).isValid()) selectedValue = new Date(moment(input.value));
    } else {
      selectedValue = null;
    }
    return (
      <>
        <DatePicker
          {...input}
          className="form-control input-lg ignore-change date-input"
          placeholderText={placeholder}
          dateFormat={datePickerFormat}
          selected={selectedValue}
          value={selectedValue}
          onChange={(date) => this.handleChange(date)}
          disabled={disabled}
          isClearable={isClearable}
          autoComplete="off"
          showTimeInput={showTimeInput}
          showTimeSelect={showTimeSelect}
          timeFormat="HH:mm"
          timeInputLabel={timeInputLabel}
          showMonthDropdown={true}
          showYearDropdown={true}
          dropdownMode="select"
          showWeekNumbers={showWeekNumbers}
        />
        {touched && error && <span>{error}</span>}
      </>
    );
  }
}

export default FormDatePicker;
