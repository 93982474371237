import {
  LOAD_LOCATION,
  LOAD_LOCATIONS,
  LOAD_PARENT_LOCATIONS,
  LOAD_LOCATION_COUNT,
  SHOW_LOADER,
  HIDE_LOADER,
  LOAD_CHILD_LOCATIONS,
  LOAD_CHILD_LOCATION_COUNT,
  LOAD_CHILD_LOCATION,
  LOAD_ONLY_PARENT_LOCATIONS,
} from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/location';

const adjustLocations = (location) => {
  if (location.type === 1000) {
    location.locationType = 'Roof';
  } else if (location.type || location.type === 0) {
    location.locationType = location.type;
  }
  location.inactiveLocation = location.disabled ? 'yes' : 'no';
  location.locationName = null;
  if (location.ParentLocation) {
    location.locationName = location.ParentLocation.name;
  }
};

export const loadLocation = (location) => ({
  type: LOAD_LOCATION,
  payload: location,
});

export const loadLocations = (locations) => ({
  type: LOAD_LOCATIONS,
  payload: locations,
});

export const loadParentLocations = (parentLocations) => ({
  type: LOAD_PARENT_LOCATIONS,
  payload: parentLocations,
});

export const loadCount = (count) => ({
  type: LOAD_LOCATION_COUNT,
  payload: count,
});

export const loadChildLocations = (locations) => ({
  type: LOAD_CHILD_LOCATIONS,
  payload: locations,
});

export const loadChildCount = (count) => ({
  type: LOAD_CHILD_LOCATION_COUNT,
  payload: count,
});

export const loadChildLocation = (location) => ({
  type: LOAD_CHILD_LOCATION,
  payload: location,
});

export const loadOnlyParentLocations = (parentLocations) => ({
  type: LOAD_ONLY_PARENT_LOCATIONS,
  payload: parentLocations,
});

export const createLocation = (location) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/create`, location)
    .then((response) => {
      if (!response.data.isParent && response.data.locationId > 0) {
        dispatch(loadChildLocation(response.data));
        Router.pushRoute('cafm/admin/child_location_profile', { location_id: response.data.id });
      } else {
        dispatch(loadLocation(response.data));
        Router.pushRoute('cafm/admin/location_profile', { location_id: response.data.id });
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, createLocation, location);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const quickCreateLocation = (location) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/create`, location)
    .then((response) => {
      dispatch(loadLocation(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, quickCreateLocation, location);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateLocation = (location) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  const isChildUpdate = location.isChildUpdate;
  const urlPath = location.urlPath;
  delete location.isChildUpdate;
  delete location.urlPath;
  api
    .put(`${routePath}/update`, location)
    .then((response) => {
      const newLocation = response.data;
      adjustLocations(newLocation);
      dispatch(loadLocation({ ...newLocation, isChildUpdate: isChildUpdate }));
      // if Child Location Profile > Edit && remove Parent then child will be flat/Parent and redirect on parent profile
      if (
        (!newLocation.locationId || newLocation.locationId <= 0) &&
        isChildUpdate &&
        urlPath == '/cafm/admin/child_location_profile'
      ) {
        Router.pushRoute('cafm/admin/location_profile', { location_id: newLocation.id });
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, updateLocation, location);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getLocation =
  (id, path = null) =>
  (dispatch) => {
    dispatch({ type: SHOW_LOADER });

    api
      .get(`${routePath}/get-item/${id}`)
      .then((response) => {
        const newLocation = response.data;
        adjustLocations(newLocation);
        if (path && path.isChildProfile) {
          dispatch(loadChildLocation(newLocation));
        } else {
          dispatch(loadLocation(newLocation));
        }
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response?.data));
        handleErrors(error, dispatch, getLocation, id);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const deleteLocation = (id, path) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${id}`)
    .then(() => {
      const item = {
        id,
        name: null,
      };
      if (path && path.locationId && path.locationId > 0 && path.isChildDelete) {
        dispatch(loadChildLocation(item));
      } else {
        dispatch(loadLocation(item));
      }
      if (path && path.sid) {
        Router.pushRoute('cafm/admin/site_profile', path);
      } else if (path && path.locationId && path.locationId > 0 && path.isChildDelete) {
        Router.pushRoute('cafm/admin/location_profile', { location_id: path.locationId });
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, deleteLocation, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getLocationList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      const newLocations = response.data;
      newLocations.forEach(adjustLocations);
      if (params.isOnlyParent) {
        dispatch(loadLocations(newLocations));
      } else {
        dispatch(loadChildLocations(newLocations));
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getLocationList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export function formatLocationData(data) {
  try {
    let finatList = [];
    const parentList = data.filter((e) => e.isParent);
    const childList = data.filter((e) => !e.isParent);
    let leftList = [...childList];

    for (let index = 0; index < parentList.length; index++) {
      const ele = parentList[index];
      ele.key = ele.id.toString();
      ele.label = ele.name;
      ele.data = ele.id;
      ele.icon = 'pi pi-fw';
      ele.children = groupBy(ele, childList);
    }

    function groupBy(item, cl) {
      const cData = cl.filter((e) => e.locationId === item.id);
      leftList = leftList.filter((e) => e.locationId !== item.id);
      for (let index = 0; index < cData.length; index++) {
        const ele = cData[index];
        ele.key = item.key + '-' + ele.id;
        ele.label = ele.name;
        ele.data = ele.id;
        ele.icon = 'pi pi-fw';
        ele.children = groupBy(ele, childList);
      }
      return cData;
    }
    if (leftList.length > 0) {
      for (let index = 0; index < leftList.length; index++) {
        const ele = leftList[index];
        ele.key = ele.id.toString();
        ele.label = ele.name;
        ele.data = ele.id;
        ele.icon = 'pi pi-fw';
        ele.children = [];
      }
    }
    finatList = [...parentList, ...leftList];
    return finatList;
  } catch (error) {
    return [];
  }
}

export function getLocationObj(list, key, checkType = 'key') {
  let result = null;
  try {
    for (let index = 0; index < list.length; index++) {
      const ele = list[index];
      if (ele[checkType].toString() == key.toString()) {
        result = ele;
      } else if (ele.children.length > 0) {
        result = getLocationObj(ele.children, key, checkType);
      }

      if (result != null) {
        return result;
      }
    }
    return result;
  } catch (error) {
    return result;
  }
}

export const getLocationFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-full-list`, { params })
    .then((response) => {
      const data = response.data;
      if (params?.isOnlyParent) {
        dispatch(loadOnlyParentLocations(response.data));
      } else {
        const formatedLocation = formatLocationData(data);
        dispatch(loadParentLocations({ parent: response.data, parentTree: formatedLocation }));
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getLocationFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getLocationListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list-with-size`, { params })
    .then((response) => {
      const newLocations = response.data.rows;
      newLocations.forEach(adjustLocations);
      if (params.isOnlyParent) {
        dispatch(loadLocations(newLocations));
        dispatch(loadCount(response.data.count));
      } else {
        dispatch(loadChildLocations(newLocations));
        dispatch(loadChildCount(response.data.count));
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getLocationListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const disableLocation = (location) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .put(`${routePath}/update-enable-disable`, {
      id: location.id,
      disabled: !location.disabled,
    })
    .then((response) => {
      const newLocation = response.data;
      adjustLocations(newLocation);
      dispatch(loadLocation({ ...newLocation, isChildUpdate: location.isChildUpdate }));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, disableLocation, location);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
