import { LOAD_BUILDING_BUDGET_CATEGORY, LOAD_BUILDING_BUDGET_CATEGORIES, LOAD_COUNT } from '../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  list: [],
};

const buildingBudgetCategory = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_BUILDING_BUDGET_CATEGORY: {
      const newItem = action.payload;
      if (action.payload && !action.payload.BudgetCategory) {
        const { item } = state;
        newItem.BuildingBudget = item.BuildingBudget;
        newItem.BudgetCategory = item.BudgetCategory;
      }
      return { ...state, item: newItem };
    }
    case LOAD_BUILDING_BUDGET_CATEGORIES: {
      return { ...state, list: action.payload };
    }
    case LOAD_COUNT:
      return { ...state, count: action.payload };
    default:
      return state;
  }
};

export default buildingBudgetCategory;
