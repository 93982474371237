import {
  LOAD_LOOKUPVALUE,
  LOAD_LOOKUPVALUES,
  LOAD_LOOKUPVALUE_COUNT,
  LOAD_LOOKUPVALUES_TAGS,
} from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  list: [],
};

const lookupvalue = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_LOOKUPVALUE: {
      const item = action.payload;
      let { list, count } = state;
      if (item.lookupvalue !== null) {
        if (list.find((element) => element.lookupvaluesid === item.lookupvaluesid) === undefined) {
          list.push(item);
          count += 1;
        }
        list = list.map((listItem) => (item.lookupvaluesid === listItem.lookupvaluesid ? item : listItem));
      } else {
        list = list.filter((listItem) => item.lookupvaluesid !== listItem.lookupvaluesid);
        count -= 1;
      }
      return {
        item,
        list,
        count,
      };
    }
    case LOAD_LOOKUPVALUES:
      return { ...state, list: action.payload };
    case LOAD_LOOKUPVALUE_COUNT:
      return { ...state, count: action.payload };
    case LOAD_LOOKUPVALUES_TAGS:
      return { ...state, tagsList: action.payload };
    default:
      return state;
  }
};

export default lookupvalue;
