import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const AddUserToSendListView = ({ addUser, t, isEmpty }) => (
  <div className="wrap-for-max-width">
    <div className="text-right profile-buttons">
      <button
        className={classNames('filter-button', { 'button-disabled': isEmpty })}
        onClick={addUser}
        disabled={isEmpty}
      >
        {t('Add User')}
      </button>
    </div>
  </div>
);

AddUserToSendListView.propTypes = {
  addUser: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isEmpty: PropTypes.bool.isRequired,
};

export default AddUserToSendListView;
