import React from 'react';
import PropTypes from 'prop-types';

import ProfileDataView from './ProfileDataView';

const ProfileView = ({ viewOptions, t }) => (
  <div className="row form-horizontal">
    {viewOptions.map((option) => (
      <div key={option.label}>
        {!option.isCustomText && <ProfileDataView label={t(`${option.label}`)} value={option.value || t('none')} />}
        {option.isCustomText && (
          <div className="form-group">
            <label className="col-sm-6 control-label">{option.label}</label>
          </div>
        )}
      </div>
    ))}
  </div>
);

ProfileView.propTypes = {
  viewOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  t: PropTypes.func.isRequired,
};

export default ProfileView;
