import {
  SHOW_LOADER,
  HIDE_LOADER,
  LOAD_ROLES_LIST_DATA,
  LOAD_ROLES_FULL_LIST_DATA,
  LOAD_ROLES_LIST_COUNT,
  LOAD_ROLE_DATA,
  LOAD_ROLE_MEMBERS_LIST_DATA,
  LOAD_ROLE_MEMBERS_FULL_LIST_DATA,
  LOAD_ROLE_MEMBERS_LIST_COUNT,
  LOAD_SITE_ROLE_MEMBERS_LIST_DATA,
  LOAD_SITE_ROLE_MEMBERS_FULL_LIST_DATA,
  LOAD_SITE_ROLE_MEMBERS_LIST_COUNT,
  LOAD_PERMISSIONS_LIST_DATA,
  ADD_ROLE_DATA,
  EDIT_ROLE_DATA,
  REMOVE_ROLE_DATA,
  ADD_ROLE_MEMBER_DATA,
  REMOVE_ROLE_MEMBER_DATA,
  REMOVE_SITE_ROLE_MEMBER_DATA,
  LOAD_ROLES_LIST_USER_NOT_ADDED,
} from '../types';

import { api } from '../../lib/api';
import { apiURL } from '../../../common/config';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

export const loadRolesList = (rolesListData) => ({
  type: LOAD_ROLES_LIST_DATA,
  payload: rolesListData,
});

export const loadRolesCount = (count) => ({
  type: LOAD_ROLES_LIST_COUNT,
  payload: count,
});

export const loadFullRolesList = (rolesListData) => ({
  type: LOAD_ROLES_FULL_LIST_DATA,
  payload: rolesListData,
});

export const loadRole = (roleData) => ({
  type: LOAD_ROLE_DATA,
  payload: roleData,
});

export const loadRoleMembersList = (roleMembersListData) => ({
  type: LOAD_ROLE_MEMBERS_LIST_DATA,
  payload: roleMembersListData,
});

export const loadSiteRoleMembersList = (roleMembersListData) => ({
  type: LOAD_SITE_ROLE_MEMBERS_LIST_DATA,
  payload: roleMembersListData,
});

export const loadFullRoleMembersList = (roleMembersListData) => ({
  type: LOAD_ROLE_MEMBERS_FULL_LIST_DATA,
  payload: roleMembersListData,
});

export const loadFullSiteRoleMembersList = (roleMembersListData) => ({
  type: LOAD_SITE_ROLE_MEMBERS_FULL_LIST_DATA,
  payload: roleMembersListData,
});

export const loadRoleMembersCount = (count) => ({
  type: LOAD_ROLE_MEMBERS_LIST_COUNT,
  payload: count,
});

export const loadSiteRoleMembersCount = (count) => ({
  type: LOAD_SITE_ROLE_MEMBERS_LIST_COUNT,
  payload: count,
});

export const loadPermissionsList = (permissionsListData) => ({
  type: LOAD_PERMISSIONS_LIST_DATA,
  payload: permissionsListData,
});

export const addRole = (role) => ({
  type: ADD_ROLE_DATA,
  payload: role,
});

export const editRole = (role) => ({
  type: EDIT_ROLE_DATA,
  payload: role,
});

export const removeRole = (roleId) => ({
  type: REMOVE_ROLE_DATA,
  payload: roleId,
});

export const addRoleMember = (roleMember) => ({
  type: ADD_ROLE_MEMBER_DATA,
  payload: roleMember,
});

export const removeRoleMember = (roleMember) => ({
  type: REMOVE_ROLE_MEMBER_DATA,
  payload: roleMember,
});

export const removeSiteRoleMember = (siteRoleMember) => ({
  type: REMOVE_SITE_ROLE_MEMBER_DATA,
  payload: siteRoleMember,
});

export const loadRolesListUserNotAdded = (rolesListData) => ({
  type: LOAD_ROLES_LIST_USER_NOT_ADDED,
  payload: rolesListData,
});

export const getRolesList = () => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get('/v2/role/get-full-list')
    .then((response) => dispatch(loadFullRolesList(response.data)))
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getRolesList);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};

export const getRolesListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get('/v2/role/get-list-with-size', { params })
    .then((response) => {
      dispatch(loadRolesList(response.data.rows));
      dispatch(loadRolesCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getRolesList);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};

export const getRole = (roleId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`/v2/role/${roleId}`)
    .then((response) => dispatch(loadRole(response.data)))
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getRole, roleId);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};

export const getRoleMembersList = () => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get('/v2/role/get-members')
    .then((response) => dispatch(loadFullRoleMembersList(response.data)))
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getRoleMembersList);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};

export const getSiteRoleMembersList = () => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get('/v2/role/get-site-members')
    .then((response) => dispatch(loadFullSiteRoleMembersList(response.data)))
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getSiteRoleMembersList);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};

export const getRoleMembersListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get('/v2/role/get-members-with-size', { params })
    .then((response) => {
      dispatch(loadRoleMembersList(response.data.rows));
      dispatch(loadRoleMembersCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getRoleMembersList);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};

export const getSiteRoleMembersListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get('/v2/role/get-site-members-with-size', { params })
    .then((response) => {
      dispatch(loadSiteRoleMembersList(response.data.rows));
      dispatch(loadSiteRoleMembersCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getSiteRoleMembersList);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};

export const getPermissionsList = () => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get('/v2/permission/get-full-list')
    .then((response) => dispatch(loadPermissionsList(response.data)))
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getPermissionsList);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};

export const createRole = (role) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  return api
    .post('/v2/role/create', role)
    .then((response) => dispatch(addRole(response.data)))
    .then(() => Router.pushRoute('/roles'))
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createRole, role);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};

export const updateRole = (role) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  return api
    .post('/v2/role/update', role)
    .then((response) => dispatch(editRole(response.data)))
    .then(() => Router.pushRoute('/roles'))
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateRole, role);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};

export const deleteRole = (roleId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  return api
    .post('/v2/role/delete', { id: roleId })
    .then(() => dispatch(removeRole(roleId)))
    .then(() => Router.pushRoute('/roles'))
    .catch((error) => {
      dispatch(showErrorModal(error.response));
      handleErrors(error, dispatch, deleteRole, roleId);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};

export const addUserToRole = (userId, roleId, siteId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  return api
    .post('/v2/role/add-user-to-role', { userId, roleId, siteId })
    .then((response) => dispatch(addRoleMember(response.data)))
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateRole, userId, roleId, siteId);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};

export const removeUserFromRole = (userId, roleId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  return api
    .post('/v2/role/remove-user-from-role', { userId, roleId })
    .then((response) => dispatch(removeRoleMember({ userId, roleId })))
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateRole, userId, roleId);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};

export const removeUserFromSiteRole = (userId, roleId, siteId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  return api
    .post('/v2/role/remove-user-from-site-role', { userId, roleId, siteId })
    .then((response) => dispatch(removeSiteRoleMember({ userId, roleId, siteId })))
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateRole, userId, roleId, siteId);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};

export const getRolesListUserNotAdded = (userId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`/v2/role/get-role-user-not-added/${userId}`)
    .then((response) => {
      dispatch(loadRolesListUserNotAdded(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getRolesListUserNotAdded);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};
