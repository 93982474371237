import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import { FormInput, FormQuill } from '../../form/parts';
import { SimpleModal } from '../../../components/modals';
import { createAssetNote, deleteAssetNote, getAssetNotesByAsset } from '../../../actions/cafm/AssetNoteActions';

class AssetNoteEntryListView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    createForm: PropTypes.shape(),
    handleSubmit: PropTypes.func.isRequired,
    createAssetNote: PropTypes.func.isRequired,
    deleteAssetNote: PropTypes.func.isRequired,
    getAssetNotesByAsset: PropTypes.func.isRequired,
    assetId: PropTypes.number.isRequired,
    siteId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    createForm: {
      assetNoteEntryForm: {},
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      deleteAssetNoteModal: {
        id: undefined,
        assetId: undefined,
        open: false,
      },
    };

    props.getAssetNotesByAsset({ assetId: props.assetId, siteId: props.siteId });
  }

  componentWillReceiveProps(nextProps) {
    const prevForm = this.props.createForm.assetNoteEntryForm;
    if (this.props.notesList !== nextProps.notesList) {
      if (prevForm && prevForm.values) {
        var element = document.getElementsByClassName('ql-editor');
        element[0].innerHTML = '';
      }
    }
  }

  handleDelete = (id, assetId) => {
    this.setState({
      deleteAssetNoteModal: {
        id,
        assetId,
        open: true,
      },
    });
  };

  handleDeleteAssetNote = (id, assetId) => {
    this.props.deleteAssetNote(id, assetId);
  };

  handleDeleteAssetNoteModalsClose = () => {
    this.setState({
      deleteAssetNoteModal: {
        open: false,
        id: undefined,
        assetId: undefined,
      },
    });
  };

  handleFormSubmit = (values) => {
    const { siteId, assetId, createForm } = this.props;
    const params = {
      siteId,
      assetId,
      notes:
        (createForm.assetNoteEntryForm &&
          createForm.assetNoteEntryForm.values &&
          createForm.assetNoteEntryForm.values.description) ||
        '',
    };
    this.props.createAssetNote(params);
  };

  render() {
    const { deleteAssetNoteModal } = this.state;
    const { notesList, t, handleSubmit } = this.props;
    return (
      <div>
        <div className="form-group">
          <form>
            <label className="col-sm-2 control-label">{t('Notes *')}</label>

            <div className="col-sm-6 description-container">
              <Field
                name="description"
                component={FormQuill}
                bounds=".description-container"
                placeholder="Enter a note"
              />
            </div>
          </form>
          <div className="col-sm-2">
            <button type="submit" className="filter-button" onClick={handleSubmit(this.handleFormSubmit)}>
              {t('Add Note')}
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <div className="feed-activity-list">
              {notesList &&
                notesList.map((data) => (
                  <div className="feed-element">
                    <a className="pull-left" href="#"></a>
                    <div className="media-body">
                      <strong>{data.CreatedBy.fullName} </strong>
                      <small className="text-muted">
                        {moment(data.createdAt).calendar(null, { sameElse: 'DD MMM YYYY - HH:mm A' })}
                      </small>
                      <button
                        type="button"
                        id={'btnNote' + data.id}
                        className="delete-note btn btn-xs btn-white"
                        onClick={() => this.handleDelete(data.id, data.assetId)}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                      <div className="well" dangerouslySetInnerHTML={{ __html: data.notes }} />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        {deleteAssetNoteModal.open && (
          <SimpleModal
            open={deleteAssetNoteModal.open}
            id={deleteAssetNoteModal.id}
            onModalClose={() => this.handleDeleteAssetNoteModalsClose()}
            header={t('Delete asset note')}
            text={`${t('Are you sure you want to delete asset note')}?`}
            handleOk={() => this.handleDeleteAssetNote(deleteAssetNoteModal.id, deleteAssetNoteModal.assetId)}
            t={t}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    tokens: { accessToken },
    form,
    assetNotes,
  } = state;

  return {
    accessToken,
    createForm: form,
    notesList: (assetNotes && assetNotes.allNotes) || [],
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createAssetNote,
      deleteAssetNote,
      getAssetNotesByAsset,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'assetNoteEntryForm',
  })(AssetNoteEntryListView),
);
