import {
  SET_ADD_ATTACHMENT_UPLOAD,
  SET_REMOVE_ATTACHMENT_UPLOAD,
  LOAD_MAINTENANCE_SCHEDULE_ATTACHMENTS,
  LOAD_MAINTENANCE_SCHEDULE_ATTACHMENT_COUNT,
  LOAD_MAINTENANCE_SCHEDULE_ATTACHMENT,
  SET_ADD_MULTIPLE_ATTACHMENT_UPLOAD,
  SET_ADD_ATTACHMENT_UPLOAD_CUSTOM,
} from '../../actions/types';

const INITIAL_STATE = {
  pictures: [],
  removed: [],
  dataRowId: null,
  uppyObject: null,
  item: null,
  count: null,
  list: [],
};

const attachmentsUpload = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ADD_ATTACHMENT_UPLOAD_CUSTOM:
      return {
        ...state,
        pictures: action.payload.isReset
          ? [...action.payload.pictures]
          : [...state.pictures, ...action.payload.pictures],
      };
    case SET_ADD_ATTACHMENT_UPLOAD:
    case SET_REMOVE_ATTACHMENT_UPLOAD:
      return {
        ...state,
        ...action.payload,
      };
    case SET_ADD_MULTIPLE_ATTACHMENT_UPLOAD:
      return {
        ...state,
        pictures: [...state.pictures.filter((e) => e.type != 'new'), ...state.pictures, action.payload],
      };
    case LOAD_MAINTENANCE_SCHEDULE_ATTACHMENTS:
      return { ...state, list: action.payload };
    case LOAD_MAINTENANCE_SCHEDULE_ATTACHMENT_COUNT:
      return { ...state, count: action.payload };
    case LOAD_MAINTENANCE_SCHEDULE_ATTACHMENT: {
      const item = action.payload;
      let { list, count } = state;
      if (item.friendlyName !== null) {
        if (list.find((element) => element.id === item.id) === undefined) {
          list.push(item);
        }

        list = list.map((listItem) => (item.id === listItem.id ? item : listItem));
      } else {
        list = list.filter((listItem) => item.id !== listItem.id);
        count -= 1;
      }
      return {
        ...state,
        item,
        list,
        count,
      };
    }
    default:
      return state;
  }
};

export default attachmentsUpload;
