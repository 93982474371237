import axios from 'axios';

import {
  LOAD_USER,
  LOAD_USERS,
  LOAD_ACCOUNT_USERS,
  LOAD_COLOR_SETTINGS,
  LOAD_CURRENT_SYSTEM,
  LOAD_USER_PERMISSIONS,
  SHOW_LOADER,
  HIDE_LOADER,
  LOAD_POST_FORGOT_DATA,
  LOAD_VALIDATE_PASSWORD_RESET_TOKEN_DATA,
  LOAD_POST_RESET_DATA,
  LOAD_POST_SIGNUP_DATA,
  LOAD_VALIDATE_SIGNUP_ACTIVATE_TOKEN_DATA,
  LOAD_POST_SIGNUP_ACTIVATE_DATA,
  LOAD_USER_CHECKRIGHTS,
  LOAD_CURRENT_USER,
  LOAD_CURRENT_USER_PERMISSIONS,
  LOAD_USER_PRODUCT_LIST,
  LOAD_ALL_USERS,
  LOAD_USER_COUNT,
  LOAD_NEW_ADDED_USER,
} from './types';

import { api } from '../lib/api';
import { loginURL, apiURL, entitlementURL, legacyURL } from '../../common/config';
import { Router } from '../../common/routes';
import { loadAccessToken, loadRefreshToken, handleErrors } from './TokenActions';
import { showErrorModal } from './ErrorActions';
import userSelectedPreference from '../lib/userSelectedPreference';
import { loadAccount } from './cafm/AccountActions';
import { loadAccountFullDetail } from './account/SubscriptionActions';

const adjustTaskCommentApprover = (user) => {
  user.label = `${user.firstName} ${user.lastName}`;
  user.value = user.id;
};

const adjustUser = (user) => {
  user.isNotified = user.notified ? 'Yes' : 'No';
  user.isApprover = user.approver ? 'Yes' : 'No';
};

export const loadUser = (user) => ({
  type: LOAD_USER,
  payload: user,
});

export const loadCurrentUser = (currentuser) => ({
  type: LOAD_CURRENT_USER,
  payload: currentuser,
});

export const loadCurrentUserPermissions = (currentUserPermissions) => ({
  type: LOAD_CURRENT_USER_PERMISSIONS,
  payload: currentUserPermissions,
});

export const loadUsers = (users) => ({
  type: LOAD_USERS,
  payload: users,
});

export const loadAccountUsers = (users) => ({
  type: LOAD_ACCOUNT_USERS,
  payload: users,
});

export const loadColorSettings = (colorSettings) => ({
  type: LOAD_COLOR_SETTINGS,
  payload: colorSettings,
});

export const loadCurrentSystem = (currentSystem) => ({
  type: LOAD_CURRENT_SYSTEM,
  payload: currentSystem,
});

export const loadUserPermissions = (userPermissions) => ({
  type: LOAD_USER_PERMISSIONS,
  payload: userPermissions,
});

export const loadPostForgotData = (postForgotData) => ({
  type: LOAD_POST_FORGOT_DATA,
  payload: postForgotData,
});
export const loadValidatePasswordResetTokenData = (validatePasswordResetTokenData) => ({
  type: LOAD_VALIDATE_PASSWORD_RESET_TOKEN_DATA,
  payload: validatePasswordResetTokenData,
});
export const loadPostResetData = (postResetData) => ({
  type: LOAD_POST_RESET_DATA,
  payload: postResetData,
});
export const loadPostSignupData = (postSignupData) => ({
  type: LOAD_POST_SIGNUP_DATA,
  payload: postSignupData,
});
export const loadValidateSignupActivateTokenData = (ValidateSignupActivateTokenData) => ({
  type: LOAD_VALIDATE_SIGNUP_ACTIVATE_TOKEN_DATA,
  payload: ValidateSignupActivateTokenData,
});
export const loadPostSignupActivateData = (postSignupActivateData) => ({
  type: LOAD_POST_SIGNUP_ACTIVATE_DATA,
  payload: postSignupActivateData,
});

export const loadUserCheckRights = (userCheckRights) => ({
  type: LOAD_USER_CHECKRIGHTS,
  payload: userCheckRights,
});

export const loadUserProductList = (userProductList) => ({
  type: LOAD_USER_PRODUCT_LIST,
  payload: userProductList,
});

export const loadAllUsers = (alluser) => ({
  type: LOAD_ALL_USERS,
  payload: alluser,
});

export const loadCount = (count) => ({
  type: LOAD_USER_COUNT,
  payload: count,
});

export const loadNewAddedUser = (newUser) => ({
  type: LOAD_NEW_ADDED_USER,
  payload: newUser,
});

export const loginUser = (user) => async (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  try {
    const response = await axios.post(loginURL, user);
    const {
      data: { accessToken, refreshToken, uuid, accessTokenExpiration },
    } = response;
    dispatch(loadUser(response.data));
    dispatch(loadAccessToken(accessToken));
    dispatch(loadRefreshToken(refreshToken));

    await axios
      .post(`${legacyURL}/interstitial/loader`, {
        accessToken,
        refreshToken,
        accessTokenExpiration,
      })
      .then(() => {
        window.location = `${legacyURL}/interstitial/loader`;
      })
      .catch((error) => {
        window.location = '/auth/logout';
      });
  } catch (error) {
    dispatch(showErrorModal(error.response.data));
    handleErrors(error, dispatch, loginUser, user);
  } finally {
    dispatch({ type: HIDE_LOADER });
  }
};

export const loginConfirm = () => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get('/v1/authAccess/loginConfirm')
    .then((response) => {
      dispatch(loadUser(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, loginConfirm, {});
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const logoutUser = (refreshToken) => (dispatch) => {
  api
    .post('/v1/authAccess/logout', { refreshToken })
    .then(() => {
      userSelectedPreference.clearUserSelectedPreference();
      window.location = `${legacyURL}/auth/logout`;
    })
    .catch((error) => {
      userSelectedPreference.clearUserSelectedPreference();
      window.location = `${legacyURL}/auth/logout`;
      // dispatch(showErrorModal(error.response.data));
      // handleErrors(error, dispatch, logoutUser, { refreshToken });
    });
};

export const getFullUserList = (params) => (dispatch) => {
  api
    .get('/v1/authAccess/getFullUserList', { params })
    .then((response) => {
      dispatch(loadUsers(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getFullUserList, {});
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAccountUserList = (params) => (dispatch) => {
  api
    .get('/v2/user/getAccountUsers', { params })
    .then((response) => {
      dispatch(loadAccountUsers(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getAccountUserList, {});
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getCurrentUserPermissions = () => (dispatch) => {
  api
    .get('/v2/user/getCurrentUserPermissions')
    .then((response) => {
      dispatch(loadCurrentUserPermissions(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data || 'error'));
      handleErrors(error, dispatch, getFullUserList, {});
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getFullUserListbyAccountId = (params) => (dispatch) => {
  api
    .get('/v1/authAccess/getFullUserListbyAccountId', { params })
    .then((response) => {
      dispatch(loadUsers(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getFullUserList, {});
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getCurrentUser = () => (dispatch) => {
  api
    .get('/v1/authAccess/getCurrentUser')
    .then((response) => {
      dispatch(loadCurrentUser(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getFullUserList, {});
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getUserList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get('/v2/userDetails/get-list', { params })
    .then((response) => {
      const newUser = response.data;
      newUser.forEach(adjustUser);
      dispatch(loadUsers(newUser));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getUserList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

const adjustAccount = (account) => {
  account.taskApprovalRequired = account.taskApprovalRequired ? 'Yes' : 'No';
};

export const getJobTimeUpdateEmailData = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`/v2/account/cafm/get-item`)
    .then((response) => {
      const newAccount = response.data;
      adjustAccount(newAccount);
      dispatch(loadAccount(newAccount));
      dispatch(loadAccountFullDetail(newAccount.uuid));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getAccount);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getUserFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get('/v2/userDetails/get-full-list', { params })
    .then((response) => {
      const newUser = response.data;
      newUser.forEach(adjustUser);
      dispatch(loadAllUsers(newUser));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getUserFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getUserListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get('/v2/userDetails/get-list-with-size', { params })
    .then((response) => {
      const newUser = response.data.rows;
      newUser.forEach(adjustUser);
      dispatch(loadUsers(newUser));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getUserListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const myProfile = () => {
  window.location = `${legacyURL}/my/profile`;
};

export const postForgot = (user) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiURL}/v1/access/forgot`, user);
    dispatch(loadPostForgotData(response.data));
  } catch (error) {
    dispatch(showErrorModal(error.response.data));
    handleErrors(error, dispatch, loginUser, user);
  } finally {
    dispatch({ type: HIDE_LOADER });
  }
};

export const validateResetToken = (token) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiURL}/v1/access/validate-reset-token`, { token });
    if (response != undefined && response != null && response.data != null && response.data != undefined) {
      if (response.data.resultKey == 'nodata' || response.data.resultKey == 'error') {
        dispatch(loadValidatePasswordResetTokenData(response.data));
      }
    } else {
      Router.pushRoute('auth/login');
    }
  } catch (error) {
    Router.pushRoute('auth/login');
  } finally {
    dispatch({ type: HIDE_LOADER });
  }
};

export const postReset = (user) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiURL}/v1/access/reset-password`, user);
    dispatch(loadPostResetData(response.data));
  } catch (error) {
    dispatch(showErrorModal(error.response.data));
    handleErrors(error, dispatch, loginUser, user);
  } finally {
    dispatch({ type: HIDE_LOADER });
  }
};

export const postSignup = (user) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiURL}/v1/access/signup`, user);
    dispatch(loadPostSignupData(response.data));
  } catch (error) {
    dispatch(showErrorModal(error.response.data));
    handleErrors(error, dispatch, loginUser, user);
  } finally {
    dispatch({ type: HIDE_LOADER });
  }
};

export const validateSignupActivateToken = (token) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiURL}/v1/access/validate-signup-activate-token`, { token });

    if (response != undefined && response != null && response.data != null && response.data != undefined) {
      if (response.data.resultKey == 'nodata' || response.data.resultKey == 'error') {
        dispatch(loadValidateSignupActivateTokenData(response.data));
      }
    } else {
      Router.pushRoute('auth/login');
    }
  } catch (error) {
    Router.pushRoute('auth/login');
  } finally {
    dispatch({ type: HIDE_LOADER });
  }
};

export const postSignupActivate = (user) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiURL}/v1/access/signup-activate`, user);
    dispatch(loadPostSignupActivateData(response.data));
  } catch (error) {
    dispatch(showErrorModal(error.response.data));
    handleErrors(error, dispatch, loginUser, user);
  } finally {
    dispatch({ type: HIDE_LOADER });
  }
};

export const getUserProductList = (uuid) => async (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  try {
    const userProductList = await axios.get(`${entitlementURL}/v2/user/${uuid}/entitlement/application`);

    dispatch(loadUserProductList(userProductList.data));
  } catch (error) {
    dispatch(showErrorModal(error.response.data));
    handleErrors(error, dispatch, getUserProductList, accessToken);
  } finally {
    dispatch({ type: HIDE_LOADER });
  }
};

export const getTaskCommentApproverUserListbyAccountId = () => (dispatch) => {
  api
    .get('/v1/authAccess/getTaskCommentApproverUserListbyAccountId')
    .then((response) => {
      const usersData = response.data;
      usersData.forEach(adjustTaskCommentApprover);
      dispatch(loadUsers(usersData));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getTaskCommentApproverUserListbyAccountId, {});
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const quickCreateUser = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .post(`/v2/user/quick-create`, params)
    .then((response) => {
      dispatch(loadNewAddedUser(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, quickCreateUser, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
