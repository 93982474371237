import {
  LOAD_NOTIFICATION,
  LOAD_NOTIFICATIONS,
  LOAD_ALL_NOTIFICATIONS,
  LOAD_NOTIFICATION_COUNT,
  SHOW_LOADER,
  HIDE_LOADER,
} from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/notifications';

const adjustnotification = (notification) => {
  notification.fullName = `${notification.firstName} ${notification.lastName}`;
};

export const loadNotification = (notification) => ({
  type: LOAD_NOTIFICATION,
  payload: notification,
});

export const loadNotifications = (notifications) => ({
  type: LOAD_NOTIFICATIONS,
  payload: notifications,
});

export const loadAllNotifications = (notifications) => ({
  type: LOAD_ALL_NOTIFICATIONS,
  payload: notifications,
});

export const loadCount = (count) => ({
  type: LOAD_NOTIFICATION_COUNT,
  payload: count,
});

export const createNotification = (notification) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/create`, notification)
    .then((response) => {
      dispatch(loadNotification(response.data));
      Router.pushRoute('cafm/admin/notification_profile', { notification_id: response.data.id });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createNotification, notification);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateNotification = (notification) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .put(`${routePath}/update`, notification)
    .then((response) => {
      const newSchedule = response.data;
      adjustnotification(newSchedule);
      dispatch(loadNotification(newSchedule));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateNotification, notification);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getNotification = (id) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-item/${id}`)
    .then((response) => {
      const newSchedule = response.data;
      dispatch(loadNotification(newSchedule));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getNotification, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteNotification = (id, siteId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${id}`)
    .then(() => {
      const item = {
        id,
        name: null,
      };
      dispatch(loadNotification(item));
      if (siteId) {
        Router.pushRoute('cafm/admin/site_profile', { sid: siteId });
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteNotification, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getNotificationList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      const newSchedules = response.data;
      dispatch(loadNotifications(newSchedules));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getNotificationList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getNotificationFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-full-list`, { params })
    .then((response) => {
      dispatch(loadAllNotifications(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getNotificationFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getNotificationListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`${routePath}/get-list-with-size`, { params })
    .then((response) => {
      const newSchedules = response.data.rows;
      dispatch(loadNotifications(newSchedules));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getNotificationListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
