import {
  LOAD_SCHEDULE_TASK,
  LOAD_SCHEDULE_TASKS,
  LOAD_ALL_SCHEDULE_TASKS,
  LOAD_SCHEDULE_TASK_COUNT,
} from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  list: [],
  all: [],
};

const scheduleTask = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_SCHEDULE_TASK: {
      const item = action.payload;
      let { list, count, all } = state;
      if (item.taskLibraryName !== null) {
        if (list && list.find((element) => element.id === item.id) === undefined) {
          list.push(item);
          count += 1;
        }
        if (all && all.find((element) => element.id === item.id) === undefined) {
          all.push(item);
        }
        list = list.map((listItem) => (item.id === listItem.id ? item : listItem));
        all = all.map((listItem) => (item.id === listItem.id ? item : listItem));
      } else {
        list = list.filter((listItem) => item.id !== listItem.id);
        all = all.filter((listItem) => item.id !== listItem.id);
        count -= 1;
      }
      return {
        item,
        list,
        count,
        all,
      };
    }
    case LOAD_SCHEDULE_TASKS:
      return { ...state, list: action.payload };
    case LOAD_ALL_SCHEDULE_TASKS:
      return { ...state, all: action.payload };
    case LOAD_SCHEDULE_TASK_COUNT:
      return { ...state, count: action.payload };
    default:
      return state;
  }
};

export default scheduleTask;
