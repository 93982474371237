import {
  //1453
  LOAD_ASSETSURVEY,
  LOAD_ASSETSURVEYS,
  LOAD_ALL_ASSETSURVEYS,
  LOAD_ASSETSURVEY_COUNT,
  LOAD_ASSETS_REVIEW,
  UPDATE_ASSETS_REVIEW,
} from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  all: [],
  list: [],
  assetsReviewList: [],
};

const assetSurvey = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_ASSETSURVEY: {
      const item = action.payload;
      let { list, count } = state;
      if (item.siteId !== null) {
        if (list.find((element) => element.id === item.id) === undefined) {
          list.push(item);
          count = list.length;
        }
        list = list.map((listItem) => (item.id === listItem.id ? item : listItem));
      } else {
        list = list.filter((listItem) => item.id !== listItem.id);
        count -= 1;
      }
      return {
        item,
        list,
        //all,
        count,
      };
    }
    case LOAD_ASSETSURVEYS:
      return { ...state, list: action.payload };
    case LOAD_ALL_ASSETSURVEYS:
      return { ...state, all: action.payload };
    case LOAD_ASSETSURVEY_COUNT:
      return { ...state, count: action.payload };
    case LOAD_ASSETS_REVIEW:
      return { ...state, assetsReviewList: action.payload };
    case UPDATE_ASSETS_REVIEW:
      let { assetsReviewList } = state;
      const updateAssetList = assetsReviewList.map((e) => {
        if (e.id == action.payload.id) {
          let status;
          switch (action.payload.review) {
            case 'approved':
              status = 2;
              break;
            case 'reviewed':
              status = 3;
              break;
            case 'ignored':
              status = 4;
              break;
          }
          return { ...e, status, comment: action.payload.comment };
        }
        return e;
      });
      return { ...state, assetsReviewList: updateAssetList };
    default:
      return state;
  }
};

export default assetSurvey;
