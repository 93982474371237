import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { Dashboard } from '@uppy/react';

class UppyFileUploader extends React.Component {
  // multiselect to be finalized
  static propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onDefault: PropTypes.func,
    isDefault: PropTypes.bool,
    accept: PropTypes.string,
    preview: PropTypes.string,
    accepted: PropTypes.object,
    removeButton: PropTypes.bool,
    maxSize: PropTypes.number,
    minSize: PropTypes.number,
    accessToken: PropTypes.string,
    maxNumberOfFiles: PropTypes.number,
    minNumberOfFiles: PropTypes.number,
    thumbnailWidth: PropTypes.number,
    restrictedType: PropTypes.string,
    previewWidth: PropTypes.number,
  };

  static defaultProps = {
    accept: 'image/*',
    onDefault: null,
    isDefault: false,
    maxSize: 52428800, // 50 Mb
    minSize: 0,
    removeButton: true,
    maxNumberOfFiles: 30,
    minNumberOfFiles: 1,
    thumbnailWidth: 220,
    restrictedType: 'gif',
    previewWidth: undefined,
  };

  constructor(data) {
    super(data);

    this.state = {
      accepted: this.props.accepted,
      preview: this.props.preview,
      isDefault: this.props.isDefault,
      isUploadFinish: false,
    };
  }

  componentWillMount() {
    const _this = this;
    this.uppy = new Uppy({
      id: _this.props.name,
      autoProceed: false,
      restrictions: {
        maxFileSize: _this.props.maxSize,
        maxNumberOfFiles: _this.props.maxNumberOfFiles,
        minNumberOfFiles: _this.props.minNumberOfFiles,
        ...(_this.props.accept != 'all' ? { allowedFileTypes: ['image/*'] } : {}),
      },
    })
      // .use(ThumbnailGenerator, {
      //   thumbnailWidth: _this.props.thumbnailWidth,
      //   waitForThumbnailsBeforeUpload: false,
      // })
      .use(AwsS3, {
        getUploadParameters(file) {
          return _this.doGeneratePreSignedUrlAndUploadFileToAws(file);
        },
      })
      // .on('thumbnail:generated', (file, preview) => {
      // })
      .on('file-added', (file) => {
        _this.onDrop([file], undefined);
      })
      .on('upload', () => {
        _this.onUploadStart();
      })
      .on('file-removed', (file) => {
        if (file.progress.uploadStarted) {
        } else _this.removeHandler(file);
      })
      // .on("complete", result => {
      // })
      .on('upload-success', (file, resp, uploadURL) => {
        _this.onUploadSuccess();
      });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      accepted: nextProps.accepted,
      preview: nextProps.preview,
      isDefault: nextProps.isDefault,
    });
  }

  componentWillUnmount() {
    const { isUploadFinish } = this.state;
    if (isUploadFinish) this.uppy.close();
  }

  onDrop(accepted, rejected) {
    const { index, onAdd, restrictedType } = this.props;

    // const { isDefault } = this.state;
    let isValid = true;

    if (accepted && accepted.length > 0) {
      accepted = accepted[0];
      accepted.uppyObject = this.uppy;

      if (/^(img|image)/i.test(accepted.type)) {
        if (restrictedType && restrictedType.length > 0) {
          var selectedFileType = accepted.extension;
          var appliedRestrictedType = restrictedType.split(',');
          for (var k = 0; k < appliedRestrictedType.length; k++) {
            if (appliedRestrictedType[k] === selectedFileType) {
              accepted.uppyObject.removeFile(accepted.id);
              accepted.uppyObject.info('Image type is not supported');
              isValid = false;
            }
          }
        }
        if (onAdd && isValid) {
          onAdd(index, accepted);
        }
      } else if (this.props.accept === 'all') {
        onAdd(index, accepted);
      }
    }
  }

  onUploadStart = () => {
    this.setState({
      isUploadFinish: false,
    });
  };

  doGeneratePreSignedUrlAndUploadFileToAws = (file) => {
    const fileName = file.data.name;
    const pictureUploadFlag = localStorage.getItem('pictureUploadFlag');
    const dataRowId = localStorage.getItem('dataId');
    let url = '/api/v2/attachments/signForS3/upload';
    if (pictureUploadFlag === 'assetPicture') {
      url = `${url}/1/${dataRowId}/${fileName}?fileType=${file.type}`;
    } else if (pictureUploadFlag === 'sitePicture') {
      url = `${url}/2/${dataRowId}/${fileName}?fileType=${file.type}`;
    } else if (pictureUploadFlag === 'helpdeskCategoryPicture') {
      url = `${url}/3/${dataRowId}/${fileName}?fileType=${file.type}`;
    } else if (pictureUploadFlag === 'helpdeskTopicPicture') {
      url = `${url}/4/${dataRowId}/${fileName}?fileType=${file.type}`;
    } else if (pictureUploadFlag === 'assetSurveyLocationDrawing') {
      url = `${url}/5/${dataRowId}/${fileName}?fileType=${file.type}`;
    }

    return fetch(url, {
      method: 'get',
      fields: [],
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        Authorization: `Bearer ${this.props.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then(function (data) {
        return {
          url: data.signedRequest,
          method: 'put',
          fields: [],
          headers: {
            'content-type': file.type,
          },
          friendlyName: file.name,
          fileName: fileName,
        };
      });
  };

  onUploadSuccess = () => {
    this.setState({ isUploadFinish: true });
  };

  removeHandler(e) {
    const { onRemove, index } = this.props;
    onRemove(index, e?.id || null);
  }

  defaultHandler() {
    const { onDefault, index } = this.props;
    onDefault(index);
  }

  render() {
    const { name, accept, index, removeButton, onDefault, maxSize, minSize, previewWidth } = this.props;

    const { accepted, preview, isDefault } = this.state;

    const classNames = cn('picture-dropzone', { 'is-default': isDefault });

    return (
      <div className={classNames}>
        {preview ? (
          <img
            alt=""
            className="image"
            src={preview}
            onClick={(e) => this.defaultHandler(e)}
            style={previewWidth ? { width: `${previewWidth}px` } : null}
          />
        ) : (
          <div>
            {(!accepted || accepted) && (
              <Dashboard
                uppy={this.uppy}
                inline
                replaceTargetContent
                showProgressDetails
                showLinkToFileUploadResult={false}
                height={140}
                hideUploadButton
                hideCancelButton
                showSelectedFiles
                proudlyDisplayPoweredByUppy={false}
                locale={{
                  strings: {
                    dropHereOr: 'Drop files to upload (or click here %{browse})',
                    browse: 'browse',
                  },
                }}
              />
            )}
          </div>
        )}
        {onDefault && (
          <div className="default-wrapper btn-group">
            <input
              type="checkbox"
              className=""
              id={`default-label-${index}`}
              checked={isDefault}
              onChange={(e) => this.defaultHandler(e)}
            />
            <label htmlFor={`default-label-${index}`} className="">
              Default
            </label>
          </div>
        )}

        {removeButton && (
          <a href="#" className="btn btn-secondary remove-btn" onClick={(e) => this.removeHandler(e)}>
            Remove
          </a>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    tokens: { accessToken },
  } = state;

  return {
    accessToken,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UppyFileUploader);
