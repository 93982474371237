import { LOAD_BUDGET_CATEGORY_GROUP, LOAD_BUDGET_CATEGORY_GROUPS, LOAD_COUNT } from '../actions/types';

const INITIAL_STATE = {
  item: null,
  list: [],
  count: null,
};

const budgetCategoryGroup = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_BUDGET_CATEGORY_GROUP: {
      const item = action.payload;
      let { list, count } = state;
      if (item.name !== null) {
        list = list.map((listItem) => (item.uuid === listItem.uuid ? item : listItem));
      } else {
        list = list.filter((listItem) => item.uuid !== listItem.uuid);
        count -= 1;
      }
      return { ...state, list, count };
    }
    case LOAD_BUDGET_CATEGORY_GROUPS:
      return { ...state, list: action.payload };
    case LOAD_COUNT:
      return { ...state, count: action.payload };
    default:
      return state;
  }
};

export default budgetCategoryGroup;
