import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import UppyFileUploader from '../../components/form/parts/UppyFileUploader';
import { setPictures, setRemoved } from '../../actions/cafm/AttachmentActions'; //1449

export class HelpdeskCategoryTopicPicture extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    initialPictures: PropTypes.arrayOf(PropTypes.shape()),
    pictures: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    removed: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    setPictures: PropTypes.func.isRequired,
    setRemoved: PropTypes.func.isRequired,
    name: PropTypes.string,
    maxNumberOfFiles: PropTypes.number,
  };

  constructor(props) {
    super(props);
    const { setPictures, setRemoved, initialPictures } = this.props;

    let pictures;

    if (!initialPictures || initialPictures.length == 0) {
      pictures = [{ type: 'new', isDefault: true }];
    } else {
      pictures = initialPictures.map((obj) => ({
        type: 'preview',
        data: obj,
        isDefault: true,
      }));
    }
    setPictures(pictures);
    setRemoved([]);
  }

  static defaultProps = {
    initialPictures: [],
    name: 'imageLarge',
    maxNumberOfFiles: 1,
  };

  onAdd = (index, accepted) => {
    //once file selected and added in uppy
    if (!accepted) {
      return;
    }

    // mutable copy
    const { setPictures, name } = this.props;
    const pictures = [...this.props.pictures];
    let newParams = { ...accepted };
    delete accepted.uppyObject;
    const picture = {
      type: 'file',
      data: accepted,
      isDefault: true,
      uppyObject: newParams.uppyObject,
    };

    pictures[index] = picture;

    setPictures(pictures);
  };

  onRemove = (index) => {
    //once file removed (x) in uppy
    let { removed, pictures } = this.props;

    const { setPictures, setRemoved } = this.props;

    const picture = pictures[index];

    pictures = pictures.filter((item, key) => key != index);

    if (pictures.length === 0) {
      pictures = [{ type: 'new', isDefault: true }];
    }

    if (picture.type === 'preview') {
      removed = [...removed, picture];
    }

    setPictures(pictures);
    setRemoved(removed);
  };

  render() {
    const { t, pictures, name, maxNumberOfFiles } = this.props;
    return (
      <UppyFileUploader
        t={t}
        key={0}
        index={0}
        name={name} //"imageLarge"
        isDefault={false}
        onAdd={(index, accepted) => this.onAdd(index, accepted)}
        onRemove={(index) => this.onRemove(index)}
        preview={pictures && pictures.length > 0 && pictures[0].type == 'preview' ? pictures[0].data.imageLarge : ''}
        removeButton={pictures && pictures.length > 0 && pictures[0].type == 'preview' ? true : false}
        previewWidth={150}
        maxNumberOfFiles={maxNumberOfFiles}
      />
    );
  }
}

const mapStateToProps = ({ attachmentUpload: { pictures, removed } }) => {
  return {
    pictures,
    removed,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPictures,
      setRemoved,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(HelpdeskCategoryTopicPicture);
