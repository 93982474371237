import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { showBodyScroll, hideBodyScroll } from '../../lib/bodyScrollToggle';

class SimpleModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    handleOk: PropTypes.func.isRequired,
    onModalClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    isArchive: PropTypes.bool,
    warning: PropTypes.string,
    warningShow: PropTypes.bool,
  };

  static defaultProps = {
    isArchive: false,
    warningShow: false,
    warning: '',
  };

  constructor(props) {
    super(props);
    hideBodyScroll();
    this.state = {
      display: true,
      isArchive: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      hideBodyScroll();
      this.setState({
        display: true,
      });
    }
  }

  onChangeArchive = (checked) => {
    this.setState({
      isArchive: checked,
    });
  };

  onOk = () => {
    this.closeModal();
    this.props.handleOk(this.state.isArchive);
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
    showBodyScroll();
    this.props.onModalClose();
  };

  render() {
    const { header, text, t, isArchive, warning, warningShow } = this.props;
    const { display } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown over-y-auto
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show profile-modal simple-modal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{header}</h2>

          {warningShow && (
            <div className="swal2-warning" style={{ display: 'block' }}>
              {warning}
            </div>
          )}

          <div className="swal2-content" style={{ display: 'block' }}>
            {text}
          </div>

          {isArchive && (
            <div className="swal2-control" style={{ display: 'block' }}>
              <input
                type="checkbox"
                name="Isarchive"
                onChange={(e) => {
                  this.onChangeArchive(e.target.checked);
                }}
              />
              <span>&nbsp;&nbsp;Archive Jobs with Notes and Service Sheets</span>
            </div>
          )}

          <div className="swal2-buttonswrapper display-block">
            <button type="submit" className="swal2-confirm swal2-styled btn-primary" onClick={this.onOk}>
              {t('Ok')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SimpleModal;
