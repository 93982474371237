import React from 'react';
import PropTypes from 'prop-types';

const TitleView = ({ title, t }) => (
  <div className="pull-left">
    <button className="btn oms-btn edit cursor-default">{t(`${title}`)}</button>
  </div>
);

TitleView.propTypes = {
  title: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default TitleView;
