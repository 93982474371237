import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInput, FormDatePicker, FormCheckbox, SelectDropdown, FormQuill } from '../../form/parts';

const ScheduleTaskEntryView = ({ t, args }) => (
  <div>
    {args.showCategory && (
      <div className="form-group">
        <label className="col-sm-4 control-label">{t('Category *')}</label>

        <div className="col-sm-8">
          <Field
            name="Category"
            component={SelectDropdown}
            drpName="Category"
            options={args.categoryList}
            placeholder={t('Category')}
            onSelect={args.onChangeCategory}
          />
        </div>
      </div>
    )}

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Task *')}</label>

      <div className={args.isNewMode ? 'col-sm-6' : 'col-sm-8'}>
        <Field
          name="task"
          component={SelectDropdown}
          drpName="task"
          options={args.taskList}
          placeholder={t('Choose task')}
          isOptionFullInfoRequired
          isDisabled={args.categoryId === 0}
        />
      </div>
      {args.isNewMode && (
        <div className="col-sm-2">
          <button
            type="button"
            disabled={args.categoryId === 0}
            className="swal2-confirm swal2-styled margin_0 modal-button"
            onClick={args.onClickNewTask}
          >
            {t('Add New')}
          </button>
        </div>
      )}
    </div>

    {args.repeatEvery && args.repeatPeriod && (
      <h4 className="col-sm-6 col-sm-offset-4 text_label text-left">
        This task will repeat every {args.repeatEvery} {args.repeatPeriod}
      </h4>
    )}

    {args.labourRequired && args.labourPeriod && (
      <h4 className="col-sm-6 col-sm-offset-4 text_label text-left">
        This task will require labour for {args.labourRequired} {args.labourPeriod}
      </h4>
    )}

    <Field
      name="overrideRepeatFrequency"
      component={FormCheckbox}
      label={t('Override Repeat Frequency?')}
      disabled={args.taskId === 0 || !args.taskId}
    />

    {args.overrideRepeatFrequency && (
      <Field
        label={t('Repeat every')}
        component={FormInput}
        name="repeatEvery"
        type="text"
        placeholder={t('Repeat every')}
      />
    )}

    {args.overrideRepeatFrequency && (
      <div className="form-group">
        <label className="col-sm-4 control-label">{t('Repeat period')}</label>
        <div className="col-sm-8">
          <Field
            name="repeatPeriod"
            component={SelectDropdown}
            drpName="repeatPeriod"
            options={args.repeatOptionList}
            _noLabelValue
            placeholder={t('Repeat period')}
          />
        </div>
      </div>
    )}

    <Field
      name="overrideLabourFrequency"
      component={FormCheckbox}
      label={t('Override Labour Frequency?')}
      disabled={args.taskId === 0 || !args.taskId}
    />

    {args.overrideLabourFrequency && (
      <Field
        label={t('Labour Required')}
        component={FormInput}
        name="labourRequired"
        type="text"
        placeholder={t('Labour Required')}
      />
    )}

    {args.overrideLabourFrequency && (
      <div className="form-group">
        <label className="col-sm-4 control-label">{t('Labour Period')}</label>

        <div className="col-sm-8">
          <Field
            name="labourPeriod"
            drpName="labourPeriod"
            component={SelectDropdown}
            options={args.periodOptionList}
            _noLabelValue
            placeholder={t('Labour Period')}
          />
        </div>
      </div>
    )}

    <Field
      name="overrideTaskDescription"
      component={FormCheckbox}
      label={t('Override Task Description?')}
      disabled={args.taskId === 0 || !args.taskId}
    />

    {args.overrideTaskDescription && (
      <div className="form-group">
        <label className="col-sm-4 control-label">{t('Description *')}</label>

        <div className="col-sm-8 description-container" style={{ minHeight: 150 }}>
          <Field
            name="taskDescription"
            component={FormQuill}
            bounds=".description-container"
            placeholder="Enter a description of the task to be carried out"
          />
        </div>
      </div>
    )}

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('First Task Date *')}</label>
      <div className="col-sm-8 full_datepicker">
        <Field
          name="firstTask"
          component={FormDatePicker}
          placeholder={t('First Task Date')}
          disabled={args.taskId === 0 || !args.taskId}
        />
      </div>
    </div>

    {args.isNewMode && (
      <div className="form-group">
        <label className="col-sm-4 control-label">{t('Create Jobs Until *')}</label>
        <div className="col-sm-8 full_datepicker">
          <Field
            name="lastTask"
            component={FormDatePicker}
            placeholder={t('Create Jobs Until')}
            disabled={args.isLastTaskDisable}
            isClearable={!args.isLastTaskDisable}
          />
        </div>
      </div>
    )}

    <div className="form-group">
      <div className="col-sm-6 col-sm-offset-4 pull-left">
        <span>Please select a subcontractor or engineer...</span>
      </div>
    </div>

    <div className="form-group">
      <div className="col-sm-6 col-sm-offset-4 text-left">
        <span>
          {t('Choose an internal user for this work or select which subcontractor the work will be carried out by')}
        </span>
      </div>
    </div>

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Internal User')}</label>

      <div className="col-sm-8">
        <Field
          name="userAssignee"
          component={SelectDropdown}
          drpName="userAssignee"
          options={args.userList}
          _optionLabel="fullName"
          isDisabled={(args.subcontract !== null && args.subcontract) || args.taskId === 0 || !args.taskId}
          placeholder={t('Choose Internal User')}
        />
      </div>
    </div>

    <div className="form-group">
      <div className="col-sm-6 col-sm-offset-4 text-left">
        <span>{t('or')}</span>
      </div>
    </div>

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Carried out by')}</label>

      <div className="col-sm-8">
        <Field
          name="company"
          component={SelectDropdown}
          drpName="company"
          options={args.companyList}
          isDisabled={(args.subcontract !== null && !args.subcontract) || args.taskId === 0 || !args.taskId}
          placeholder={t('Choose Subcontractor')}
        />
      </div>
    </div>

    {args.showContacts && (
      <div className="form-group">
        <label className="col-sm-4 control-label">{t('Contact')}</label>

        <div className="col-sm-8">
          <Field
            name="contact"
            component={SelectDropdown}
            drpName="contact"
            options={args.contactList}
            _optionLabel="fullName"
            isDisabled={(args.subcontract !== null && !args.subcontract) || args.taskId === 0 || !args.taskId}
            placeholder={t('Choose Subcontractor Contact')}
          />
        </div>
      </div>
    )}

    <Field
      name="notifySubcontractor"
      component={FormCheckbox}
      disabled={!args.subcontract}
      label={t('Notify subcontractor')}
    />
  </div>
);

const scheduleTaskProps = PropTypes.shape({
  taskList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  repeatEvery: PropTypes.string.isRequired,
  repeatPeriod: PropTypes.string.isRequired,
  overrideRepeatFrequency: PropTypes.bool.isRequired,
  repeatOptionList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  userList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  subcontract: PropTypes.bool.isRequired,
  companyList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  contactList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  categoryList: PropTypes.arrayOf(PropTypes.shape()),
  onChangeCategory: PropTypes.func,
  onChangeTask: PropTypes.func,
  categoryId: PropTypes.number,
  taskId: PropTypes.number,
  isLastTaskDisable: PropTypes.bool,
  showContacts: PropTypes.bool,
  showCategory: PropTypes.bool,
  // showLastTaskDate: PropTypes.bool,
  overrideTaskDescription: PropTypes.bool,
  isNewMode: PropTypes.bool,
  onClickNewTask: PropTypes.func,
}).isRequired;

ScheduleTaskEntryView.propTypes = {
  args: scheduleTaskProps,
  t: PropTypes.func.isRequired,
};

ScheduleTaskEntryView.defaultProps = {
  args: {
    taskList: [],
    repeatEvery: '',
    repeatPeriod: '',
    overrideRepeatFrequency: false,
    repeatOptionList: [],
    userList: [],
    subcontract: false,
    companyList: [],
    contactList: [],
    categoryList: [],
    onChangeCategory: undefined,
    onChangeTask: undefined,
    categoryId: 0,
    taskId: 0,
    isLastTaskDisable: false,
    showContacts: true,
    showCategory: true,
    // showLastTaskDate: true,
    overrideTaskDescription: false,
    isNewMode: false,
    onClickNewTask: undefined,
  },
};

export default ScheduleTaskEntryView;
