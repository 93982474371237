import { LOAD_CUSTOMER_CONTACT_LIST, SHOW_LOADER, HIDE_LOADER } from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/internalContact';

export const loadContactList = (contactList) => ({
  type: LOAD_CUSTOMER_CONTACT_LIST,
  payload: contactList,
});

export const getCompanyContactList =
  (internalCompanyUUID, params = {}) =>
  (dispatch) => {
    dispatch({ type: SHOW_LOADER });

    dispatch(loadContactList([]));
    api
      .get(`${routePath}/get-list-by-internal-company/${internalCompanyUUID}`, { params })
      .then((response) => {
        dispatch(loadContactList(response.data));
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response.data));
        handleErrors(error, dispatch, getCompanyContactList, params);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };
