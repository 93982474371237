import {
  LOAD_PROBLEM_CATEGORY,
  LOAD_PROBLEM_CATEGORIES,
  LOAD_ALL_PROBLEM_CATEGORIES,
  LOAD_PROBLEM_CATEGORIES_COUNT,
  REMOVE_PROBLEM_CATEGORY,
  CREATE_PROBLEM_CATEGORY,
  SHOW_LOADER,
  HIDE_LOADER,
} from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';
import { uploadUpdateAttachment } from '../cafm/AttachmentActions'; //1449

const routePath = '/v2/problemCategory';

const enabledMap = (list, t) => list.map((item) => enabledObj(item, t));
const enabledObj = (item, t) => ({ ...item, enabled: item.isEnabled ? t('enabled') : t('disabled') });

export const addProblemCategory = (problemCategory) => ({
  type: CREATE_PROBLEM_CATEGORY,
  payload: problemCategory,
});

export const loadProblemCategory = (problemCategory) => ({
  type: LOAD_PROBLEM_CATEGORY,
  payload: problemCategory,
});

export const removeProblemCategory = (id) => ({
  type: REMOVE_PROBLEM_CATEGORY,
  payload: id,
});

export const loadProblemCategories = (problemCategories) => ({
  type: LOAD_PROBLEM_CATEGORIES,
  payload: problemCategories,
});

export const loadAllProblemCategories = (problemCategories) => ({
  type: LOAD_ALL_PROBLEM_CATEGORIES,
  payload: problemCategories,
});

export const loadCount = (count) => ({
  type: LOAD_PROBLEM_CATEGORIES_COUNT,
  payload: count,
});

export const createProblemCategory = (problemCategory) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  const multipart = new FormData();

  for (const i in problemCategory) {
    multipart.append(i, problemCategory[i]);
  }

  api
    .post(`${routePath}/create`, multipart, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return dispatch(uploadUpdateAttachment(response.data.id, 'helpdeskCategoryPicture')) //1449
        .then((result) => {
          dispatch(addProblemCategory(result));
          Router.pushRoute('cafm/admin/issue_category_profile', { problemCategory_id: result.id });
        });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createProblemCategory, problemCategory);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateProblemCategory = (problemCategory, t) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  const multipart = new FormData();

  for (const i in problemCategory) {
    multipart.append(i, problemCategory[i]);
  }

  api
    .put(`${routePath}/update`, multipart, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return dispatch(uploadUpdateAttachment(response.data.id, 'helpdeskCategoryPicture')) //1449
        .then((result) => {
          dispatch(loadProblemCategory(enabledObj(result, t)));
        });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateProblemCategory, problemCategory, t);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const disableProblemCategory = (problemCategory, t) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  const multipart = new FormData();

  multipart.append('id', problemCategory.id);
  multipart.append('isEnabled', !problemCategory.isEnabled);

  api
    .put(`${routePath}/update`, multipart, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      dispatch(loadProblemCategory(enabledObj(response.data, t)));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateProblemCategory, problemCategory, t);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getProblemCategory = (id, t) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-item/${id}`)
    .then((response) => {
      dispatch(loadProblemCategory(enabledObj(response.data, t)));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getProblemCategory, id, t);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteProblemCategory = (id, path) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${id}`)
    .then(() => {
      dispatch(removeProblemCategory(id));
      if (path) {
        Router.pushRoute('cafm/admin/issue_categories');
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteProblemCategory, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getProblemCategoryList = (params, t) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      dispatch(loadProblemCategories(enabledMap(response.data, t)));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getProblemCategoryList, params, t);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getProblemCategoryFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-full-list`, { params })
    .then((response) => {
      dispatch(loadAllProblemCategories(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getProblemCategoryFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getProblemCategoryListWithSize = (params, t) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list-with-size`, { params })
    .then((response) => {
      dispatch(loadProblemCategories(enabledMap(response.data.rows, t)));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getProblemCategoryListWithSize, params, t);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
