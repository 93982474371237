import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { quickCreateTask } from '../../actions/cafm/TaskActions';
import { getRepeatOptions, getPeriodOptions } from '../../constants/options';
import TaskLibraryEntryView from '../cafm/admin/TaskLibraryEntryView';
import { getCustomizeParentChildFullList } from '../../actions/cafm/TaskComplianceActions';

class AddNewTaskModal extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    quickCreateTask: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    createForm: PropTypes.shape(),
    getCustomizeParentChildFullList: PropTypes.func,
    taskCompliances: PropTypes.arrayOf(PropTypes.shape()),
    isOpenFromScheduleTask: PropTypes.bool,
  };

  static defaultProps = {
    createForm: {
      addNewTaskForm: {},
    },
    taskCompliances: [],
    isOpenFromScheduleTask: false,
  };

  constructor(props) {
    super(props);
    const repeatOptions = getRepeatOptions(props.t);
    const periodOptions = getPeriodOptions(props.t);

    this.state = {
      repeatOptions,
      periodOptions,
      isTaskSimpleView: 'true',
    };
    props.getCustomizeParentChildFullList();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }

    const form = nextProps.createForm.addNewTaskForm;
    if (form.values && nextProps.task) {
      if (nextProps.task.name == form.values.name && form.submitSucceeded) {
        if (nextProps.createForm.quickAddNewPPMForm) {
          nextProps.createForm.quickAddNewPPMForm.values.TaskName = {
            label: nextProps.task.name,
            value: nextProps.task.id,
          };
        } else if (nextProps.createForm.addEditScheduleTaskForm) {
          nextProps.createForm.addEditScheduleTaskForm.values.task = {
            label: nextProps.task.name,
            value: nextProps.task.id,
            info: nextProps.task,
          };
        }
        this.props.onModalClose();
      }
    }
  }

  handleFormSubmit = (values) => {
    const { url } = this.props;

    const contentOnly = values && values.description ? values.description.replace(/<(?:.|\n)*?>/gm, '') : '';
    if (contentOnly != undefined && contentOnly.trim() == '') {
      values.description = '';
    }

    const task = _.pick(values, [
      'name',
      'description',
      'repeatEvery',
      'labourRequired',
      'isRegulatory',
      'majorSfg20',
      'minorSfg20',
    ]);
    task.siteId = this.props.initialValues.siteId;
    task.taskCategoryId = this.props.initialValues.taskCategoryId;
    const { repeatPeriod, labourPeriod, compliance } = values;
    task.repeatPeriod = (repeatPeriod && repeatPeriod.value) || '';
    task.labourPeriod = (labourPeriod && labourPeriod.value) || null;
    task.taskComplianceId = (compliance && compliance.value) || null;
    this.props.quickCreateTask(task);
    // this.props.onModalClose();
  };

  closeModal = () => {
    this.props.onModalClose();
  };

  handleTaskChangeSimpleandAdvanced = (value) => {
    this.setState({
      isTaskSimpleView: value,
    });
  };

  render() {
    const { handleSubmit, t, initialValues, taskCompliances, isOpenFromScheduleTask } = this.props;
    const { repeatOptions, periodOptions, display, isTaskSimpleView } = this.state;

    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                  ${!display && 'display-none'}`}
        // onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show modal-template form-horizontal"
          // onClick={(e) => {
          //   e.stopPropagation();
          // }}
        >
          <div className="col-sm-12 title_with_filter">
            <h2 className="swal2-title text-left col-sm-offset-3 col-sm-9">
              {t('Add New Task')}
              {!isOpenFromScheduleTask && (
                <div className="switch_field">
                  <input
                    type="radio"
                    name="TaskSimpleandAdvanced"
                    checked={isTaskSimpleView === 'false'}
                    onChange={(e) => this.handleTaskChangeSimpleandAdvanced(e.target.value)}
                    id="RBTaskAdvanced"
                    value="false"
                  />{' '}
                  <label className="radio_label" htmlFor="RBTaskAdvanced">
                    {t('Advanced')}
                  </label>
                  <input
                    type="radio"
                    name="TaskSimpleandAdvanced"
                    checked={isTaskSimpleView === 'true'}
                    onChange={(e) => this.handleTaskChangeSimpleandAdvanced(e.target.value)}
                    id="RBTaskSimple"
                    value="true"
                  />{' '}
                  <label className="radio_label" htmlFor="RBTaskSimple">
                    {t('Simple')}
                  </label>
                </div>
              )}
            </h2>
          </div>

          <form>
            <TaskLibraryEntryView
              t={t}
              args={{
                isAPTAddNewTask: true,
                isTaskCategoryShow: false,
                repeatOptionList: repeatOptions,
                periodOptionList: periodOptions,
                isTaskSimpleView: isOpenFromScheduleTask ? 'false' : isTaskSimpleView,
                taskCompliancesList: taskCompliances,
              }}
            />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleFormSubmit)}
            >
              {t('Save')}
            </button>
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ tokens: { accessToken }, task: { item }, form, taskCompliance }) => ({
  accessToken,
  task: item,
  createForm: form,
  taskCompliances: taskCompliance.allCustomizeParentChild || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      quickCreateTask,
      getCustomizeParentChildFullList,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'addNewTaskForm',
  })(AddNewTaskModal),
);
