//1446/1453
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import { FormInput, FormCheckbox } from '../form/parts';

import { quickCreateUser } from '../../actions/UserActions';

class AddNewUserModal extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    quickCreateUser: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    createForm: PropTypes.shape(),
    initialValues: PropTypes.shape().isRequired,
    onModalClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    createForm: {
      addNewUserForm: {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
    const form = nextProps.createForm.addNewUserForm;
    if (form.values && nextProps.newAddedUser) {
      if (nextProps.newAddedUser.firstName === form.values.firstName && form.submitSucceeded) {
        if (nextProps.createForm.assetSurveyForm) {
          nextProps.createForm.assetSurveyForm.values.userAssignee = {
            label: nextProps.newAddedUser.firstName + ' ' + nextProps.newAddedUser.lastName,
            value: nextProps.newAddedUser.id,
          };
        }
        this.props.onModalClose();
      }
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    this.props.onModalClose();
  };

  handleFormSubmit = (values) => {
    const { firstName, lastName, emailAddress, landlineNumber, mobileNumber } = values;

    const data = {
      firstName,
      lastName,
      emailAddress,
      landlineNumber,
      mobileNumber,
    };
    this.props.quickCreateUser(data);
  };

  render() {
    const { handleSubmit, t, initialValues, title } = this.props;
    const { display } = this.state;

    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                        ${!display && 'display-none'}`}
      >
        <div aria-modal="true" className="swal2-modal swal2-show modal-template form-horizontal">
          <h2 className="swal2-title">Add New User</h2>

          <form>
            <Field
              label={t('First Name *')}
              component={FormInput}
              name="firstName"
              type="text"
              placeholder={t('First Name')}
            />
            <Field
              label={t('Last Name *')}
              component={FormInput}
              name="lastName"
              type="text"
              placeholder={t('Last Name')}
            />
            <Field
              label={t('Contact Email *')}
              component={FormInput}
              name="emailAddress"
              type="text"
              placeholder={t('Contact Email')}
            />
            <Field
              label={t('Landline Number *')}
              component={FormInput}
              name="landlineNumber"
              type="text"
              placeholder={t('Landline Number')}
            />
            <Field
              label={t('Mobile Number *')}
              component={FormInput}
              name="mobileNumber"
              type="text"
              placeholder={t('Mobile Number')}
            />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleFormSubmit)}
            >
              {t('Save')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    tokens: { accessToken },
    form,
    user: { newAddedUser },
  } = state;

  return {
    accessToken,
    createForm: form,
    initialValues: props.initialValues,
    newAddedUser,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      quickCreateUser,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'addNewUserForm',
  })(AddNewUserModal),
);
