import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInput, FormDatePicker, FormCheckbox, SelectDropdown, TagsDropdown, FormQuill } from '../../form/parts';

const MaintenanceScheduleEntryView = ({ t, args }) => (
  <div>
    <Field label={t(' Name *')} component={FormInput} name="name" type="text" placeholder={t(' Name')} />
    {args.isAddPlannedTask && (
      <div>
        <div className="form-group">
          <label className="col-sm-4 control-label">{t('Site *')}</label>

          <div className="col-sm-8">
            <Field
              name="Site"
              component={SelectDropdown}
              drpName="Site"
              options={args.siteList}
              placeholder={t('Site')}
              onSelect={args.onChangeSite}
            />
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-4 control-label">{t('Category *')}</label>

          <div className="col-sm-6">
            <Field
              name="Category"
              component={SelectDropdown}
              drpName="Category"
              options={args.categoryList}
              placeholder={t('Category')}
              onSelect={args.onChangeCategory}
              isDisabled={!args.siteId || args.siteId == 0}
            />
          </div>
          <div className="col-sm-2">
            <button
              type="button"
              disabled={!args.siteId || args.siteId == 0}
              className="swal2-confirm swal2-styled margin_0 modal-button"
              onClick={args.onClickNewTaskCategory}
            >
              {t('Add New')}
            </button>
          </div>
        </div>
      </div>
    )}

    {(args.isAddPlannedTask || args.showTaskFields) && (
      <div>
        <div className="form-group">
          <label className="col-sm-4 control-label">{t('Task *')}</label>

          <div className={args.isAddPlannedTask ? 'col-sm-6' : 'col-sm-8'}>
            <Field
              name={args.isAddPlannedTask ? 'TaskName' : 'task'}
              component={SelectDropdown}
              drpName={args.isAddPlannedTask ? 'TaskName' : 'task'}
              options={args.taskList}
              placeholder={t('Choose task')}
              onSelect={args.onChangeTask}
              isDisabled={args.isAddPlannedTask ? args.categoryId == 0 : false}
            />
          </div>
          {args.isAddPlannedTask && (
            <div className="col-sm-2">
              <button
                type="button"
                disabled={args.categoryId == 0}
                className="swal2-confirm swal2-styled margin_0 modal-button"
                onClick={args.onClickNewTask}
              >
                {t('Add New')}
              </button>
            </div>
          )}
        </div>
      </div>
    )}

    {(args.isAddPlannedTask || args.showTaskFields) && args.repeatEvery && args.repeatPeriod && (
      <h4 className="col-sm-6 col-sm-offset-4 text_label text-left">
        This task will repeat every {args.repeatEvery} {args.repeatPeriod}
      </h4>
    )}

    {(args.isAddPlannedTask || args.showTaskFields) && args.labourRequired && args.labourPeriod && (
      <h4 className="col-sm-6 col-sm-offset-4 text_label text-left">
        This task will require labour for {args.labourRequired} {args.labourPeriod}
      </h4>
    )}

    {(args.isAddPlannedTask || args.showTaskFields) && (
      <Field
        name="overrideRepeatFrequency"
        component={FormCheckbox}
        label={t('Override Repeat Frequency?')}
        disabled={args.isAddPlannedTask ? args.taskId == 0 || !args.taskId : false}
      />
    )}

    {(args.isAddPlannedTask || args.showTaskFields) && args.overrideRepeatFrequency && (
      <Field
        label={t('Repeat every')}
        component={FormInput}
        name="repeatEvery"
        type="text"
        placeholder={t('Repeat every')}
        isDisabled={args.isOtherRepeatPeriod}
        {...(args.isOtherRepeatPeriod ? { input: { value: 1 } } : {})}
      />
    )}

    {(args.isAddPlannedTask || args.showTaskFields) && args.overrideRepeatFrequency && (
      <div className="form-group">
        <label className="col-sm-4 control-label">{t('Repeat period')}</label>
        <div className="col-sm-8">
          <Field
            name="repeatPeriod"
            component={SelectDropdown}
            drpName="repeatPeriod"
            options={args.repeatOptionList}
            _noLabelValue
            placeholder={t('Repeat period')}
            onSelect={args.onRepeatPeriod}
          />
        </div>
      </div>
    )}

    {(args.isAddPlannedTask || args.showTaskFields) && args.overrideRepeatFrequency && args.isCustomRepeatPeriod && (
      <div className="form-group">
        <label className="col-sm-4 control-label">{t('Select custom date')}</label>
        <div className="col-sm-8 customRepearDateSelectMain">
          {args.customDateList.map((e, i) => {
            let isMach = args.customRepeatList.filter((ele) => ele.id == e.id);
            isMach = isMach.length > 0 ? true : false;
            return (
              <div
                className="customRepearDateSelect"
                style={{
                  backgroundColor: isMach ? 'rgb(26, 115, 232)' : 'lightgray',
                  color: isMach ? 'white' : 'black',
                }}
                onClick={() => args.handleCustomRepeatList({ id: e.id, val: e.val })}
              >
                {e.val}
              </div>
            );
          })}
        </div>
      </div>
    )}

    {(args.isAddPlannedTask || args.showTaskFields) &&
      args.overrideRepeatFrequency &&
      args.isEdit &&
      args.isRPChange && (
        <div className="form-group">
          <label className="col-sm-4 control-label">{t('Delete onwords')}</label>
          <div className="col-sm-8 full_datepicker">
            <Field name="deleteOnwords" component={FormDatePicker} placeholder={t('Select delete onwords date')} />
          </div>
        </div>
      )}

    {(args.isAddPlannedTask || args.showTaskFields) && (
      <Field
        name="overrideLabourFrequency"
        component={FormCheckbox}
        label={t('Override Labour Frequency?')}
        disabled={args.isAddPlannedTask ? args.taskId == 0 || !args.taskId : false}
      />
    )}

    {(args.isAddPlannedTask || args.showTaskFields) && args.overrideLabourFrequency && (
      <Field
        label={t('Labour Required')}
        component={FormInput}
        name="labourRequired"
        type="text"
        placeholder={t('Labour Required')}
      />
    )}

    {(args.isAddPlannedTask || args.showTaskFields) && args.overrideLabourFrequency && (
      <div className="form-group">
        <label className="col-sm-4 control-label">{t('Labour Period')}</label>

        <div className="col-sm-8">
          <Field
            name="labourPeriod"
            drpName="labourPeriod"
            component={SelectDropdown}
            options={args.periodOptionList}
            _noLabelValue
            placeholder={t('Labour Period')}
          />
        </div>
      </div>
    )}

    {(args.isAddPlannedTask || args.showTaskFields) && (
      <Field
        name="overrideTaskDescription"
        component={FormCheckbox}
        label={t('Override Task Description?')}
        disabled={args.isAddPlannedTask ? args.taskId === 0 || !args.taskId : false}
      />
    )}

    {(args.isAddPlannedTask || args.showTaskFields) && args.overrideTaskDescription && (
      <div className="form-group">
        <label className="col-sm-4 control-label">{t('Description *')}</label>

        <div className="col-sm-8 description-container" style={{ minHeight: 200 }}>
          <Field
            name="taskDescription"
            component={FormQuill}
            bounds=".description-container"
            placeholder="Enter a description of the task to be carried out"
          />
        </div>
      </div>
    )}

    {(args.isAddPlannedTask || args.showTaskFields) && (
      <div className="form-group">
        <label className="col-sm-4 control-label">{t('First Task Date *')}</label>
        <div className="col-sm-8 full_datepicker">
          <Field
            name="firstTask"
            component={FormDatePicker}
            placeholder={t('First Task Date')}
            disabled={args.isAddPlannedTask ? args.taskId == 0 || !args.taskId : false}
          />
        </div>
      </div>
    )}

    {args.isAddPlannedTask && (
      <div className="form-group">
        <label className="col-sm-4 control-label">{t('Create Jobs Until *')}</label>
        <div className="col-sm-8 full_datepicker">
          <Field
            name="lastTask"
            component={FormDatePicker}
            placeholder={t('Create Jobs Until')}
            disabled={args.isLastTaskDisable}
            isClearable={!args.isLastTaskDisable}
          />
        </div>
      </div>
    )}

    {(args.isAddPlannedTask || args.showTaskFields) && (
      <div>
        <div className="form-group">
          <div className="col-sm-6 col-sm-offset-4 pull-left">
            <span>Please select a subcontractor or engineer...</span>
          </div>
        </div>

        <div className="form-group">
          <div className="col-sm-6 col-sm-offset-4 text-left">
            <span>
              {t('Choose an internal user for this work or select which subcontractor the work will be carried out by')}
            </span>
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-4 control-label">{t('Internal User')}</label>

          <div className="col-sm-8">
            <Field
              name="userAssignee"
              component={SelectDropdown}
              drpName="userAssignee"
              options={args.userList}
              _optionLabel="fullName"
              isDisabled={
                (args.subcontract !== null && args.subcontract) ||
                (args.isAddPlannedTask && (args.taskId == 0 || !args.taskId))
              }
              placeholder={t('Choose Internal User')}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="col-sm-6 col-sm-offset-4 text-left">
            <span>{t('or')}</span>
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-4 control-label">{t('Carried out by')}</label>

          <div className="col-sm-8">
            <Field
              name="company"
              component={SelectDropdown}
              drpName="company"
              options={args.companyList}
              isDisabled={
                (args.subcontract !== null && !args.subcontract) ||
                (args.isAddPlannedTask && (args.taskId == 0 || !args.taskId))
              }
              placeholder={t('Choose Subcontractor')}
            />
          </div>
        </div>
      </div>
    )}

    {(args.isAddPlannedTask || args.showTaskFields) && args.showContacts && (
      <div className="form-group">
        <label className="col-sm-4 control-label">{t('Contact')}</label>

        <div className="col-sm-8">
          <Field
            name="contact"
            component={SelectDropdown}
            drpName="contact"
            options={args.contactList}
            _optionLabel="fullName"
            isDisabled={
              (args.subcontract !== null && !args.subcontract) ||
              (args.isAddPlannedTask && (args.taskId == 0 || !args.taskId))
            }
            placeholder={t('Choose Subcontractor Contact')}
          />
        </div>
      </div>
    )}

    {(args.isAddPlannedTask || args.showTaskFields) && (
      <Field
        name="notifySubcontractor"
        component={FormCheckbox}
        disabled={!args.subcontract}
        label={t('Notify subcontractor')}
      />
    )}

    {!args.isAddPlannedTask && (
      <div>
        <div className="form-group">
          <label className="col-sm-4 control-label">{t('Asset')}</label>

          <div className="col-sm-8">
            <Field
              name="asset"
              component={SelectDropdown}
              drpName="asset"
              options={args.assetList}
              isDisabled={args.isshowasset}
              placeholder={t('Choose asset')}
            />
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-4 control-label">{t('Location')}</label>

          <div className="col-sm-8">
            <Field
              name="location"
              component={SelectDropdown}
              drpName="location"
              options={args.locationList.map((data) => {
                return { name: data.name, id: data.id, isDisabled: data.disabled };
              })}
              isDisabled={args.isshowlocation}
              placeholder={t('Choose Location')}
            />
          </div>
        </div>

        <Field name="active" component={FormCheckbox} label={t('Active')} />
      </div>
    )}

    {args.isAddPlannedTask && args.isSimpleView == 'false' && (
      <div>
        <div className="form-group">
          <label className="col-sm-4 control-label">{t('Asset')}</label>

          <div className="col-sm-6">
            <Field
              name="asset"
              component={SelectDropdown}
              drpName="asset"
              options={args.assetList}
              placeholder={t('Asset Name')}
              isDisabled={args.taskId == 0 || !args.taskId || args.locationId != 0}
              onSelect={args.onChangeAssetLocation}
            />
          </div>
          <div className="col-sm-2">
            <button
              type="button"
              disabled={args.taskId == 0 || !args.taskId || args.locationId != 0}
              className="swal2-confirm swal2-styled margin_0 modal-button"
              onClick={args.onClickNewAsset}
            >
              {t('Add New')}
            </button>
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-4 control-label">{t('Location')}</label>

          <div className="col-sm-6">
            <Field
              name="location"
              component={SelectDropdown}
              drpName="location"
              options={args.locationList.map((data) => {
                return { name: data.name, id: data.id, isDisabled: data.disabled };
              })}
              placeholder={t('Location')}
              onSelect={args.onChangeAssetLocation}
              isDisabled={args.taskId == 0 || !args.taskId || args.assetId != 0}
            />
          </div>
          <div className="col-sm-2">
            <button
              type="button"
              disabled={args.taskId == 0 || !args.taskId || args.assetId != 0}
              className="swal2-confirm swal2-styled margin_0 modal-button"
              onClick={args.onClickNewLocation}
            >
              {t('Add New')}
            </button>
          </div>
        </div>
      </div>
    )}

    <TagsDropdown
      name="tags"
      label="Tags"
      placeholder={t('Choose or Create Tags')}
      disabled={args.isAddPlannedTask ? args.taskId == 0 || !args.taskId : false}
    />
    {!args.isAddPlannedTask && !args.showTaskFields && (
      <div className="form-group">
        <label className="col-sm-8 control-label">{t('Go to the Tasks tab to edit more details')}</label>
      </div>
    )}
  </div>
);

const maintenanceScheduleProps = PropTypes.shape({
  taskList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  repeatEvery: PropTypes.string.isRequired,
  repeatPeriod: PropTypes.string.isRequired,
  overrideRepeatFrequency: PropTypes.bool.isRequired,
  repeatOptionList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  userList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  subcontract: PropTypes.bool.isRequired,
  companyList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  contactList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  assetList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isshowasset: PropTypes.bool.isRequired,
  locationList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isshowlocation: PropTypes.bool.isRequired,
  isAddPlannedTask: PropTypes.bool,
  siteList: PropTypes.arrayOf(PropTypes.shape()),
  onChangeSite: PropTypes.func,
  categoryList: PropTypes.arrayOf(PropTypes.shape()),
  onChangeCategory: PropTypes.func,
  onClickNewTaskCategory: PropTypes.func,
  onChangeTask: PropTypes.func,
  onClickNewTask: PropTypes.func,
  siteId: PropTypes.number,
  categoryId: PropTypes.number,
  taskId: PropTypes.number,
  isLastTaskDisable: PropTypes.bool,
  isSimpleView: PropTypes.string,
  locationId: PropTypes.number,
  assetId: PropTypes.number,
  onChangeAssetLocation: PropTypes.func,
  onClickNewAsset: PropTypes.func,
  onClickNewLocation: PropTypes.func,
  showContacts: PropTypes.bool,
  showTaskFields: PropTypes.bool,
  overrideTaskDescription: PropTypes.bool,
}).isRequired;

MaintenanceScheduleEntryView.propTypes = {
  args: maintenanceScheduleProps,
  t: PropTypes.func.isRequired,
};

MaintenanceScheduleEntryView.defaultProps = {
  args: {
    isEdit: false,
    taskList: [],
    repeatEvery: '',
    repeatPeriod: '',
    isOtherRepeatPeriod: false,
    overrideRepeatFrequency: false,
    repeatOptionList: [],
    userList: [],
    subcontract: false,
    companyList: [],
    contactList: [],
    assetList: [],
    isshowasset: false,
    locationList: [],
    isshowlocation: false,
    isAddPlannedTask: false,
    siteList: [],
    onChangeSite: undefined,
    categoryList: [],
    onChangeCategory: undefined,
    onClickNewTaskCategory: undefined,
    onChangeTask: undefined,
    onClickNewTask: undefined,
    siteId: 0,
    categoryId: 0,
    taskId: 0,
    isLastTaskDisable: false,
    isSimpleView: 'false',
    locationId: 0,
    assetId: 0,
    onChangeAssetLocation: undefined,
    onClickNewAsset: undefined,
    onClickNewLocation: undefined,
    onRepeatPeriod: undefined,
    showContacts: true,
    showTaskFields: true,
    overrideTaskDescription: false,
    isCustomRepeatPeriod: false,
    handleCustomRepeatList: undefined,
    customRepeatList: [],
    customDateList: [],
    isRPChange: false,
  },
};

export default MaintenanceScheduleEntryView;
