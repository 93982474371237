import {
  SHOW_ERROR_MODAL,
  HIDE_ERROR_MODAL,
  SHOW_LOADER,
  HIDE_LOADER,
  LOAD_TASK_CATEGORY,
  LOAD_MANUFACTURER,
  LOAD_TASK_COMPLIANCE_PARENT_DELETE,
  LOAD_LOCATION,
  HIDE_SUCCESS_MODAL,
  SHOW_SUCCESS_MODAL,
} from './types';

import { api } from '../lib/api';
import { handleErrors } from './TokenActions';
import { Router } from '../../common/routes';

export const showErrorModal = (errorList) => ({
  type: SHOW_ERROR_MODAL,
  payload: errorList,
});

export const showSuccessModal = (messageList) => ({
  type: SHOW_SUCCESS_MODAL,
  payload: messageList,
});

export const hideSuccessModal = () => ({
  type: HIDE_SUCCESS_MODAL,
});

export const hideErrorModal = () => ({
  type: HIDE_ERROR_MODAL,
});

export const loadTaskCategoryOnDelete = (taskCategory) => ({
  type: LOAD_TASK_CATEGORY,
  payload: taskCategory,
});

export const deleteTaskCategoryWithUpdateTasksLibrary = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .put('/v2/taskCategory/delete-taskcategory-tasklibrary', params)
    .then((response) => {
      const item = {
        uuid: params.sourceUuid,
        name: null,
      };
      dispatch(loadTaskCategoryOnDelete(item));
      Router.pushRoute('cafm/admin/task_categories');
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteTaskCategoryWithUpdateTasksLibrary, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const loadManufacturerOnDelete = (manufacturer) => ({
  type: LOAD_MANUFACTURER,
  payload: manufacturer,
});

export const loadTaskComplianceOnParentDelete = (taskCompliance) => ({
  type: LOAD_TASK_COMPLIANCE_PARENT_DELETE,
  payload: taskCompliance,
});

export const loadLocation = (location) => ({
  type: LOAD_LOCATION,
  payload: location,
});

export const deleteParentChildData = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  let apiUrl = '';
  let item = {};
  let tempData = [];
  if (params.entityType === 'model') {
    apiUrl = '/v2/manufacturer/delete-manufacturer-model';
    item = {
      uuid: params.sourceUuid,
      name: null,
    };
  } else if (params.entityType === 'job') {
    apiUrl = '/v2/maintenanceSchedule/delete-maintenanceschedule-job';
  } else if (params.entityType === 'childLocation') {
    apiUrl = '/v2/location/delete-parent-reassign-child';
  } else if (params.entityType === 'childTaskCompliance') {
    apiUrl = '/v2/taskCompliance/delete-parent-reassign-child';
  } else if (params.entityType === 'complianceLinkedTasks') {
    apiUrl = '/v2/taskCompliance/delete-with-reassign-tasks-to-other-compliance';
  } else if (params.entityType === 'parentComplianceLinkedTasks') {
    apiUrl = '/v2/taskCompliance/update-parent-with-reassign-tasks-to-other-compliance';
  } else if (params.entityType === 'scheduleTaskJob') {
    apiUrl = '/v2/scheduleTask/delete-scheduletask-job';
  }
  api
    .put(apiUrl, params)
    .then((response) => {
      if (params.entityType === 'model') {
        dispatch(loadManufacturerOnDelete(item));
        Router.pushRoute('cafm/admin/manufacturers');
      } else if (params.entityType === 'job') {
        Router.pushRoute('cafm/admin/site_profile', { sid: params.sourceUuid }); // here it's siteId in sourceUuid
      } else if (params.entityType === 'childLocation') {
        item = {
          id: parseInt(params.sourceId),
          name: null,
        };
        dispatch(loadLocation(item));
        Router.pushRoute('cafm/admin/site_profile', { sid: params.sourceUuid }); // here it's siteId in sourceUuid
      } else if (params.entityType === 'childTaskCompliance') {
        tempData = response.data || [];
        item = {
          ParentTaskCompliance: null,
          accountId: 0,
          id: parseInt(params.sourceId),
          name: null,
          parentId: null,
        };
        tempData.push(item);
        dispatch(loadTaskComplianceOnParentDelete(tempData));
        Router.pushRoute('cafm/admin/settings');
      } else if (params.entityType === 'complianceLinkedTasks') {
        item = {
          ParentTaskCompliance: null,
          accountId: 0,
          id: parseInt(params.sourceId),
          name: null,
          parentId: null,
        };
        tempData.push(item);
        dispatch(loadTaskComplianceOnParentDelete(tempData));
        Router.pushRoute('cafm/admin/settings');
      } else if (params.entityType === 'scheduleTaskJob') {
        // here it's maintenanceScheduleId in sourceUuid
        Router.pushRoute('cafm/admin/schedule_profile', { schedule_id: params.sourceUuid });
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteParentChildData, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
