import { LOAD_ALL_TRACKERS } from '../../actions/types';

const INITIAL_STATE = {
  all: [],
};

const tracker = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_ALL_TRACKERS:
      return { ...state, all: action.payload };

    default:
      return state;
  }
};

export default tracker;
