import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import PickerView from './PickerView';
import DateInput from '../input/DateInput';

import { MONTH_LIST } from '../../constants/pickerdata';

class RangeMonthPicker extends React.Component {
  static propTypes = {
    placeholderStart: PropTypes.string,
    placeholderEnd: PropTypes.string,

    initialValues: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),

    changeDates: PropTypes.func,
  };

  static defaultProps = {
    initialValues: null,
    placeholderStart: 'Pick start date',
    placeholderEnd: 'Pick end date',
    changeDates: undefined,
  };

  constructor(props) {
    super(props);

    const initialData = this.getInitialData(props.initialValues);
    const initialState = {
      ...initialData,
      openStart: false,
      openEnd: false,
    };
    this.state = initialState;
    moment.locale('en');
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.initialValues) {
      const initialData = this.getInitialData(nextProps.initialValues);
      this.setState(initialData);
    }
  }

  getInitialData = (initialValues) => {
    let initialData = {};
    if (!initialValues) {
      initialData = {
        chosenStart: {
          year: null,
          month: null,
          mNumber: null,
        },

        chosenEnd: {
          year: null,
          month: null,
          mNumber: null,
        },

        startDate: null,
        endDate: null,
      };
    } else {
      const { startDate, endDate } = initialValues;
      const start = moment(startDate, 'YYYY-MM-DD');
      const end = moment(endDate, 'YYYY-MM-DD');
      initialData = {
        chosenStart: {
          year: start.year(),
          month: MONTH_LIST[start.month()],
          mNumber: start.month(),
        },
        chosenEnd: {
          year: end.year(),
          month: MONTH_LIST[end.month()],
          mNumber: end.month(),
        },
        startDate,
        endDate,
      };
    }
    return initialData;
  };

  addOverflowHiddenToBody = () => {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('range-picker-open');
  };

  removeOverflowHiddenFromBody = () => {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('range-picker-open');
  };

  handleStartClick = (year, month, mNumber) => {
    this.removeOverflowHiddenFromBody();
    const date = moment({ year, month: mNumber });
    const strDate = date.format('YYYY-MM-DD');

    let { chosenEnd, endDate } = this.state;
    let endYear = chosenEnd.year;
    let endMonth = chosenEnd.month;
    let endMNumber = chosenEnd.mNumber;

    if (year > endYear || (year === endYear && mNumber >= endMNumber)) {
      endYear = null;
      endMonth = null;
      endMNumber = null;
      endDate = null;
    }

    this.setState({
      chosenStart: {
        year,
        month,
        mNumber,
      },
      chosenEnd: {
        year: endYear,
        month: endMonth,
        mNumber: endMNumber,
      },
      startDate: strDate,
      endDate,
      openStart: false,
    });

    this.props.changeDates(strDate, endDate);
  };

  handleEndClick = (year, month, mNumber) => {
    this.removeOverflowHiddenFromBody();
    const date = moment({ year, month: mNumber });
    const strDate = date.format('YYYY-MM-DD');

    this.setState({
      chosenEnd: {
        year,
        month,
        mNumber,
      },
      endDate: strDate,
      openEnd: false,
    });

    this.props.changeDates(this.state.startDate, strDate);
  };

  openStartPicker = () => {
    this.addOverflowHiddenToBody();
    this.setState({
      openStart: true,
      openEnd: false,
    });
  };

  openEndPicker = () => {
    this.addOverflowHiddenToBody();
    this.setState({
      openEnd: true,
      openStart: false,
    });
  };

  handlePickersClose = (e) => {
    e.stopPropagation();
    this.removeOverflowHiddenFromBody();
    this.setState({
      openStart: false,
      openEnd: false,
    });
  };

  render() {
    const { chosenStart, chosenEnd, startDate, endDate, openStart, openEnd } = this.state;

    const { placeholderStart, placeholderEnd } = this.props;

    return (
      <div className="range-date-picker">
        {(openStart || openEnd) && <div onClick={this.handlePickersClose} className="elements-close-on-click" />}

        <DateInput
          placeholder={placeholderStart || 'Click to pick start date'}
          value={startDate}
          onClick={this.openStartPicker}
          isRange
        />

        {startDate && (
          <DateInput
            placeholder={placeholderEnd || 'Click to pick end date'}
            value={endDate}
            onClick={this.openEndPicker}
            isRange
          />
        )}

        {openStart && <PickerView chosen={chosenStart} isStart onMonthClick={this.handleStartClick} />}

        {openEnd && (
          <PickerView chosen={chosenEnd} chosenStart={chosenStart} isEnd onMonthClick={this.handleEndClick} />
        )}
      </div>
    );
  }
}

export default RangeMonthPicker;
