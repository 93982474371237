import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import moment from 'moment';
import _ from 'lodash';
import { updateJob, updateMultipleJob } from '../../../actions/cafm/JobActions';
import { getLocationFullList } from '../../../actions/cafm/LocationActions';
import { getFullUserListbyAccountId } from '../../../actions/UserActions';
import { showBodyScroll, hideBodyScroll } from '../../../lib/bodyScrollToggle';
import { PPMJobEntryView } from '../../cafm/admin';

class EditJobModal extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    initialValues: PropTypes.shape().isRequired,
    statusList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    updateJob: PropTypes.func.isRequired,
    updateMultipleJob: PropTypes.func,
    getLocationFullList: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape()),
    internalCompanies: PropTypes.arrayOf(PropTypes.shape()),
    getFullUserListbyAccountId: PropTypes.func,
    createForm: PropTypes.shape(),
    scheduleTaskList: PropTypes.arrayOf(PropTypes.shape()),
    isMultiple: PropTypes.bool,
  };

  static defaultProps = {
    users: [],
    internalCompanies: [],
    getFullUserListbyAccountId: undefined,
    createForm: {
      editJobForm: {},
    },
    scheduleTaskList: [],
    isMultiple: false,
    siteAccountId: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };

    props.getFullUserListbyAccountId({ accountId: this.props.siteAccountId });
    const { internalCompanies, initialValues } = props;
    const chosenCompany = _.find(internalCompanies, [
      'id',
      initialValues.supplierCompanyId && initialValues.supplierCompanyId.value,
    ]);
    const contacts = (chosenCompany && chosenCompany.InternalContact) || [];
    this.state = {
      companies: internalCompanies,
      contacts: contacts || [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      hideBodyScroll();
      this.setState({
        display: true,
      });
    }
    const { internalCompanies } = nextProps;
    if (internalCompanies !== this.props.internalCompanies) {
      this.setState({
        companies: internalCompanies,
      });
    }
    const form = nextProps.createForm.editJobForm;
    const prevForm = this.props.createForm.editJobForm;
    let subcontract = null;
    let siteId = null;

    if (prevForm && prevForm.values && form && form.values && form.values.siteId) {
      if (prevForm.values.siteId.value != form.values.siteId.value) {
        this.props.getLocationFullList({ siteId: form.values.siteId.value });
        this.props.change('locationId', null);
      }
      siteId = form.values.siteId.label;
    } else if ((prevForm && prevForm.values) || (form && form.values)) {
      this.props.getLocationFullList({ siteId: form?.values?.siteId?.value || prevForm?.values?.siteId?.value });
      siteId = form?.values?.siteId?.label || prevForm?.values?.siteId?.label;
    }

    if (
      prevForm &&
      prevForm.values &&
      prevForm.values.status &&
      form &&
      form.values &&
      form.values.status &&
      prevForm.values.status.label != form.values.status.label &&
      form.values.status.label === 'Complete'
    ) {
      this.props.change('closedDate', new Date());
    }

    if (prevForm && prevForm.values && form && form.values) {
      if (form.values.siteId) {
        siteId = form.values.siteId.label;
      } else {
        siteId = null;
      }

      if (
        (prevForm.values.supplierCompanyId && prevForm.values.supplierCompanyId !== form.values.supplierCompanyId) ||
        (!prevForm.values.supplierCompanyId && form.values.supplierCompanyId)
      ) {
        if (form.values.supplierCompanyId) {
          const chosenCompany = _.find(this.props.internalCompanies, ['id', form.values.supplierCompanyId.value]);
          if (chosenCompany && chosenCompany.InternalContact) {
            const contacts = chosenCompany.InternalContact;
            this.props.change('supplierContactId', null);
            this.setState({
              contacts,
            });
          }
        } else {
          this.props.change('supplierContactId', null);
          this.setState({
            contacts: [],
          });
        }
      }
    }
    if (form && form.values && (form.values.supplierCompanyId || form.values.userAssigneeId)) {
      if (
        form.values.supplierCompanyId &&
        form.values.supplierCompanyId.value > 0 &&
        (!form.values.userAssigneeId || form.values.userAssigneeId.value <= 0)
      )
        subcontract = true;
      else if (
        (!form.values.supplierCompanyId || form.values.supplierCompanyId.value <= 0) &&
        form.values.userAssigneeId &&
        form.values.userAssigneeId.value > 0
      )
        subcontract = false;
    }
    this.setState({
      subcontract,
      siteId,
    });
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    showBodyScroll();
    this.props.onModalClose();
  };

  handleUpdateJob = (job) => {
    const { id, isMultiple, initialValues } = this.props;
    let newJob = _.pickBy(job, (field) => field !== null && field !== undefined);

    newJob.id = id;
    newJob.locationId = job.locationId && job.locationId.value > 0 ? job.locationId.value : null;
    newJob.siteId = job.siteId && job.siteId.value > 0 ? job.siteId.value : null;

    newJob.status = job.status && job.status.value > 0 ? job.status.value : null;
    newJob.userAssigneeId = job.userAssigneeId && job.userAssigneeId.value > 0 ? job.userAssigneeId.value : null;
    newJob.supplierCompanyId =
      job.supplierCompanyId && job.supplierCompanyId.value > 0 ? job.supplierCompanyId.value : null;
    newJob.supplierContactId =
      job.supplierContactId && job.supplierContactId.value > 0 ? job.supplierContactId.value : null;

    newJob.dueDate = job.dueDate && moment(job.dueDate).isValid() ? moment(job.dueDate).toISOString(true) : null;
    newJob.visitDate = job.visitDate && moment(job.visitDate).isValid() ? moment(job.visitDate).toISOString(true) : null;
    newJob.closedDate =
      job.dueDate && moment(job.closedDate).isValid() ? moment(job.closedDate).toISOString(true) : null;

    newJob.scheduleTaskId = job.scheduleTask && job.scheduleTask.value > 0 ? job.scheduleTask.value : 0;
    newJob.changeAssignee = true;
    newJob.isReactive = this.props.initialValues?.jobData?.jobType === 2 ? true : false;
    newJob = _.pickBy(newJob, (field) => field !== null && field !== undefined);

    if (isMultiple) {
      newJob.isBulkUpdate = true;
      newJob.jobIds = id;
      delete newJob.id;
      this.props.updateMultipleJob(newJob, { ...this.props.gridFilter, jobType: initialValues.jobData.jobType });
    } else {
      this.props.updateJob(newJob);
    }
    this.closeModal();
  };

  render() {
    const {
      handleSubmit,
      t,
      statusList,
      users,
      scheduleTaskList,
      internalCompanies,
      initialValues,
      siteList,
      locationList,
    } = this.props;
    const { display, subcontract, companies, contacts, siteId } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show modal-template form-horizontal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{t('Update job')}</h2>

          <form>
            <PPMJobEntryView
              t={t}
              args={{
                userList: users,
                subcontract,
                siteId,
                companyList: companies,
                contactList: contacts,
                siteList,
                locationList,
                statusList,
                scheduleTaskList,
                jobData: initialValues.jobData,
              }}
            />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleUpdateJob)}
            >
              {t('Update')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues,
  createForm: state.form,
  users: state.user.list,
  locationList: state.location.parent.length > 0 ? state.location.parent : state.location.list,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateJob,
      updateMultipleJob,
      getFullUserListbyAccountId,
      getLocationFullList,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'editJobForm',
    enableReinitialize: true,
  })(EditJobModal),
);
