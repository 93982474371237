import {
  LOAD_BUDGET_CATEGORY_GROUP,
  LOAD_BUDGET_CATEGORY_GROUPS,
  LOAD_COUNT,
  SHOW_LOADER,
  HIDE_LOADER,
} from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/budgetCategoryGroup';

export const loadBudgetCategoryGroup = (budgetCategoryGroup) => ({
  type: LOAD_BUDGET_CATEGORY_GROUP,
  payload: budgetCategoryGroup,
});

export const loadBudgetCategoryGroups = (budgetCategoryGroups) => ({
  type: LOAD_BUDGET_CATEGORY_GROUPS,
  payload: budgetCategoryGroups,
});

export const loadCount = (count) => ({
  type: LOAD_COUNT,
  payload: count,
});

export const createBudgetCategoryGroup = (budgetCategoryGroup) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/create`, budgetCategoryGroup)
    .then((response) => {
      dispatch(loadBudgetCategoryGroup(response.data));
      Router.pushRoute('category_groups');
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createBudgetCategoryGroup, budgetCategoryGroup);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateBudgetCategoryGroup = (budgetCategoryGroup) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .put(`${routePath}/update`, budgetCategoryGroup)
    .then((response) => {
      dispatch(loadBudgetCategoryGroup(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateBudgetCategoryGroup, budgetCategoryGroup);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getBudgetCategoryGroup = (uuid) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-item/${uuid}`)
    .then((response) => {
      dispatch(loadBudgetCategoryGroup(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getBudgetCategoryGroup, uuid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteBudgetCategoryGroup = (uuid) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${uuid}`)
    .then(() => {
      const item = {
        uuid,
        name: null,
        code: null,
      };
      dispatch(loadBudgetCategoryGroup(item));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteBudgetCategoryGroup, uuid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getBudgetCategoryGroupList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      dispatch(loadBudgetCategoryGroups(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getBudgetCategoryGroupList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getBudgetCategoryGroupFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-full-list`, { params })
    .then((response) => {
      dispatch(loadBudgetCategoryGroups(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getBudgetCategoryGroupFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getBudgetCategoryGroupListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list-with-size`, { params })
    .then((response) => {
      dispatch(loadBudgetCategoryGroups(response.data.rows));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getBudgetCategoryGroupListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
