import {
  LOAD_ASSET,
  LOAD_ASSETS,
  LOAD_ALL_ASSETS,
  LOAD_ASSET_COUNT,
  SHOW_LOADER,
  HIDE_LOADER,
  LOAD_ASSETS_ON_MOVE_FROM_ASSET_GROUP_PROFILE,
  LOAD_ALL_ASSETS_LINKED_WITH_ASSET_GROUP,
  LOAD_ALL_ASSETS_LINKED_WITH_MAINTENANCE_SCHEDULE,
} from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

import commonFunction from '../../lib/commonFunction';
import { uploadUpdateAttachment } from './AttachmentActions';

const routePath = '/v2/asset';

export const adjustAssets = (asset) => {
  // if (!asset.locationId || !asset.Location) {
  //   asset.locationName = null;
  // } else {
  //   asset.locationName = asset.Location.name;
  // }

  if (asset.AssetGroup && asset.AssetGroup.Location && asset.AssetGroup.Location.ParentLocation) {
    asset.locationName = asset.AssetGroup.Location.ParentLocation.name;
  } else if (asset.AssetGroup && asset.AssetGroup.Location) {
    asset.locationName = asset.AssetGroup.Location.name;
  } else if (asset.Location && asset.Location.ParentLocation) {
    asset.locationName = asset.Location.ParentLocation.name;
  } else if (asset.locationId && asset.Location) {
    asset.locationName = asset.Location.name;
  } else {
    asset.locationName = null;
  }

  if (!asset.modelId || !asset.Model) {
    asset.modelName = null;
    asset.manufacturerName = null;
  } else {
    asset.modelName = asset.Model.name;
    if (!asset.Model.Manufacturer) {
      asset.manufacturerName = null;
    } else {
      asset.manufacturerName = asset.Model.Manufacturer.name;
    }
  }
  asset.installDate = commonFunction.displayDateWithValidFormat(asset.installDate);
};

export const loadAsset = (asset) => ({
  type: LOAD_ASSET,
  payload: asset,
});

export const loadAssets = (assets) => ({
  type: LOAD_ASSETS,
  payload: assets,
});

export const loadAllAssets = (assets) => ({
  type: LOAD_ALL_ASSETS,
  payload: assets,
});

export const loadCount = (count) => ({
  type: LOAD_ASSET_COUNT,
  payload: count,
});

export const loadAssetsOnMoveFromAssetGroup = (asset) => ({
  type: LOAD_ASSETS_ON_MOVE_FROM_ASSET_GROUP_PROFILE,
  payload: asset,
});

export const loadAllAssetsByAssetGroup = (assets) => ({
  type: LOAD_ALL_ASSETS_LINKED_WITH_ASSET_GROUP,
  payload: assets,
});

export const loadAllAssetsByMaintenanceSchedule = (assets) => ({
  type: LOAD_ALL_ASSETS_LINKED_WITH_MAINTENANCE_SCHEDULE,
  payload: assets,
});

export const createAsset =
  (asset, args = null) =>
  (dispatch) => {
    dispatch({ type: SHOW_LOADER });

    api
      .post(`${routePath}/create`, asset)
      .then((response) => {
        const newAsset = response.data;
        return (
          dispatch(uploadUpdateAttachment(newAsset.id, 'assetPicture'))
            // pictures is the return value of updateAssetPictures
            // which returns the value of AssetPicturesActions.update()
            .then((pictures) => {
              newAsset.AssetPictures = pictures;
              dispatch(loadAsset(newAsset));
              if (args && args.entityType === 'assetgroupprofile') {
                Router.pushRoute('cafm/admin/asset_profile_by_assetgroup', { asset_id: response.data.id });
              } else {
                Router.pushRoute('cafm/admin/asset_profile', { asset_id: response.data.id });
              }
            })
        );
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response?.data));
        handleErrors(error, dispatch, createAsset, asset);
      })
      .then(() => dispatch({ type: HIDE_LOADER }));
  };

export const quickCreateAsset = (asset) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/create`, asset)
    .then((response) => {
      const newAsset = response.data;
      return (
        dispatch(uploadUpdateAttachment(newAsset.id, 'assetPicture'))
          // pictures is the return value of updateAssetPictures
          // which returns the value of AssetPicturesActions.update()
          .then((pictures) => {
            newAsset.AssetPictures = pictures;
            dispatch(loadAsset(newAsset));
          })
      );
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, quickCreateAsset, asset);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};

export const updateAsset = (asset) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  return api
    .put(`${routePath}/update`, asset)
    .then((response) => {
      const newAsset = response.data;
      return (
        dispatch(uploadUpdateAttachment(newAsset.id, 'assetPicture'))
          // pictures is the return value of updateAssetPictures
          // which returns the value of AssetPicturesActions.update()
          .then((pictures) => {
            newAsset.AssetPictures = pictures;
            adjustAssets(newAsset);
            dispatch(loadAsset(newAsset));
          })
      );
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      dispatch({ type: HIDE_LOADER });
      handleErrors(error, dispatch, updateAsset, asset);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};

export const getAsset = (id) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-item/${id}`)
    .then((response) => {
      const newAsset = response.data;
      adjustAssets(newAsset);
      dispatch(loadAsset(newAsset));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getAsset, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteAsset =
  (id, siteId, args = null) =>
  (dispatch) => {
    dispatch({ type: SHOW_LOADER });

    api
      .delete(`${routePath}/delete/${id}`)
      .then(() => {
        const item = {
          id,
          name: null,
        };
        dispatch(loadAsset(item));
        if (args && args.entityType === 'assetgroupprofile') {
          // Assetgroup profile > Assets (table) > view/add new > delete then back on assetgroup profile
          Router.pushRoute('cafm/admin/asset_group_profile', { asset_group_id: args.assetGroupId });
        } else if (siteId) {
          Router.pushRoute('cafm/admin/site_profile', { sid: siteId });
        }
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response?.data));
        handleErrors(error, dispatch, deleteAsset, id);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const getAssetList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  // params.surveyStatus = 3;
  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      const newAssets = response.data;
      newAssets.forEach(adjustAssets);
      dispatch(loadAssets(newAssets));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getAssetList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAssetFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-full-list`, { params })
    .then((response) => {
      const newAssets = response.data;
      newAssets.forEach(adjustAssets);
      dispatch(loadAllAssets(newAssets));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getAssetFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAssetListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  params.surveyStatus = 3;
  api
    .get(`${routePath}/get-list-with-size-multiple-filter`, { params })
    .then((response) => {
      const newAssets = response.data.rows;
      newAssets.forEach(adjustAssets);
      dispatch(loadAssets(newAssets));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getAssetListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const moveAsset =
  (body, args = null) =>
  (dispatch) => {
    dispatch({ type: SHOW_LOADER });

    return api
      .put(`${routePath}/move-asset`, body)
      .then((response) => {
        const asset = response.data;
        adjustAssets(asset);
        if (args && args.entityType === 'assetgroupprofile') {
          // here we pass oldAssetGroupId
          dispatch(loadAssetsOnMoveFromAssetGroup(asset));
        } else {
          dispatch(loadAsset(asset));
        }
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response?.data));
        handleErrors(error, dispatch, moveAsset, body);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const addRemoveAssetToAssetGroup = (body) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  return api
    .put(`${routePath}/add-remove-to-assetgroup`, body)
    .then((response) => {
      const asset = response.data;
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, addRemoveAssetToAssetGroup, body);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAssetFullListByAssetGroup = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  return api
    .get(`${routePath}/get-full-list`, { params })
    .then((response) => {
      const newAssets = response.data;
      newAssets.forEach(adjustAssets);
      dispatch(loadAllAssetsByAssetGroup(newAssets));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getAssetFullListByAssetGroup, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const addRemoveAssetToMaintenanceSchedule = (body) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  return api
    .put(`${routePath}/add-remove-to-maintenanceschedule`, body)
    .then((response) => {
      const asset = response.data;
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, addRemoveAssetToMaintenanceSchedule, body);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAssetFullListByMaintenanceSchedule = (maintenanceScheduleId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  return api
    .get(`${routePath}/get-full-list-by-maintenanceschedule/${maintenanceScheduleId}`)
    .then((response) => {
      const newAssets = response.data;
      dispatch(loadAllAssetsByMaintenanceSchedule(newAssets));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getAssetFullListByMaintenanceSchedule, maintenanceScheduleId);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAssetByJobId = (jobid) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  return api
    .get(`/cafm/task/${jobid}/assets`)
    .then((response) => {
      const newAssets = response.data.data;
      newAssets.forEach(adjustAssets);
      dispatch(loadAllAssets(newAssets));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getAssetByJobId, jobid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
