import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';
import { updateSchedule, getScheduleTaskCountByScheduleId } from '../../../actions/cafm/ScheduleActions';
import { showBodyScroll, hideBodyScroll } from '../../../lib/bodyScrollToggle';
import {
  getRepeatOptions,
  getPeriodOptions,
  getCustomDateList,
  getRepeatOtherOptions,
} from '../../../constants/options';

import { getFullUserListbyAccountId } from '../../../actions/UserActions';
import { getLookupvalueList } from '../../../actions/cafm/LookupvaluesAction';
import { MaintenanceScheduleEntryView } from '../../cafm/admin';
import commonFunction from '../../../lib/commonFunction';
import moment from 'moment';

class EditScheduleModal extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    initialValues: PropTypes.shape().isRequired,
    createForm: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    tasks: PropTypes.arrayOf(PropTypes.shape()),
    assets: PropTypes.arrayOf(PropTypes.shape()),
    internalCompanies: PropTypes.arrayOf(PropTypes.shape()),
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    updateSchedule: PropTypes.func.isRequired,
    locations: PropTypes.arrayOf(PropTypes.shape()),
    users: PropTypes.arrayOf(PropTypes.shape()),
    getFullUserListbyAccountId: PropTypes.func,
    getScheduleTaskCountByScheduleId: PropTypes.func,
    scheduleTaskCountByMS: PropTypes.number,
  };

  static defaultProps = {
    tasks: [],
    assets: [],
    internalCompanies: [],
    createForm: {
      editScheduleForm: {},
    },
    locations: [],
    users: [],
    getFullUserListbyAccountId: undefined,
    getScheduleTaskCountByScheduleId: undefined,
    scheduleTaskCountByMS: 0,
  };

  constructor(props) {
    super(props);
    const repeatOptions = getRepeatOptions(props.t);
    const periodOptions = getPeriodOptions(props.t);
    const customDateList = getCustomDateList(props.t);
    const repeatOtherOptions = getRepeatOtherOptions(props.t);
    const { assets, tasks, internalCompanies, initialValues, locations } = props;
    const chosenCompany = _.find(internalCompanies, ['id', initialValues.company && initialValues.company.value]);
    const contacts = (chosenCompany && chosenCompany.InternalContact) || [];
    const repeatPeriodValue = initialValues?.repeatPeriod?.value ?? null;
    const isOtherRepeatPeriod =
      repeatPeriodValue != null && repeatOtherOptions.includes(repeatPeriodValue) ? true : false;
    const isCustomRepeatPeriod = repeatPeriodValue != null && repeatPeriodValue === 'Custom' ? true : false;
    this.state = {
      display: false,
      assets: assets || [],
      locations: locations || [],
      tasks: tasks || [],
      companies: internalCompanies || [],
      repeatEvery: initialValues?.repeatEvery != '-' ? initialValues.repeatEvery : 1,
      repeatPeriod: repeatPeriodValue,
      oldRpValue: _.clone(repeatPeriodValue),
      contacts: contacts || [],
      repeatOptions,
      isRPChange: false,
      customDateList,
      repeatOtherOptions,
      isOtherRepeatPeriod: isOtherRepeatPeriod,
      isCustomRepeatPeriod: isCustomRepeatPeriod,
      customRepeatList: initialValues?.customRepeatList || [],
      oldCustomRepeatList: _.clone(initialValues.customRepeatList),
      overrideRepeatFrequency: initialValues.overrideRepeatFrequency === 1 ? true : false,
      periodOptions,
      overrideLabourFrequency: false,
      labourRequired: '',
      labourPeriod: '',
      overrideTaskDescription: false,
    };
    props.getFullUserListbyAccountId();
    props.getScheduleTaskCountByScheduleId(props.id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      hideBodyScroll();
      this.setState({
        display: true,
      });
    }
    const { tasks, assets, internalCompanies, locations, users } = nextProps;

    if (tasks !== this.props.tasks) {
      this.setState({
        tasks: tasks || [],
      });
    }
    if (assets !== this.props.assets) {
      this.setState({
        assets: assets || [],
      });
    }
    if (internalCompanies !== this.props.internalCompanies) {
      this.setState({
        companies: internalCompanies || [],
      });
    }

    if (locations !== this.props.locations) {
      this.setState({
        locations: locations || [],
      });
    }
    const form = nextProps.createForm.editScheduleForm;
    const prevForm = this.props.createForm.editScheduleForm;
    let subcontract = null;

    if (prevForm && prevForm.values && form && form.values) {
      if (
        (prevForm.values.company && prevForm.values.company !== form.values.company) ||
        (!prevForm.values.company && form.values.company)
      ) {
        if (form.values.company) {
          const chosenCompany = _.find(this.props.internalCompanies, ['id', form.values.company.value]);
          if (chosenCompany && chosenCompany.InternalContact) {
            const contacts = chosenCompany.InternalContact;
            this.props.change('contact', null);
            this.setState({
              contacts: contacts || [],
            });
          }
        } else {
          this.props.change('contact', null);
          this.setState({
            contacts: [],
          });
        }
      }
    }

    if (form && form.values && (form.values.company || form.values.userAssignee)) {
      if (form.values.company && !form.values.userAssignee) subcontract = true;
      else if (!form.values.company && form.values.userAssignee) subcontract = false;
    }
    if (!subcontract) {
      this.props.change('notifySubcontractor', false);
    }

    let repeatEvery = this.state.repeatEvery;
    let repeatPeriod = this.state.repeatPeriod;
    let labourRequired = null;
    let labourPeriod = null;
    if (form.values && form.values.task && form.values.task[0]) {
      const chosenTask = form.values.task[0];
      if (chosenTask && chosenTask.repeatEvery && chosenTask.repeatPeriod) {
        repeatEvery = chosenTask.repeatEvery;
        repeatPeriod = chosenTask.repeatPeriod;
      }
      if (chosenTask && chosenTask.labourRequired && chosenTask.labourPeriod) {
        labourRequired = chosenTask.labourRequired;
        labourPeriod = chosenTask.labourPeriod;
      }
      if (
        prevForm &&
        prevForm.values &&
        (prevForm.values.task[0] !== form.values.task[0] || (!prevForm.values.task[0] && form.values.task[0]))
      ) {
        form.values.taskDescription = chosenTask && chosenTask.description;
      }
    }

    let isshowasset = false;
    let isshowlocation = false;
    if (form.values && form.values.asset && form.values.asset[0]) {
      const asset = form.values.asset[0];
      if (asset && asset.name) {
        isshowlocation = true;
      }
    }
    if (form.values && form.values.location && form.values.location[0]) {
      const location = form.values.location[0];
      if (location && location.name) {
        isshowasset = true;
      }
    }
    let overrideRepeatFrequency = false;
    if (form.values && form.values.overrideRepeatFrequency) {
      overrideRepeatFrequency = true;
    }
    let overrideLabourFrequency = false;
    if (form.values && form.values.overrideLabourFrequency) {
      overrideLabourFrequency = true;
    }
    let overrideTaskDescription = false;
    if (form.values && form.values.overrideTaskDescription) {
      overrideTaskDescription = true;
    }
    if (overrideRepeatFrequency) {
      if (
        this.state.repeatPeriod &&
        this.state.repeatOtherOptions.length > 0 &&
        this.state.repeatOtherOptions.includes(this.state.repeatPeriod.value)
      ) {
        repeatEvery = 1;
      } else {
        repeatEvery = this.state.repeatEvery;
      }
    }

    this.setState({
      repeatEvery,
      repeatPeriod,
      isshowlocation,
      isshowasset,
      subcontract,
      overrideRepeatFrequency,
      overrideLabourFrequency,
      labourRequired,
      labourPeriod,
      overrideTaskDescription,
    });
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    showBodyScroll();
    this.props.onModalClose();
  };

  handleUpdateSchedule = (schedule) => {
    const { id, scheduleTaskCountByMS } = this.props;
    const {
      name,
      task,
      asset,
      company,
      contact,
      subcontract,
      active,
      firstTask,
      location,
      overrideRepeatFrequency,
      notifySubcontractor,
      repeatEvery,
      repeatPeriod,
      userAssignee,
      tags,
      overrideLabourFrequency,
      labourRequired,
      labourPeriod,
      overrideTaskDescription,
      deleteOnwords,
    } = schedule;

    const contentOnly =
      schedule && schedule.taskDescription ? schedule.taskDescription.replace(/<(?:.|\n)*?>/gm, '') : '';
    if (contentOnly !== undefined && contentOnly.trim() === '') {
      schedule.taskDescription = '';
    }
    const newCreatedLookup = _.filter(
      tags,
      (item) => item.value === item.label, // react-select/creatable, new has label/value same
    );
    const selectedLookup = _.filter(tags, (item) => item.value !== undefined && item.value !== item.label);
    const Createlookupvalue = _.map(newCreatedLookup, (lookup, key) => ({ lookupvalue: lookup.value }));
    const selectedLookupValues = selectedLookup.map((lookup) => lookup.value);

    const newSchedule = {
      id,
      name,
      assetId: asset && typeof asset === 'object' ? asset.value : null,
      enabled: active,
      locationId: location && typeof location === 'object' ? location.value : null,
      selectedLookupValues,
      Createlookupvalue,
      scheduleTaskCountByMS,
      customRepeatList: this.state.customRepeatList,
      repeatEvery: overrideRepeatFrequency ? repeatEvery : this.state.repeatEvery,
      deleteOnwords: commonFunction.getDateWithValidFormat(deleteOnwords),
      isRPChange: this.state.isRPChange,
      lastTaskDate: this.props.initialValues.lastTaskDate
        ? moment(this.props.initialValues.lastTaskDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      taskRepeatEvery: this.state.repeatEvery,
      taskRepeatPeriod: repeatPeriod?.value || null,
    };

    if (scheduleTaskCountByMS === 1) {
      newSchedule.taskLibraryId = task && typeof task === 'object' ? task.value : null;
      newSchedule.supplierCompanyId = company && typeof company === 'object' ? company.value : null;
      newSchedule.supplierContactId = contact && typeof contact === 'object' ? contact.value : null;
      newSchedule.userAssigneeId = userAssignee && typeof userAssignee === 'object' ? userAssignee.value : null;
      newSchedule.subcontract = subcontract;
      newSchedule.firstTaskDate = firstTask ? moment(firstTask, 'DD/MM/YYYY').format('YYYY-MM-DD') : null;
      newSchedule.overrideRepeatFrequency = overrideRepeatFrequency ? true : false;
      newSchedule.notifySubcontractor = notifySubcontractor;
      newSchedule.overrideLabourFrequency = overrideLabourFrequency;
      newSchedule.labourRequired = overrideLabourFrequency ? labourRequired : undefined;
      newSchedule.labourPeriod = overrideLabourFrequency ? labourPeriod.value : undefined;
      newSchedule.overrideTaskDescription = overrideTaskDescription;
      newSchedule.taskDescription = overrideTaskDescription ? schedule.taskDescription : undefined;

      newSchedule.repeatPeriod = overrideRepeatFrequency ? repeatPeriod.value : undefined;
      newSchedule.repeatEvery = overrideRepeatFrequency ? repeatEvery : this.state.repeatEvery;
    }

    if (overrideRepeatFrequency) {
      const isOther = this.state.repeatOtherOptions.includes(repeatPeriod?.value || '');
      if (isOther) {
        newSchedule.repeatEvery = 1;
      } else {
        newSchedule.repeatEvery = repeatEvery;
      }
    } else {
      newSchedule.repeatEvery = this.state.repeatEvery;
    }
    this.props.updateSchedule(newSchedule, true);
    this.closeModal();
  };

  handleCustomRepeatList = (value) => {
    const checkIfSelected = this.state.customRepeatList.filter((e) => e.id === value.id);
    const newRPList =
      checkIfSelected.length > 0
        ? this.state.customRepeatList.filter((e) => e.id !== value.id)
        : this.state.customRepeatList.concat(value);

    let isRPChange = false;

    let newArr = newRPList.map((e) => e.id);
    let oldArr = this.state.oldCustomRepeatList.map((e) => e.id);
    newArr.sort();
    oldArr.sort();
    if (JSON.stringify(newArr) != JSON.stringify(oldArr)) {
      isRPChange = true;
    }

    this.setState({
      customRepeatList: newRPList,
      isRPChange,
    });
  };

  handleRepeatPeriod = (e) => {
    let isRPChange = false;
    if (e.value != this.state.oldRpValue) {
      isRPChange = true;
    }
    this.setState({
      isOtherRepeatPeriod: this.state.repeatOtherOptions.includes(e.value) ? true : false,
      isCustomRepeatPeriod: e.value === 'Custom' ? true : false,
      isRPChange,
    });
  };

  render() {
    const { handleSubmit, t, users, scheduleTaskCountByMS } = this.props;
    let showTaskFields = true;
    if (scheduleTaskCountByMS > 1) showTaskFields = false;
    const {
      display,
      tasks,
      assets,
      companies,
      contacts,
      repeatEvery,
      repeatPeriod,
      locations,
      isshowlocation,
      isshowasset,
      subcontract,
      repeatOptions,
      overrideRepeatFrequency,
      periodOptions,
      overrideLabourFrequency,
      labourRequired,
      labourPeriod,
      overrideTaskDescription,
      isOtherRepeatPeriod,
      isCustomRepeatPeriod,
      customRepeatList,
      customDateList,
      isRPChange,
    } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show modal-template form-horizontal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{t('Update Maintenance Schedule')}</h2>

          <form>
            <MaintenanceScheduleEntryView
              t={t}
              args={{
                taskList: tasks,
                repeatEvery,
                repeatPeriod,
                overrideRepeatFrequency,
                repeatOptionList: repeatOptions,
                userList: users,
                subcontract,
                companyList: companies,
                contactList: contacts,
                showContacts: true,
                assetList: assets,
                isshowasset,
                locationList: locations,
                isshowlocation,
                overrideLabourFrequency,
                periodOptionList: periodOptions,
                labourRequired,
                labourPeriod,
                showTaskFields,
                overrideTaskDescription,
                isRPChange,
                isEdit: true,
                customRepeatList,
                handleCustomRepeatList: this.handleCustomRepeatList,
                onRepeatPeriod: this.handleRepeatPeriod,
                isOtherRepeatPeriod,
                isCustomRepeatPeriod,
                customDateList,
              }}
            />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleUpdateSchedule)}
            >
              {t('Update')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues,
  createForm: state.form,
  users: state.user.list,
  scheduleTaskCountByMS: state && state.schedule ? state.schedule.scheduleTaskCountByMS : 0,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateSchedule,
      getFullUserListbyAccountId,
      getScheduleTaskCountByScheduleId,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'editScheduleForm',
    enableReinitialize: true,
  })(EditScheduleModal),
);
