import { LOAD_BUILDING, LOAD_BUILDINGS, LOAD_COUNT, SHOW_LOADER, HIDE_LOADER } from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal, showSuccessModal } from '../ErrorActions';

import commonFunction from '../../lib/commonFunction';
import { uploadUpdateAttachment } from '../cafm/AttachmentActions';

const routePath = '/v2/building';

const adjustBuildings = (building) => {
  building.siteActive = building.isActive ? 'yes' : 'no';
  if (!building.BuildingBudget || !building.BuildingBudget[0]) {
    building.startDate = commonFunction.displayDateWithValidFormat(building.startDate);
    building.endDate = commonFunction.displayDateWithValidFormat(building.endDate);
    building.createdAt = commonFunction.displayDateWithValidFormat(building.createdAt);
    return;
  }
  building.typeId = 'bid';
  building.amount = building.BuildingBudget[0].amount;
  building.remainingAmount = building.BuildingBudget[0].remainingAmount;
  building.startDate = commonFunction.displayDateWithValidFormat(building.BuildingBudget[0].startDate);
  building.endDate = commonFunction.displayDateWithValidFormat(building.BuildingBudget[0].endDate);
};

export const loadBuilding = (building) => ({
  type: LOAD_BUILDING,
  payload: { ...building, type: building.type == 1 ? 'Commercial' : 'Residential' },
});

const formatSiteData = (buildings) => {
  return buildings.map((e) => ({ ...e, type: e.type == 1 ? 'Commercial' : 'Residential', typeId: e.type }));
};

export const loadBuildings = (buildings) => ({
  type: LOAD_BUILDINGS,
  payload: formatSiteData(buildings),
});

export const loadCount = (count) => ({
  type: LOAD_COUNT,
  payload: count,
});

export const createBuilding = (building, path) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  return api
    .post(`${routePath}/create`, building)
    .then((response) => {
      const site = response.data;
      return dispatch(uploadUpdateAttachment(site.id, 'sitePicture')).then(() => {
        if (path !== 'cafm') {
          Router.pushRoute('bms/building_profile', { bid: site.id });
        } else {
          Router.pushRoute('cafm/admin/site_profile', { sid: site.id });
        }
      });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createBuilding, building);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};

export const updateBuilding = (building) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  return api
    .put(`${routePath}/update`, building)
    .then((response) => {
      const site = response.data;
      if (building.isUpdateOnlyTempleate && building.isUpdateOnlyTempleate === 1) {
        if (!building.isMultipleEdit) {
          adjustBuildings(site);
          dispatch(loadBuilding(site));
        }

        dispatch(
          showSuccessModal([
            {
              message: 'Successfully Updated',
            },
          ]),
        );
      } else {
        return (
          dispatch(uploadUpdateAttachment(site.id, 'sitePicture'))
            // pictures is the return value of updateSitePictures
            // which returns the value of SitePicturesActions.update()
            .then((pictures) => {
              site.SitePictures = pictures;
              adjustBuildings(site);
              dispatch(loadBuilding(site));
            })
        );
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateBuilding, building);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};

export const getBuilding = (id) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-item/${id}`)
    .then((response) => {
      const newBuilding = response.data;
      adjustBuildings(newBuilding);
      dispatch(loadBuilding(newBuilding));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getBuilding, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteBuilding = (id, path) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${id}`)
    .then(() => {
      const item = {
        id,
        name: null,
      };
      dispatch(loadBuilding(item));
      if (!path) {
        Router.pushRoute('bms/buildings');
      } else if (path === 'sites') {
        Router.pushRoute('cafm/admin/sites');
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteBuilding, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getBuildingList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      const buildings = response.data;
      buildings.forEach(adjustBuildings);
      dispatch(loadBuildings(buildings));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getBuildingList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getBuildingListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list-with-size-multiple-filter`, { params })
    .then((response) => {
      const buildings = response.data.rows;
      buildings.forEach(adjustBuildings);
      dispatch(loadBuildings(buildings));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getBuildingListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const addBudgetPeriod = (budget) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/add-budget-period`, budget)
    .then((response) => {
      const newBuilding = response.data;
      adjustBuildings(newBuilding);
      dispatch(loadBuilding(newBuilding));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, addBudgetPeriod, budget);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateBudgetPeriod = (budget) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .put(`${routePath}/update-budget-period`, budget)
    .then((response) => {
      const newBuilding = response.data;
      adjustBuildings(newBuilding);
      dispatch(loadBuilding(newBuilding));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateBudgetPeriod, budget);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteBudgetPeriod = (id) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete-budget-period/${id}`)
    .then((response) => {
      const newBuilding = response.data;
      adjustBuildings(newBuilding);
      dispatch(loadBuilding(newBuilding));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteBudgetPeriod, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const bulkUpdateSiteData = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  const tmpRoutePath = '/v2/bulkUpdate';
  api
    .put(`${tmpRoutePath}/site-update`, params)
    .then((response) => {
      window.location.href = '/cafm/admin/sites';
      dispatch({ type: HIDE_LOADER });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, bulkUpdateSiteData, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
