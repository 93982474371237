export const permissions = {
  accounts: {
    Edit: 'core.accounts.edit.own',
  },

  roles: {
    View: 'core.roles.view',
    ViewAll: 'core.roles.view.all',
    ViewOwn: 'core.roles.view.own',
    Create: 'core.roles.create',
    Edit: 'core.roles.edit',
    EditAll: 'core.roles.edit.all',
    EditOwn: 'core.roles.edit.own',
    Delete: 'core.roles.delete',
    DeleteAll: 'core.roles.delete.all',
    DeleteOwn: 'core.roles.delete.own',
  },

  subscriptions: {
    Cafm: 'cafm.access',
    Alp: 'alp.access',
    Jms: 'jms.access',
  },

  sites: {
    View: 'cafm.sites.view',
    ViewAll: 'cafm.sites.view.all',
    EditAll: 'cafm.sites.edit.all',
    DeleteAll: 'cafm.sites.delete.all',
  },

  site: {
    View: 'cafm.site.view',
    ViewOwn: 'cafm.site.view.own',
    Create: 'cafm.site.create',
    Edit: 'cafm.site.edit',
    EditOwn: 'cafm.site.edit.own',
    Delete: 'cafm.site.delete',
    DeleteOwn: 'cafm.site.delete.own',

    Admin: 'cafm.site.admin',
    AdminAll: 'cafm.site.admin.all',
    AdminOwn: 'cafm.site.admin.own',
    AdminActivity: 'cafm.site.admin.activity.view',
    ProfileActivity: 'cafm.site.profile.activity.view',
    reactiveSettings: 'cafm.site.reactive.setting.edit',
    postroomSettings: 'cafm.site.postroom.setting.edit',

    reportGlobal: 'cafm.site.report.global.view',
    reportEngineer: 'cafm.site.report.engineer.view',
    reportTimeSpent: 'cafm.site.report.timespent.view',

    Approval: 'cafm.site.approval',
    ApprovalAll: 'cafm.site.approval.all',
    ApprovalOwn: 'cafm.site.approval.own',

    users: {
      View: 'cafm.site.users.view',
      ViewAll: 'cafm.site.users.view.all',
      ViewOwn: 'cafm.site.users.view.own',
      Add: 'cafm.site.users.add',
      AddAll: 'cafm.site.users.add.all',
      AddOwn: 'cafm.site.users.add.own',
      Delete: 'cafm.site.users.delete',
      DeleteAll: 'cafm.site.users.delete.all',
      DeleteOwn: 'cafm.site.users.delete.own',
    },

    addresses: {
      View: 'cafm.site.addresses.view',
      ViewAll: 'cafm.site.addresses.view.all',
      ViewOwn: 'cafm.site.addresses.view.own',
      Create: 'cafm.site.addresses.create',
      CreateAll: 'cafm.site.addresses.create.all',
      CreateOwn: 'cafm.site.addresses.create.own',
      Edit: 'cafm.site.addresses.edit',
      EditAll: 'cafm.site.addresses.edit.all',
      EditOwn: 'cafm.site.addresses.edit.own',
      Delete: 'cafm.site.addresses.delete',
      DeleteAll: 'cafm.site.addresses.delete.all',
      DeleteOwn: 'cafm.site.addresses.delete.own',
    },

    notes: {
      View: 'cafm.site.notes.view',
      ViewAll: 'cafm.site.notes.view.all',
      ViewOwn: 'cafm.site.notes.view.own',
      Create: 'cafm.site.notes.create',
      CreateAll: 'cafm.site.notes.create.all',
      CreateOwn: 'cafm.site.notes.create.own',
      Edit: 'cafm.site.notes.edit',
      EditAll: 'cafm.site.notes.edit.all',
      EditOwn: 'cafm.site.notes.edit.own',
      Delete: 'cafm.site.notes.delete',
      DeleteAll: 'cafm.site.notes.delete.all',
      DeleteOwn: 'cafm.site.notes.delete.own',
    },

    contractors: {
      View: 'cafm.site.contractors.view',
      ViewAll: 'cafm.site.contractors.view.all',
      ViewOwn: 'cafm.site.contractors.view.own',
      Create: 'cafm.site.contractors.create',
      CreateAll: 'cafm.site.contractors.create.all',
      CreateOwn: 'cafm.site.contractors.create.own',
      Edit: 'cafm.site.contractors.edit',
      EditAll: 'cafm.site.contractors.edit.all',
      EditOwn: 'cafm.site.contractors.edit.own',
      Delete: 'cafm.site.contractors.delete',
      DeleteAll: 'cafm.site.contractors.delete.all',
      DeleteOwn: 'cafm.site.contractors.delete.own',
    },

    locations: {
      View: 'cafm.site.locations.view',
      ViewAll: 'cafm.site.locations.view.all',
      ViewOwn: 'cafm.site.locations.view.own',
      Create: 'cafm.site.locations.create',
      CreateAll: 'cafm.site.locations.create.all',
      CreateOwn: 'cafm.site.locations.create.own',
      Edit: 'cafm.site.locations.edit',
      EditAll: 'cafm.site.locations.edit.all',
      EditOwn: 'cafm.site.locations.edit.own',
      Delete: 'cafm.site.locations.delete',
      DeleteAll: 'cafm.site.locations.delete.all',
      DeleteOwn: 'cafm.site.locations.delete.own',
    },

    notifications: {
      View: 'cafm.site.notifications.view',
      ViewAll: 'cafm.site.notifications.view.all',
      ViewOwn: 'cafm.site.notifications.view.own',
      Create: 'cafm.site.notifications.create',
      CreateAll: 'cafm.site.notifications.create.all',
      CreateOwn: 'cafm.site.notifications.create.own',
      Edit: 'cafm.site.notifications.edit',
      EditAll: 'cafm.site.notifications.edit.all',
      EditOwn: 'cafm.site.notifications.edit.own',
      Delete: 'cafm.site.notifications.delete',
      DeleteAll: 'cafm.site.notifications.delete.all',
      DeleteOwn: 'cafm.site.notifications.delete.own',
    },

    schedule: {
      View: 'cafm.site.schedule.view',
      ViewAll: 'cafm.site.schedule.view.all',
      ViewOwn: 'cafm.site.schedule.view.own',
      Create: 'cafm.site.schedule.create',
      CreateAll: 'cafm.site.schedule.create.all',
      CreateOwn: 'cafm.site.schedule.create.own',
      Edit: 'cafm.site.schedule.edit',
      EditAll: 'cafm.site.schedule.edit.all',
      EditOwn: 'cafm.site.schedule.edit.own',
      Delete: 'cafm.site.schedule.delete',
      DeleteAll: 'cafm.site.schedule.delete.all',
      DeleteOwn: 'cafm.site.schedule.delete.own',
    },

    assets: {
      View: 'cafm.assets.view',
      ViewAll: 'cafm.assets.view.all',
      ViewOwn: 'cafm.assets.view.own',
      Create: 'cafm.assets.create',
      CreateAll: 'cafm.assets.create.all',
      CreateOwn: 'cafm.assets.create.own',
      Edit: 'cafm.assets.edit',
      EditAll: 'cafm.assets.edit.all',
      EditOwn: 'cafm.assets.edit.own',
      Delete: 'cafm.assets.delete',
      DeleteAll: 'cafm.assets.delete.all',
      DeleteOwn: 'cafm.assets.delete.own',
    },

    assetSurvey: {
      View: 'cafm.reactive.view',
      ViewAll: 'cafm.assetsurvey.view.all',
      ViewOwn: 'cafm.assetsurvey.view.own',
      Edit: 'cafm.reactive.edit',
      EditAll: 'cafm.assetsurvey.edit.all',
      EditOwn: 'cafm.assetsurvey.edit.own',
      Create: 'cafm.assetsurvey.create',
      CreateAll: 'cafm.assetsurvey.create.all',
      CreateOwn: 'cafm.assetsurvey.create.own',
      Delete: 'cafm.reactive.delete',
      DeleteAll: 'cafm.assetsurvey.delete.all',
      DeleteOwn: 'cafm.assetsurvey.delete.own',
      Approval: 'cafm.assetsurvey.approve',
      ApprovalAll: 'cafm.assetsurvey.approve.all',
      ApprovalOwn: 'cafm.assetsurvey.approve.own',
    },

    reactive: {
      View: 'cafm.reactive.view',
      ViewAll: 'cafm.reactive.view.all',
      ViewOwn: 'cafm.reactive.view.own',
      Edit: 'cafm.reactive.edit',
      EditAll: 'cafm.reactive.edit.all',
      EditOwn: 'cafm.reactive.edit.own',
      Create: 'cafm.reactive.create',
      CreateAll: 'cafm.reactive.create.all',
      CreateOwn: 'cafm.reactive.create.own',
      Delete: 'cafm.reactive.delete',
      DeleteAll: 'cafm.reactive.delete.all',
      DeleteOwn: 'cafm.reactive.delete.own',
    },

    postroom: {
      View: 'cafm.postroom.view',
      ViewAll: 'cafm.postroom.view.all',
      ViewOwn: 'cafm.postroom.view.own',
      Edit: 'cafm.postroom.edit',
      EditAll: 'cafm.postroom.edit.all',
      EditOwn: 'cafm.postroom.edit.own',
      Create: 'cafm.postroom.create',
      CreateAll: 'cafm.postroom.create.all',
      CreateOwn: 'cafm.postroom.create.own',
      Delete: 'cafm.postroom.delete',
      DeleteAll: 'cafm.postroom.delete.all',
      DeleteOwn: 'cafm.postroom.delete.own',
    },

    assetRegister: {
      View: 'cafm.assets.register.view',
      ViewAll: 'cafm.assets.register.view.all',
      ViewOwn: 'cafm.assets.register.view.own',
      Create: 'cafm.assets.register.create',
      CreateAll: 'cafm.assets.register.create.all',
      CreateOwn: 'cafm.assets.register.create.own',
      Edit: 'cafm.assets.register.edit',
      EditAll: 'cafm.assets.register.edit.all',
      EditOwn: 'cafm.assets.register.edit.own',
      Download: 'cafm.assets.register.download',
      DownloadAll: 'cafm.assets.register.download.all',
      DownloadOwn: 'cafm.assets.register.download.own',
      Delete: 'cafm.assets.register.delete',
      DeleteAll: 'cafm.assets.register.delete.all',
      DeleteOwn: 'cafm.assets.register.delete.own',
    },

    calendar: {
      View: 'cafm.calendar.view',
      ViewAll: 'cafm.calendar.view.all',
      ViewOwn: 'cafm.calendar.view.own',
      ViewAssigned: 'cafm.calendar.view.assigned',
      ViewTasks: 'cafm.calendar.tasks.view',
      ViewTasksAll: 'cafm.calendar.tasks.view.all',
      ViewTasksOwn: 'cafm.calendar.tasks.view.own',
      ViewTasksAssigned: 'cafm.calendar.tasks.view.assigned',
      ViewDetail: 'cafm.calendar.detail.view',
      ViewDetailAll: 'cafm.calendar.detail.view.all',
      ViewDetailOwn: 'cafm.calendar.detail.view.own',
      ViewDetailAssigned: 'cafm.calendar.detail.view.assigned',
      ViewEngineer: 'cafm.calendar.engineer.view',
      ViewEngineerAll: 'cafm.calendar.engineer.view.all',
      ViewEngineerOwn: 'cafm.calendar.engineer.view.own',
      ViewEngineerAssigned: 'cafm.calendar.engineer.view.assigned',
      ApproveTasks: 'cafm.calendar.tasks.approve',
      ApproveTasksAll: 'cafm.calendar.tasks.approve.all',
      ApproveTasksOwn: 'cafm.calendar.tasks.approve.own',
      ApproveTasksAssigned: 'cafm.calendar.tasks.approve.assisgned',
      CreateTasks: 'cafm.calendar.create.tasks',
      CreateTasksAll: 'cafm.calendar.create.tasks.all',
      CreateTasksOwn: 'cafm.calendar.create.tasks.own',
      CreateTasksAssigned: 'cafm.calendar.create.tasks.assigned',
      Edit: 'cafm.calendar.edit',
      EditAll: 'cafm.calendar.edit.all',
      EditOwn: 'cafm.calendar.edit.own',
      EditAssigned: 'cafm.calendar.edit.assigned',
      EditEngineer: 'cafm.calendar.engineer.edit',
      EditEngineerAll: 'cafm.calendar.engineer.edit.all',
      EditEngineerOwn: 'cafm.calendar.engineer.edit.own',
      EditEngineerAssigned: 'cafm.calendar.engineer.edit.assigned',
      DeleteTasks: 'cafm.calendar.tasks.delete',
      DeleteTasksAll: 'cafm.calendar.tasks.delete.all',
      DeleteTasksOwn: 'cafm.calendar.tasks.delete.own',
      DeleteTasksAssigned: 'cafm.calendar.tasks.delete.assigned',

      CommentEdit: 'cafm.calendar.task.comment.edit',
      CommentEditAll: 'cafm.calendar.task.comment.edit.all',
      CommentEditOwn: 'cafm.calendar.task.comment.edit.own',
      CommentDelete: 'cafm.calendar.task.comment.delete',
      CommentDeleteAll: 'cafm.calendar.task.comment.delete.all',
      CommentDeleteOwn: 'cafm.calendar.task.comment.delete.own',

      ManagerNotification: 'cafm.calendar.manager.notification',
    },

    helpdesk: {
      View: 'cafm.helpdesk.view',
      ViewAll: 'cafm.helpdesk.view.all',
      ViewOwn: 'cafm.helpdesk.view.own',
      ViewAssigned: 'cafm.helpdesk.view.assigned',
      ViewIssues: 'cafm.helpdesk.issues.view',
      ViewIssuesAll: 'cafm.helpdesk.issues.view.all',
      ViewIssuesOwn: 'cafm.helpdesk.issues.view.own',
      ViewIssuesAssigned: 'cafm.helpdesk.issues.view.assigned',
      ViewIssue: 'cafm.helpdesk.issue.view',
      ViewIssueAll: 'cafm.helpdesk.issue.view.all',
      ViewIssueOwn: 'cafm.helpdesk.issue.view.own',
      ViewIssueAssigned: 'cafm.helpdesk.issue.view.assigned',
      CreateIssue: 'cafm.helpdesk.issues.create',
      CreateIssueAll: 'cafm.helpdesk.issues.create.all',
      CreateIssueOwn: 'cafm.helpdesk.issues.create.own',
      CreateIssueAssigned: 'cafm.helpdesk.issues.create.assigned',
      Comment: 'cafm.helpdesk.comment',
      CommentAll: 'cafm.helpdesk.comment.all',
      CommentOwn: 'cafm.helpdesk.comment.own',
      CommentAssigned: 'cafm.helpdesk.comment.assigned',
      Cancel: 'cafm.helpdesk.cancel',
      CancelAll: 'cafm.helpdesk.cancel.all',
      CancelOwn: 'cafm.helpdesk.cancel.own',
      CancelAssigned: 'cafm.helpdesk.cancel.assigned',

      Delete: 'cafm.helpdesk.delete',
      DeleteAll: 'cafm.helpdesk.delete.all',
      DeleteOwn: 'cafm.helpdesk.delete.own',
    },

    engineer: {
      View: 'cafm.engineer.view',
      ViewAll: 'cafm.engineer.view.all',
      ViewOwn: 'cafm.engineer.view.own',
      ViewIssues: 'cafm.engineer.issues.view',
      ViewIssuesAll: 'cafm.engineer.issues.view.all',
      ViewIssuesOwn: 'cafm.engineer.issues.view.own',
      ViewIssuesAssigned: 'cafm.engineer.issues.view.assigned',
      ViewIssue: 'cafm.engineer.issue.view',
      ViewIssueAll: 'cafm.engineer.issue.view.all',
      ViewIssueOwn: 'cafm.engineer.issue.view.own',
      ViewIssueAssigned: 'cafm.engineer.issue.view.assigned',
      Edit: 'cafm.engineer.issue.edit',
      EditAll: 'cafm.engineer.issue.edit.all',
      EditOwn: 'cafm.engineer.issue.edit.own',
      EditAssigned: 'cafm.engineer.issue.edit.assigned',
      LogTime: 'cafm.engineer.issue.logtime',
      LogTimeAll: 'cafm.engineer.issue.logtime.all',
      LogTimeOwn: 'cafm.engineer.issue.logtime.own',
      LogTimeAssigned: 'cafm.engineer.issue.logtime.assigned',
    },

    documents: {
      View: 'cafm.documents.view',
      ViewAll: 'cafm.documents.view.all',
      ViewOwn: 'cafm.documents.view.own',
      Download: 'cafm.documents.download',
      DownloadAll: 'cafm.documents.download.all',
      DownloadOwn: 'cafm.documents.download.own',
      Create: 'cafm.documents.create',
      CreateAll: 'cafm.documents.create.all',
      CreateOwn: 'cafm.documents.create.own',
      Delete: 'cafm.documents.delete',
      DeleteAll: 'cafm.documents.delete.all',
      DeleteOwn: 'cafm.documents.delete.own',
      CreateFolder: 'cafm.documents.folder.create',
      CreateFolderAll: 'cafm.documents.folder.create.all',
      CreateFolderOwn: 'cafm.documents.folder.create.own',
      DeleteFolder: 'cafm.documents.folder.delete',
      DeleteFolderAll: 'cafm.documents.folder.delete.all',
      DeleteFolderOwn: 'cafm.documents.folder.delete.own',
    },

    induction: {
      View: 'cafm.induction.view',
      ViewAll: 'cafm.induction.view.all',
      ViewOwn: 'cafm.induction.view.own',
      Create: 'cafm.induction.create',
      Edit: 'cafm.induction.edit',
      EditAll: 'cafm.induction.edit.all',
      EditOwn: 'cafm.induction.edit.own',
      Delete: 'cafm.induction.delete',
      DeleteAll: 'cafm.induction.delete.all',
      DeleteOwn: 'cafm.induction.delete.own',
    },

    risk: {
      View: 'cafm.risks.view',
      ViewAll: 'cafm.risks.view.all',
      ViewOwn: 'cafm.risks.view.own',
      Create: 'cafm.risks.create',
      Edit: 'cafm.risks.edit',
      EditAll: 'cafm.risks.edit.all',
      EditOwn: 'cafm.risks.edit.own',
      Delete: 'cafm.risks.delete',
      DeleteAll: 'cafm.risks.delete.all',
      DeleteOwn: 'cafm.risks.delete.own',
    },
  },

  jms: {
    jobs: {
      View: 'jms.jobs.view',
      ViewAll: 'jms.jobs.view.all',
      ViewOwn: 'jms.jobs.view.own',
      Create: 'jms.jobs.create',
      // CreateAll: 'jms.jobs.create.all',
      // CreateOwn: 'jms.jobs.create.own',
      Edit: 'jms.jobs.edit',
      EditAll: 'jms.jobs.edit.all',
      EditOwn: 'jms.jobs.edit.own',
      Delete: 'jms.jobs.delete',
      DeleteAll: 'jms.jobs.delete.all',
      DeleteOwn: 'jms.jobs.delete.own',

      Unlock: 'jms.jobs.unlock',
      UnlockAll: 'jms.jobs.unlock.all',
      UnlockOwn: 'jms.jobs.unlock.own',

      ViewArchived: 'jms.jobs.archived.view',
      EditArchived: 'jms.jobs.archived.edit',
    },
    quotes: {
      View: 'jms.quotes.view',
      ViewAll: 'jms.quotes.view.all',
      ViewOwn: 'jms.quotes.view.own',
      Create: 'jms.quotes.create',
      // CreateAll: 'jms.quotes.create.all',
      // CreateOwn: 'jms.quotes.create.own',
      Edit: 'jms.quotes.edit',
      EditAll: 'jms.quotes.edit.all',
      EditOwn: 'jms.quotes.edit.own',
      Delete: 'jms.quotes.delete',
      DeleteAll: 'jms.quotes.delete.all',
      DeleteOwn: 'jms.quotes.delete.own',
    },
    purchaseOrders: {
      View: 'jms.purchaseorders.view',
      ViewAll: 'jms.purchaseorders.view.all',
      ViewOwn: 'jms.purchaseorders.view.own',
      Create: 'jms.purchaseorders.create',
      // CreateAll: 'jms.purchaseorders.create.all',
      // CreateOwn: 'jms.purchaseorders.create.own',
      Edit: 'jms.purchaseorders.edit',
      EditAll: 'jms.purchaseorders.edit.all',
      EditOwn: 'jms.purchaseorders.edit.own',
      Delete: 'jms.purchaseorders.delete',
      DeleteAll: 'jms.purchaseorders.delete.all',
      DeleteOwn: 'jms.purchaseorders.delete.own',
      POValueExceedApprover: 'jms.purchaseorders.value.exceed.approver',
    },
    companies: {
      View: 'jms.companies.view',
      ViewAll: 'jms.companies.view.all',
      ViewOwn: 'jms.companies.view.own',
      Create: 'jms.companies.create',
      // CreateAll: 'jms.companies.create.all',
      // CreateOwn: 'jms.companies.create.own',
      Edit: 'jms.companies.edit',
      EditAll: 'jms.companies.edit.all',
      EditOwn: 'jms.companies.edit.own',
      Delete: 'jms.companies.delete',
      DeleteAll: 'jms.companies.delete.all',
      DeleteOwn: 'jms.companies.delete.own',
      LinkRequest: 'jms.companies.link.request',
      LinkRespond: 'jms.companies.link.respond',

      contacts: {
        View: 'jms.companies.contacts.view',
        ViewAll: 'jms.companies.contacts.view.all',
        ViewOwn: 'jms.companies.contacts.view.own',
        Create: 'jms.companies.contacts.create',
        // CreateAll: 'jms.companies.contacts.create.all',
        // CreateOwn: 'jms.companies.contacts.create.own',
        Edit: 'jms.companies.contacts.edit',
        EditAll: 'jms.companies.contacts.edit.all',
        EditOwn: 'jms.companies.contacts.edit.own',
        Delete: 'jms.companies.contacts.delete',
        DeleteAll: 'jms.companies.contacts.delete.all',
        DeleteOwn: 'jms.companies.contacts.delete.own',
      },
      addresses: {
        View: 'jms.companies.addresses.view',
        ViewAll: 'jms.companies.addresses.view.all',
        ViewOwn: 'jms.companies.addresses.view.own',
        Create: 'jms.companies.addresses.create',
        // CreateAll: 'jms.companies.addresses.create.all',
        // CreateOwn: 'jms.companies.addresses.create.own',
        Edit: 'jms.companies.addresses.edit',
        EditAll: 'jms.companies.addresses.edit.all',
        EditOwn: 'jms.companies.addresses.edit.own',
        Delete: 'jms.companies.addresses.delete',
        DeleteAll: 'jms.companies.addresses.delete.all',
        DeleteOwn: 'jms.companies.addresses.delete.own',
      },
    },

    jobVisits: {
      View: 'jms.visits.view',
      ViewAll: 'jms.visits.view.all',
      ViewOwn: 'jms.visits.view.own',
      Create: 'jms.visits.create',
      Edit: 'jms.visits.edit',
      EditAll: 'jms.visits.edit.all',
      EditOwn: 'jms.visits.edit.own',
      Delete: 'jms.visits.delete',
      DeleteAll: 'jms.visits.delete.all',
      DeleteOwn: 'jms.visits.delete.own',
    },
    jobVisitsTimeLog: {
      View: 'jms.visits.timelog.view',
      ViewAll: 'jms.visits.timelog.view.all',
      ViewOwn: 'jms.visits.timelog.view.own',
      Create: 'jms.visits.timelog.create',
      Edit: 'jms.visits.timelog.edit',
      EditAll: 'jms.visits.timelog.edit.all',
      EditOwn: 'jms.visits.timelog.edit.own',
      Approve: 'jms.visits.timelog.approve',
    },

    jobSalesInvoice: {
      View: 'jms.jobs.salesInvoice.view',
      Create: 'jms.jobs.salesInvoice.create',
      Edit: 'jms.jobs.salesInvoice.edit',
      Delete: 'jms.jobs.salesInvoice.delete',
      Advanced: 'jms.jobs.salesInvoice.advanced',
    },
    poPurchaseInvoice: {
      View: 'jms.purchaseorders.purchaseInvoice.view',
      Create: 'jms.purchaseorders.purchaseInvoice.create',
      Edit: 'jms.purchaseorders.purchaseInvoice.edit',
      Delete: 'jms.purchaseorders.purchaseInvoice.delete',
      Advanced: 'jms.purchaseorders.purchaseInvoice.advanced',
    },
  },

  alp: {
    alltimeoff: {
      View: 'alp.alltimeoff.view',
      ViewAll: 'alp.alltimeoff.view.all',
      ViewOwn: 'alp.alltimeoff.view.own',
      Create: 'alp.alltimeoff.create',
      CreateAll: 'alp.alltimeoff.create.all',
      CreateOwn: 'alp.alltimeoff.create.own',
      Delete: 'alp.alltimeoff.delete',
      DeleteAll: 'alp.alltimeoff.delete.all',
      DeleteOwn: 'alp.alltimeoff.delete.own',
      Edit: 'alp.alltimeoff.edit',
      EditAll: 'alp.alltimeoff.edit.all',
      EditOwn: 'alp.alltimeoff.edit.own',
      Approve: 'alp.alltimeoff.approve',
      ManualApproval: 'alp.alltimeoff.manual-approval',
      AdminEditTimeOffDetail: 'alp.timeoffdetail.admin.edit',
    },
    settings: {
      View: 'alp.settings.view',
      ViewAll: 'alp.settings.view.all',
      ViewOwn: 'alp.settings.view.own',
      Create: 'alp.settings.create',
      Edit: 'alp.settings.edit',
      EditAll: 'alp.settings.edit.all',
      EditOwn: 'alp.settings.edit.own',
      Delete: 'alp.settings.delete',
      DeleteAll: 'alp.settings.delete.all',
      DeleteOwn: 'alp.settings.delete.own',
      periods: {
        View: 'alp.settings.periods.view',
        ViewAll: 'alp.settings.periods.view.all',
        ViewOwn: 'alp.settings.periods.view.own',
        Create: 'alp.settings.periods.create',
      },
    },
    allowances: {
      View: 'alp.allowances.view',
      ViewAll: 'alp.allowances.view.all',
      ViewOwn: 'alp.allowances.view.own',
      Delete: 'alp.allowances.delete',
      DeleteAll: 'alp.allowances.delete.all',
      DeleteOwn: 'alp.allowances.delete.own',
      adjustments: {
        View: 'alp.allowances.adjustments.view',
        ViewAll: 'alp.allowances.adjustments.view.all',
        ViewOwn: 'alp.allowances.adjustments.view.own',
        Delete: 'alp.allowances.adjustments.delete',
        DeleteAll: 'alp.allowances.adjustments.delete.all',
        DeleteOwn: 'alp.allowances.adjustments.delete.own',
        Create: 'alp.allowances.adjustments.create',
      },
    },

    employees: {
      View: 'alp.employees.view',
      ViewAll: 'alp.employees.view.all',
      ViewOwn: 'alp.employees.view.own',
      Create: 'alp.employees.create',
      Edit: 'alp.employees.edit',
      EditAll: 'alp.employees.edit.all',
      EditOwn: 'alp.employees.edit.own',
      Delete: 'alp.employees.delete',
      DeleteAll: 'alp.employees.delete.all',
      DeleteOwn: 'alp.employees.delete.own',
    },

    teams: {
      View: 'alp.teams.view',
      ViewAll: 'alp.teams.view.all',
      ViewOwn: 'alp.teams.view.own',
      Create: 'alp.teams.create',
      Edit: 'alp.teams.edit',
      EditAll: 'alp.teams.edit.all',
      EditOwn: 'alp.teams.edit.own',
      Delete: 'alp.teams.delete',
      DeleteAll: 'alp.teams.delete.all',
      DeleteOwn: 'alp.teams.delete.own',
    },
  },

  tags: {
    View: 'core.tags.view',
    ViewAll: 'core.tags.view.all',
    ViewOwn: 'core.tags.view.own',
    Create: 'core.tags.create',
    Edit: 'core.tags.edit',
    EditAll: 'core.tags.edit.all',
    EditOwn: 'core.tags.edit.own',
    Delete: 'core.tags.delete',
    DeleteAll: 'core.tags.delete.all',
    DeleteOwn: 'core.tags.delete.own',
  },

  cafm: {
    companies: {
      View: 'cafm.companies.view',
      ViewAll: 'cafm.companies.view.all',
      ViewOwn: 'cafm.companies.view.own',
      Create: 'cafm.companies.create',
      Edit: 'cafm.companies.edit',
      EditAll: 'cafm.companies.edit.all',
      EditOwn: 'cafm.companies.edit.own',
      Delete: 'cafm.companies.delete',
      DeleteAll: 'cafm.companies.delete.all',
      DeleteOwn: 'cafm.companies.delete.own',
    },
  },

  addOns: {
    sysCom: {
      View: 'addons.syscom.view',
      UserType: 'addons.syscom.usertype',
      ManagerUser: 'addons.syscom.usertype.manager',
      MoverUser: 'addons.syscom.usertype.mover',
    },
  },
};
