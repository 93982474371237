import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInput, FormCheckbox, FormDatePicker, SelectDropdown } from '../../form/parts';

const SiteEntryView = ({ t, args }) => (
  <div>
    <Field label={t('Name *')} component={FormInput} name="name" type="text" placeholder={t('Enter name')} />

    <Field
      label={t('Address *')}
      component={FormInput}
      name="addressLine1"
      type="text"
      placeholder={t('Enter address')}
    />

    <Field
      label={t('Address 2')}
      component={FormInput}
      name="addressLine2"
      type="text"
      placeholder={t('Enter address 2')}
    />

    <Field
      label={t('Address 3')}
      component={FormInput}
      name="addressLine3"
      type="text"
      placeholder={t('Enter address 3')}
    />

    <Field label={t('Town *')} component={FormInput} name="town" type="text" placeholder={t('Enter town')} />

    <Field label={t('County *')} component={FormInput} name="county" type="text" placeholder={t('Enter county')} />

    <Field
      label={t('Postcode *')}
      component={FormInput}
      name="postcode"
      type="text"
      placeholder={t('Enter postcode')}
    />

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Start Date')}</label>
      <div className="col-sm-8 full_datepicker">
        <Field name="startDate" component={FormDatePicker} placeholder={t('Choose Start Date')} />
      </div>
    </div>

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('End Date')}</label>
      <div className="col-sm-8 full_datepicker">
        <Field name="endDate" component={FormDatePicker} placeholder={t('Choose End Date')} />
      </div>
    </div>

    {args.isEditMode && (
      <div className="col-sm-12 checkbox_left">
        <Field name="isActive" component={FormCheckbox} label={t('Site is Active')} />
      </div>
    )}

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Type *')}</label>

      <div className="col-sm-8">
        <Field
          name="type"
          component={SelectDropdown}
          drpName="type"
          options={args.siteTypeList}
          _optionLabel="label"
          _optionValue="value"
          placeholder={t('Select Type')}
        />
      </div>
    </div>
  </div>
);

const problemTopicProps = PropTypes.shape({
  siteTypeList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isEditMode: PropTypes.bool,
}).isRequired;

SiteEntryView.propTypes = {
  args: problemTopicProps,
  t: PropTypes.func.isRequired,
};

SiteEntryView.defaultProps = {
  args: {
    siteTypeList: [],
    isEditMode: false,
  },
};

export default SiteEntryView;
