import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

class SelectDropdown extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    drpName: PropTypes.string.isRequired,
    input: PropTypes.shape().isRequired,
    onSelect: PropTypes.func,
    isClearable: PropTypes.bool,
    isSearchable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isMulti: PropTypes.bool,
    width: PropTypes.string,
    defaultValue: PropTypes.shape(),
    _optionLabel: PropTypes.string,
    _optionValue: PropTypes.string,
    _noLabelValue: PropTypes.bool,
    _className: PropTypes.string,
    isOptionFullInfoRequired: PropTypes.bool,
    isTooltipShow: PropTypes.bool,
  };

  static defaultProps = {
    onSelect: () => {},
    width: '100%',
    isClearable: true,
    isSearchable: true,
    isDisabled: false,
    isMulti: false,
    defaultValue: undefined,
    _optionLabel: 'name',
    _optionValue: 'id',
    _noLabelValue: false,
    _className: '',
    isOptionFullInfoRequired: false,
    isTooltipShow: false,
  };

  onChange = (option) => {
    const { onChange } = this.props.input;
    onChange(option);
    setTimeout(() => {
      this.props.onSelect(option);
    }, 0);
  };

  isOptionDisabled = (option) => {
    return option.isDisabled;
  };

  render() {
    const {
      placeholder,
      options,
      drpName,
      isSearchable,
      isClearable,
      isDisabled,
      isMulti,
      width,
      defaultValue,
      _optionLabel,
      _optionValue,
      _noLabelValue,
      _className,
      isOptionFullInfoRequired,
      isTooltipShow,
    } = this.props;

    let {
      input: { value },
    } = this.props;
    if (value && value != '') {
      if (!isMulti) {
        if (!value.hasOwnProperty('label')) {
          value = {
            label: value,
            value,
          };
        }
      }
    } else if (defaultValue && defaultValue != '') {
      // default to show when load (Roles > no access)
      if (!isMulti) {
        if (!defaultValue.hasOwnProperty('label')) {
          const tmpFilter = options.filter((x) => x[_optionValue] == defaultValue);
          value = {
            label:
              tmpFilter &&
              tmpFilter[0] &&
              (isTooltipShow ? (
                <span title={tmpFilter[0].tooltipText || tmpFilter[0][_optionLabel]}>{tmpFilter[0][_optionLabel]}</span>
              ) : (
                tmpFilter[0][_optionLabel]
              )),
            value: tmpFilter && tmpFilter[0] && tmpFilter[0][_optionValue],
            info: isOptionFullInfoRequired ? tmpFilter[0] : null,
          };
        } else {
          value = defaultValue;
        }
      } else {
        value = defaultValue;
      }
    }
    return (
      <div className={_className && _className != '' ? _className : ''} style={{ width }}>
        <Select
          classNamePrefix="drp-react-selct"
          name={drpName}
          isClearable={isClearable}
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          isMulti={isMulti}
          // options={options}
          options={
            (options &&
              options.map((item) => ({
                label: _noLabelValue ? (
                  isTooltipShow ? (
                    <span title={item} className="text">
                      {item}
                    </span>
                  ) : (
                    item
                  )
                ) : isTooltipShow ? (
                  <span title={item.tooltipText || item[_optionLabel]} className="text">
                    {item[_optionLabel]}
                  </span>
                ) : (
                  item[_optionLabel]
                ),
                value: _noLabelValue ? item : item[_optionValue],
                info: isOptionFullInfoRequired ? item : null,
                isDisabled: item?.isDisabled,
              }))) ||
            []
          }
          placeholder={placeholder}
          onChange={this.onChange}
          value={value}
          isOptionDisabled={this.isOptionDisabled}
          styles={{
            option: (styles, { isDisabled }) => {
              return {
                ...styles,
                cursor: isDisabled ? 'not-allowed' : 'default',
              };
            },
          }}
        />
      </div>
    );
  }
}

export default SelectDropdown;
