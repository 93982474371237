import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  SET_ADD_ATTACHMENT_UPLOAD,
  SET_REMOVE_ATTACHMENT_UPLOAD,
  SHOW_LOADER,
  HIDE_LOADER,
  LOAD_MAINTENANCE_SCHEDULE_ATTACHMENTS,
  LOAD_MAINTENANCE_SCHEDULE_ATTACHMENT_COUNT,
  LOAD_MAINTENANCE_SCHEDULE_ATTACHMENT,
  SET_ADD_MULTIPLE_ATTACHMENT_UPLOAD,
  SET_ADD_ATTACHMENT_UPLOAD_CUSTOM,
} from '../types';
import { api } from '../../lib/api';
import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';
import { getAssetByJobId } from './AssetActions';
import { getActivityList } from './ActivityActions';

export const setPictures = (pictures) => {
  return {
    type: SET_ADD_ATTACHMENT_UPLOAD,
    payload: { pictures },
  };
};

export const setCustomPictures = (pictures, isReset = false) => {
  return {
    type: SET_ADD_ATTACHMENT_UPLOAD_CUSTOM,
    payload: { pictures, isReset },
  };
};

export const addPictures = (pictures) => {
  return {
    type: SET_ADD_MULTIPLE_ATTACHMENT_UPLOAD,
    payload: pictures,
  };
};

export const setRemoved = (removed) => {
  return {
    type: SET_REMOVE_ATTACHMENT_UPLOAD,
    payload: { removed },
  };
};

export const loadMaintenanceScheduleAttachments = (scheduleAttachments) => ({
  type: LOAD_MAINTENANCE_SCHEDULE_ATTACHMENTS,
  payload: scheduleAttachments,
});

export const loadMaintenanceScheduleAttachmentCount = (count) => ({
  type: LOAD_MAINTENANCE_SCHEDULE_ATTACHMENT_COUNT,
  payload: count,
});

export const loadMaintenanceScheduleAttachment = (attachment) => ({
  type: LOAD_MAINTENANCE_SCHEDULE_ATTACHMENT,
  payload: attachment,
});

async function updateAttachment(dataRowId, pictures, removed, flag, dispatch) {
  let routePath = '';
  const addedPictures = pictures
    .filter((obj) => obj.type === 'file')
    .map((obj) => ({
      name: obj.data.data.name,
      isDefault: obj.isDefault,
    }));
  const updatedPictures = pictures
    .filter((obj) => obj.type === 'preview')
    .map((obj) => {
      obj.data.isDefault = obj.isDefault;
      return obj.data;
    });
  const removedPictures = removed.map((obj) => obj.data);
  const data = {
    addedPictures,
    updatedPictures,
    removedPictures,
  };

  if (flag === 'sitePicture') {
    routePath = '/v2/building-pictures/update';
    data.siteId = dataRowId;
  } else if (flag === 'assetPicture') {
    routePath = '/v2/asset-pictures/update';
    data.assetId = dataRowId;
  } else if (flag === 'helpdeskCategoryPicture') {
    routePath = '/v2/problemCategory/update-helpdesk-category-image';
    data.id = dataRowId;
  } else if (flag === 'helpdeskTopicPicture') {
    routePath = '/v2/problemTopic/update-helpdesk-topic-image';
    data.id = dataRowId;
  } else if (flag === 'assetSurveyLocationDrawing') {
    routePath = '/v2/asset-survey/update-location-drawing';
    data.id = dataRowId; // it's core.location.id
  }

  return (
    api
      .put(routePath, data)
      // returns the pictures so they are available
      // in siteActions where the update has been called
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response.data));
        handleErrors(error, dispatch, update, dataRowId, pictures, removed);
      })
  );
}

async function uploadAttachments(dataRowId, pictures, flag) {
  const files = pictures.filter((obj) => obj.type === 'file').map((file) => file.data.data);
  if (files.length === 0) {
    return null;
  }

  const tempFiles = pictures.filter((obj) => obj.type === 'file');
  if (tempFiles && tempFiles.length > 0) {
    for (const i in tempFiles) {
      localStorage.setItem('dataId', dataRowId);
      localStorage.setItem('pictureUploadFlag', flag);
      if (tempFiles[i].uppyObject) {
        await tempFiles[i].uppyObject.upload();
      }
      localStorage.setItem('dataId', '');
      localStorage.setItem('pictureUploadFlag', '');
    }
  }
  return;
}

// dataRowId - id column value
// flag - asset, site, helpdeskCategory, helpdeskTopic
export const uploadUpdateAttachment = (dataRowId, flag) => (dispatch, getState) => {
  const { pictures, removed } = getState().attachmentUpload;

  // rename files
  pictures.map((obj) => {
    if (obj.type === 'file') {
      const filename = obj.data.name;
      const ext = filename.split('.').pop();
      const uniqueName = uuidv4(filename);
      const uniqueFilename = `${uniqueName}.${ext}`;

      Object.defineProperty(obj.data.data, 'name', {
        value: uniqueFilename,
        writable: false,
      });
    }
  });

  return Promise.resolve().then(() =>
    uploadAttachments(dataRowId, pictures, flag).then(async () => {
      return await updateAttachment(dataRowId, pictures, removed, flag, dispatch).then((response) => {
        return response;
      });
    }),
  );
};

// args hold db rows object like [{}, {}] and image to update for id. In above case single id has single/multiple image
// where for asset Survey location, each location may have picture or not. So kind of 1st location 1st picture, 2nd location 2nd picture
export const uploadUpdateAttachmentByDynamicRows = (args, flag) => (dispatch, getState) => {
  // // const { pictures, removed } = getState().attachmentUpload;
  // // const tempData = args.data;
  // // if (tempData && tempData.length > 0) {
  // //   for (var i = 0; i < tempData.length; i++) {
  // //     const tempPicture = pictures.filter((obj) => obj.type === 'file' && obj.uppyObject.opts.id === tempData[i].pictureFlag);
  // //     if (tempPicture && tempPicture.length > 0) {
  // //       const filename = tempPicture[0].data.name;
  // //       const ext = filename.split('.').pop();
  // //       const uniqueName = uuidv4(filename);
  // //       const uniqueFilename = `${uniqueName}.${ext}`;

  // //       Object.defineProperty(tempPicture[0].data.data, 'name', {
  // //         value: uniqueFilename,
  // //         writable: false,
  // //       });
  // //       const dataRowId = tempData[i].id;
  // //       // const userAccountActivePromise = await new Promise((resolve, reject) => {
  // //       //   uploadAttachments(dataRowId, tempPicture, flag)
  // //       //     .then(
  // //       //       async () => {
  // //       //         await updateAttachment(dataRowId, tempPicture, removed, flag, dispatch)
  // //       //         .then((response) => { resolve(response); });
  // //       //       })
  // //       // })
  // //       // await uploadAttachments(dataRowId, tempPicture, flag);
  // //       // await updateAttachment(dataRowId, tempPicture, removed, flag, dispatch);
  // //       return Promise.resolve()
  // //         .then(() =>
  // //           uploadAttachments(dataRowId, tempPicture, flag)
  // //             .then(
  // //               async () => {
  // //                 await updateAttachment(dataRowId, tempPicture, removed, flag, dispatch)
  // //                   .then((response)=>{});
  // //               }
  // //             )
  // //         );
  // //     }
  // //   }
  // // }
  // // return { success: true };
  return Promise.resolve().then(() =>
    uploadUpdateAttachmentByDynamicRowsInternal(args, flag, dispatch, getState).then((response) => {
      return response;
    }),
  );
};

async function uploadUpdateAttachmentByDynamicRowsInternal(args, flag, dispatch, getState) {
  const { pictures, removed } = getState().attachmentUpload;
  const tempData = args.data;
  if (tempData && tempData.length > 0) {
    for (var i = 0; i < tempData.length; i++) {
      const tempPicture = pictures.filter(
        (obj) => obj.type === 'file' && obj.uppyObject.opts.id === tempData[i].pictureFlag,
      );
      if (tempPicture && tempPicture.length > 0) {
        const filename = tempPicture[0].data.name;
        const ext = filename.split('.').pop();
        const uniqueName = uuidv4(filename);
        const uniqueFilename = `${uniqueName}.${ext}`;

        Object.defineProperty(tempPicture[0].data.data, 'name', {
          value: uniqueFilename,
          writable: false,
        });
        const dataRowId = tempData[i].id;
        await uploadAttachments(dataRowId, tempPicture, flag);
        await updateAttachment(dataRowId, tempPicture, removed, flag, dispatch);
      }
    }
  }
  return { success: true };
}

export const getMaintenaneScheduleAttachmentList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get('/v2/maintenanceScheduleAttachment/get-list', { params })
    .then((response) => {
      const scheduleAttachments = response.data;
      dispatch(loadMaintenanceScheduleAttachments(scheduleAttachments));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getMaintenaneScheduleAttachmentList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getMaintenanceScheduleAttachmentListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get('/v2/maintenanceScheduleAttachment/get-list-with-size', { params })
    .then((response) => {
      const scheduleAttachments = response.data.rows;
      dispatch(loadMaintenanceScheduleAttachments(scheduleAttachments));
      dispatch(loadMaintenanceScheduleAttachmentCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getMaintenanceScheduleAttachmentListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

// make sure to pass entityType so respective api URL can be called
export const downloadAttachment = (id, entityType) => (dispatch) => {
  let apiUrl = '';
  if (entityType === 'maintenanceschedule') {
    apiUrl = '/v2/maintenanceScheduleAttachment';
  }
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${apiUrl}/download/${id}`)
    .then((response) => {
      const url = response.data;
      if (url && url !== '') {
        window.open(url, '_blank');
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, download, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteAttachment = (id, entityType) => (dispatch) => {
  let apiUrl = '';
  if (entityType === 'maintenanceschedule') {
    apiUrl = '/v2/maintenanceScheduleAttachment';
  }
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${apiUrl}/delete/${id}`)
    .then(() => {
      const item = {
        id,
        friendlyName: null,
      };
      if (entityType === 'maintenanceschedule') {
        dispatch(loadMaintenanceScheduleAttachment(item));
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteAttachment, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteJobAttachment = (id, job) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .delete(`v2/ppmJob/delete-job-attachment/${id}`)
    .then(() => {
      dispatch(getActivityList(job.id, job.jobType));
      dispatch(getAttachmentListByJobId(job.id));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteJobAttachment, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAttachmentListByJobId = (jobid) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`v2/jobVisitTimeEntry/get-timelog-attachments-by-task/${jobid}`)
    .then((response) => {
      const attachments = response.data;
      dispatch(loadMaintenanceScheduleAttachments(attachments));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data || 'error'));
      handleErrors(error, dispatch, getAttachmentListByJobId, jobid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteParcelAttachment = (id, parcelItem) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .delete(`v2/parcel/delete-parcel-attachment/${id}`)
    .then(() => {
      dispatch(getAttachmentListByParcelId(parcelItem.id));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteParcelAttachment, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAttachmentListByParcelId = (parcelId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`v2/parcel/get-parcel-attachments-by-id/${parcelId}`)
    .then((response) => {
      const attachments = response.data;
      dispatch(loadMaintenanceScheduleAttachments(attachments));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getAttachmentListByParcelId, parcelId);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const addMaintenanceScheduleAttachment =
  (params, callback = () => {}) =>
  async (dispatch, getState) => {
    dispatch({ type: SHOW_LOADER });

    const { pictures } = getState().attachmentUpload;
    var uploads = pictures;
    var tempData = {
      maintenanceScheduleId: params.maintenanceScheduleId,
    };
    if (uploads && uploads.length > 0) {
      var multipart = new FormData();
      var addedAttachments = [];
      multipart.append('maintenanceScheduleId', params.maintenanceScheduleId);
      for (var i = 0; i < uploads.length; i++) {
        var fileName = uuidv4();
        +'.' + uploads[i].data.name.split('.').pop();
        multipart.append('file' + i, uploads[i].data.data);
        multipart.append('fileName' + i, fileName);
        addedAttachments.push({ associatedId: params.maintenanceScheduleId, fileName: fileName, friendlyName: uploads[i].data.name });
      }
      api
        .post(`/v2/maintenanceScheduleAttachment/upload-attachments`, multipart, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(async (response) => {
          dispatch(setPictures([]));
          tempData.addedAttachments = addedAttachments;
          await saveMaintenanceScheduleAttachment(params.maintenanceScheduleId, tempData, dispatch);
          callback();
          window.location.reload();
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(showErrorModal(error.response?.data));
          handleErrors(error, dispatch, addMaintenanceScheduleAttachment, tempData);
        })
        .then(() => {
          dispatch({ type: HIDE_LOADER });
        });
    }
  };

function saveMaintenanceScheduleAttachment(id, params, dispatch) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v2/maintenanceScheduleAttachment/${id}/bulk-create`, params)
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response?.data));
        handleErrors(error, dispatch, saveTaskTimelogAttachments, params);
        resolve();
      });
  });
}
