import React from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

const SearchableSelectBox = ({
  placeholder,
  options,
  onSelect,
  input,
  drpName,
  isSearchable,
  isClearable,
  isDisabled,
  isMulti,
  width,
  defaultValue,
  _optionLabel,
  _optionValue,
  _noLabelValue,
  _className,
  isOptionFullInfoRequired,
  isTooltipShow,
  inputSearchString = '',
}) => {
  const handleOnSearch = (string, results) => {
    const { onChange } = input;
    onChange(string);
  };

  const handleOnSelect = (item) => {
    const { onChange } = input;
    onChange(item.name);
  };

  const formatResult = (item) => {
    return (
      <div>
        <span>{item.name}</span>
      </div>
    );
  };

  return (
    <div>
      <ReactSearchAutocomplete
        items={options}
        inputSearchString={inputSearchString}
        onSearch={handleOnSearch}
        onSelect={handleOnSelect}
        autoFocus
        formatResult={formatResult}
        showIcon={false}
        styling={{
          height: '46px',
          border: '1px solid #e5e6e7',
          borderRadius: '8px',
          backgroundColor: 'white',
          boxShadow: 'none',
          hoverBackgroundColor: '#d3d7e1',
          fontSize: '18px',
          padding: '0px',
          zIndex: 2,
          placeholderColor: '#333333',
          clearIconMargin: '3px 0 0 0',
        }}
        fuseOptions={{
          ignoreLocation: true,
          threshold: 0,
        }}
      />
    </div>
  );
};

export default SearchableSelectBox;
