import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import IntableMenu from './IntableMenu';
import userSelectedPreference from '../../lib/userSelectedPreference';

const Image = ({ url, iconOnClick, id }) => (
  <img
    src={url}
    height="47px"
    onClick={() => {
      iconOnClick(id);
    }}
    className="list-image cursor-pointer"
  />
);

const displayRowValue = (
  value,
  label,
  id,
  type,
  iconOnClick,
  getSelectedValue,
  multiDeleteMode,
  multiArchiveMode,
  isCheckBoxOnEachRow,
  userId,
) => {
  if (/\d{4}/gm.test(label) && id !== 'total-row' && value.amount !== undefined && type !== 'checkbox') {
    return numeral(value.amount).format();
  }
  if (isFinite(value) && type === 'number') {
    return numeral(value).format();
  }
  if (value === 0 && type !== 'checkbox') {
    return 0;
  }
  if (type === 'image' && value) {
    return <Image url={value} iconOnClick={iconOnClick} id={id} />;
  }
  if (type === 'checkbox') {
    if (multiDeleteMode || isCheckBoxOnEachRow) {
      let isChecked = false;
      const tempMultiSelData = userSelectedPreference.getSpecificTableMultiSelectionData({
        currentPageUrl: typeof window !== 'undefined' ? window.location.pathname : '',
        userId,
      });
      if (tempMultiSelData && tempMultiSelData.selectedIds && tempMultiSelData.selectedIds.length > 0) {
        isChecked = tempMultiSelData.selectedIds.indexOf(id.toString()) >= 0;
      }
      if (isChecked) {
        return (
          <input
            type="checkbox"
            name="Delete"
            value={id}
            onChange={(e) => getSelectedValue(e.target.checked, e.target.value)}
            checked
          />
        );
      }
      return (
        <input
          type="checkbox"
          name="Delete"
          value={id}
          onChange={(e) => getSelectedValue(e.target.checked, e.target.value)}
        />
      );
    }
    if (multiArchiveMode) {
      if (value === 1) {
        return (
          <input
            type="checkbox"
            name="Archive"
            value={id}
            onChange={(e) => getSelectedValue(e.target.checked, e.target.value)}
          />
        );
      }
      return '';
    }
    return '';
  }
  if (type === 'radio') {
    return (
      <input
        type="radio"
        name="select"
        style={{ transform: 'scale(1.3)' }}
        value={id}
        onChange={(e) => getSelectedValue(e.target.checked, e.target.value)}
      />
    );
  }
  return value || '-';
};

const getRowValue = (row, name) => {
  if (name === 'SitePictures.{isDefault=True}.url') {
    if (row && row.SitePictures) {
      const value = row.SitePictures.find((obj) => obj.isDefault);
      return value ? value.url : null;
    }
    return null;
  }
  // you can now access nested objects by name
  // like so: SitePictures.0.url (url of first picture in array)
  // or so: BuildingBudget.something
  // and it doesn't change anything for simple names
  return name.split('.').reduce((value, step) => value[step], row);
};

const TableBody = ({
  headers,
  rows,
  intableMenu,
  funcs,
  t,
  typeId,
  useUuid,
  navParams,
  showIntable,
  onMonthAmountClick,
  navigateOnClick,
  taskNavParams,
  extended,
  iconOnClick,
  multiDeleteMode,
  multiArchiveMode,
  getSelectedValue,
  isCheckBoxOnEachRow,
  userId,
}) => (
  <div className="dataTables_scrollBody table_custom_body">
    <table className="display dataTable no-footer" role="grid" style={{ tableLayout: 'fixed' }}>
      <tbody>
        {rows.map((row, index) => (
          <tr
            key={row.id || row.uuid}
            className={
              multiDeleteMode || multiArchiveMode
                ? index % 2 === 0
                  ? 'odd Second-Selecter'
                  : 'even Second-Selecter'
                : index % 2 === 0
                ? 'odd First-Selecter'
                : 'even First-Selecter'
            }
            role="row"
          >
            {headers.map((header) =>
              !(multiDeleteMode || multiArchiveMode || isCheckBoxOnEachRow) && header.type == 'checkbox' ? (
                ''
              ) : (
                <td
                  className={header.order !== 'NO' && header.order !== 'DISABLED' ? 'sorting_1' : ''}
                  onDoubleClick={
                    header.type !== 'checkbox'
                      ? /\d{4}/gm.test(header.label) && row.id !== 'total-row'
                        ? () => {
                            onMonthAmountClick(row[header.name]);
                          }
                        : () => {
                            useUuid ? navigateOnClick(row.uuid) : navigateOnClick(row.id);
                          }
                      : () => {}
                  }
                  style={
                    extended
                      ? {
                          minWidth: header.width,
                          tableLayout: 'fixed',
                          backgroundColor: header.type === 'cell-color' ? row[header.name] : '',
                        }
                      : { width: header.width, backgroundColor: header.type === 'cell-color' ? row[header.name] : '' }
                  }
                  key={header.name}
                >
                  {displayRowValue(
                    getRowValue(row, header.name),
                    header.label,
                    row.id,
                    header.type,
                    iconOnClick,
                    getSelectedValue,
                    multiDeleteMode,
                    multiArchiveMode,
                    isCheckBoxOnEachRow,
                    userId,
                  )}
                </td>
              ),
            )}

            {showIntable && (
              <td className="action-column" style={{ width: '4%' }}>
                <IntableMenu
                  firstOrLastRow={index === 0 || index === rows.length - 1}
                  id={useUuid ? row.uuid : row.id}
                  typeId={row.typeId || typeId}
                  intableMenu={row.intableMenu ? row.intableMenu : intableMenu}
                  funcs={row.intableFuncs ? row.intableFuncs : funcs}
                  navParams={navParams}
                  taskParam={taskNavParams[index] || ''}
                  t={t}
                  row={row}
                />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

TableBody.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  intableMenu: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showIntable: PropTypes.bool.isRequired,
  useUuid: PropTypes.bool.isRequired,
  navParams: PropTypes.shape().isRequired,
  t: PropTypes.func.isRequired,
  onMonthAmountClick: PropTypes.func.isRequired,
  navigateOnClick: PropTypes.func.isRequired,
  taskNavParams: PropTypes.arrayOf(PropTypes.string).isRequired,
  funcs: PropTypes.arrayOf(PropTypes.func),
  typeId: PropTypes.string,
  extended: PropTypes.bool,
  iconOnClick: PropTypes.func,
  userId: PropTypes.number,
};

TableBody.defaultProps = {
  funcs: [],
  typeId: null,
  extended: false,
  userId: 0,
};

export default TableBody;
