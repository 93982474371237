import {
  LOAD_ROLES_LIST_DATA,
  LOAD_ROLES_FULL_LIST_DATA,
  LOAD_ROLES_LIST_COUNT,
  LOAD_ROLE_DATA,
  LOAD_ROLE_MEMBERS_LIST_DATA,
  LOAD_ROLE_MEMBERS_FULL_LIST_DATA,
  LOAD_ROLE_MEMBERS_LIST_COUNT,
  LOAD_SITE_ROLE_MEMBERS_LIST_DATA,
  LOAD_SITE_ROLE_MEMBERS_FULL_LIST_DATA,
  LOAD_SITE_ROLE_MEMBERS_LIST_COUNT,
  LOAD_PERMISSIONS_LIST_DATA,
  ADD_ROLE_DATA,
  EDIT_ROLE_DATA,
  REMOVE_ROLE_DATA,
  ADD_ROLE_MEMBER_DATA,
  ADD_SITE_ROLE_MEMBER_DATA,
  REMOVE_ROLE_MEMBER_DATA,
  REMOVE_SITE_ROLE_MEMBER_DATA,
  LOAD_ROLES_LIST_USER_NOT_ADDED,
} from '../../actions/types';

const INITIAL_STATE = {
  roleData: {},
  rolesListData: [],
  rolesFullListData: [],
  rolesCount: 0,
  roleMembersListData: [],
  roleMembersFullListData: [],
  rolesMembersCount: 0,
  siteRoleMembersListData: [],
  siteRoleMembersFullListData: [],
  siteRolesMembersCount: 0,
  permissionsListData: [],
};

const roles = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_ROLES_LIST_DATA:
      return { ...state, rolesListData: action.payload };

    case LOAD_ROLES_FULL_LIST_DATA:
      return { ...state, rolesFullListData: action.payload };

    case LOAD_ROLES_LIST_COUNT:
      return { ...state, rolesCount: action.payload };

    case LOAD_ROLE_DATA:
      return { ...state, roleData: action.payload };

    case LOAD_ROLE_MEMBERS_LIST_DATA:
      return { ...state, roleMembersListData: action.payload };

    case LOAD_ROLE_MEMBERS_FULL_LIST_DATA:
      return { ...state, roleMembersFullListData: action.payload };

    case LOAD_ROLE_MEMBERS_LIST_COUNT:
      return { ...state, rolesMembersCount: action.payload };

    case LOAD_SITE_ROLE_MEMBERS_LIST_DATA:
      return { ...state, siteRoleMembersListData: action.payload };

    case LOAD_SITE_ROLE_MEMBERS_FULL_LIST_DATA:
      return { ...state, siteRoleMembersFullListData: action.payload };

    case LOAD_SITE_ROLE_MEMBERS_LIST_COUNT:
      return { ...state, siteRolesMembersCount: action.payload };

    case ADD_ROLE_MEMBER_DATA:
      const roleMember = action.payload;
      if (roleMember.siteId) {
        return {
          ...state,
          siteRolesMembersCount: state.siteRolesMembersCount + 1,
          siteRoleMembersListData: [action.payload, ...state.siteRoleMembersListData],
        };
      }
      return {
        ...state,
        rolesMembersCount: state.rolesMembersCount + 1,
        roleMembersListData: [action.payload, ...state.roleMembersListData],
      };

    case REMOVE_ROLE_MEMBER_DATA:
      const deletedMember = state.roleMembersListData
        .map((r) => `${r.roleId}_${r.userId}`)
        .indexOf(`${action.payload.roleId}_${action.payload.userId}`);
      return {
        ...state,
        rolesMembersCount: state.rolesMembersCount - 1,
        roleMembersListData: [
          ...state.roleMembersListData.slice(0, deletedMember),
          ...state.roleMembersListData.slice(deletedMember + 1),
        ],
      };

    case REMOVE_SITE_ROLE_MEMBER_DATA:
      const deletedSiteMember = state.siteRoleMembersListData
        .map((r) => `${r.roleId}_${r.userId}_${r.siteId}`)
        .indexOf(`${action.payload.roleId}_${action.payload.userId}_${action.payload.siteId}`);

      return deletedSiteMember === -1
        ? state
        : {
            ...state,
            siteRolesMembersCount: state.siteRolesMembersCount - 1,
            siteRoleMembersListData: [
              ...state.siteRoleMembersListData.slice(0, deletedSiteMember),
              ...state.siteRoleMembersListData.slice(deletedSiteMember + 1),
            ],
          };

    case LOAD_PERMISSIONS_LIST_DATA:
      return { ...state, permissionsListData: action.payload };

    case ADD_ROLE_DATA:
      return { ...state, rolesListData: [action.payload, ...state.rolesListData] };

    case EDIT_ROLE_DATA:
      const editedRole = state.rolesListData.map((role) => role.id).indexOf(action.payload.id);
      return {
        ...state,
        rolesListData: [
          ...state.rolesListData.slice(0, editedRole),
          action.payload,
          ...state.rolesListData.slice(editedRole + 1),
        ],
      };

    case REMOVE_ROLE_DATA:
      const rolesListData = state.rolesListData.filter((r) => r.id !== action.payload);
      const rolesFullListData = state.rolesFullListData.filter((r) => r.id !== action.payload);
      const roleMembersFullListData = state.roleMembersFullListData.filter((r) => r.roleId !== action.payload);
      const siteRoleMembersFullListData = state.siteRoleMembersFullListData.filter((r) => r.roleId !== action.payload);

      return {
        ...state,
        rolesListData,
        rolesFullListData: state.rolesFullListData.filter((r) => r.roleId !== action.payload),
        rolesCount: rolesFullListData.length,
        roleMembersListData: state.roleMembersListData.filter((r) => r.roleId !== action.payload),
        roleMembersFullListData,
        rolesMembersCount: roleMembersFullListData.length,
        siteRoleMembersListData: state.siteRoleMembersListData.filter((r) => r.roleId !== action.payload),
        siteRoleMembersFullListData,
        siteRolesMembersCount: siteRoleMembersFullListData.length,
      };
    case LOAD_ROLES_LIST_USER_NOT_ADDED:
      return { ...state, rolesListUserNotAdded: action.payload };

    default:
      return state;
  }
};

export default roles;
