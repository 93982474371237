import React from 'react';
import PropTypes from 'prop-types';

const renderCircle = (page, number, onPageClick) => (
  <button
    key={number}
    className={`paginate_button ${number === page ? 'current' : ''}`}
    onClick={() => {
      if (number !== page) onPageClick(number);
    }}
  >
    {number + 1}
  </button>
);

const renderPageCircles = (page, maxpages, onPageClick) => {
  if (maxpages <= 7) {
    return <span>{new Array(maxpages).fill(1).map((item, number) => renderCircle(page, number, onPageClick))}</span>;
  }
  if (page < 4) {
    return (
      <span>
        {[0, 1, 2, 3, 4].map((number) => renderCircle(page, number, onPageClick))}
        <span className="ellipsis">...</span>
        <button className="paginate_button" onClick={() => onPageClick(maxpages - 1)}>
          {maxpages}
        </button>
      </span>
    );
  }
  if (maxpages - page < 4) {
    return (
      <span>
        <button className="paginate_button" onClick={() => onPageClick(0)}>
          1
        </button>
        <span className="ellipsis">...</span>
        {[maxpages - 5, maxpages - 4, maxpages - 3, maxpages - 2, maxpages - 1].map((number) =>
          renderCircle(page, number, onPageClick),
        )}
      </span>
    );
  }
  return (
    <span>
      <button className="paginate_button" onClick={() => onPageClick(0)}>
        1
      </button>

      <span className="ellipsis">...</span>

      <button className="paginate_button" onClick={() => onPageClick(page - 1)}>
        {page}
      </button>

      <button className="paginate_button current">{page + 1}</button>

      <button className="paginate_button" onClick={() => onPageClick(page + 1)}>
        {page + 2}
      </button>

      <span className="ellipsis">...</span>

      <button className="paginate_button" onClick={() => onPageClick(maxpages - 1)}>
        {maxpages}
      </button>
    </span>
  );
};

const Pagination = ({ page, count, maxpages, onPageClick, pageSize, t }) => (
  <div className="flo-table-bottom">
    <div className="dataTables_info">
      {`${t('Showing')} ${page * pageSize + 1} ${t('to')}
       ${Math.min(page * pageSize + pageSize, count)} ${t('of')} ${count} ${t('entries')}`}
    </div>

    {pageSize <= count && (
      <div
        tabIndex={0}
        className="dataTables_paginate paging_simple_numbers"
        onMouseDown={() => false}
        onDoubleClick={() => false}
        role="button"
      >
        <button
          className={`paginate_button previous ${page === 0 ? 'disabled' : ''}`}
          disabled={page === 0}
          onClick={() => onPageClick(page - 1)}
        >
          {t('Previous')}
        </button>

        {renderPageCircles(page, maxpages, onPageClick)}

        <button
          className={`paginate_button next ${page + 1 === maxpages ? 'disabled' : ''}`}
          disabled={page + 1 === maxpages}
          onClick={() => onPageClick(page + 1)}
        >
          {t('Next')}
        </button>
      </div>
    )}
  </div>
);

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  count: PropTypes.number,
  maxpages: PropTypes.number.isRequired,
  onPageClick: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  count: 0,
};

export default Pagination;
