import {
  LOAD_INTERNAL_COMPANY,
  LOAD_INTERNAL_COMPANIES,
  LOAD_ALL_INTERNAL_COMPANIES,
  LOAD_ALL_INTERNAL_COMPANIE_CONTACTS,
  LOAD_ALL_INTERNAL_COMPANIE_CONTACTS_BY_ID,
  LOAD_INTERNAL_COMPANY_COUNT,
  LOAD_ALL_INTERNAL_AND_EXTERNAL,
} from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  list: [],
  all: [],
  contacts: [],
  contactsById: [],
  allUsers: [],
};

const internalCompany = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_INTERNAL_COMPANY: {
      const item = action.payload;
      let { list, count, all } = state;
      if (item.name !== null) {
        list = list.map((listItem) => (item.id === listItem.id ? item : listItem));
        all = all.map((listItem) => (item.id === listItem.id ? item : listItem));
      } else {
        list = list.filter((listItem) => item.id !== listItem.id);
        all = all.filter((listItem) => item.id !== listItem.id);
        count -= 1;
      }
      return {
        ...state,
        item,
        list,
        count,
        all,
      };
    }
    case LOAD_INTERNAL_COMPANIES:
      return { ...state, list: action.payload };
    case LOAD_ALL_INTERNAL_AND_EXTERNAL:
      return { ...state, allUsers: action.payload };
    case LOAD_ALL_INTERNAL_COMPANIES:
      return { ...state, all: action.payload };
    case LOAD_ALL_INTERNAL_COMPANIE_CONTACTS:
      return { ...state, contacts: action.payload };
    case LOAD_ALL_INTERNAL_COMPANIE_CONTACTS_BY_ID:
      return { ...state, contactsById: action.payload };
    case LOAD_INTERNAL_COMPANY_COUNT:
      return { ...state, count: action.payload };
    default:
      return state;
  }
};

export default internalCompany;
