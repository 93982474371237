import {
  LOAD_TASK_CATEGORY,
  LOAD_TASK_CATEGORIES,
  LOAD_ALL_TASK_CATEGORIES,
  LOAD_TASK_CATEGORY_COUNT,
  SHOW_LOADER,
  HIDE_LOADER,
} from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/taskCategory';

const adjustCategories = (category) => {
  if (!category.siteId || !category.Site) {
    category.site = null;
  } else {
    category.site = category.Site.name;
  }
};

export const loadTaskCategory = (taskCategory) => ({
  type: LOAD_TASK_CATEGORY,
  payload: taskCategory,
});

export const loadTaskCategories = (taskCategories) => ({
  type: LOAD_TASK_CATEGORIES,
  payload: taskCategories,
});

export const loadAllTaskCategories = (taskCategories) => ({
  type: LOAD_ALL_TASK_CATEGORIES,
  payload: taskCategories,
});

export const loadCount = (count) => ({
  type: LOAD_TASK_CATEGORY_COUNT,
  payload: count,
});

export const createTaskCategory = (taskCategory) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/create`, taskCategory)
    .then((response) => {
      dispatch(loadTaskCategory(response.data));
      Router.pushRoute('cafm/admin/task_category_profile', { task_category_id: response.data.uuid });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createTaskCategory, taskCategory);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const quickCreateTaskCategory = (taskCategory) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/create`, taskCategory)
    .then((response) => {
      dispatch(loadTaskCategory(response.data));
      // Router.pushRoute('cafm/admin/task_category_profile', { taskCategoryId: response.data.uuid });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createTaskCategory, taskCategory);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateTaskCategory = (taskCategory, path) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .put(`${routePath}/update`, taskCategory)
    .then((response) => {
      const newTaskCategory = response.data;
      newTaskCategory.path = path;
      adjustCategories(newTaskCategory);
      dispatch(loadTaskCategory(newTaskCategory));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateTaskCategory, taskCategory);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getTaskCategory = (uuid) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-item/${uuid}`)
    .then((response) => {
      const newTaskCategory = response.data;
      adjustCategories(newTaskCategory);
      dispatch(loadTaskCategory(newTaskCategory));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getTaskCategory, uuid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteTaskCategory = (uuid, path) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${uuid}`)
    .then(() => {
      const item = {
        uuid,
        name: null,
      };
      dispatch(loadTaskCategory(item));
      if (path) {
        Router.pushRoute('cafm/admin/task_categories');
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteTaskCategory, uuid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getTaskCategoryList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      const newCategories = response.data;
      newCategories.forEach(adjustCategories);
      dispatch(loadTaskCategories(newCategories));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getTaskCategoryList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getTaskCategoryFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-full-list`, { params })
    .then((response) => {
      const newCategories = response.data;
      newCategories.forEach(adjustCategories);
      dispatch(loadAllTaskCategories(newCategories));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getTaskCategoryFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getTaskCategoryListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list-with-size-multiple-filter`, { params })
    .then((response) => {
      const newCategories = response.data.rows;
      newCategories.forEach(adjustCategories);
      dispatch(loadTaskCategories(newCategories));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getTaskCategoryListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
