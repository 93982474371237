import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { updateBudgetCategoryGroup } from '../../actions/bms/BudgetCategoryGroupActions';
import { FormInput } from '../form/parts';

class EditCategoryGroupModal extends Component {
  static propTypes = {
    uuid: PropTypes.string.isRequired,
    initialValues: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    updateBudgetCategoryGroup: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    this.props.onModalClose();
  };

  handleUpdateBudgetCategoryGroup = (categoryGroup) => {
    const { uuid } = this.props;
    const newBudgetCategoryGroup = {
      uuid,
      name: categoryGroup.name,
    };
    this.props.updateBudgetCategoryGroup(newBudgetCategoryGroup);
    this.closeModal();
  };

  render() {
    const { handleSubmit, t } = this.props;
    const { display } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown over-y-auto
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show profile-modal edit-category-modal form-horizontal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{t('Update category')}</h2>

          <form>
            <Field label={t('Name')} component={FormInput} name="name" type="text" placeholder={t('Enter name')} />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleUpdateBudgetCategoryGroup)}
            >
              {t('Update')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateBudgetCategoryGroup,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'editCategoryGroupForm',
    enableReinitialize: true,
  })(EditCategoryGroupModal),
);
