import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInput, SelectDropdown } from '../../form/parts';

const ModelEntryView = ({ t, args }) => (
  <div>
    <Field label={t('Name *')} component={FormInput} name="name" type="text" placeholder={t('Name')} />

    <Field
      label={t('Description *')}
      component={FormInput}
      name="description"
      type="text"
      placeholder={t('Description')}
    />

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Manufacturer *')}</label>

      <div className="col-sm-8">
        <Field
          name="manufacturer"
          component={SelectDropdown}
          drpName="manufacturer"
          options={args.manufacturerList}
          _optionLabel="name"
          _optionValue="uuid"
          placeholder={t('Manufacturer')}
        />
      </div>
    </div>
  </div>
);

const modelProps = PropTypes.shape({
  manufacturerList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}).isRequired;

ModelEntryView.propTypes = {
  args: modelProps,
  t: PropTypes.func.isRequired,
};

ModelEntryView.defaultProps = {
  args: {
    manufacturerList: [],
  },
};

export default ModelEntryView;
