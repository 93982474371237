import {
  LOAD_ACCOUNT_FULL_DATA,
  LOAD_ACCOUNT_SUBSCRIPTION_DATA,
  LOAD_ACCOUNT_SUBSCRIPTION_PRODUCT_LIST,
  LOAD_ACCOUNT_BILLINGS,
  LOAD_ACCOUNT_BILLINGS_COUNT,
  LOAD_ROLES_LIST_DATA,
  LOAD_ROLE_MEMBERS_LIST_DATA,
  LOAD_PERMISSIONS_LIST_DATA,
  LOAD_ACCOUNT_ACTIVE_PAYMENTMETHOD_DATA,
  ADD_ROLE_DATA,
  EDIT_ROLE_DATA,
  REMOVE_ROLE_DATA,
  LOAD_ENTITLED_ACCOUNT_APPLICATION_FEATURE_LIST,
} from '../../actions/types';

const INITIAL_STATE = {
  accountFullData: {},
  accountSubscriptionData: {},
  accountSubscriptionProductList: [],
  rolesListData: [],
  roleMembersListData: [],
  permissionsListData: [],
  count: null,
  list: [],
  activePaymentMethodData: {},
  entitledApplicationFeatureList: [],
};

const subscription = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_ACCOUNT_FULL_DATA:
      return { ...state, accountFullData: action.payload };
    case LOAD_ACCOUNT_SUBSCRIPTION_DATA:
      return { ...state, accountSubscriptionData: action.payload };
    case LOAD_ACCOUNT_SUBSCRIPTION_PRODUCT_LIST:
      return { ...state, accountSubscriptionProductList: action.payload };
    case LOAD_ACCOUNT_BILLINGS:
      return { ...state, list: action.payload };
    case LOAD_ACCOUNT_BILLINGS_COUNT:
      return { ...state, count: action.payload };
    case LOAD_ROLES_LIST_DATA:
      return { ...state, rolesListData: action.payload };
    case LOAD_ROLE_MEMBERS_LIST_DATA:
      return { ...state, roleMembersListData: action.payload };
    case LOAD_PERMISSIONS_LIST_DATA:
      return { ...state, permissionsListData: action.payload };
    case LOAD_ACCOUNT_ACTIVE_PAYMENTMETHOD_DATA:
      return { ...state, activePaymentMethodData: action.payload };
    case ADD_ROLE_DATA:
      return { ...state, rolesListData: [action.payload, ...state.rolesListData] };
    case EDIT_ROLE_DATA:
      const editedRole = state.rolesListData.map((role) => role.id).indexOf(action.payload.id);
      return {
        ...state,
        rolesListData: [
          ...state.rolesListData.slice(0, editedRole),
          action.payload,
          ...state.rolesListData.slice(editedRole + 1),
        ],
      };
    case REMOVE_ROLE_DATA:
      const deletedRole = state.rolesListData.map((role) => role.id).indexOf(action.payload);
      return {
        ...state,
        rolesListData: [...state.rolesListData.slice(0, deletedRole), ...state.rolesListData.slice(deletedRole + 1)],
      };
    case LOAD_ENTITLED_ACCOUNT_APPLICATION_FEATURE_LIST:
      return { ...state, entitledApplicationFeatureList: action.payload };
    default:
      return state;
  }
};

export default subscription;
