import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { permissions } from '../../constants/permissions';
import { Router, Link } from '../../../common/routes';
import SideMenuCafmView from './SideMenuCafmView';

const SideMenuView = ({
  pathname,
  currentSystem,
  t,
  accountUUID,
  activeSiteId,
  userPermissions,
  router,
  jobItem,
  activeSite,
  currentuser,
}) => {
  const sitePermissions =
    userPermissions &&
    userPermissions.sitePermissions &&
    userPermissions.sitePermissions
      .filter((sp) => sp.siteId === activeSiteId)
      .map(({ permissions }) => permissions)
      .toString();
  const canEditSite =
    (userPermissions && userPermissions.permissions.includes(permissions.sites.EditAll)) ||
    (sitePermissions &&
      (sitePermissions.includes(permissions.sites.EditAll) || sitePermissions.includes(permissions.site.EditOwn)));
  const hasSiteAdmin =
    (userPermissions && userPermissions.permissions.includes(permissions.site.AdminAll)) ||
    (sitePermissions &&
      (sitePermissions.includes(permissions.site.AdminAll) || sitePermissions.includes(permissions.site.AdminOwn)));
  const canManageAccount = userPermissions && userPermissions.permissions.includes(permissions.accounts.Edit);

  const canAccessTags =
    (userPermissions &&
      (userPermissions.permissions.includes(permissions.tags.ViewAll) ||
        userPermissions.permissions.includes(permissions.tags.ViewOwn))) ||
    (sitePermissions &&
      (sitePermissions.includes(permissions.tags.ViewAll) || sitePermissions.includes(permissions.tags.ViewOwn)));

  const allSitePermissions = userPermissions ? userPermissions.permissions.map((permission) => permission) : [];

  function sideBarMenuToggle(e) {
    const menuDiv = window.document.getElementById('side menu menuDiv');
    Object.values(menuDiv.children).forEach((ele) => {
      ele.classList.remove('active');
      if (ele.children.length > 1) {
        ele.children[1].classList.remove('in');
      }
    });
    e.target.parentNode.classList.toggle('active');
    e.target.parentNode.children[1].classList.toggle('in');
  }


  return (
    <nav className="navbar-default navbar-static-side" id="menu-left">
      <div className="sidebar-collapse">
        {(currentSystem === null || currentSystem === undefined || currentSystem === '') && canManageAccount && (
          <ul className="nav metismenu" id="side menu">
            <li className={pathname === '/account/my_account' ? 'active' : ''}>
              <a href={`/account/${accountUUID}`}>{t('My Account')}</a>
            </li>

            <li className={pathname === `/account/${accountUUID}/user` ? 'active' : ''}>
              <a href={`/account/${accountUUID}/user`}>{t('Users')}</a>
            </li>

            <li
              className={
                pathname === '/account/roles' ||
                pathname === '/account/edit_role' ||
                pathname === '/account/create_role'
                  ? 'active'
                  : ''
              }
            >
              <a href={'/roles'}>{t('Roles')}</a>
            </li>

            <li className={pathname === `/account/${accountUUID}/userGroup` ? 'active' : ''}>
              <a href={`/account/${accountUUID}/userGroup`}>{t('Groups')}</a>
            </li>

            <li className={pathname === `/account/${accountUUID}/setup` ? 'active' : ''}>
              <a href={`/account/${accountUUID}/setup`}>{t('Business Preferences')}</a>
            </li>

            <li
              className={
                ['/account/email_template', '/account/email_template_view', '/account/email_template_edit'].includes(
                  pathname,
                )
                  ? 'active'
                  : ''
              }
            >
              <a href={`/email-templates`}>{t('Email Templates')}</a>
            </li>

            <li className={pathname === `/account/${accountUUID}/customise` ? 'active' : ''}>
              <a href={`/account/${accountUUID}/customise`}>{t('Customise Website')}</a>
            </li>

            {canAccessTags && (
              <li className={pathname === `/account/${accountUUID}/tags` ? 'active' : ''}>
                <a href={`/account/${accountUUID}/tags`}>{t('Tags')}</a>
              </li>
            )}
          </ul>
        )}

        {currentSystem === 'bms' && (
          <ul className="nav metismenu" id="side menu">
            <li className={pathname === '/buildings' ? 'active' : ''}>
              <a
                onClick={() => {
                  Router.pushRoute('buildings');
                }}
              >
                {t('buildings')}
              </a>
            </li>

            <li className={pathname === '/categories' ? 'active' : ''}>
              <a
                onClick={() => {
                  Router.pushRoute('categories');
                }}
              >
                {t('categories')}
              </a>
            </li>

            <li className={pathname === '/category_groups' ? 'active' : ''}>
              <a
                onClick={() => {
                  Router.pushRoute('category_groups');
                }}
              >
                {t('category groups')}
              </a>
            </li>
          </ul>
        )}
        {currentSystem === 'cafm' && (
          <SideMenuCafmView
            pathname={pathname}
            currentSystem={currentSystem}
            t={t}
            accountUUID={accountUUID}
            activeSiteId={activeSiteId}
            userPermissions={userPermissions}
            router={router}
            jobItem={jobItem}
            activeSite={activeSite}
            currentuser={currentuser}
          />
        )}

        {currentSystem === 'cafm-admin' && (canEditSite || hasSiteAdmin) && (
          <ul className="nav metismenu" id="side menu">
            <li className={pathname === '/cafm/' ? 'active' : ''}>
              <a
                onClick={() => {
                  window.location = '/cafm/';
                }}
              >
                {t('<- Site Admin')}
              </a>
            </li>
            {
              <li className={pathname === '/cafm/admin/site_profile' ? 'active' : ''}>
                <a
                  onClick={(e) => {
                    window.location = activeSiteId ? `/cafm/admin/site/${activeSiteId}/profile` : '/cafm/admin/sites';
                  }}
                >
                  {t('site Profile')}
                </a>
              </li>
            }
            {
              <li className={pathname === '/cafm/admin/manufacturers' ? 'active' : ''}>
                <a
                  onClick={(e) => {
                    window.location = '/cafm/admin/manufacturers';
                  }}
                >
                  {t('manufacturers')}
                </a>
              </li>
            }
            {
              <li className={pathname === '/cafm/admin/task_categories' ? 'active' : ''}>
                <a
                  onClick={(e) => {
                    window.location = '/cafm/admin/task-categories';
                  }}
                >
                  {t('task categories')}
                </a>
              </li>
            }
            {
              <li className={pathname === '/cafm/admin/issue_categories' ? 'active' : ''}>
                <a
                  onClick={(e) => {
                    window.location = '/cafm/admin/issue-categories';
                  }}
                >
                  {t('issue categories & topics')}
                </a>
              </li>
            }

            {
              <li className={pathname === '/cafm/admin/settings' ? 'active' : ''}>
                <a
                  onClick={(e) => {
                    window.location = '/cafm/admin/settings';
                  }}
                >
                  {t('Settings')}
                </a>
              </li>
            }
            <li className={pathname === '/cafm/admin/sites' ? 'active' : ''}>
              <a
                onClick={(e) => {
                  window.location = '/cafm/admin/sites';
                }}
              >
                {t('Change Site')}
              </a>
            </li>

            {/* //1453 */}
            {/* <li className={pathname === '/cafm/admin/asset_surveys' ? 'active' : ''}>
              <Link route="/cafm/admin/asset-surveys">
                <a>{t('Asset Survey')}</a>
              </Link>
            </li> */}
          </ul>
        )}
      </div>
    </nav>
  );
};

SideMenuView.propTypes = {
  pathname: PropTypes.string.isRequired,
  currentSystem: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  userPermissions: PropTypes.shape(),
};

export default SideMenuView;
