import {
  LOAD_SUBSCRIPTION_PRICE_CALCULATTION_SUMMARY,
  LOAD_PLAN_SKU_DURATION,
  LOAD_APPLICATION_LIST_FOR_PURCHASE,
} from '../../actions/types';

const INITIAL_STATE = {
  subscriptionPriceCalculationSummary: {},
  planSkuDuration: {},
  applicationListForPurchase: [],
};

const buySubscription = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_SUBSCRIPTION_PRICE_CALCULATTION_SUMMARY:
      return { ...state, subscriptionPriceCalculationSummary: action.payload };
    case LOAD_PLAN_SKU_DURATION:
      return { ...state, planSkuDuration: action.payload };
    case LOAD_APPLICATION_LIST_FOR_PURCHASE:
      return { ...state, applicationListForPurchase: action.payload };
    default:
      return state;
  }
};

export default buySubscription;
