import { LOAD_PARCEL, LOAD_PARCELS, LOAD_ALL_PARCELS, LOAD_PARCEL_COUNT, SET_SLA_TIME } from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  all: [],
  list: [],
  slaTime: {},
};

const parcel = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_PARCEL: {
      return {
        ...state,
        item: action.payload,
      };
    }
    case LOAD_PARCELS:
      return { ...state, list: action.payload };
    case LOAD_ALL_PARCELS:
      return { ...state, all: action.payload };
    case LOAD_PARCEL_COUNT:
      return { ...state, count: action.payload };
    case SET_SLA_TIME:
      return { ...state, slaTime: action.payload };

    default:
      return state;
  }
};

export default parcel;
