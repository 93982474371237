export const MAINTENANCE_SCHEDULE = [
  {
    name: 'name',
    label: 'Name',
    width: '10%',
    order: 'DISABLED',
  },
  {
    name: 'assetName',
    label: 'Asset',
    width: '25%',
    order: 'DISABLED',
  },

  {
    name: 'taskLibraryName',
    label: 'Task',
    width: '25%',
    order: 'DISABLED',
  },

  {
    name: 'supplierCompanyName',
    label: 'Company',
    width: '25%',
    order: 'DISABLED',
  },

  {
    name: 'supplierContactName',
    label: 'Contact',
    width: '25%',
    order: 'DISABLED',
  },
];

export const ASSET = [
  {
    name: 'name',
    label: 'Name',
    width: '30%',
    order: 'DISABLED',
  },

  {
    name: 'description',
    label: 'Description',
    width: '70%',
    order: 'DISABLED',
  },
];

export const TASK_CATEGORY = [
  {
    name: 'name',
    label: 'Name',
    width: '20%',
    order: 'DISABLED',
  },

  {
    name: 'description',
    label: 'Description',
    width: '60%',
    order: 'DISABLED',
  },

  {
    name: 'siteName',
    label: 'Site',
    width: '20%',
    order: 'DISABLED',
  },
];

export const LOCATION = [
  {
    name: 'name',
    label: 'Name',
    width: '40%',
    order: 'DISABLED',
  },

  {
    name: 'type',
    label: 'Type',
    width: '20%',
    order: 'DISABLED',
  },

  {
    name: 'locationName',
    label: 'Parent',
    width: '30%',
    order: 'DISABLED',
  },

  {
    name: 'inactiveLocation',
    label: 'Disabled',
    width: '10%',
    order: 'DISABLED',
  },
];

export const TASK = [
  {
    name: 'name',
    label: 'Name',
    width: '20%',
    order: 'DISABLED',
  },

  {
    name: 'description',
    label: 'Description',
    width: '60%',
    order: 'DISABLED',
  },

  {
    name: 'sfg20Title',
    label: 'Sfg20 Title',
    width: '20%',
    order: 'DISABLED',
  },
];

export const PPM_JOB = [
  {
    name: 'statusName',
    label: 'Status',
    width: '20%',
    order: 'DISABLED',
  },

  {
    name: 'dueDate',
    label: 'Due date',
    width: '20%',
    order: 'DISABLED',
  },

  {
    name: 'visitDate',
    label: 'Visit date',
    width: '20%',
    order: 'DISABLED',
  },

  /* {
    name: 'notes',
    label: 'Notes',
    width: '40%',
    order: 'DISABLED',
  }, */
];

export const MODEL = [
  {
    name: 'name',
    label: 'Name',
    width: '30%',
    order: 'DISABLED',
  },

  {
    name: 'description',
    label: 'Description',
    width: '70%',
    order: 'DISABLED',
  },
];

export const PROBLEM_TOPIC = [
  {
    name: 'name',
    label: 'Name',
    width: '10%',
    order: 'NO',
  },
  {
    name: 'description',
    label: 'Description',
    width: '20%',
    order: 'NO',
  },
];

export const TASK_LINKED_MAINTENANCE = [
  {
    name: 'name',
    label: 'Name',
    width: '12%',
    order: 'DISABLED',
  },
  {
    name: 'repeatEveryPeriod',
    label: 'Repeat Every',
    width: '10%',
    order: 'DISABLED',
  },
  {
    name: 'assetName',
    label: 'Asset',
    width: '12%',
    order: 'DISABLED',
  },
  {
    name: 'locationName',
    label: 'Location',
    width: '12%',
    order: 'DISABLED',
  },
  {
    name: 'supplierCompanyName',
    label: 'Company',
    width: '12%',
    order: 'DISABLED',
  },
  {
    name: 'supplierContactName',
    label: 'Contact',
    width: '10%',
    order: 'DISABLED',
  },
  {
    name: 'active',
    label: 'Active',
    width: '5%',
    order: 'DISABLED',
  },
  {
    name: 'firstTaskDate',
    label: 'First Task',
    width: '8%',
    order: 'DISABLED',
  },
  {
    name: 'lastTaskDate',
    label: 'Last Task',
    width: '8%',
    order: 'DISABLED',
  },
];

export const TASK_COMPLIANCES = [
  {
    name: 'name',
    label: 'Name',
    width: '30%',
    order: 'DISABLED',
  },

  {
    name: 'parentCompliance',
    label: 'Parent',
    width: '30%',
    order: 'DISABLED',
  },
];

export const MAINTENANCE_LINKED_SCHEDULE_TASKS = [
  {
    name: 'taskLibraryName',
    label: 'Task',
    width: '15%',
    order: 'DISABLED',
  },
  {
    name: 'repeat',
    label: 'Repeat Every',
    width: '8%',
    order: 'DISABLED',
  },
  {
    name: 'labour',
    label: 'Labour Required',
    width: '8%',
    order: 'DISABLED',
  },
  {
    name: 'supplierCompanyName',
    label: 'Company',
    width: '12%',
    order: 'DISABLED',
  },
  {
    name: 'supplierContactName',
    label: 'Contact',
    width: '10%',
    order: 'DISABLED',
  },
  {
    name: 'haveSubcontract',
    label: 'Subcontract',
    width: '8%',
    order: 'DISABLED',
  },
  {
    name: 'active',
    label: 'Active',
    width: '5%',
    order: 'DISABLED',
  },
  {
    name: 'lastTaskDate',
    label: 'Last task',
    width: '8%',
    order: 'DISABLED',
  },
];

export const TASK_LIBRARY_LINKED_SCHEDULE_TASKS = [
  {
    name: 'name',
    label: 'Name',
    width: '10%',
    order: 'DISABLED',
  },
  {
    name: 'siteName',
    label: 'Site',
    width: '15%',
    order: 'DISABLED',
  },
  {
    name: 'assetName',
    label: 'Asset',
    width: '15%',
    order: 'DISABLED',
  },
  {
    name: 'locationName',
    label: 'Location',
    width: '15%',
    order: 'DISABLED',
  },
  {
    name: 'taskLibraryName',
    label: 'Task',
    width: '15%',
    order: 'DISABLED',
  },
  {
    name: 'supplierCompanyName',
    label: 'Company',
    width: '12%',
    order: 'DISABLED',
  },
  {
    name: 'supplierContactName',
    label: 'Contact',
    width: '10%',
    order: 'DISABLED',
  },
];
