import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { SelectDropdown } from '../../form/parts';

const AttachAssetToAssetGroupScheduleView = ({ t, args }) => (
  <div>
    <div className="form-group">
      <label className="col-sm-3 control-label">{t('Asset *')}</label>

      <div className="col-sm-9">
        <Field
          name="asset"
          component={SelectDropdown}
          drpName="asset"
          options={args.allAssetList}
          placeholder={t('Select Asset')}
          isMulti
        />
      </div>
    </div>
  </div>
);

const attachAssetToAssetGroupScheduleProps = PropTypes.shape({
  allAssetList: PropTypes.arrayOf(PropTypes.shape()),
}).isRequired;

AttachAssetToAssetGroupScheduleView.propTypes = {
  args: attachAssetToAssetGroupScheduleProps,
  t: PropTypes.func.isRequired,
};

AttachAssetToAssetGroupScheduleView.defaultProps = {
  args: {
    allAssetList: [],
  },
};

export default AttachAssetToAssetGroupScheduleView;
