import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

class CreatableSelectDropdown extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    drpName: PropTypes.string.isRequired,
    input: PropTypes.shape().isRequired,
    onSelect: PropTypes.func,
    isClearable: PropTypes.bool,
    isSearchable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isMulti: PropTypes.bool,
    width: PropTypes.string,
    _optionLabel: PropTypes.string,
    _optionValue: PropTypes.string,
    _noLabelValue: PropTypes.bool,
    _className: PropTypes.string,
  };

  static defaultProps = {
    width: '100%',
    onSelect: () => {},
    isClearable: true,
    isSearchable: true,
    isDisabled: false,
    isMulti: false,
    _optionLabel: 'name',
    _optionValue: 'id',
    _noLabelValue: false,
    _className: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      chosenOptions: props.input.value || [],
    };
  }

  onChange = (clickedOption, actionMeta) => {
    const { onChange } = this.props.input;
    let { chosenOptions } = this.state;
    if (clickedOption) {
      if (!this.props.isMulti) {
        chosenOptions = [];
        chosenOptions.push(clickedOption);
      } else {
        const tmpNewOption = clickedOption.filter((x) => x.__isNew__ == true);
        switch (actionMeta.action) {
          case 'remove-value':
            chosenOptions = chosenOptions.filter((option) => option.value !== actionMeta.removedValue.value);
            break;
          case 'select-option':
            chosenOptions.push(actionMeta.option);
            break;
          case 'create-option':
            chosenOptions.push({
              label: tmpNewOption[0].label,
              value: tmpNewOption[0].value,
            });
            break;
          default:
            // or 'clear'
            chosenOptions = [];
            break;
        }
      }

      this.setState({
        chosenOptions,
      });
    } else {
      chosenOptions = [];
      this.setState({
        chosenOptions,
      });
    }
    onChange(chosenOptions);
    setTimeout(() => {
      this.props.onSelect(chosenOptions);
    }, 0);
  };

  render() {
    const {
      placeholder,
      options,
      drpName,
      input: { value },
      isSearchable,
      isClearable,
      isDisabled,
      isMulti,
      width,
      _optionLabel,
      _optionValue,
      _noLabelValue,
      _className,
    } = this.props;

    return (
      <div className={_className && _className != '' ? _className : ''} style={{ width }}>
        <CreatableSelect
          classNamePrefix="drp-react-selct"
          name={drpName}
          isClearable={isClearable}
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          isMulti={isMulti}
          options={
            (options &&
              options.map((item) => ({
                label: _noLabelValue ? item : item[_optionLabel],
                value: _noLabelValue ? item : item[_optionValue],
              }))) ||
            []
          }
          placeholder={placeholder}
          onChange={this.onChange}
          value={value}
        />
      </div>
    );
  }
}

export default CreatableSelectDropdown;
