import React, { Component } from 'react';

import { HeaderView } from '../../components/decorate';

class Header extends Component {
  render() {
    const { colorSettings, currentSystem } = this.props;
    return <HeaderView colorSettings={colorSettings} currentSystem={currentSystem} />;
  }
}

export default Header;
