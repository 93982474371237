const checkPreferenceExistByUserAndUrl = (args) => {
  let rowIndex = -1;
  const tableId = args.tableId || '';
  if (args.tempPreference && args.tempPreference.length > 0) {
    for (let i = 0; i < args.tempPreference.length; i++) {
      const preference = args.tempPreference[i];
      if (preference.userId === args.userId && preference.currentPageUrl === args.currentPageUrl) {
        if (tableId !== '') {
          if (tableId === preference.tableId) {
            rowIndex = i;
            break;
          }
        } else {
          rowIndex = i;
          break;
        }
      }
    }
  }
  return rowIndex;
};

const getUserSelectedPreference = () => {
  let tempPreference = [];
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('userSelectedPreferences')) {
      tempPreference = JSON.parse(localStorage.getItem('userSelectedPreferences'));
    }
  }
  return tempPreference;
};

// { currentPageUrl, tabIndex, tablePagingPreference}
const setUserSelectedPreference = (args) => {
  const tempPreference = getUserSelectedPreference();
  let isOverwrite = false;
  let isPagingFilterReset = false;
  let rowIndex = -1;
  if (tempPreference && tempPreference.length > 0) {
    rowIndex = checkPreferenceExistByUserAndUrl({ ...args, tempPreference });
    if (rowIndex >= 0) {
      isOverwrite = true;
    }
  }
  if (isOverwrite) {
    tempPreference[rowIndex].currentPageUrl = args.currentPageUrl;
    tempPreference[rowIndex].userId = args.userId;
    tempPreference[rowIndex].tableId = args.tableId || '';
    if (args.tabIndex >= 0) {
      if (
        tempPreference[rowIndex].clickedCurrentComponent &&
        tempPreference[rowIndex].clickedCurrentComponent !== args.tabIndex
      ) {
        isPagingFilterReset = true;
      }
      tempPreference[rowIndex].clickedCurrentComponent = args.tabIndex;
    }
    if (isPagingFilterReset) {
      tempPreference[rowIndex].clickedTableCurrentPagingData = null;
      tempPreference[rowIndex].clickedTableCurrentFilterData = null;
      tempPreference[rowIndex].clickedTableMultiSelectionData = null;
    } else if (args.isFilterResetOnDropFilter) {
      tempPreference[rowIndex].clickedTableCurrentFilterData = null;
    } else {
      if (args.isMultipleSelectionReset) {
        tempPreference[rowIndex].clickedTableMultiSelectionData = null;
      }
      if (args.tablePagingPreference) {
        tempPreference[rowIndex].clickedTableCurrentPagingData = args.tablePagingPreference;
      }
      if (args.tableFilterPreference) {
        tempPreference[rowIndex].clickedTableCurrentFilterData = args.tableFilterPreference;
      }
      if (args.tableMultiSelectPreference) {
        tempPreference[rowIndex].clickedTableMultiSelectionData = args.tableMultiSelectPreference;
      }
    }
  } else {
    const tempParams = {
      currentPageUrl: args.currentPageUrl,
      userId: args.userId,
      tableId: args.tableId || '',
    };
    if (args.tabIndex) tempParams.clickedCurrentComponent = args.tabIndex;
    if (args.tablePagingPreference) tempParams.clickedTableCurrentPagingData = args.tablePagingPreference;
    if (args.tableFilterPreference) tempParams.clickedTableCurrentFilterData = args.tableFilterPreference;
    if (args.tableMultiSelectPreference) tempParams.clickedTableMultiSelectionData = args.tableMultiSelectPreference;
    tempPreference.push(tempParams);
  }
  if (typeof window !== 'undefined') {
    localStorage.setItem('userSelectedPreferences', JSON.stringify(tempPreference));
  }
};

const userSelectedPreference = {
  clearUserSelectedPreference: () => {
    if (typeof window !== 'undefined') {
      localStorage.removeItem('userSelectedPreferences');
    }
  },
  getUserSelectedPreference,
  setUserSelectedPreference,
  isAlreadyExist: (args) => {
    const tempPreference = getUserSelectedPreference();
    if (tempPreference && tempPreference.length > 0) {
      const rowIndex = checkPreferenceExistByUserAndUrl({ ...args, tempPreference });
      if (rowIndex >= 0) {
        return true;
      }
    }
    return false;
  },
  getSpecificTablePagingData: (args) => {
    const tempPreference = getUserSelectedPreference();
    if (tempPreference && tempPreference.length > 0) {
      const rowIndex = checkPreferenceExistByUserAndUrl({ ...args, tempPreference });
      if (rowIndex >= 0) {
        return tempPreference[rowIndex].clickedTableCurrentPagingData;
      }
    }
    return null;
  },
  getSpecificTableFilterData: (args) => {
    const tempPreference = getUserSelectedPreference();
    if (tempPreference && tempPreference.length > 0) {
      const rowIndex = checkPreferenceExistByUserAndUrl({ ...args, tempPreference });
      if (rowIndex >= 0) {
        return tempPreference[rowIndex].clickedTableCurrentFilterData;
      }
    }
    return null;
  },
  getSpecificTableMultiSelectionData: (args) => {
    const tempPreference = getUserSelectedPreference();
    if (tempPreference && tempPreference.length > 0) {
      const rowIndex = checkPreferenceExistByUserAndUrl({ ...args, tempPreference });
      if (rowIndex >= 0) {
        return tempPreference[rowIndex].clickedTableMultiSelectionData;
      }
    }
    return null;
  },
  checkPreferenceExistByUserAndUrl,
};

export default userSelectedPreference;
