import React from 'react';

const InputWithoutLabel = ({ input, name, placeholder, divClassName, inputClassName, meta: { touched, error } }) => (
  <div className={`${divClassName || 'col-sm-8'}`}>
    <input
      {...input}
      placeholder={placeholder}
      className={`${inputClassName || ''} form-control input-lg`}
      type="text"
      name={name}
    />
  </div>
);

export default InputWithoutLabel;
