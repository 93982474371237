import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';

import { updateProblemTopic } from '../../../actions/cafm/ProblemTopicActions';
import { ProblemTopicEntryView } from '../../cafm/admin';

class EditProblemTopicModal extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    initialValues: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    updateProblemTopic: PropTypes.func.isRequired,
    trackerFullList: PropTypes.arrayOf(PropTypes.shape()),
    enumerationFullList: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    trackerFullList: [],
    enumerationFullList: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    this.props.onModalClose();
  };

  handleUpdateProblemTopic = (problemTopic) => {
    const { id, t } = this.props;
    const {
      name,
      enabled,
      description,
      acknowledgeTime,
      acknowledgePeriod,
      resolveTime,
      resolvePeriod,
      imageLarge,
      priorityId,
      trackerId,
      labourType,
      detailOnly,
    } = problemTopic;
    const newProblemTopic = {
      id,
      name,
      description,
      acknowledgeTime,
      resolveTime,
    };

    if (enabled) {
      newProblemTopic.isEnabled = enabled.value === this.props.t('enabled');
    }
    newProblemTopic.labourType = (labourType && labourType.value) || '';
    newProblemTopic.resolvePeriod = (resolvePeriod && resolvePeriod.value) || '';
    newProblemTopic.acknowledgePeriod = (acknowledgePeriod && acknowledgePeriod.value) || '';
    newProblemTopic.trackerId = (trackerId && trackerId.value) || '';
    newProblemTopic.priorityId = (priorityId && priorityId.value) || '';

    if (detailOnly) {
      newProblemTopic.detailOnly = 0;
    } else {
      newProblemTopic.detailOnly = 1;
    }

    if (imageLarge && imageLarge.length && _.isArray(imageLarge)) {
      newProblemTopic.imageLarge = imageLarge[0];
    } else if (_.isNull(imageLarge)) {
      newProblemTopic.imageSmall = null;
      newProblemTopic.imageLarge = null;
    }
    this.props.updateProblemTopic(newProblemTopic, t);
    this.closeModal();
  };

  render() {
    const { handleSubmit, t, trackerFullList, enumerationFullList, initialValues } = this.props;
    const { display } = this.state;

    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show modal-template form-horizontal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{t('Update problem topic')}</h2>

          <form>
            <ProblemTopicEntryView
              t={t}
              args={{
                enumerationList: enumerationFullList,
                trackerList: trackerFullList,
                imageLarge: initialValues.imageLarge,
                imageSmall: initialValues.imageSmall,
              }}
            />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleUpdateProblemTopic)}
            >
              {t('Update')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProblemTopic,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'editProblemTopicForm',
    enableReinitialize: true,
  })(EditProblemTopicModal),
);
