import _ from 'lodash';
import {
  LOAD_SCHEDULE,
  LOAD_SCHEDULES,
  LOAD_ALL_SCHEDULES,
  LOAD_SCHEDULE_COUNT,
  SHOW_LOADER,
  HIDE_LOADER,
  LOAD_BUILDING,
  LOAD_SCHEDULE_WITH_TASK,
  LOAD_SCHEDULETASK_COUNT_BY_MAINTENANCESCHEDULE,
  LOAD_SCHEDULE_DELETE_POPUP_ON,
  LOAD_SCHEDULE_DELETE_POPUP_OFF,
  REMOVE_SCHEDULE,
} from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';
import commonFunction from '../../lib/commonFunction';
import moment from 'moment';

const routePath = '/v2/maintenanceSchedule';

const adjustSchedules = (schedule) => {
  if (schedule.Asset && Object.prototype.hasOwnProperty.call(schedule.Asset, 'id') && schedule.Asset.id) {
    schedule.Asset = [schedule.Asset];
  }
  if (schedule.Asset && schedule.Asset.length > 0) {
    schedule.assetName = commonFunction.getAssetName({ Asset: schedule.Asset });
  } else {
    schedule.assetName = null;
  }

  if (!schedule.taskLibraryId || !schedule.TaskLibrary) {
    schedule.taskLibraryName = null;
  } else {
    schedule.taskLibraryName = schedule.TaskLibrary.name;
  }
  if (
    schedule.SupplierCompany &&
    Object.prototype.hasOwnProperty.call(schedule.SupplierCompany, 'id') &&
    schedule.SupplierCompany.id
  ) {
    schedule.SupplierCompany = [schedule.SupplierCompany];
  }
  if (schedule.SupplierCompany && schedule.SupplierCompany.length > 0) {
    const tempCompanyAssignee = commonFunction.getInternalExternamAssignee({
      SupplierCompany: schedule.SupplierCompany,
    });
    schedule.supplierCompanyName =
      tempCompanyAssignee && tempCompanyAssignee.length > 0 && tempCompanyAssignee.toString();
  } else {
    schedule.supplierCompanyName = null;
  }
  if (
    schedule.userAssignee &&
    Object.prototype.hasOwnProperty.call(schedule.userAssignee, 'id') &&
    schedule.userAssignee.id
  ) {
    schedule.userAssignee = [schedule.userAssignee];
  } else if (
    schedule.SupplierContact &&
    Object.prototype.hasOwnProperty.call(schedule.SupplierContact, 'id') &&
    schedule.SupplierContact.id
  ) {
    schedule.SupplierContact = [schedule.SupplierContact];
  }
  if (schedule.userAssignee && schedule.userAssignee.length > 0) {
    const tempUserAssignee = commonFunction.getInternalExternamAssignee({ UserAssignee: schedule.userAssignee });
    schedule.supplierContactName = tempUserAssignee && tempUserAssignee.length > 0 && tempUserAssignee.toString();
  } else if (schedule.SupplierContact && schedule.SupplierContact.length > 0) {
    const tempContactAssignee = commonFunction.getInternalExternamAssignee({
      SupplierContact: schedule.SupplierContact,
    });
    schedule.supplierContactName =
      tempContactAssignee && tempContactAssignee.length > 0 && tempContactAssignee.toString();
  } else {
    schedule.supplierContactName = null;
  }

  if (schedule.Asset && schedule.Asset.length > 0 && schedule.Asset[0].Location) {
    schedule.locationName = schedule.Asset[0].Location.name;
  } else if (schedule.location) {
    schedule.locationName = schedule.location.name;
  } else {
    schedule.locationName = null;
  }

  schedule.haveSubcontract = schedule.subcontract ? 'yes' : 'no';
  schedule.sendNotifications = schedule.notifySubcontractor ? 'yes' : 'no';
  schedule.active = schedule.MaintenanceSchedule.enabled ? 'yes' : 'no';
  schedule.overrideRepeatFreq = schedule.overrideRepeatFrequency ? 'yes' : 'no';
  schedule.customRepeatList =
    schedule.customRepeatList != null && schedule.customRepeatList != '-' ? JSON.parse(schedule.customRepeatList) : [];

  if (schedule.overrideRepeatFrequency) {
    if (schedule.repeatPeriod === 'Custom') {
      const days = schedule.customRepeatList.map((e) => moment().days(e.id).format('dddd'));
      schedule.repeat = `Every ${days.join(' & ')}`;
    } else {
      schedule.repeat = `${schedule.repeatEvery} ${schedule.repeatPeriod}`;
    }
  } else if (schedule.TaskLibrary) {
    schedule.repeat = `${schedule.TaskLibrary.repeatEvery} ${schedule.TaskLibrary.repeatPeriod}`;
  } else {
    schedule.repeat = null;
  }
  schedule.overrideLabourFreq = schedule.overrideLabourFrequency ? 'yes' : 'no';
  if (schedule.overrideLabourFrequency && schedule.labourRequired) {
    schedule.labour = `${schedule.labourRequired} ${schedule.labourPeriod}`;
  } else if (schedule.TaskLibrary && schedule.TaskLibrary.labourRequired) {
    schedule.labour = `${schedule.TaskLibrary.labourRequired} ${schedule.TaskLibrary.labourPeriod}`;
  } else {
    schedule.labour = null;
  }
  schedule.firstTaskDate = commonFunction.displayDateWithValidFormat(schedule.firstTaskDate);
  schedule.lastTaskDate = commonFunction.displayDateWithValidFormat(schedule.lastTaskDate);
  schedule.nextTaskDate = commonFunction.displayDateWithValidFormat(schedule.nextTaskDate);
  if (schedule.Site) {
    schedule.Site.startDate = commonFunction.displayDateWithValidFormat(schedule.Site.startDate);
    schedule.Site.endDate = commonFunction.displayDateWithValidFormat(schedule.Site.endDate);
  }
  schedule.name = schedule.MaintenanceSchedule ? schedule.MaintenanceSchedule.name : schedule.name;
};

export const loadBuilding = (building) => ({
  type: LOAD_BUILDING,
  payload: building,
});

export const loadSchedule = (schedule) => ({
  type: LOAD_SCHEDULE,
  payload: schedule,
});

export const loadSchedules = (schedules) => ({
  type: LOAD_SCHEDULES,
  payload: schedules,
});

export const loadAllSchedules = (schedules) => ({
  type: LOAD_ALL_SCHEDULES,
  payload: schedules,
});

export const loadCount = (count) => ({
  type: LOAD_SCHEDULE_COUNT,
  payload: count,
});

export const loadScheduleWithTask = (item) => ({
  type: LOAD_SCHEDULE_WITH_TASK,
  payload: item,
});

export const removeSchedule = (item) => ({
  type: REMOVE_SCHEDULE,
  payload: item,
});

export const loadDeletePopup = () => ({
  type: LOAD_SCHEDULE_DELETE_POPUP_ON,
});

export const closeDeletePopup = () => ({
  type: LOAD_SCHEDULE_DELETE_POPUP_OFF,
});

export const loadScheduleTaskCountByMaintenanceSchedule = (count) => ({
  type: LOAD_SCHEDULETASK_COUNT_BY_MAINTENANCESCHEDULE,
  payload: count,
});

export const createSchedule = (schedule) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/create`, schedule)
    .then((response) => {
      dispatch(loadSchedule(response.data));
      Router.pushRoute('cafm/admin/schedule_profile', { schedule_id: response.data.id });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createSchedule, schedule);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateSchedule =
  (schedule, isReload = false) =>
  (dispatch) => {
    dispatch({ type: SHOW_LOADER });

    api
      .put(`${routePath}/update`, schedule)
      .then((response) => {
        if (isReload) {
          window.location.href = window.location.href;
        }
        const newSchedule = response.data;
        newSchedule.forEach(adjustSchedules);
        dispatch(loadScheduleWithTask(newSchedule));
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response.data));
        handleErrors(error, dispatch, updateSchedule, schedule);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const disableSchedule = (schedule) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .put(`${routePath}/update`, {
      id: schedule.id,
      enabled: !schedule.enabled,
    })
    .then((response) => {
      const newSchedule = response.data;
      newSchedule.forEach(adjustSchedules);
      dispatch(loadScheduleWithTask(newSchedule));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateSchedule, schedule);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getSchedule = (id) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-item/${id}`)
    .then((response) => {
      const newSchedule = response.data;
      adjustSchedules(newSchedule[0]);
      dispatch(loadSchedule(newSchedule[0]));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getSchedule, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteSchedule = (id, siteId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${id}`)
    .then((res) => {
      if (res.data.result === 'success') {
        const item = {
          id, // core.maintenanceSchedule.id
          name: null,
          flag: 'delete',
        };
        dispatch(loadScheduleWithTask([item]));
      } else {
        dispatch(loadDeletePopup());
      }
      if (siteId) {
        Router.pushRoute('cafm/admin/site_profile', { sid: siteId });
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteSchedule, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const closePopup = () => (dispatch) => {
  dispatch(closeDeletePopup());
};

export const deleteSchedulewithJobs = (id, scheduleId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/deletewithJobs/${id}/${scheduleId}`)
    .then((res) => {
      if (res.data.result === 'success') {
        dispatch(removeSchedule(scheduleId));
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteSchedule, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const scheduleArchived = (id, siteId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .put(`${routePath}/scheduleArchived`, {
      id,
      archived: 1,
    })
    .then(() => {
      const item = {
        id,
        name: null,
      };
      dispatch(loadScheduleWithTask([item]));
      // // dispatch(loadSchedule(item));
      if (siteId) {
        Router.pushRoute('cafm/admin/site_profile', { sid: siteId });
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteSchedule, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getScheduleList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      const newSchedules = response.data;
      newSchedules.forEach(adjustSchedules);
      dispatch(loadSchedules(newSchedules));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getScheduleList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getScheduleFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-full-list`, { params })
    .then((response) => {
      dispatch(loadAllSchedules(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getScheduleFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getScheduleListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list-with-size-multiple-filter`, { params })
    .then((response) => {
      const newSchedules = response.data.rows;
      newSchedules.forEach(adjustSchedules);
      dispatch(loadSchedules(newSchedules));
      dispatch(loadCount(response.data.count || newSchedules.length));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getScheduleListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const generateJob = (schedule) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .post(`${routePath}/generateJob`, schedule)
    .then((response) => {
      if (schedule.isSite == false) {
        window.location.href = window.location.href;
      } else {
        const newSchedule = response.data;
        _.each(newSchedule, (value, key) => {
          adjustSchedules(value);
          dispatch(loadSchedule(value));
        });

        if (schedule.isSite && schedule.isSave) {
          schedule.startDate = commonFunction.displayDateWithValidFormat(schedule.startDate);
          schedule.endDate = commonFunction.displayDateWithValidFormat(schedule.endDate);
          dispatch(loadBuilding(schedule));
        }
      }

      // dispatch(loadSchedule(response.data));
      // Router.pushRoute('cafm/admin/schedule_profile', { schedule_id: response.data.id });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, generateJob, schedule);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getScheduleTaskCountByScheduleId = (maintenanceScheduleId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  return api
    .get(`${routePath}/get-scheduletask-count-by-schedule/${maintenanceScheduleId}`)
    .then((response) => {
      dispatch(loadScheduleTaskCountByMaintenanceSchedule(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getScheduleTaskCountByScheduleId, maintenanceScheduleId);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
