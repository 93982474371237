import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { updateTaskBudget } from '../../actions/bms/BuildingBudgetActions';
import { FormInput } from '../form/parts';
import { ProfileDataView } from '../decorate';
import { Table } from '../table';

const COLUMN_WIDTH = '150px';

class EditMonthBudgetModal extends Component {
  static propTypes = {
    uuid: PropTypes.string.isRequired,
    initialValues: PropTypes.shape().isRequired,
    supplier: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    updateTaskBudget: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const headers = this.renderHeaders();
    const rows = this.renderRows();
    this.state = {
      display: false,
      headers,
      rows,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    this.props.onModalClose();
  };

  handleUpdateTaskBudget = (taskBudget) => {
    const { uuid } = this.props;
    const newBudget = {
      uuid,
      amount: taskBudget.amount,
      description: taskBudget.description,
    };
    this.props.updateTaskBudget(newBudget);
    this.closeModal();
  };

  renderHeaders = () => {
    const headers = [
      {
        name: 'name',
        label: 'Supplier name',
        width: COLUMN_WIDTH,
        order: 'DISABLED',
      },
      {
        name: 'value',
        label: 'Total value',
        type: 'number',
        width: COLUMN_WIDTH,
        order: 'DISABLED',
      },
      {
        name: 'poNumber',
        label: 'PO Number',
        width: COLUMN_WIDTH,
        order: 'DISABLED',
      },
    ];
    return headers;
  };

  renderRows = () => {
    const { supplier } = this.props;
    const quotes = supplier.SupplierQuoteForBMS;
    const rows = [];
    let error = false;
    let totalValue = 0;
    if (quotes.length > 0) {
      quotes.forEach((quote) => {
        if (quote.SupplierQuote) {
          const { name } = quote.SupplierQuote.SupplierInternalCompany || {};
          const value = +quote.SupplierQuote.netValue;
          totalValue += +value;
          const { id } = quote.SupplierQuote || {};
          const poNumber = '-';
          const row = {
            id,
            name,
            value,
            poNumber,
          };
          rows.push(row);
        } else if (quote.PurchaseOrder) {
          let name = '-';
          if (quote.PurchaseOrder.SupplierQuote && quote.PurchaseOrder.SupplierQuote.SupplierInternalCompany) {
            name = quote.PurchaseOrder.SupplierQuote.SupplierInternalCompany.name;
          }
          const value = +quote.PurchaseOrder.value;
          totalValue += +value;
          const { id } = quote.PurchaseOrder || {};
          const poNumber = quote.PurchaseOrder.number;
          const row = {
            id,
            name,
            value,
            poNumber,
          };
          rows.push(row);
        } else {
          error = true;
        }
      });
      if (!error) {
        const totalRow = {
          id: 'total-row',
          name: 'total',
          value: totalValue.toFixed(2),
          poNumber: '-',
        };
        rows.push(totalRow);
      }
    }
    return rows;
  };

  render() {
    const { initialValues, handleSubmit, t } = this.props;
    const { display, headers, rows } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown over-y-auto
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show edit-task-modal form-horizontal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{t('Update task budget')}</h2>

          {rows.length > 0 ? (
            <Table headers={headers} rows={rows} showIntable={false} t={t} paginate={false} />
          ) : (
            <div className="no-orders">{t('No orders to be displayed')}</div>
          )}

          <div className="update-task-budget-gap" />

          <ProfileDataView label={t('Category')} value={initialValues.category} isInForm />

          <ProfileDataView label={t('Date')} value={initialValues.date} isInForm />

          <form>
            <Field
              label={t('Description')}
              component={FormInput}
              name="description"
              type="text"
              placeholder={t('Description (optional)')}
            />

            <Field
              label={t('Amount')}
              component={FormInput}
              name="amount"
              type="text"
              placeholder={t('Enter amount')}
            />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleUpdateTaskBudget)}
            >
              {t('Update')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateTaskBudget,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'editMonthBudgetForm',
    enableReinitialize: true,
  })(EditMonthBudgetModal),
);
