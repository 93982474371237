const axios = require('axios');

const { apiURL } = require('../../common/config');
import { reduxStore } from './initRedux';

export const api = axios.create({
  baseURL: apiURL,
});

const source = axios.CancelToken.source();

api.interceptors.request.use(
  (config) => {
    let token = reduxStore.getState()?.user.currentuser.accessToken;
    config.headers.Authorization = `Bearer ${token}`;
    if (!config.headers.Authorization) {
      source.cancel('no tokens');
      const newconfig = {};
      newconfig.cancelToken = source.token;
      return newconfig;
    }

    return config;
  },
  (error) => Promise.reject(error),
);
