import { LOAD_CONDITION, LOAD_CONDITIONS } from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  list: [],
  all: [],
};

const condition = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_CONDITION: {
      const item = action.payload;
      let { list, all } = state;
      if (item.name !== null) {
        if (all.find((element) => element.id === item.id) === undefined) {
          all.push(item);
        }
        if (list.find((element) => element.id === item.id) === undefined) {
          list.push(item);
        }

        list = list.map((listItem) => (item.uuid === listItem.uuid ? item : listItem));
        all = all.map((listItem) => (item.uuid === listItem.uuid ? item : listItem));
      } else {
        list = list.filter((listItem) => item.uuid !== listItem.uuid);
        all = all.filter((listItem) => item.uuid !== listItem.uuid);
        count -= 1;
      }
      return {
        item,
        list,
        all,
      };
    }
    case LOAD_CONDITIONS:
      return { ...state, list: action.payload };
    default:
      return state;
  }
};

export default condition;
