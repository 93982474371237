import { LOAD_ASSET_NOTE, LOAD_ALL_ASSET_NOTES, SHOW_LOADER, HIDE_LOADER } from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/asset-note';

export const loadAssetNote = (assetNote) => ({
  type: LOAD_ASSET_NOTE,
  payload: assetNote,
});

export const loadAllAssetNotes = (assetNotes) => ({
  type: LOAD_ALL_ASSET_NOTES,
  payload: assetNotes,
});

export const createAssetNote = (note) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/create`, note)
    .then((response) => {
      dispatch(loadAssetNote(response.data[0]));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createAssetNote, note);
    })
    .then(() => dispatch({ type: HIDE_LOADER }));
};

export const deleteAssetNote = (id, assetId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${id}`)
    .then(() => {
      const item = {
        id,
        notes: null,
      };
      dispatch(loadAssetNote(item));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteAssetNote, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAssetNotesByAsset = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-asset-note-by-assetid`, { params })
    .then((response) => {
      const newAssetNotes = response.data;
      dispatch(loadAllAssetNotes(newAssetNotes));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getAssetNotesByAsset, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
