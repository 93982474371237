import { LOAD_LOGS, LOAD_EMAIL_DELIVERY } from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  list: [],
  all: [],
  deliveryList: [],
};

const auditLogs = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_LOGS:
      return { ...state, list: action.payload };
    case LOAD_EMAIL_DELIVERY:
      return { ...state, deliveryList: action.payload };
    default:
      return state;
  }
};

export default auditLogs;
