import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { SelectDropdown } from '../form/parts';

class AddUserToListModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleAddUser: PropTypes.func.isRequired,
    onModalClose: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      display: true,
      chosenName: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
  }

  onSelectChange = (option) => {
    this.setState({
      chosenName: option,
    });
  };

  onUserAdd = () => {
    const { chosenName } = this.state;
    // const { users } = this.props;
    const chosenUser = chosenName && chosenName.value; // users.find(user => user.fullName === chosenName);
    this.closeModal();
    this.props.handleAddUser(chosenUser.id);
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
    this.props.onModalClose();
  };

  render() {
    const { users, t } = this.props;
    const { display, chosenName } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown over-y-auto
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show profile-modal add-user-modal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{t('Add user to send list')}</h2>

          <div className="form-group">
            <label className="col-sm-4 control-label">{t('Choose full name')}</label>

            <div className="col-sm-8">
              <Field
                name="user"
                component={SelectDropdown}
                drpName="user"
                options={users}
                _optionLabel="fullName"
                placeholder={t('Choose full name')}
                onChange={this.onSelectChange}
              />
            </div>
          </div>

          <div className="select-gap" />

          <div className="swal2-buttonswrapper display-block">
            <button type="submit" className="swal2-confirm swal2-styled btn-primary" onClick={this.onUserAdd}>
              {t('Add User')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AddUserToListModal;
