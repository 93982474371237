import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FilterButton = ({ width, onSubmit, disabled, t }) => (
  <button
    className={classNames('filter-button', { 'button-disabled': disabled })}
    type="submit"
    onClick={onSubmit}
    disabled={disabled}
    style={{ width }}
  >
    {t('Filter')}
  </button>
);

FilterButton.propTypes = {
  width: PropTypes.string,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
FilterButton.defaultProps = {
  width: '100%',
  disabled: false,
};

export default FilterButton;
