import { LOAD_ASSET_NOTE, LOAD_ALL_ASSET_NOTES } from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  list: [],
  all: [],
  allNotes: [],
};

const assetNotes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_ASSET_NOTE: {
      const item = action.payload;
      let { allNotes } = state;
      if (item && item.notes !== null) {
        const tempInfo = [];
        tempInfo.push(item);
        allNotes.map((data) => {
          tempInfo.push(data);
        });
        allNotes = tempInfo;
      } else {
        allNotes = allNotes.filter((listItem) => item.id !== listItem.id);
      }
      return {
        ...state,
        item,
        allNotes,
      };
    }
    case LOAD_ALL_ASSET_NOTES:
      return { ...state, allNotes: action.payload };
    default:
      return state;
  }
};

export default assetNotes;
