import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { getLocationFullList } from '../../../actions/cafm/LocationActions';
import { moveAssetGroup, getAssetGroupFullList } from '../../../actions/cafm/AssetGroupActions';
import { moveAsset } from '../../../actions/cafm/AssetActions';
import { showBodyScroll, hideBodyScroll } from '../../../lib/bodyScrollToggle';
import { MoveAssetGroupLocationView } from '../../cafm/admin';

class MoveAssetGroupLocationModal extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    siteId: PropTypes.number.isRequired,
    flag: PropTypes.string.isRequired,
    initialValues: PropTypes.shape().isRequired,
    createForm: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    allLocationList: PropTypes.arrayOf(PropTypes.shape()),
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    getLocationFullList: PropTypes.func,
    moveAssetGroup: PropTypes.func,
    getAssetGroupFullList: PropTypes.func,
    moveAsset: PropTypes.func,
    entityType: PropTypes.string,
  };

  static defaultProps = {
    allLocationList: [],
    createForm: {
      moveAssetGroupLocationForm: {},
    },
    entityType: '',
  };

  constructor(props) {
    super(props);
    const { siteId, flag } = props;

    this.state = {
      display: false,
      childLocationList: [],
    };
    // if (flag === 'asset-group') {}
    props.getLocationFullList({ siteId, isOnlyParent: false });
    if (flag === 'asset') {
      props.getAssetGroupFullList({ siteId });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      hideBodyScroll();
      this.setState({
        display: true,
      });
    }
    const { allLocationList } = nextProps;
    const form = nextProps.createForm.moveAssetGroupLocationForm;
    const prevForm = this.props.createForm.moveAssetGroupLocationForm;
    const childLocationList = [];
    if (prevForm && prevForm.values && form && form.values) {
      if (
        (prevForm.values.parentLocation && prevForm.values.parentLocation !== form.values.parentLocation) ||
        (!prevForm.values.parentLocation && form.values.parentLocation)
      ) {
        if (form.values.parentLocation) {
          const chosenParentLocation = _.find(this.props.allLocationList, ['id', form.values.parentLocation.value]);
          if (
            chosenParentLocation &&
            (_.isUndefined(chosenParentLocation.ParentLocation) || _.isNull(chosenParentLocation.ParentLocation))
          ) {
            allLocationList.map((item, i) => {
              if (item.locationId === chosenParentLocation.id && item.ParentLocation) {
                childLocationList.push(item);
              }
            });
          }
        }
        this.props.change('childLocation', null);
        this.setState({
          childLocationList,
        });
      }
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    showBodyScroll();
    this.props.onModalClose();
  };

  handleUpdateMoveAssetGroupLocation = (moveData) => {
    // id = if assetGroupMove then assetGroup.id
    const { id, siteId, flag, moveAssetGroup, moveAsset, entityType, initialValues } = this.props;
    const { parentLocation, childLocation, assetGroup } = moveData;
    const data = {
      id,
    };
    if (flag === 'asset-group') {
      if (parentLocation && childLocation) {
        data.locationId = childLocation.value;
      } else if (parentLocation) {
        data.locationId = parentLocation.value;
      }
      moveAssetGroup(data).then(() => {
        this.closeModal();
      });
    } else if (flag === 'asset') {
      if (childLocation) {
        data.locationId = childLocation.value;
      } else if (parentLocation) {
        data.locationId = parentLocation.value;
      }

      if (assetGroup) {
        data.assetGroupId = assetGroup.value;
      }
      if (entityType === 'assetgroupprofile') {
        moveAsset(data, { entityType, oldAssetGroupId: initialValues.assetGroupId }).then(() => {
          this.closeModal();
        });
      } else {
        moveAsset(data).then(() => {
          this.closeModal();
        });
      }
    }
  };

  render() {
    const { initialValues, handleSubmit, t, title, allLocationList, flag, assetGroupList } = this.props;
    const { display, childLocationList } = this.state;
    const parentLocationList = [];
    if (allLocationList && allLocationList.length > 0) {
      allLocationList.map((data, i) => {
        if (data && (_.isUndefined(data.ParentLocation) || _.isNull(data.ParentLocation))) {
          parentLocationList.push(data);
        }
      });
    }
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show modal-template large-modal form-horizontal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{title}</h2>

          <form>
            <div className="row row-eq-height">
              <div className="col col-md-8">
                <MoveAssetGroupLocationView
                  t={t}
                  args={{
                    parentLocationList,
                    childLocationList,
                    isAssetMove: flag === 'asset' ? true : false,
                    assetGroupList,
                  }}
                />
              </div>
            </div>
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleUpdateMoveAssetGroupLocation)}
            >
              {t('Update')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    tokens: { accessToken },
    form,
    location,
    assetGroup,
  } = state;
  return {
    accessToken,
    createForm: form,
    initialValues: props.initialValues,
    allLocationList: location.parent || [],
    assetGroupList: assetGroup.all || [],
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getLocationFullList,
      moveAssetGroup,
      getAssetGroupFullList,
      moveAsset,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'moveAssetGroupLocationForm',
    enableReinitialize: true,
  })(MoveAssetGroupLocationModal),
);
