import {
  LOAD_ASSET_GROUP,
  LOAD_ASSET_GROUPS,
  LOAD_ALL_ASSET_GROUPS,
  LOAD_ASSET_GROUP_COUNT,
  SHOW_LOADER,
  HIDE_LOADER,
} from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/assetGroup';

const adjustAssetGroup = (assetGroup) => {
  if (assetGroup.Site) {
    assetGroup.siteName = assetGroup.Site.name;
  } else {
    assetGroup.siteName = null;
  }
  if (assetGroup.Location) {
    assetGroup.locationName = assetGroup.Location.name;
  } else {
    assetGroup.locationName = null;
  }
};

export const loadAssetGroup = (item) => ({
  type: LOAD_ASSET_GROUP,
  payload: item,
});

export const loadAssetGroups = (items) => ({
  type: LOAD_ASSET_GROUPS,
  payload: items,
});

export const loadAllAssetGroups = (items) => ({
  type: LOAD_ALL_ASSET_GROUPS,
  payload: items,
});

export const loadCount = (count) => ({
  type: LOAD_ASSET_GROUP_COUNT,
  payload: count,
});

export const createAssetGroup = (body) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  return api
    .post(`${routePath}/create`, body)
    .then((response) => {
      adjustAssetGroup(response.data);
      dispatch(loadAssetGroup(response.data));
      Router.pushRoute('cafm/admin/asset_group_profile', { asset_group_id: response.data.id });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createAssetGroup, body);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateAssetGroup = (body) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  return api
    .put(`${routePath}/update`, body)
    .then((response) => {
      const assetGroup = response.data;
      adjustAssetGroup(assetGroup);
      dispatch(loadAssetGroup(assetGroup));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateAssetGroup, body);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAssetGroup = (id) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-item/${id}`)
    .then((response) => {
      const assetGroup = response.data;
      adjustAssetGroup(assetGroup);
      dispatch(loadAssetGroup(assetGroup));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getAssetGroup, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteAssetGroup = (id, path) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${id}`)
    .then(() => {
      const item = {
        id,
        name: null,
      };
      dispatch(loadAssetGroup(item));
      if (path && path.sid) {
        Router.pushRoute('cafm/admin/site_profile', path);
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteAssetGroup, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAssetGroupList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      const assetGroups = response.data;
      assetGroups.forEach(adjustAssetGroup);
      dispatch(loadAssetGroups(assetGroups));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getAssetGroupList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAssetGroupFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-full-list`, { params })
    .then((response) => {
      const assetGroups = response.data;
      dispatch(loadAllAssetGroups(assetGroups));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getAssetGroupFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAssetGroupListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list-with-size-multiple-filter`, { params })
    .then((response) => {
      const assetGroups = response.data.rows;
      assetGroups.forEach(adjustAssetGroup);
      dispatch(loadAssetGroups(assetGroups));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getAssetGroupListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const moveAssetGroup = (body) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  return api
    .put(`${routePath}/move-asset-group`, body)
    .then((response) => {
      const assetGroup = response.data;
      adjustAssetGroup(assetGroup);
      dispatch(loadAssetGroup(assetGroup));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, moveAssetGroup, body);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
