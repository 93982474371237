import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';
import { updateModel } from '../../../actions/cafm/ModelActions';
import { showBodyScroll, hideBodyScroll } from '../../../lib/bodyScrollToggle';
import { ModelEntryView } from '../../cafm/admin';

class EditModelModal extends Component {
  static propTypes = {
    uuid: PropTypes.string.isRequired,
    initialValues: PropTypes.shape().isRequired,
    path: PropTypes.shape(),
    manufacturers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    updateModel: PropTypes.func.isRequired,
  };

  static defaultProps = {
    path: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      hideBodyScroll();
      this.setState({
        display: true,
      });
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    showBodyScroll();
    this.props.onModalClose();
  };

  handleUpdateModel = (model) => {
    const { uuid, path } = this.props;
    const newModel = {
      uuid,
      name: model.name,
      description: model.description,
    };
    newModel.manufacturerUUID = (model.manufacturer && model.manufacturer.value) || '';
    this.props.updateModel(newModel, path);
    this.closeModal();
  };

  render() {
    const { handleSubmit, t, manufacturers } = this.props;
    const { display } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show modal-template form-horizontal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{t('Update model')}</h2>

          <form>
            <ModelEntryView
              t={t}
              args={{
                manufacturerList: manufacturers,
              }}
            />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleUpdateModel)}
            >
              {t('Update')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateModel,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'editCategoryGroupForm',
    enableReinitialize: true,
  })(EditModelModal),
);
