import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import { FormInput } from '../form/parts';

import { quickCreateTaskCategory } from '../../actions/cafm/TaskCategoryActions';

class AddNewTaskCategory extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    quickCreateTaskCategory: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    createForm: PropTypes.shape(),
  };

  static defaultProps = {
    createForm: {
      addNewTaskCategoryForm: {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
    const form = nextProps.createForm.addNewTaskCategoryForm;
    if (form.values && nextProps.taskCategory) {
      if (nextProps.taskCategory.name == form.values.name && form.submitSucceeded) {
        if (nextProps.createForm.quickAddNewPPMForm) {
          nextProps.createForm.quickAddNewPPMForm.values.Category = {
            label: nextProps.taskCategory.name,
            value: nextProps.taskCategory.id,
          };
        } else if (nextProps.createForm.editCategoryGroupForm) {
          nextProps.createForm.editCategoryGroupForm.values.category = {
            label: nextProps.taskCategory.name,
            value: nextProps.taskCategory.id,
          };
        }
        this.props.onModalClose();
      }
    }
  }

  closeModal = () => {
    this.props.onModalClose();
  };

  handleFormSubmit = (values) => {
    const { name, description } = values;
    const taskCategory = {
      name,
      description,
    };
    this.props.quickCreateTaskCategory(taskCategory);
    // this.props.onModalClose();
  };

  render() {
    const { handleSubmit, t } = this.props;
    const { display } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
              ${!display && 'display-none'}`}
        // onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show modal-template form-horizontal"
          // onClick={(e) => {
          //   e.stopPropagation();
          // }}
        >
          <h2 className="swal2-title">{t('Add New Task Category')}</h2>

          <form>
            <Field label={t('Name *')} component={FormInput} name="name" type="text" placeholder={t('Name')} />

            <Field
              label={t('Description')}
              component={FormInput}
              name="description"
              type="text"
              placeholder={t('Description')}
            />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleFormSubmit)}
            >
              {t('Save')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ tokens: { accessToken }, taskCategory: { item }, form }) => ({
  accessToken,
  taskCategory: item,
  createForm: form,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      quickCreateTaskCategory,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'addNewTaskCategoryForm',
  })(AddNewTaskCategory),
);
