import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

// const ReactQuill = dynamic(import('react-quill'));
let ReactQuill;

class FormQuill extends React.Component {
  static propTypes = {
    input: PropTypes.shape().isRequired,
    bounds: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
  };

  modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ],
  };

  formats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link'];

  showQuill = true;

  constructor(props) {
    super(props);
    if (typeof window !== 'undefined') {
      ReactQuill = dynamic(import('react-quill'));
    }
    this.showQuill = typeof document !== 'undefined';

    this.state = {
      showQuill: this.showQuill,
      text: props.input.value || '',
    };
  }

  handleChange = (value) => {
    const { input } = this.props;
    this.setState(
      {
        text: value,
      },
      () => {
        input.onChange(value);
      },
    );
  };

  render() {
    const { showQuill, text } = this.state;
    if (!showQuill) return null;
    return (
      <ReactQuill
        theme="snow"
        modules={this.modules}
        formats={this.formats}
        value={text}
        placeholder={this.props.placeholder}
        bounds={this.props.bounds}
        onChange={this.handleChange}
        readOnly={this.props?.readOnly || false}
      />
    );
  }
}

export default FormQuill;
