import {
  LOAD_BUILDING_BUDGET_CATEGORY,
  LOAD_BUILDING_BUDGET_CATEGORIES,
  LOAD_COUNT,
  SHOW_LOADER,
  HIDE_LOADER,
} from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/buildingBudgetCategory';

export const loadBuildingBudgetCategory = (buildingBudgetCategory) => ({
  type: LOAD_BUILDING_BUDGET_CATEGORY,
  payload: buildingBudgetCategory,
});

export const loadBuildingBudgetCategories = (buildingBudgetCategorys) => ({
  type: LOAD_BUILDING_BUDGET_CATEGORIES,
  payload: buildingBudgetCategorys,
});

export const loadCount = (count) => ({
  type: LOAD_COUNT,
  payload: count,
});

const adjustCategories = (category) => {
  if (!category.BudgetCategory) return;
  category.name = category.BudgetCategory.name;
  category.code = category.BudgetCategory.code;
  category.BudgetCategory = null;
};

export const createBuildingBudgetCategory = (buildingBudgetCategory) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/create`, buildingBudgetCategory)
    .then((response) => {
      Router.pushRoute('building_budget_category_profile', { bbcuuid: response.data.uuid });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createBuildingBudgetCategory, buildingBudgetCategory);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateBuildingBudgetCategory = (buildingBudgetCategory) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .put(`${routePath}/update`, buildingBudgetCategory)
    .then((response) => {
      dispatch(loadBuildingBudgetCategory(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateBuildingBudgetCategory, buildingBudgetCategory);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getBuildingBudgetCategory = (uuid) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-item/${uuid}`)
    .then((response) => {
      dispatch(loadBuildingBudgetCategory(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getBuildingBudgetCategory, uuid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteBuildingBudgetCategory = (uuid, buildingBudgetUuid) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${uuid}`)
    .then(() => {
      dispatch(loadBuildingBudgetCategory(null));
      Router.pushRoute('building_budget_profile', { bbuuid: buildingBudgetUuid });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteBuildingBudgetCategory, uuid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getBuildingBudgetCategoryList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      const categoryList = response.data;
      categoryList.forEach(adjustCategories);
      dispatch(loadBuildingBudgetCategories(categoryList));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getBuildingBudgetCategoryList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getBuildingBudgetCategoryListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list-with-size`, { params })
    .then((response) => {
      const categoryList = response.data.rows;
      categoryList.forEach(adjustCategories);
      dispatch(loadBuildingBudgetCategories(categoryList));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getBuildingBudgetCategoryListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
