import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Selector extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    keyValueOptions: PropTypes.arrayOf(PropTypes.shape),

    value: PropTypes.string,
    width: PropTypes.string,
    scrollable: PropTypes.boolean,

    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: null,
    width: '100%',
    scrollable: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isShown: false,
    };
  }

  toggleOptions = () => {
    this.setState({ isShown: !this.state.isShown });
  };

  handleOnChange = (option) => {
    const { onChange } = this.props;
    this.setState({ isShown: false });
    onChange(option);
  };

  handleOnMouseLeave = () => {
    this.setState({ isShown: false });
  };

  render() {
    const { placeholder, width, scrollable, options, keyValueOptions } = this.props;
    const { isShown } = this.state;

    let { value } = this.props;
    let titleTooltipText = '';
    if (keyValueOptions && value) {
      // value = keyValueOptions.filter(o => o.key == value)[0].value
      const filterData = keyValueOptions.filter((o) => o.key === value)[0];
      if (filterData && Object.keys(filterData).length > 0) {
        value = filterData.value;
        if (filterData.tooltipText && filterData.tooltipText !== '') {
          titleTooltipText = filterData.tooltipText;
        }
      }
    }

    return (
      <div
        className={classNames('btn-group bootstrap-select form-control bs-select input-lg col-sm-9', { open: isShown })}
        title={value || placeholder}
        style={{ width }}
        onMouseLeave={this.handleOnMouseLeave}
      >
        <button
          className={classNames('btn dropdown-toggle btn-default btn-lg', { 'bs-placeholder': !value })}
          type="button"
          onClick={this.toggleOptions}
        >
          {titleTooltipText ? (
            <span title={titleTooltipText} className="filter-option pull-left">
              {value || placeholder}
            </span>
          ) : (
            <span className="filter-option pull-left">{value || placeholder}</span>
          )}
          &nbsp;
          <span className="bs-caret">
            <span className="caret" />
          </span>
        </button>

        <div className="dropdown-menu open">
          <ul className={classNames('dropdown-menu inner', { scrollable })} role="listbox" aria-expanded={isShown}>
            {keyValueOptions
              ? keyValueOptions.map((option) => (
                  <li key={option.key} className={value === option.key ? 'selected active' : ''}>
                    <a
                      aria-selected={value === option.key}
                      onClick={() => {
                        this.handleOnChange(option.key);
                      }}
                    >
                      {option.tooltipText ? (
                        <span title={option.tooltipText} className="text">
                          {option.value}
                        </span>
                      ) : (
                        <span className="text">{option.value}</span>
                      )}
                    </a>
                  </li>
                ))
              : options.map((option) => (
                  <li key={option} className={value === option ? 'selected active' : ''}>
                    <a
                      aria-selected={value === option}
                      onClick={() => {
                        this.handleOnChange(option);
                      }}
                    >
                      <span className="text">{option}</span>
                    </a>
                  </li>
                ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Selector;
