import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';

import { updateProblemCategory } from '../../../actions/cafm/ProblemCategoryActions';
import { ProblemCategoryEntryView } from '../../cafm/admin';

class EditProblemCategoryModal extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    initialValues: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    updateProblemCategory: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    this.props.onModalClose();
  };

  handleUpdateProblemCategory = (problemCategory) => {
    const { id, t } = this.props;
    const { name, enabled, order, imageLarge } = problemCategory;
    const newProblemCategory = {
      id,
      name,
    };

    if (imageLarge && imageLarge.length && _.isArray(imageLarge)) {
      newProblemCategory.imageLarge = imageLarge[0];
    } else if (_.isNull(imageLarge)) {
      newProblemCategory.imageSmall = null;
      newProblemCategory.imageLarge = null;
    }

    if (enabled) {
      newProblemCategory.isEnabled = enabled.value === t('enabled');
    }

    if (order) {
      newProblemCategory.order = order;
    }

    this.props.updateProblemCategory(newProblemCategory, t);
    this.closeModal();
  };

  render() {
    const { handleSubmit, t, initialValues } = this.props;
    const { display } = this.state;

    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show modal-template form-horizontal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{t('Update problem category')}</h2>

          <form>
            <ProblemCategoryEntryView
              t={t}
              args={{
                imageLarge: initialValues.imageLarge,
              }}
            />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleUpdateProblemCategory)}
            >
              {t('Update')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProblemCategory,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'editProblemCategoryForm',
    enableReinitialize: true,
  })(EditProblemCategoryModal),
);
