import _ from 'lodash';
import {
  LOAD_TASK_COMPLIANCE,
  LOAD_TASK_COMPLIANCES,
  LOAD_ALL_TASK_COMPLIANCE,
  LOAD_TASK_COMPLIANCE_COUNT,
  LOAD_PARENT_TASK_COMPLIANCES,
  LOAD_ALL_CUSTOMIZED_PARENT_CHILD_TASK_COMPLIANCE,
  LOAD_TASK_COMPLIANCE_PARENT_DELETE,
  LOAD_CHILD_TASK_COMPLIANCES,
  LOAD_CHILD_TASK_COMPLIANCE_COUNT,
  LOAD_CHILD_TASK_COMPLIANCE,
  LOAD_ONLY_PARENT_TASK_COMPLIANCES,
} from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  list: [],
  all: [],
  allParentTaskCompliances: [],
  allCustomizeParentChild: [],
  childCount: null,
  childList: [],
  childItem: null,
};

const taskCompliance = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_TASK_COMPLIANCE: {
      const actionItem = action.payload;
      const isChildUpdate = actionItem.isChildUpdate;
      delete actionItem.isChildUpdate;
      let { list, count, childList, childItem, childCount, item } = state;
      if (actionItem.name !== null) {
        if (list.find((element) => element.id === actionItem.id) === undefined) {
          list.push(actionItem);
          count += 1;
        }
        list = list.map((listItem) => (actionItem.id === listItem.id ? actionItem : listItem));

        // From Site Profile > Locations > Edit & set Parent on location to other then it will visible. It's now child
        // Ex. Ground, Resident flat. Edit Resident > Parent to Ground. Now Resident is child of Ground rather parent
        var parentIndex = list && list.findIndex((x) => x.id == actionItem.id);
        if (parentIndex >= 0 && !actionItem.isParent && actionItem.parentId && actionItem.parentId > 0) {
          list = list && list.filter((listItem) => listItem.id != actionItem.id);
          count = (list && list.length) || 0;
        }

        // Ex. Ground > Child Resident. Edit Resident > Remove Parent or set to other parent then Resident remove from that child list
        var childIndex = childList && childList.findIndex((x) => x.id == actionItem.id);
        if (
          childIndex >= 0 &&
          !actionItem.isParent &&
          (!actionItem.parentId || actionItem.parentId < 0 || childList[childIndex].parentId != actionItem.parentId)
        ) {
          childList = childList && childList.filter((listItem) => listItem.id != actionItem.id);
        } else {
          childList = childList && childList.map((listItem) => (actionItem.id === listItem.id ? actionItem : listItem));
        }
        childCount = (childList && childList.length) || 0;
        if (!isChildUpdate) {
          item = actionItem;
        } else {
          childItem = actionItem;
        }
      } else {
        list = list.filter((listItem) => actionItem.id !== listItem.id);
        count -= 1;
      }
      return {
        item,
        list,
        count,
        childList,
        childItem,
        childCount,
      };
    }
    case LOAD_TASK_COMPLIANCES:
      return { ...state, list: action.payload };
    case LOAD_TASK_COMPLIANCE_COUNT:
      return { ...state, count: action.payload };
    case LOAD_ALL_TASK_COMPLIANCE:
      return { ...state, all: action.payload };
    case LOAD_PARENT_TASK_COMPLIANCES:
      return { ...state, allParentTaskCompliances: action.payload };
    case LOAD_ALL_CUSTOMIZED_PARENT_CHILD_TASK_COMPLIANCE:
      return { ...state, allCustomizeParentChild: action.payload };
    case LOAD_TASK_COMPLIANCE_PARENT_DELETE:
      const actionItem = action.payload;
      let { list, count, item, childCount, childItem, childList } = state;
      let tempList = [];
      if (actionItem && actionItem.length > 0) {
        _.map(actionItem, (data) => {
          if (data.name !== null) {
            if (list.find((element) => element.id === data.id) === undefined) {
              tempList.push(data);
              count += 1;
            }
            tempList = list.map((listItem) => (data.id === listItem.id ? data : listItem));
          } else {
            tempList = list.filter((listItem) => data.id !== listItem.id);
            count -= 1;

            childList = childList && childList.filter((listItem) => data.id !== listItem.id);
            childCount -= 1;
          }
        });
      }
      return {
        list: tempList,
        item,
        count,
        childList,
        childCount,
        childItem,
      };
    case LOAD_CHILD_TASK_COMPLIANCES:
      return { ...state, childList: action.payload };
    case LOAD_CHILD_TASK_COMPLIANCE_COUNT:
      return { ...state, childCount: action.payload };
    case LOAD_CHILD_TASK_COMPLIANCE: {
      const childItem = action.payload;
      let { childList, childCount } = state;
      if (childItem.name !== null) {
        if (childList && childList.find((element) => element.id === childItem.id) === undefined) {
          childList.push(childItem);
          childCount += 1;
        }

        childList =
          childList &&
          childList.map((listItem) =>
            childItem.id === listItem.id && childItem.parentId && childItem.parentId > 0 && !childItem.isParent
              ? childItem
              : listItem,
          );
      } else {
        childList = childList && childList.filter((listItem) => childItem.id !== listItem.id);
        childList &&
          childList.forEach((listItem) => {
            if (listItem.parentId === childItem.id) {
              listItem.parentId = null;
              listItem.name = null;
            }
          });
        childCount -= 1;
      }
      return {
        ...state,
        childList,
        childCount,
        childItem,
      };
    }
    case LOAD_ONLY_PARENT_TASK_COMPLIANCES:
      return { ...state, onlyParent: action.payload };
    default:
      return state;
  }
};

export default taskCompliance;
