import {
  LOAD_JOB_STATUS,
  LOAD_ALL_JOB_STATUSES,
  LOAD_JOB_STATUSES,
  LOAD_COUNT,
  SHOW_LOADER,
  HIDE_LOADER,
} from '../types';

import { api } from '../../lib/api';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/jobStatus';

const adjustJobStatus = (jobStatus) => {
  jobStatus.statusComplete = jobStatus.isComplete ? 'Yes' : 'No';
  jobStatus.statusDefault = jobStatus.isDefault ? 'Yes' : 'No';
  jobStatus.statusActive = jobStatus.isActive ? 'Yes' : 'No';
};

export const loadJobStatus = (jobStatus) => ({
  type: LOAD_JOB_STATUS,
  payload: jobStatus,
});

export const loadAllJobStatuses = (jobStatuses) => ({
  type: LOAD_ALL_JOB_STATUSES,
  payload: jobStatuses,
});

export const loadJobStatuses = (jobStatuses) => ({
  type: LOAD_JOB_STATUSES,
  payload: jobStatuses,
});

export const loadCount = (count) => ({
  type: LOAD_COUNT,
  payload: count,
});

export const getJobStatusFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/${params.accountId}/get-full-list`, { params })
    .then((response) => {
      dispatch(loadAllJobStatuses(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getJobStatusFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getJobStatusList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      const newLookupvalue = response.data;
      // newLocations.forEach(adjustLocations);
      newLookupvalue.forEach(adjustJobStatus);
      dispatch(loadJobStatuses(newLookupvalue));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getJobStatusList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getJobStatusListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list-with-size`, { params })
    .then((response) => {
      const newLookupvalue = response.data.rows;
      newLookupvalue.forEach(adjustJobStatus);
      dispatch(loadJobStatuses(newLookupvalue));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getJobStatusListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const createJobStatus = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  return api
    .post(`${routePath}/create`, params)
    .then((response) => {
      dispatch(loadJobStatus(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createJobStatus, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateJobStatus = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  return api
    .put(`${routePath}/update`, params)
    .then((response) => {
      // const updatedData = response.data;
      // updatedData.statusComplete = updatedData.isComplete ? 'Yes' : 'No';
      // updatedData.statusDefault = updatedData.isDefault ? 'Yes' : 'No';
      // updatedData.statusActive = updatedData.isActive ? 'Yes' : 'No';
      dispatch(getJobStatusList());
      // dispatch(loadJobStatus(updatedData));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateJobStatus, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteJobStatus = (id) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${id}`)
    .then(() => {
      const item = {
        id,
        name: null,
      };
      dispatch(loadJobStatus(item));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteJobStatus, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
