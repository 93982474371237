import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';

import { updateBudgetCategory } from '../../actions/bms/BudgetCategoryActions';
import { FormInput, ReduxFormSelect } from '../form/parts';

class EditCategoryModal extends Component {
  static propTypes = {
    uuid: PropTypes.string.isRequired,
    initialValues: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    categoryGroups: PropTypes.arrayOf(PropTypes.shape()),
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    updateBudgetCategory: PropTypes.func,
  };

  static defaultProps = {
    updateBudgetCategory: undefined,
    categoryGroups: [],
  };

  constructor(props) {
    super(props);

    const categoryGroupNames = props.categoryGroups.map((group) => group.name);
    categoryGroupNames.unshift('none');

    this.state = {
      display: false,
      categoryGroupNames,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
    if (nextProps.categoryGroups !== this.props.categoryGroups) {
      const categoryGroupNames = nextProps.categoryGroups.map((group) => group.name);
      categoryGroupNames.unshift('none');
      this.setState({
        categoryGroupNames,
      });
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    this.props.onModalClose();
  };

  handleUpdateBudgetCategory = (category) => {
    const { uuid, categoryGroups } = this.props;
    const group = _.find(categoryGroups, { name: category.group });
    const newBudgetCategory = {
      uuid,
      name: category.name,
      code: category.code,
      budgetCategoryGroupUUID: group ? group.uuid : null,
    };
    this.props.updateBudgetCategory(newBudgetCategory);
    this.closeModal();
  };

  render() {
    const { handleSubmit, t } = this.props;
    const { display, categoryGroupNames } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown over-y-auto
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show profile-modal edit-category-modal form-horizontal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{t('Update category')}</h2>

          <form>
            <div className="form-group">
              <label className="col-sm-4 control-label">{t('Category group')}</label>

              <div className="col-sm-8">
                <Field
                  name="group"
                  component={ReduxFormSelect}
                  options={categoryGroupNames}
                  placeholder={t('Choose category group')}
                />
              </div>
            </div>

            <Field label={t('Name')} component={FormInput} name="name" type="text" placeholder={t('Enter name')} />

            <Field label={t('Code')} component={FormInput} name="code" type="text" placeholder={t('Enter code')} />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleUpdateBudgetCategory)}
            >
              {t('Update')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateBudgetCategory,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'editCategoryForm',
    enableReinitialize: true,
  })(EditCategoryModal),
);
