import React from 'react';
import PropTypes from 'prop-types';

const SubHeader = ({ text }) => (
  <div className="row row-title white-bg p-sm">
    <h1 className="sub-header">{text}</h1>
  </div>
);

SubHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SubHeader;
