import { toast } from 'react-toastify';
import {
  LOAD_ASSETSURVEY,
  LOAD_ASSETSURVEYS,
  LOAD_ALL_ASSETSURVEYS,
  LOAD_ASSETSURVEY_COUNT,
  SHOW_LOADER,
  HIDE_LOADER,
  LOAD_ASSETS_REVIEW,
  UPDATE_ASSETS_REVIEW,
  COMPLETE_ASSETS_REVIEW,
} from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';
import { uploadUpdateAttachmentByDynamicRows } from '../cafm/AttachmentActions';

const routePath = '/v2/asset-survey';

const adjustAssets = (asset) => {
  if (!asset.Asset.locationId || !asset.Asset.Location) {
    asset.locationName = null;
  } else {
    asset.locationName = asset.Asset.Location.name;
  }

  if (!asset.Asset.modelId || !asset.Asset.Model) {
    asset.modelName = null;
    asset.manufacturerName = null;
  } else {
    asset.modelName = asset.Asset.Model.name;
    if (!asset.Asset.Model.Manufacturer) {
      asset.manufacturerName = null;
    } else {
      asset.manufacturerName = asset.Asset.Model.Manufacturer.name;
    }
  }
  // asset.Asset.installDate = commonFunction.displayDateWithValidFormat(asset.installDate);
};

const adjustAssetSurveys = (survey) => {
  if (survey.status) {
    switch (survey.status) {
      case 1:
        survey.statusName = 'Scheduled';
        break;
      case 2:
        survey.statusName = 'In-Progress';
        break;
      case 3:
        survey.statusName = 'Completed';
        break;
    }
  }
  if (survey.progress) {
    survey.progressPercent = survey.progress + '%';
  }
  if (!survey.supplierCompanyId || !survey.SupplierCompany) {
    survey.supplierCompanyName = null;
  } else {
    survey.supplierCompanyName = survey.SupplierCompany.name;
  }

  if (survey.userAssigneeId && survey.UserAssignee) {
    survey.supplierContactName = survey.UserAssignee.fullName;
  } else if (survey.supplierContactId && survey.SupplierContact) {
    survey.supplierContactName = survey.SupplierContact.fullName;
  } else {
    survey.supplierContactName = null;
  }
};

export const loadAssetSurvey = (manufacturer) => ({
  type: LOAD_ASSETSURVEY,
  payload: manufacturer,
});

export const loadAssetSurveys = (manufacturers) => ({
  type: LOAD_ASSETSURVEYS,
  payload: manufacturers,
});

export const loadAllAssetSurveys = (manufacturers) => ({
  type: LOAD_ALL_ASSETSURVEYS,
  payload: manufacturers,
});

export const loadCount = (count) => ({
  type: LOAD_ASSETSURVEY_COUNT,
  payload: count,
});

export const loadReviewAssets = (assets) => ({
  type: LOAD_ASSETS_REVIEW,
  payload: assets,
});

export const updateReviewAssets = (assets) => ({
  type: UPDATE_ASSETS_REVIEW,
  payload: assets,
});

export const completeSurvey = () => ({
  type: COMPLETE_ASSETS_REVIEW,
  payload: null,
});

export const createAssetSurvey = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  return api
    .post(`${routePath}/create`, params)
    .then((response) => {
      adjustAssetSurveys(response.data);
      dispatch(loadAssetSurvey(response.data));
      if (params.hasFloorPlan) {
        Router.pushRoute('cafm/admin/asset_survey_setup_floor_plan', { asset_survey_id: response.data.id });
      } else {
        Router.pushRoute('cafm/admin/asset_survey_profile', { asset_survey_id: response.data.id });
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createAssetSurvey, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateAssetSurvey = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  return api
    .put(`${routePath}/update`, params)
    .then((response) => {
      adjustAssetSurveys(response.data);
      dispatch(loadAssetSurvey(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateAssetSurvey, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAssetSurvey = (id) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-item/${id}`)
    .then((response) => {
      adjustAssetSurveys(response.data);
      dispatch(loadAssetSurvey(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getAssetSurvey, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteAssetSurvey = (id, path) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${id}`)
    .then(() => {
      const item = {
        id: id,
        siteId: null,
      };
      dispatch(loadAssetSurvey(item));
      if (path === 'assetsurveys') {
        Router.pushRoute('cafm/admin/asset_surveys');
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteAssetSurvey, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAssetSurveyList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      dispatch(loadAssetSurveys(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getAssetSurveyList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAssetSurveyReviewList = (id) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`${routePath}/review/${id}`)
    .then((response) => {
      const assets = response.data;
      assets.forEach(adjustAssets);
      dispatch(loadReviewAssets(assets));
    })
    .catch((error) => {
      dispatch(showErrorModal(error?.response?.data));
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const responseSurveyAsset = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .post(`${routePath}/review`, params)
    .then((response) => {
      if (response.data) {
        dispatch(updateReviewAssets(params));
        toast.success('Response submitted successfully');
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error?.response?.data));
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const completeSurveyAction = (id, siteId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`${routePath}/complete/${id}`)
    .then((response) => {
      if (response.data) dispatch(completeSurvey());
      toast.success('Asset Survey completed successfully');
      Router.pushRoute(`/cafm/admin/site/${siteId}/profile`);
    })
    .catch((error) => {
      dispatch(showErrorModal(error?.response?.data));
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAssetSurveyFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-full-list`, { params })
    .then((response) => {
      dispatch(loadAllAssetSurveys(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getAssetSurveyFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAssetSurveyListWithSize = (params, siteId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  params.siteId = siteId;
  api
    .get(`${routePath}/get-list-with-size-multiple-filter`, { params })
    .then((response) => {
      const surveys = response.data.rows;
      surveys.forEach(adjustAssetSurveys);
      dispatch(loadAssetSurveys(surveys));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getAssetSurveyListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const grantSubContractorAccessAssetSurey = (id) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  return api
    .put(`${routePath}/grant-subcontractor-access/${id}`, {})
    .then((response) => {})
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, grantSubContractorAccessAssetSurey, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const createAssetSurveyFloorPlan = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  return api
    .post(`${routePath}/setup-floor-plan`, params)
    .then((response) => {
      return (
        dispatch(uploadUpdateAttachmentByDynamicRows({ data: response.data }, 'assetSurveyLocationDrawing'))
          // pictures is the return value of updateAssetPictures
          // which returns the value of AssetPicturesActions.update()
          .then((pictures) => {
            Router.pushRoute('cafm/admin/asset_survey_profile', { asset_survey_id: params.id });
          })
      );
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createAssetSurveyFloorPlan, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
