import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormLabel, FormInput } from '../../form/parts';
import HelpdeskCategoryTopicPicture from '../../../containers/decorate/HelpdeskCategoryTopicPicture';

const AssetSurveySetupFloorPlanView = ({ t, args }) => (
  <div className="col-sm-12 white-bg">
    <div className="row">
      <div className="col-sm-8">
        <Field
          label={t('Floor Name *')}
          component={FormInput}
          name={'floorName-' + args.number}
          type="text"
          placeholder={t('Floor Name')}
        />
        <Field
          label={t('Location Name *')}
          component={FormInput}
          name={'locationName-' + args.number}
          type="text"
          placeholder={t('Location Name')}
        />
      </div>
      <div className="col-sm-4">
        <HelpdeskCategoryTopicPicture t={t} name={'pictureDrawing-' + args.number} />
      </div>
    </div>
    <hr />
  </div>
);

const assetSurveySetupFloorPlanProps = PropTypes.shape({
  number: PropTypes.number,
}).isRequired;

AssetSurveySetupFloorPlanView.propTypes = {
  args: assetSurveySetupFloorPlanProps,
  t: PropTypes.func.isRequired,
};

AssetSurveySetupFloorPlanView.defaultProps = {
  args: {
    number: 1,
  },
};

export default AssetSurveySetupFloorPlanView;
