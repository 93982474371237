import { reducer as FormReducer } from 'redux-form';

import BuildingReducer from './BuildingReducer';
import TokenReducer from './TokenReducer';
import UserReducer from './UserReducer';
import ThresholdReducer from './ThresholdReducer';
import BudgetCategoryReducer from './BudgetCategoryReducer';
import budgetCategoryGroupReducer from './BudgetCategoryGroupReducer';
import BuildingBudgetReducer from './BuildingBudgetReducer';
import BuildingBudgetCategoryReducer from './BuildingBudgetCategoryReducer';
import ErrorsReducer from './ErrorsReducer';
import LoadingReducer from './LoadingReducer';
import TaskCategoryReducer from './cafm/TaskCategoryReducer';
import AssetReducer from './cafm/AssetReducer';
import LocationReducer from './cafm/LocationReducer';
import ScheduleReducer from './cafm/ScheduleReducer';
import TaskReducer from './cafm/TaskReducer';
import InternalCompanyReducer from './cafm/InternalCompanyReducer';
import SiteReducer from './cafm/SiteReducer';
import ManufacturerReducer from './cafm/ManufacturerReducer';
import ModelReducer from './cafm/ModelReducer';
import Sfg20Reducer from './cafm/Sfg20Reducer';
import problemCategory from './cafm/ProblemCategoryReducer';
import problemTopic from './cafm/ProblemTopicReducer';
import tracker from './cafm/TrackerReducer';
import enumeration from './cafm/EnumerationReducer';
import JobReducer from './cafm/JobReducer';
import JobStatusReducer from './cafm/JobStatusReducer';
import omsInternalCompany from './oms/internalCompanyReducer';
import omsInternalContact from './oms/internalContactReducer';
import omsInternalAddress from './oms/internalAddressReducer';
import ConditionReducer from './cafm/ConditionReducer';
import LookupValuesReducer from './cafm/LookupValuesReducer';
import SubscriptionReducer from './account/SubscriptionReducer';
import BuySubscriptionReducer from './setup/BuySubscriptionReducer';
import RoleReducer from './account/RoleReducer';
import NotificationsReducer from './cafm/NotificationsReducer';
import assetPictures from './cafm/AssetPicturesReducer';
import sitePictures from './cafm/SitePicturesReducer';
import tags from './cafm/TagsReducer';
import ApproverReducer from './cafm/ApproverReducer';
import LogReducer from './audit/LogReducer';
import AccountReducer from './cafm/AccountReducer';
import assetNotes from './cafm/AssetNoteReducer';
import taskCompliance from './cafm/TaskComplianceReducer';
import AssetSurveyReducer from './cafm/AssetSurveyReducer';
import AttachmentReducer from './cafm/AttachmentReducer'; //1449
import AssetGroupReducer from './cafm/AssetGroupReducer';
import ScheduleAssetGroupReducer from './cafm/ScheduleAssetGroupReducer';
import ScheduleTaskReducer from './cafm/ScheduleTaskReducer';
import ActivityReducer from './cafm/ActivityReducer';
import UserReportReducer from './UserReportReducer';
import ParcelReducer from './cafm/ParcelReducer';
import calendarReducer from './cafm/CalendarReducer';

const reducers = {
  form: FormReducer,
  user: UserReducer,
  tokens: TokenReducer,
  building: BuildingReducer,
  threshold: ThresholdReducer,
  budgetCategory: BudgetCategoryReducer,
  budgetCategoryGroup: budgetCategoryGroupReducer,
  buildingBudget: BuildingBudgetReducer,
  buildingBudgetCategory: BuildingBudgetCategoryReducer,
  errors: ErrorsReducer,
  isLoading: LoadingReducer,
  taskCategory: TaskCategoryReducer,
  asset: AssetReducer,
  activity: ActivityReducer,
  location: LocationReducer,
  schedule: ScheduleReducer,
  task: TaskReducer,
  internalCompany: InternalCompanyReducer,
  site: SiteReducer,
  manufacturer: ManufacturerReducer,
  model: ModelReducer,
  sfg20: Sfg20Reducer,
  problemCategory,
  problemTopic,
  tracker,
  enumeration,
  ppmJob: JobReducer,
  jobStatus: JobStatusReducer,
  omsInternalCompany,
  omsInternalContact,
  omsInternalAddress,
  condition: ConditionReducer,
  lookupvalues: LookupValuesReducer,
  subscription: SubscriptionReducer,
  buySubscription: BuySubscriptionReducer,
  roles: RoleReducer,
  notifications: NotificationsReducer,
  assetPictures,
  sitePictures,
  tags,
  approvers: ApproverReducer,
  auditLogs: LogReducer,
  account: AccountReducer,
  assetNotes,
  taskCompliance,
  assetSurvey: AssetSurveyReducer,
  attachmentUpload: AttachmentReducer, //1449
  assetGroup: AssetGroupReducer,
  scheduleAssetGroup: ScheduleAssetGroupReducer,
  scheduleTask: ScheduleTaskReducer,
  userReport: UserReportReducer,
  parcel: ParcelReducer,
  calendar: calendarReducer,
};

export default reducers;
