import { LOAD_BUDGET_CATEGORY, LOAD_BUDGET_CATEGORIES, LOAD_COUNT, SHOW_LOADER, HIDE_LOADER } from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/budgetCategory';

const adjustCategories = (category) => {
  if (!category.BudgetCategoryGroup) {
    return;
  }
  category.group = category.BudgetCategoryGroup.name || 'none';
};

export const loadBudgetCategory = (budgetCategory) => ({
  type: LOAD_BUDGET_CATEGORY,
  payload: budgetCategory,
});

export const loadBudgetCategories = (budgetCategorys) => ({
  type: LOAD_BUDGET_CATEGORIES,
  payload: budgetCategorys,
});

export const loadCount = (count) => ({
  type: LOAD_COUNT,
  payload: count,
});

export const createBudgetCategory = (budgetCategory) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/create`, budgetCategory)
    .then((response) => {
      dispatch(loadBudgetCategory(response.data));
      Router.pushRoute('categories');
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createBudgetCategory, budgetCategory);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateBudgetCategory = (budgetCategory) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .put(`${routePath}/update`, budgetCategory)
    .then((response) => {
      const newCategory = response.data;
      adjustCategories(newCategory);
      dispatch(loadBudgetCategory(newCategory));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateBudgetCategory, budgetCategory);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getBudgetCategory = (uuid) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-item/${uuid}`)
    .then((response) => {
      const newCategory = response.data;
      adjustCategories(newCategory);
      dispatch(loadBudgetCategory(newCategory));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getBudgetCategory, uuid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteBudgetCategory = (uuid) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${uuid}`)
    .then(() => {
      const item = {
        uuid,
        name: null,
        code: null,
      };
      dispatch(loadBudgetCategory(item));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteBudgetCategory, uuid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getBudgetCategoryList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      const newCategories = response.data;
      newCategories.forEach(adjustCategories);
      dispatch(loadBudgetCategories(newCategories));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getBudgetCategoryList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getBudgetCategoryFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-full-list`, { params })
    .then((response) => {
      dispatch(loadBudgetCategories(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getBudgetCategoryFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getBudgetCategoryListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list-with-size`, { params })
    .then((response) => {
      const newCategories = response.data.rows;
      newCategories.forEach(adjustCategories);
      dispatch(loadBudgetCategories(newCategories));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getBudgetCategoryListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
