import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OpenProductFeaturesModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    header: PropTypes.string.isRequired,
    onModalClose: PropTypes.func.isRequired,
    features: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      display: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    this.props.onModalClose();
  };

  render() {
    const { header, features } = this.props;
    const { display } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown over-y-auto
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show profile-modal open-features-modal form-horizontal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{header}</h2>

          <div className="col-sm-12">
            <div className="selpro_featured_box">
              <ul dangerouslySetInnerHTML={{ __html: features }} />
            </div>
          </div>

          <div className="swal2-buttonswrapper display-block">
            <button type="button" className="swal2-confirm swal2-styled btn-primary" onClick={this.closeModal}>
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default OpenProductFeaturesModal;
