import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { Table } from '../../components/table';
import { Filter, SmartFilter } from '../../components/filter';
import { AddNewItemView, TitleView, AddInModalViewSmall } from '../../components/decorate';

import PAGE_SIZE from '../../constants/tabledata/page_size';
import userSelectedPreference from '../../lib/userSelectedPreference';

class SmartTable extends Component {
  static propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape()),
    headers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    intableMenu: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    addNewItem: PropTypes.shape(),
    count: PropTypes.number,
    store: PropTypes.shape({
      dispatch: PropTypes.func.isRequired,
    }).isRequired,
    getListWithSize: PropTypes.func.isRequired,
    getList: PropTypes.func.isRequired,
    filterData: PropTypes.shape().isRequired,
    filterValues: PropTypes.shape(),
    boolTypes: PropTypes.string,
    extended: PropTypes.bool,
    budgetPeriodFilter: PropTypes.bool,
    typeId: PropTypes.string,
    funcs: PropTypes.arrayOf(PropTypes.func),
    useUuid: PropTypes.bool,
    navigateOnClick: PropTypes.func,
    extraParams: PropTypes.shape(),
    taskNavParams: PropTypes.arrayOf(PropTypes.string),
    categoryGroupFilter: PropTypes.bool,
    categoryGroups: PropTypes.arrayOf(PropTypes.shape()),
    navParams: PropTypes.shape(),
    orderField: PropTypes.string,
    showAddNew: PropTypes.bool,
    showIntable: PropTypes.bool,
    hideFilter: PropTypes.bool,
    useSmartFilter: PropTypes.bool,
    title: PropTypes.string,
    t: PropTypes.func.isRequired,
    showQuickAddNew: PropTypes.bool,
    quickAddNewItem: PropTypes.shape(),
    iconOnClick: PropTypes.func,
    multiDeleteMode: PropTypes.bool,
    multiArchiveMode: PropTypes.bool,
    handleDeleteMultiJob: PropTypes.func,
    handleArchiveMultiJob: PropTypes.func,
    showStartEndDate: PropTypes.bool,
    startEndDate: PropTypes.shape(),
    checkBoxOnEachRowSetting: PropTypes.shape(),
    showSearchBar: PropTypes.bool,
    tableId: PropTypes.string,
  };

  static defaultProps = {
    list: [],
    count: 0,
    addNewItem: {},
    extended: false,
    budgetPeriodFilter: false,
    boolTypes: null,
    typeId: null,
    useUuid: false,
    funcs: [],
    extraParams: {},
    navParams: {},
    filterValues: {},
    orderField: 'id',
    taskNavParams: [],
    categoryGroupFilter: false,
    categoryGroups: [],
    navigateOnClick: () => {},
    showAddNew: true,
    showIntable: true,
    hideFilter: false,
    useSmartFilter: false,
    title: null,
    showQuickAddNew: false,
    quickAddNewItem: {},
    iconOnClick: () => {},
    multiDeleteMode: false,
    multiArchiveMode: false,
    handleDeleteMultiJob: () => {},
    handleArchiveMultiJob: () => {},
    showStartEndDate: false,
    startEndDate: {},
    checkBoxOnEachRowSetting: {},
    showSearchBar: false,
    tableId: '',
  };

  constructor(props) {
    super(props);
    const defaultParams = {
      page: 0,
      pageSize: PAGE_SIZE,
      orderField: props.orderField,
      orderType: 'ASC',
    };
    const { user, tableId } = props;
    const tempPagingData = userSelectedPreference.getSpecificTablePagingData({
      currentPageUrl: typeof window !== 'undefined' ? window.location.pathname : '',
      userId: user && user.id,
      tableId,
    });
    let tempMultiSelData;
    if (props.multiDeleteMode) {
      tempMultiSelData = userSelectedPreference.getSpecificTableMultiSelectionData({
        currentPageUrl: typeof window !== 'undefined' ? window.location.pathname : '',
        userId: user && user.id,
        tableId,
      });
    }
    if (tempPagingData && tempPagingData.pagingInfo) {
      defaultParams.page = tempPagingData.pagingInfo.page;
      defaultParams.pageSize = tempPagingData.pagingInfo.pageSize;
      defaultParams.orderField = tempPagingData.pagingInfo.orderField;
      defaultParams.orderType = tempPagingData.pagingInfo.orderType;
      if (tempPagingData.pagingInfo.searchTerm && tempPagingData.pagingInfo.searchTerm !== '') {
        defaultParams.searchTerm = tempPagingData.pagingInfo.searchTerm;
      }
    }
    const params = {
      ...defaultParams,
      ...props.extraParams,
      ...(tempPagingData && tempPagingData.filterParams),
    };
    this.state = {
      intableMenu: props.intableMenu,
      headers: props.headers,
      page:
        tempPagingData && tempPagingData.pagingInfo && tempPagingData.pagingInfo.page
          ? tempPagingData.pagingInfo.page
          : 0,
      params,
      paramsWithFilter: { ...params },
      filterParams: tempPagingData && tempPagingData.filterParams ? tempPagingData.filterParams : null, // null,
      isMultiDelete: tempMultiSelData && tempMultiSelData.isMultiDelete ? true : false, // false,
      isMultiArchive: false,
      selectedIds:
        tempMultiSelData && tempMultiSelData.selectedIds && tempMultiSelData.selectedIds.length > 0
          ? tempMultiSelData.selectedIds
          : [], // [],
      searchTerm:
        tempPagingData && tempPagingData.pagingInfo && tempPagingData.pagingInfo.searchTerm
          ? tempPagingData.pagingInfo.searchTerm
          : '', // ''
    };
    props.getListWithSize(this.state.params, this.props.t);
  }

  handleSort = (header) => {
    const orderField = header.orderField || header.name;
    switch (header.order) {
      case 'NO':
        this.sortByOrder(orderField, 'ASC');
        break;

      case 'ASC':
        this.sortByOrder(orderField, 'DESC');
        break;

      case 'DESC':
        this.sortByOrder(orderField, 'ASC');
        break;

      default:
        this.sortByOrder('id', 'ASC');
    }
  };

  sortByOrder = (field, order) => {
    const { params: newParams, headers: newHeaders, filterParams, isMultiArchive, searchTerm } = this.state;

    newParams.orderField = field;
    newParams.orderType = order;

    newHeaders.forEach((newHeader) => {
      const orderField = newHeader.orderField || newHeader.name;
      newHeader.order = orderField === field ? order : 'NO';
    });
    const paramsWithFilter = {
      ...newParams,
      ...filterParams,
    };
    if (this.props.multiArchiveMode) {
      paramsWithFilter.includeAll = isMultiArchive;
    }
    if (searchTerm && searchTerm !== '') {
      paramsWithFilter.searchTerm = searchTerm;
    }
    this.setState({
      selectedIds: [],
    });
    this.setState(
      {
        headers: newHeaders,
        params: newParams,
        paramsWithFilter,
      },
      this.props.getList(paramsWithFilter, this.props.t),
    );
  };

  handlePageClick = (page) => {
    const { params: newParams, filterParams, isMultiArchive, searchTerm } = this.state;
    let { url, user, tableId } = this.props;
    if (!url || url === '') {
      url = { asPath: window.location.pathname };
    }
    newParams.page = page;
    const paramsWithFilter = {
      ...newParams,
      ...filterParams,
    };
    if (this.props.multiArchiveMode) {
      paramsWithFilter.includeAll = isMultiArchive;
    }
    if (searchTerm && searchTerm !== '') {
      paramsWithFilter.searchTerm = searchTerm;
    }
    this.setState({
      selectedIds: [],
    });
    userSelectedPreference.setUserSelectedPreference({
      currentPageUrl: url.asPath,
      userId: user && user.id,
      tablePagingPreference: {
        pagingInfo: paramsWithFilter,
        filterParams,
      },
      isMultipleSelectionReset: true,
      tableId,
    });
    this.setState(
      {
        params: newParams,
        page,
        paramsWithFilter,
      },
      this.props.getListWithSize(paramsWithFilter, this.props.t),
    );
  };

  handleFilter = (filterParams) => {
    const { params, isMultiArchive, searchTerm } = this.state;
    let { url, user, tableId } = this.props;
    if (!url || url === '') {
      url = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    }
    params.page = 0;
    if (Object.keys(filterParams).length <= 0) {
      delete params.fieldComparisonType;
      delete params.filterField;
      delete params.filterValue;
    }
    const paramsWithFilter = {
      ...params,
      ...filterParams,
    };
    if (this.props.multiArchiveMode) {
      paramsWithFilter.includeAll = isMultiArchive;
    }
    if (searchTerm && searchTerm !== '') {
      paramsWithFilter.searchTerm = searchTerm;
    }
    this.setState({
      page: 0,
      filterParams,
      paramsWithFilter,
    });
    this.setState({
      selectedIds: [],
    });
    // this.props.store.dispatch(reset('filterForm'));
    userSelectedPreference.setUserSelectedPreference({
      currentPageUrl: url.asPath,
      userId: user && user.id,
      tableId,
      tablePagingPreference: {
        pagingInfo: paramsWithFilter,
        filterParams,
      },
    });
    this.props.getListWithSize(paramsWithFilter, this.props.t);
  };

  onChangeMultiDelete = (ischecked) => {
    let { url, user } = this.props;
    if (!url || url === '') {
      url = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    }
    this.setState({
      isMultiDelete: ischecked,
    });
    const tempParams = {
      currentPageUrl: url.asPath,
      userId: user && user.id,
    };
    if (!ischecked) {
      this.setState({
        selectedIds: [],
      });
      tempParams.isMultipleSelectionReset = true;
    } else {
      tempParams.tableMultiSelectPreference = {
        isMultiDelete: ischecked,
      };
    }
    userSelectedPreference.setUserSelectedPreference(tempParams);
  };

  onChangeMultiArchive = (ischecked) => {
    const { params: newParams, filterParams } = this.state;
    newParams.page = 0;
    const paramsWithFilter = {
      ...newParams,
      ...filterParams,
    };
    this.setState({
      isMultiArchive: ischecked,
    });
    if (!ischecked) {
      this.setState({
        selectedIds: [],
      });
      paramsWithFilter.includeAll = false;
    }
    if (ischecked) {
      paramsWithFilter.includeAll = true;
    }
    this.setState(
      {
        params: newParams,
        page: 0,
        paramsWithFilter,
      },
      this.props.getListWithSize(paramsWithFilter, this.props.t),
    );
  };

  onGetSelected = (checked, value) => {
    let { url, user } = this.props;
    if (!url || url === '') {
      url = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    }
    const tempMultiSelectionData = userSelectedPreference.getSpecificTableMultiSelectionData({
      currentPageUrl: url.asPath,
      userId: user && user.id,
    });
    const { selectedIds, isMultiDelete } = this.state;
    selectedIds.map((item, i) => {
      if (item == value) {
        selectedIds.splice(i, 1);
      }
    });
    if (checked) {
      selectedIds.push(value);
    }
    this.setState({
      selectedIds,
    });
    const tempParams = {
      currentPageUrl: url.asPath,
      userId: user && user.id,
    };
    if (tempMultiSelectionData && Object.keys(tempMultiSelectionData).length > 0) {
      tempParams.tableMultiSelectPreference = tempMultiSelectionData;
    } else {
      tempParams.tableMultiSelectPreference = {};
    }
    tempParams.tableMultiSelectPreference.isMultiDelete = isMultiDelete;
    tempParams.tableMultiSelectPreference.selectedIds = selectedIds;
    userSelectedPreference.setUserSelectedPreference(tempParams);
  };

  onClickSearchIcon = () => {
    const { searchTerm } = this.state;
    this.loadSearchData(searchTerm);
  };

  onChangeSearchText = (event) => {
    this.setState({ searchTerm: event.target.value });
    this.loadSearchData(event.target.value);
  };

  onKeyUpSearch = (event) => {
    this.setState({ searchTerm: event.target.value });
    this.loadSearchData(event.target.value);
  };

  loadSearchData = (searchTerm) => {
    const { params, filterParams, isMultiArchive } = this.state;
    let { url, user, tableId } = this.props;
    if (!url || url === '') {
      url = { asPath: window.location.pathname };
    }
    params.page = 0;
    const paramsWithFilter = {
      ...params,
      ...filterParams,
    };
    if (this.props.multiArchiveMode) {
      paramsWithFilter.includeAll = isMultiArchive;
    }
    if (searchTerm && searchTerm !== '') {
      paramsWithFilter.searchTerm = searchTerm;
    } else {
      delete paramsWithFilter.searchTerm;
    }
    this.setState({
      page: 0,
      filterParams,
      paramsWithFilter,
    });
    this.setState({
      selectedIds: [],
    });
    userSelectedPreference.setUserSelectedPreference({
      currentPageUrl: url.asPath,
      userId: user && user.id,
      tableId,
      tablePagingPreference: {
        pagingInfo: paramsWithFilter,
        filterParams,
      },
    });
    this.props.getListWithSize(paramsWithFilter, this.props.t);
  };

  render() {
    const {
      list,
      count,
      extended,
      addNewItem,
      filterData,
      filterValues,
      budgetPeriodFilter,
      boolTypes,
      typeId,
      useUuid,
      funcs,
      navParams,
      taskNavParams,
      categoryGroupFilter,
      categoryGroups,
      navigateOnClick,
      store,
      showAddNew,
      showIntable,
      hideFilter,
      useSmartFilter,
      title,
      t,
      showQuickAddNew,
      quickAddNewItem,
      iconOnClick,
      multiDeleteMode,
      multiArchiveMode,
      handleDeleteMultiJob,
      handleArchiveMultiJob,
      showStartEndDate,
      startEndDate,
      checkBoxOnEachRowSetting,
      showSearchBar,
      user,
    } = this.props;
    const {
      headers,
      intableMenu,
      page,
      filterParams,
      isMultiDelete,
      isMultiArchive,
      selectedIds,
      paramsWithFilter,
      searchTerm,
    } = this.state;
    const maxPages = Math.ceil(count / PAGE_SIZE);
    return (
      <div className="col-lg-12">
        <div className="row row-title white-bg p-sm">
          <div className={`${title ? 'smart-header-title dis_block' : 'smart-header-no-title dis_block'}`}>
            {title && <TitleView title={title} t={t} />}

            {showAddNew &&
              (addNewItem.isModal ? (
                <AddInModalViewSmall openModal={addNewItem.openModal} title={addNewItem.title} />
              ) : (
                <AddNewItemView route={addNewItem.route} params={addNewItem.params} label={addNewItem.label} t={t} />
              ))}
            {showQuickAddNew && (
              <div className="wrap-for-max-width pull-right margin_right_20">
                <div className="text-right">
                  <button type="button" className="btn oms-btn new" onClick={quickAddNewItem.openModal}>
                    {quickAddNewItem.title}
                  </button>
                </div>
              </div>
              // <AddInModalView
              //   openModal={quickAddNewItem.openModal}
              //   title={quickAddNewItem.title}
              // />
            )}
            {showStartEndDate && (
              <div>
                <div className="wrap-for-max-width pull-right margin_right_20">
                  <div className="text-right">
                    <h3 className="text-right lineHeight">
                      End Date:
                      {startEndDate.endDate}
                    </h3>
                  </div>
                </div>
                <div className="wrap-for-max-width pull-right margin_right_20">
                  <div className="text-right">
                    <h3 className="text-right lineHeight">
                      Start Date:
                      {startEndDate.startDate}
                    </h3>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {showSearchBar && (
          <div className="row row-advanced-search p-sm">
            <div className="col-lg-3 wrap-for-max-width pull-right">
              <div className="input-group add-on search basic-search-form">
                <input
                  className="form-control input-lg ignore-change"
                  id="searchTerm"
                  name="searchTerm"
                  type="text"
                  placeholder="Search"
                  onChange={this.onChangeSearchText}
                  onKeyUp={this.onKeyUpSearch}
                  value={searchTerm}
                />
                <div className="input-group-btn">
                  <button
                    type="button"
                    className="btn btn-default"
                    id="btnSearchWithoutTable"
                    onClick={this.onClickSearchIcon}
                  >
                    <i className="fas fa-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {(count > 0 || (count === 0 && filterParams !== null)) && !hideFilter && (
          <div className="row p-sm">
            {useSmartFilter ? (
              <SmartFilter
                onFilter={this.handleFilter}
                filterFields={filterData}
                filterValues={filterValues}
                boolTypes={boolTypes}
                budgetPeriodFilter={budgetPeriodFilter}
                categoryGroupFilter={categoryGroupFilter}
                categoryGroups={categoryGroups}
                store={store}
                t={t}
              />
            ) : (
              <Filter
                onFilter={this.handleFilter}
                filterFields={filterData}
                boolTypes={boolTypes}
                budgetPeriodFilter={budgetPeriodFilter}
                categoryGroupFilter={categoryGroupFilter}
                categoryGroups={categoryGroups}
                store={store}
                t={t}
              />
            )}
          </div>
        )}

        {count > 0 && list.length !== 0 && (
          <div className="row">
            {multiDeleteMode && (
              <div className="col-sm-12">
                {isMultiDelete && (
                  <input type="checkbox" checked onChange={(e) => this.onChangeMultiDelete(e.target.checked)} />
                )}
                {!isMultiDelete && (
                  <input type="checkbox" onChange={(e) => this.onChangeMultiDelete(e.target.checked)} />
                )}{' '}
                Delete Multiple
                {isMultiDelete && !isMultiArchive && (
                  <button
                    title="Remove job"
                    className="btn wrap-btn-round"
                    onClick={() => {
                      handleDeleteMultiJob(selectedIds, paramsWithFilter);
                    }}
                  >
                    <i className="fas fa-1p5x fa-trash" />
                  </button>
                )}
              </div>
            )}
            {multiArchiveMode && (
              <div className="col-sm-12">
                <input type="checkbox" onChange={(e) => this.onChangeMultiArchive(e.target.checked)} /> Include All
                {isMultiArchive && !isMultiDelete && (
                  <button
                    title="Remove job"
                    className="btn wrap-btn-round"
                    onClick={() => {
                      handleArchiveMultiJob(selectedIds, paramsWithFilter);
                    }}
                  >
                    <i className="fas fa-1p5x fa-undo" />
                  </button>
                )}
              </div>
            )}
            {checkBoxOnEachRowSetting && checkBoxOnEachRowSetting.isCheckBoxOnEachRow && (
              <div className="col-sm-12">
                <button
                  className="filter-button"
                  onClick={() => {
                    checkBoxOnEachRowSetting.onTopButtonClick(selectedIds, paramsWithFilter);
                  }}
                  style={{ width: `${checkBoxOnEachRowSetting.topButtonWidth}px` }}
                >
                  {checkBoxOnEachRowSetting.topButtonTitle}
                </button>
              </div>
            )}
            <Table
              headers={headers}
              rows={list}
              intableMenu={intableMenu}
              count={count}
              page={page}
              maxpages={maxPages}
              onSort={this.handleSort}
              onPageClick={this.handlePageClick}
              typeId={typeId}
              funcs={funcs}
              t={t}
              useUuid={useUuid}
              extended={extended}
              navParams={navParams}
              taskNavParams={taskNavParams}
              navigateOnClick={navigateOnClick}
              showIntable={showIntable}
              iconOnClick={iconOnClick}
              multiDeleteMode={isMultiDelete}
              multiArchiveMode={isMultiArchive}
              getSelectedValue={this.onGetSelected}
              isCheckBoxOnEachRow={checkBoxOnEachRowSetting.isCheckBoxOnEachRow || false}
              userId={user && user.id}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;

  return {
    user: user.currentuser,
  };
};

export default connect(
  mapStateToProps,
  null,
)(
  reduxForm({
    form: 'smartTable',
  })(SmartTable),
);
// export default SmartTable;
