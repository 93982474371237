import {
  LOAD_LOOKUPVALUE,
  LOAD_LOOKUPVALUES,
  LOAD_LOOKUPVALUE_COUNT,
  SHOW_LOADER,
  HIDE_LOADER,
  LOAD_LOOKUPVALUES_TAGS,
} from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/lookupvalues';

const adjustLocations = (location) => {
  if (!location.locationId || !location.ParentLocation) {
    location.locationName = null;
  } else {
    location.locationName = location.ParentLocation.name;
  }

  if (location.type === 1) {
    location.locationType = 'Floor';
  } else if (location.type === 2) {
    location.locationType = 'Room';
  }
};

export const loadLookupvalue = (lookupvalue) => ({
  type: LOAD_LOOKUPVALUE,
  payload: lookupvalue,
});

export const loadLookupvalues = (lookupvalue) => ({
  type: LOAD_LOOKUPVALUES,
  payload: lookupvalue,
});

export const loadCount = (count) => ({
  type: LOAD_LOOKUPVALUE_COUNT,
  payload: count,
});

export const loadLookupvaluesTags = (lookupvalue) => ({
  type: LOAD_LOOKUPVALUES_TAGS,
  payload: lookupvalue,
});

export const createLookupvalue = (lookupvalue) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/create`, lookupvalue)
    .then((response) => {
      dispatch(loadLookupvalue(response.data));
      // Router.pushRoute('cafm/admin/location_profile', { location_id: response.data.id });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createLookupvalue, lookupvalue);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateLookupvalue = (lookupvalue) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .put(`${routePath}/update`, lookupvalue)
    .then((response) => {
      const newLookupvalue = response.data;
      // adjustLocations(newLocation);
      dispatch(loadLookupvalue(newLookupvalue));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateLookupvalue, lookupvalue);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getLookupvalue = (id) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-item/${id}`)
    .then((response) => {
      const newLookupvalue = response.data;
      // adjustLocations(newLookupvalue);
      dispatch(loadLookupvalue(newLookupvalue));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getLookupvalue, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteLookupvalue = (id, path) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${id}`)
    .then(() => {
      const item = {
        lookupvaluesid: id,
        lookupvalue: null,
      };
      dispatch(loadLookupvalue(item));
      if (path && path.sid) {
        Router.pushRoute('cafm/admin/site_profile', path);
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteLookupvalue, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getLookupvalueList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      const newLookupvalue = response.data;
      // newLocations.forEach(adjustLocations);
      if (params.lookupname === 'Tags') {
        dispatch(loadLookupvaluesTags(newLookupvalue));
      } else {
        dispatch(loadLookupvalues(newLookupvalue));
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data || 'error'));
      handleErrors(error, dispatch, getLookupvalueList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

// export const getLookupvalueFullList = params => (dispatch) => {
//   dispatch({ type: SHOW_LOADER });

//   api.get(`${routePath}/get-full-list`, { params })
//     .then((response) => {
//       dispatch(loadParentLocations(response.data));
//     })
//     .catch((error) => {
//       dispatch(showErrorModal(error.response.data));
//       handleErrors(error, dispatch, getLookupvalueFullList, params);
//     })
//     .then(() => {
//       dispatch({ type: HIDE_LOADER });
//     });
// };

export const getLookupvalueListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list-with-size`, { params })
    .then((response) => {
      const newLookupvalue = response.data.rows;
      // newLookupvalue.forEach(adjustLocations);
      dispatch(loadLookupvalues(newLookupvalue));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data || 'error'));
      handleErrors(error, dispatch, getLookupvalueListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
