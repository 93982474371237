import { LOAD_MODEL, LOAD_MODELS, LOAD_MODEL_COUNT } from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  list: [],
};

const model = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_MODEL: {
      const item = action.payload;
      let { list, count } = state;
      if (item.name !== null) {
        list = list.map((listItem) => (item.uuid === listItem.uuid ? item : listItem));
        if (item.path && item.path.manufacturer_id) {
          list = list.filter((listItem) => {
            const check = listItem.Manufacturer && item.path.manufacturer_id === listItem.Manufacturer.uuid;
            if (!check) {
              count -= 1;
            }
            return check;
          });
        }
      } else {
        list = list.filter((listItem) => item.uuid !== listItem.uuid);
        count -= 1;
      }
      return { item, list, count };
    }
    case LOAD_MODELS: {
      return { ...state, list: action.payload };
    }
    case LOAD_MODEL_COUNT:
      return { ...state, count: action.payload };
    default:
      return state;
  }
};

export default model;
