import {
  LOAD_TASK_COMPLIANCES,
  LOAD_ALL_TASK_COMPLIANCE,
  LOAD_TASK_COMPLIANCE,
  LOAD_TASK_COMPLIANCE_COUNT,
  LOAD_PARENT_TASK_COMPLIANCES,
  LOAD_ALL_CUSTOMIZED_PARENT_CHILD_TASK_COMPLIANCE,
  SHOW_LOADER,
  HIDE_LOADER,
  LOAD_CHILD_TASK_COMPLIANCES,
  LOAD_CHILD_TASK_COMPLIANCE_COUNT,
  LOAD_CHILD_TASK_COMPLIANCE,
  LOAD_ONLY_PARENT_TASK_COMPLIANCES,
} from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';
import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/taskCompliance';

const adjustTaskCompliance = (taskCompliance) => {
  taskCompliance.parentCompliance = null;
  if (taskCompliance && taskCompliance.ParentTaskCompliance) {
    taskCompliance.parentCompliance = taskCompliance.ParentTaskCompliance.name;
  }
};

export const loadTaskCompliance = (taskCompliance) => ({
  type: LOAD_TASK_COMPLIANCE,
  payload: taskCompliance,
});

export const loadTaskCompliances = (taskCompliances) => ({
  type: LOAD_TASK_COMPLIANCES,
  payload: taskCompliances,
});

export const loadCount = (count) => ({
  type: LOAD_TASK_COMPLIANCE_COUNT,
  payload: count,
});

export const loadAllTaskCompliances = (taskCompliances) => ({
  type: LOAD_ALL_TASK_COMPLIANCE,
  payload: taskCompliances,
});

export const loadParentTaskCompliances = (taskCompliances) => ({
  type: LOAD_PARENT_TASK_COMPLIANCES,
  payload: taskCompliances,
});

export const loadCustomizedParentChileFull = (taskCompliances) => ({
  type: LOAD_ALL_CUSTOMIZED_PARENT_CHILD_TASK_COMPLIANCE,
  payload: taskCompliances,
});

export const loadChildTaskCompliances = (compliances) => ({
  type: LOAD_CHILD_TASK_COMPLIANCES,
  payload: compliances,
});

export const loadChildTaskCompliancesCount = (count) => ({
  type: LOAD_CHILD_TASK_COMPLIANCE_COUNT,
  payload: count,
});

export const loadChildTaskCompliance = (compliance) => ({
  type: LOAD_CHILD_TASK_COMPLIANCE,
  payload: compliance,
});

export const loadOnlyParentTaskCompliances = (parentCompliances) => ({
  type: LOAD_ONLY_PARENT_TASK_COMPLIANCES,
  payload: parentCompliances,
});

export const getTaskComplianceFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`${routePath}/get-full-list`, { params })
    .then((response) => {
      if (params?.isOnlyParent) {
        dispatch(loadOnlyParentTaskCompliances(response.data));
      } else {
        dispatch(loadAllTaskCompliances(response.data));
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getTaskComplianceFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const createTaskCompliance = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/create`, params)
    .then((response) => {
      const result = response.data;
      adjustTaskCompliance(result);
      if (!response.data.isParent && response.data.parentId > 0) {
        dispatch(loadChildTaskCompliance(response.data));
        Router.pushRoute('cafm/admin/child_taskcompliance_profile', { taskcompliance_id: response.data.id });
      } else {
        dispatch(loadTaskCompliance(result));
        Router.pushRoute('cafm/admin/taskcompliance_profile', { taskcompliance_id: response.data.id });
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createTaskCompliance, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateTaskCompliance = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  const isChildUpdate = params.isChildUpdate;
  const urlPath = params.urlPath;
  delete params.isChildUpdate;
  delete params.urlPath;
  return api
    .put(`${routePath}/update`, params)
    .then((response) => {
      const result = response.data;
      adjustTaskCompliance(result);
      dispatch(loadTaskCompliance({ ...result, isChildUpdate: isChildUpdate }));
      // if Child Compliance Profile > Edit && remove Parent then child will be Parent and redirect on parent profile
      if (
        (!result.parentId || result.parentId <= 0) &&
        isChildUpdate &&
        urlPath == '/cafm/admin/child_taskcompliance_profile'
      ) {
        Router.pushRoute('cafm/admin/taskcompliance_profile', { taskcompliance_id: result.id });
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateTaskCompliance, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getTaskCompliance =
  (id, path = null) =>
  (dispatch) => {
    dispatch({ type: SHOW_LOADER });

    api
      .get(`${routePath}/get-item/${id}`)
      .then((response) => {
        const result = response.data;
        adjustTaskCompliance(result);
        if (path && path.isChildProfile) {
          dispatch(loadChildTaskCompliance(result));
        } else {
          dispatch(loadTaskCompliance(result));
        }
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response.data));
        handleErrors(error, dispatch, getTaskCompliance, id);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const deleteTaskCompliance =
  (id, path = null) =>
  (dispatch) => {
    dispatch({ type: SHOW_LOADER });
    api
      .delete(`${routePath}/delete/${id}`)
      .then(() => {
        const item = {
          id,
          name: null,
        };
        if (path && path.parentId && path.parentId > 0 && path.isChildDelete) {
          dispatch(loadChildTaskCompliance(item));
        } else {
          dispatch(loadTaskCompliance(item));
        }
        if (path && path.isRedirectOnSettings) {
          Router.pushRoute('cafm/admin/settings');
        } else if (path && path.parentId && path.parentId > 0 && path.isChildDelete) {
          Router.pushRoute('cafm/admin/taskcompliance_profile', { taskcompliance_id: path.parentId });
        }
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response.data));
        handleErrors(error, dispatch, deleteTaskCompliance, id);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const getTaskComplianceList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      const result = response.data;
      result.forEach(adjustTaskCompliance);
      dispatch(loadTaskCompliances(result));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getTaskComplianceList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getTaskComplianceListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list-with-size`, { params })
    .then((response) => {
      const result = response.data.rows;
      result.forEach(adjustTaskCompliance);
      if (params.isOnlyParent) {
        dispatch(loadTaskCompliances(result));
        dispatch(loadCount(response.data.count));
      } else {
        dispatch(loadChildTaskCompliances(result));
        dispatch(loadChildTaskCompliancesCount(response.data.count));
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getTaskComplianceListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getParentTaskComplianceFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`${routePath}/get-parent-compliance-list`, { params })
    .then((response) => {
      dispatch(loadParentTaskCompliances(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getParentTaskComplianceFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

// Parent will be Disabled and Child enabled in dropdown for Add/Edit task related screens
export const getCustomizeParentChildFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`${routePath}/get-customize-parent-child-full-list`, { params })
    .then((response) => {
      dispatch(loadCustomizedParentChileFull(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getCustomizeParentChildFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
