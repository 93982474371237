import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Router } from '../../../common/routes';
import { MenuUserFlo } from '../styled';
import { reduxStore } from '../../../src/lib/initRedux';

const handleHamburgerClick = () => {
  const menuRight = document.getElementById('menu-right');
  const menuBar = document.getElementById('menu-bar');
  if (menuRight.className.indexOf('hamburger-open') === -1) {
    menuRight.className += ' hamburger-open';
    menuBar.style.right = '300px';
    menuBar.style.left = '-300px';
  } else {
    menuRight.className = menuRight.className.replace(' hamburger-open', '');
    menuBar.style = {};
  }
};

class HeaderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentuser: reduxStore?.getState()?.user.currentuser,
    };
  }

  render() {
    const { userFullName, companyName, colorSettings, currentSystem } = this.props;
    let pageLogo = '/img/logo-octanise-h.png';
    switch (currentSystem) {
      case 'cafm':
      case 'cafm-admin':
        pageLogo = colorSettings.cafmLogoImage154_64 || '/img/logo-cafm-h.png';
        break;
      case 'bms':
        pageLogo = colorSettings.bmsLogoImage142_64 || '/img/logo-bms-h.png';
        break;
    }

    return (
      <div className="row border-bottom main-header" id="header-row">
        <nav className="navbar navbar-fixed-top white-bg" id="menu-bar">
          <div className="navbar-header">
            <a
              className="img-responsive"
              onClick={() => {
                Router.pushRoute('main');
              }}
            >
              <img src={pageLogo} alt="octanise" />
            </a>
          </div>

          <div className="nav navbar-top-links pull-right">
            <div className="pull-right menu-bar-btn" id="menu-icon-flo">
              <a className="btn off-canvas-btn" onClick={handleHamburgerClick}>
                <i className="fal fa-bars fa-3x" />
              </a>
            </div>
            {/* <div className="pull-right menu-bar-btn">
              <a className="btn notification-btn">
                <i className="fal fa-bell fa-2x notification" />
              </a>
            </div> */}
            <MenuUserFlo id="menu-user-flo">
              <div className="list-group">
                <a className="list-group-item" href="/my/profile">
                  <i className="fal fa-user fa-2x" />
                  <h4 className="list-group-item-heading">{userFullName || '[Not Logged In]'}</h4>
                  <span className="list-group-item-text">{companyName || '[No Account]'}</span>
                </a>
              </div>
            </MenuUserFlo>
          </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const data = {};

  if (state && state.user && state.user.user && state.user.user.userDetails && state.user.user.userDetails.fullName) {
    data.userFullName = state.user.user.userDetails.fullName;

    if (
      state.user.user.userDetails.Accounts &&
      state.user.user.userDetails.Accounts[0] &&
      state.user.user.userDetails.Accounts[0].Company &&
      state.user.user.userDetails.Accounts[0].Company[0] &&
      state.user.user.userDetails.Accounts[0].Company[0].name
    ) {
      data.companyName = state.user.user.userDetails.Accounts[0].name;
    }
  }

  return data;
};

export default connect(mapStateToProps)(HeaderView);
