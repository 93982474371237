import {
  LOAD_LOCATION,
  LOAD_LOCATIONS,
  LOAD_PARENT_LOCATIONS,
  LOAD_LOCATION_COUNT,
  LOAD_CHILD_LOCATIONS,
  LOAD_CHILD_LOCATION_COUNT,
  LOAD_CHILD_LOCATION,
  LOAD_ONLY_PARENT_LOCATIONS,
} from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  parent: [],
  parent: [],
  parentTree: [],
  list: [],
  childList: [],
  childCount: null,
  childItem: null,
  onlyParent: [],
};

const location = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_LOCATION: {
      const actionItem = action.payload;
      const isChildUpdate = actionItem.isChildUpdate;
      delete actionItem.isChildUpdate;
      let { list, parent, count, childList, childItem, childCount, item } = state;
      if (actionItem.name !== null) {
        if (parent && parent.find((element) => element.id === actionItem.id) === undefined) {
          parent.push(actionItem);
        }
        if (list && list.find((element) => element.id === actionItem.id) === undefined) {
          list.push(actionItem);
          count += 1;
        }

        list = list && list.map((listItem) => (actionItem.id === listItem.id ? actionItem : listItem));
        parent = parent && parent.map((listItem) => (actionItem.id === listItem.id ? actionItem : listItem));
        // From Site Profile > Locations > Edit & set Parent on location to other then it will visible. It's now child
        // Ex. Ground, Resident flat. Edit Resident > Parent to Ground. Now Resident is child of Ground rather parent
        var parentIndex = list && list.findIndex((x) => x.id == actionItem.id);
        if (parentIndex >= 0 && !actionItem.isParent && actionItem.locationId && actionItem.locationId > 0) {
          list = list && list.filter((listItem) => listItem.id != actionItem.id);
          count = (list && list.length) || 0;
        }

        // Ex. Ground > Child Resident. Edit Resident > Remove Parent or set to other parent then Resident remove from that child list
        var childIndex = childList && childList.findIndex((x) => x.id == actionItem.id);
        if (
          childIndex >= 0 &&
          !actionItem.isParent &&
          (!actionItem.locationId ||
            actionItem.locationId < 0 ||
            childList[childIndex].locationId != actionItem.locationId)
        ) {
          childList = childList && childList.filter((listItem) => listItem.id != actionItem.id);
        } else {
          childList = childList && childList.map((listItem) => (actionItem.id === listItem.id ? actionItem : listItem));
        }
        // Parent Profile > Edit > Disabled (true/false) then update value accordingly in childList table disabled column
        var childFilter = childList && childList.filter((x) => x.locationId === actionItem.id);
        if (childFilter && childFilter.length > 0) {
          childList = childList.map((listItem) => {
            listItem.disabled = actionItem.disabled;
            listItem.inactiveLocation = actionItem.inactiveLocation;
            return listItem;
          });
        }
        childCount = (childList && childList.length) || 0;
        if (!isChildUpdate) {
          item = actionItem;
        } else {
          childItem = actionItem;
        }
      } else {
        list = list && list.filter((listItem) => actionItem.id !== listItem.id);
        list &&
          list.forEach((listItem) => {
            if (listItem.locationId === actionItem.id) {
              listItem.locationId = null;
              listItem.locationName = null;
            }
          });
        parent = parent && parent.filter((listItem) => actionItem.id !== listItem.id);
        count -= 1;
      }
      return {
        item,
        list,
        parent,
        count,
        childList,
        childItem,
        childCount,
      };
    }
    case LOAD_LOCATIONS:
      return { ...state, list: action.payload, childList: [], childCount: null };
    case LOAD_PARENT_LOCATIONS:
      return { ...state, parent: action.payload.parent, parentTree: action.payload.parentTree };
    case LOAD_LOCATION_COUNT:
      return { ...state, count: action.payload };
    case LOAD_CHILD_LOCATIONS:
      return { ...state, childList: action.payload };
    case LOAD_CHILD_LOCATION_COUNT:
      return { ...state, childCount: action.payload };
    case LOAD_CHILD_LOCATION: {
      const childItem = action.payload;
      let { childList, childCount } = state;
      if (childItem.name !== null) {
        if (childList && childList.find((element) => element.id === childItem.id) === undefined) {
          childList.push(childItem);
          childCount += 1;
        }

        childList =
          childList &&
          childList.map((listItem) =>
            childItem.id === listItem.id && childItem.locationId && childItem.locationId > 0 && !childItem.isParent
              ? childItem
              : listItem,
          );
      } else {
        childList = childList && childList.filter((listItem) => childItem.id !== listItem.id);
        childList &&
          childList.forEach((listItem) => {
            if (listItem.locationId === childItem.id) {
              listItem.locationId = null;
              listItem.locationName = null;
              listItem.inactiveLocation = null;
            }
          });
        childCount -= 1;
      }
      return {
        ...state,
        childList,
        childCount,
        childItem,
      };
    }
    case LOAD_ONLY_PARENT_LOCATIONS:
      return { ...state, onlyParent: action.payload };
    default:
      return state;
  }
};

export default location;
