import React from 'react';
import PropTypes from 'prop-types';

const DateInput = ({ placeholder, value, onClick, isRange }) => (
  <div className={`input-group add-on search basic-search-form ${isRange ? 'range-picker' : ''}`}>
    <input
      className="form-control input-lg ignore-change date-input"
      placeholder={placeholder}
      type="text"
      value={value || placeholder}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      readOnly
    />
  </div>
);

DateInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isRange: PropTypes.bool,
  value: PropTypes.string,
};

DateInput.defaultProps = {
  value: null,
  isRange: false,
};

export default DateInput;
