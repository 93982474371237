import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';
import { quickCreateLocation, getLocationFullList } from '../../actions/cafm/LocationActions';

import { getFloorOptions } from '../../constants/options';
import LocationEntryView from '../cafm/admin/LocationEntryView';

class AddNewLocationModal extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    initialValues: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    quickCreateLocation: PropTypes.func.isRequired,
    getLocationFullList: PropTypes.func.isRequired,
    locations: PropTypes.arrayOf(PropTypes.shape()),
    createForm: PropTypes.shape(),
  };

  static defaultProps = {
    locations: [],
    createForm: {
      addNewLocationForm: {},
    },
  };

  constructor(props) {
    super(props);

    const floorOptions = getFloorOptions(props.t);
    this.state = {
      display: false,
      floorOptions,
    };
    props.getLocationFullList({ siteId: this.props.initialValues.siteId, isOnlyParent: true });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
    const form = nextProps.createForm.addNewLocationForm;
    if (form.values && nextProps.location) {
      if (nextProps.location.name == form.values.name && form.submitSucceeded) {
        nextProps.createForm.quickAddNewPPMForm.values.location = {
          label: nextProps.location.name,
          value: nextProps.location.id,
        };
        this.props.onModalClose();
      }
    }
  }

  closeModal = () => {
    this.props.onModalClose();
  };

  handleFormSubmit = (values) => {
    const fields = ['name'];
    const newLocation = _.pick(values, fields);
    // if (values.parent !== 'none') {
    //   const parentLocation = _.find(this.props.locations, ['name', values.parent]);
    //   newLocation.locationId = parentLocation && parentLocation.id;
    // }
    newLocation.siteId = this.props.initialValues.siteId;
    newLocation.type = values.type && values.type.value;
    newLocation.locationId = values.parent && values.parent.value;
    this.props.quickCreateLocation(newLocation);
    // this.props.onModalClose();
  };

  render() {
    const { handleSubmit, t, initialValues, locations } = this.props;
    const { display, floorOptions } = this.state;
    // const locationNames = locations.map(location => location.name);
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                  ${!display && 'display-none'}`}
        // onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show modal-template form-horizontal"
          // onClick={(e) => {
          //   e.stopPropagation();
          // }}
        >
          <h2 className="swal2-title">{t('Add New location')}</h2>

          <form>
            <LocationEntryView
              t={t}
              args={{
                floorList: floorOptions,
                parentLocationList: locations || [],
                isParentDropdownShow: true,
              }}
            />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleFormSubmit)}
            >
              {t('Save')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    tokens: { accessToken },
    location: { parent, item, onlyParent },
    form,
  } = state;
  return {
    accessToken,
    location: item,
    locations: onlyParent || [],
    createForm: form,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      quickCreateLocation,
      getLocationFullList,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'addNewLocationForm',
    enableReinitialize: true,
  })(AddNewLocationModal),
);
