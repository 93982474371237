import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { FormInput, SelectDropdown, CreatableSelectDropdown } from '../../form/parts';

import { createAssetGroup, updateAssetGroup } from '../../../actions/cafm/AssetGroupActions';
import { showBodyScroll, hideBodyScroll } from '../../../lib/bodyScrollToggle';
import { bulkCreateScheduleAssetGroup } from '../../../actions/cafm/ScheduleAssetGroupActions';

class AddEditAssetGroupModal extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    createAssetGroup: PropTypes.func.isRequired,
    updateAssetGroup: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    createForm: PropTypes.shape(),
    initialValues: PropTypes.shape().isRequired,
    onModalClose: PropTypes.func.isRequired,
    id: PropTypes.number,
    siteId: PropTypes.number,
    title: PropTypes.string,
    maintenanceScheduleId: PropTypes.number,
    entityType: PropTypes.string,
    bulkCreateScheduleAssetGroup: PropTypes.func,
  };

  static defaultProps = {
    createForm: {
      addEditAssetGroupForm: {},
    },
    maintenanceScheduleId: 0,
    entityType: 'site',
  };

  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      hideBodyScroll();
      this.setState({
        display: true,
      });
    }
  }

  closeModal = () => {
    const { onModalClose } = this.props;
    this.setState({
      display: false,
    });
    showBodyScroll();
    onModalClose();
  };

  handleFormSubmit = (values) => {
    const { name } = values;
    const {
      id,
      siteId,
      createAssetGroup,
      updateAssetGroup,
      maintenanceScheduleId,
      entityType,
      bulkCreateScheduleAssetGroup,
    } = this.props;
    if (entityType === 'maintenanceschedule') {
      let assetGroupId = '';
      if (values && values.assetGroup && Object.keys(values.assetGroup).length > 0) {
        const assetGroups = values.assetGroup.map((data) => data.value);
        assetGroupId = assetGroups.toString();
      }
      bulkCreateScheduleAssetGroup({ assetGroup: assetGroupId, maintenanceScheduleId, siteId }).then(() =>
        this.closeModal(),
      );
    } else {
      const params = {
        name,
        siteId,
      };
      if (id) {
        params.id = id;
        params.locationId = values && values.locationId ? values.locationId : undefined;
        params.status = values && values.status ? values.status : undefined;
        updateAssetGroup(params).then(() => this.closeModal());
      } else {
        params.status = 1; // default Status
        createAssetGroup(params).then(() => this.closeModal());
      }
    }
  };

  render() {
    const { handleSubmit, t, initialValues, title, entityType } = this.props;
    const { display } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                        ${!display && 'display-none'}`}
      >
        <div aria-modal="true" className="swal2-modal swal2-show modal-template form-horizontal">
          <h2 className="swal2-title">{title}</h2>

          <form>
            {entityType === 'maintenanceschedule' && (
              <div>
                <div className="form-group">
                  <label className="col-sm-3 control-label">{t('Asset Group')}</label>

                  <div className="col-sm-9">
                    <Field
                      name="assetGroup"
                      component={CreatableSelectDropdown}
                      drpName="assetGroup"
                      isMulti
                      options={initialValues.assetGroupList.map((data) => {
                        return { name: data.name, id: data.id };
                      })}
                      placeholder={t('Choose asset group')}
                    />
                  </div>
                </div>
              </div>
            )}
            {entityType !== 'maintenanceschedule' && (
              <div>
                <Field label={t('Name *')} component={FormInput} name="name" type="text" placeholder={t('Name')} />
              </div>
            )}
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleFormSubmit)}
            >
              {t('Save')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    tokens: { accessToken },
    form,
  } = state;

  return {
    accessToken,
    createForm: form,
    initialValues: props.initialValues,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createAssetGroup,
      updateAssetGroup,
      bulkCreateScheduleAssetGroup,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'addEditAssetGroupForm',
  })(AddEditAssetGroupModal),
);
