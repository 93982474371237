import {
  LOAD_MANUFACTURER,
  LOAD_MANUFACTURERS,
  LOAD_ALL_MANUFACTURERS,
  LOAD_MANUFACTURER_COUNT,
  SHOW_LOADER,
  HIDE_LOADER,
} from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/manufacturer';

export const loadManufacturer = (manufacturer) => ({
  type: LOAD_MANUFACTURER,
  payload: manufacturer,
});

export const loadManufacturers = (manufacturers) => ({
  type: LOAD_MANUFACTURERS,
  payload: manufacturers,
});

export const loadAllManufacturers = (manufacturers) => ({
  type: LOAD_ALL_MANUFACTURERS,
  payload: manufacturers,
});

export const loadCount = (count) => ({
  type: LOAD_MANUFACTURER_COUNT,
  payload: count,
});

export const createManufacturer = (manufacturer) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/create`, manufacturer)
    .then((response) => {
      dispatch(loadManufacturer(response.data));
      Router.pushRoute('cafm/admin/manufacturer_profile', { manufacturer_id: response.data.uuid });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createManufacturer, manufacturer);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateManufacturer = (manufacturer) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .put(`${routePath}/update`, manufacturer)
    .then((response) => {
      dispatch(loadManufacturer(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateManufacturer, manufacturer);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getManufacturer = (uuid) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-item/${uuid}`)
    .then((response) => {
      dispatch(loadManufacturer(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getManufacturer, uuid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteManufacturer = (uuid, path) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${uuid}`)
    .then(() => {
      const item = {
        uuid,
        name: null,
      };
      dispatch(loadManufacturer(item));
      if (path) {
        Router.pushRoute('cafm/admin/manufacturers');
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteManufacturer, uuid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getManufacturerList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      dispatch(loadManufacturers(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getManufacturerList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getManufacturerFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-full-list`, { params })
    .then((response) => {
      dispatch(loadAllManufacturers(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getManufacturerFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getManufacturerListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list-with-size-multiple-filter`, { params })
    .then((response) => {
      dispatch(loadManufacturers(response.data.rows));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getManufacturerListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
