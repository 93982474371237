import { LOAD_APPROVER, LOAD_APPROVERS, LOAD_APPROVER_COUNT, LOAD_SITE_APPROVERS } from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  list: [],
  siteApproverList: [],
};

const approver = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_APPROVER: {
      const item = action.payload;
      let { list, count } = state;
      if (item.name !== null) {
        if (list.find((element) => element.id === item.id) === undefined) {
          list.push(item);
          count += 1;
        }
        list = list.map((listItem) => (item.id === listItem.id ? item : listItem));
      } else {
        list = list.filter((listItem) => item.id !== listItem.id);
        count -= 1;
      }
      return {
        item,
        list,
        count,
      };
    }
    case LOAD_APPROVERS:
      return { ...state, list: action.payload };
    case LOAD_APPROVER_COUNT:
      return { ...state, count: action.payload };
    case LOAD_SITE_APPROVERS:
      return { ...state, siteApproverList: action.payload };
    default:
      return state;
  }
};

export default approver;
