export const COMPARISON = {
  equal: '=',
  'less than or equal': '<=',
  'greater than or equal': '>=',
  greater: '>',
  less: '<',
  like: 'like',
};

export const AMOUNT_TYPES = {
  amount: true,
  percentage: false,
};

export const TASK_TYPES = {
  maintenance: true,
  reactive: false,
};

export const FILTER_BUILDINGS = {
  name: {
    value: 'name',
    type: 'string',
  },
  address: {
    value: 'addressLine1',
    type: 'string',
  },
  amount: {
    value: 'amount',
    type: 'number',
  },
  'remaining amount': {
    value: 'remainingAmount',
    type: 'number',
  },
  'start date': {
    value: 'startDate',
    type: 'date',
  },
  'end date': {
    value: 'endDate',
    type: 'date',
  },
};

export const FILTER_BUDGET_CATEGORIES = {
  name: {
    value: 'name',
    type: 'string',
  },
  code: {
    value: 'code',
    type: 'string',
  },
};

export const FILTER_BUDGET_CATEGORY_GROUPS = {
  name: {
    value: 'name',
    type: 'string',
  },
};

export const FILTER_BUILDING_BUDGETS = {
  amount: {
    value: 'amount',
    type: 'number',
  },
  'remaining amount': {
    value: 'remainingAmount',
    type: 'number',
  },
  'start date': {
    value: 'startDate',
    type: 'date',
  },
  'end date': {
    value: 'endDate',
    type: 'date',
  },
};

export const FILTER_BUILDING_BUDGET_CATEGORIES = {
  name: {
    value: 'name',
    type: 'string',
  },
  code: {
    value: 'code',
    type: 'string',
  },
  'total amount': {
    value: 'totalAmount',
    type: 'number',
  },
  'planned amount': {
    value: 'plannedAmount',
    type: 'number',
  },
  'unplanned amount': {
    value: 'unplannedAmount',
    type: 'number',
  },
  contingency: {
    value: 'contingency',
    type: 'number',
  },
};

export const FILTER_THRESHOLDS = {
  amountSize: {
    value: 'amountSize',
    type: 'number',
  },
  amountType: {
    value: 'amountType',
    type: 'boolean',
  },
};
