import {
  LOAD_BUILDING_BUDGET,
  LOAD_TASK_BUDGET,
  LOAD_BUILDING_BUDGETS,
  LOAD_COUNT,
  SHOW_LOADER,
  HIDE_LOADER,
} from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/buildingBudget';
const taskPath = '/v2/taskBudget';

export const loadBuildingBudget = (buildingBudget) => ({
  type: LOAD_BUILDING_BUDGET,
  payload: buildingBudget,
});

export const loadTaskBudget = (taskBudget) => ({
  type: LOAD_TASK_BUDGET,
  payload: taskBudget,
});

export const loadBuildingBudgets = (buildingBudgets) => ({
  type: LOAD_BUILDING_BUDGETS,
  payload: buildingBudgets,
});

export const loadCount = (count) => ({
  type: LOAD_COUNT,
  payload: count,
});

export const createBuildingBudget = (buildingBudget) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/create`, buildingBudget)
    .then((response) => {
      Router.pushRoute('building_budget_profile', { bbuuid: response.data.uuid });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createBuildingBudget, buildingBudget);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateBuildingBudget = (buildingBudget) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .put(`${routePath}/update`, buildingBudget)
    .then((response) => {
      dispatch(loadBuildingBudget(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateBuildingBudget, buildingBudget);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getBuildingBudget = (uuid) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-item/${uuid}`)
    .then((response) => {
      dispatch(loadBuildingBudget(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getBuildingBudget, uuid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteBuildingBudget = (uuid, siteId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${uuid}`)
    .then(() => {
      const item = {
        uuid,
        name: null,
        code: null,
      };
      dispatch(loadBuildingBudget(item));
      Router.pushRoute('building_profile', { bid: siteId });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteBuildingBudget, uuid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getBuildingBudgetFullData = (uuid) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-full-data/${uuid}`)
    .then((response) => {
      dispatch(loadBuildingBudget(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getBuildingBudgetFullData, uuid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getBuildingBudgetList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      dispatch(loadBuildingBudgets(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getBuildingBudgetList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getBuildingBudgetListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list-with-size`, { params })
    .then((response) => {
      dispatch(loadBuildingBudgets(response.data.rows));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getBuildingBudgetListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateTaskBudget = (taskBudget) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .put(`${taskPath}/update-budget`, taskBudget)
    .then((response) => {
      dispatch(loadTaskBudget(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateTaskBudget, taskBudget);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
