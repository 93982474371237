import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Router } from '../../../../common/routes';
import {
  getAssetFullList,
  addRemoveAssetToAssetGroup,
  addRemoveAssetToMaintenanceSchedule,
  getAssetListWithSize,
} from '../../../actions/cafm/AssetActions';
import { getSchedule } from '../../../actions/cafm/ScheduleActions';
import PAGE_SIZE from '../../../constants/tabledata/page_size';
import { showBodyScroll, hideBodyScroll } from '../../../lib/bodyScrollToggle';
import { AttachAssetToAssetGroupScheduleView } from '../../cafm/admin';

class AttachAssetToAssetGroupScheduleModal extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    siteId: PropTypes.number.isRequired,
    flag: PropTypes.string.isRequired,
    entityType: PropTypes.string,
    initialValues: PropTypes.shape().isRequired,
    createForm: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    allAssetList: PropTypes.arrayOf(PropTypes.shape()),
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    getAssetFullList: PropTypes.func,
    addRemoveAssetToAssetGroup: PropTypes.func,
    addRemoveAssetToMaintenanceSchedule: PropTypes.func,
    getAssetListWithSize: PropTypes.func,
    getSchedule: PropTypes.func,
  };

  static defaultProps = {
    allAssetList: [],
    createForm: {
      moveAssetGroupLocationForm: {},
    },
    flag: '',
    entityType: '',
  };

  constructor(props) {
    super(props);
    const { siteId } = props;

    this.state = {
      display: false,
    };
    props.getAssetFullList({ siteId });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      hideBodyScroll();
      this.setState({
        display: true,
      });
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    showBodyScroll();
    this.props.onModalClose();
  };

  handleAttachAssetToAssetGroupSchedule = (attach) => {
    // id = if assetGroup then assetGroup.id, if schedule then maintenanceSchedule.id
    const {
      id,
      siteId,
      flag,
      entityType,
      initialValues,
      addRemoveAssetToAssetGroup,
      addRemoveAssetToMaintenanceSchedule,
      getAssetListWithSize,
      getSchedule,
    } = this.props;
    const { asset } = attach;
    const params = {
      id,
      flag,
    };
    const addedAssets = [];
    const removedAssets = [];
    if (asset && asset.length > 0) {
      asset.map((data) => {
        addedAssets.push(data.value);
      });
      params.addedAssets = addedAssets;
    }
    // check if any saved linked asset removed(unticked) from assetGroup
    if (initialValues && initialValues.asset) {
      initialValues.asset.map((item) => {
        const elementIndex = addedAssets.findIndex((x) => x === item.value);
        if (elementIndex < 0) {
          removedAssets.push(item.value);
        }
      });
      params.removedAssets = removedAssets;
    }
    if (flag === 'assetgroup') {
      addRemoveAssetToAssetGroup(params).then(() => {
        this.closeModal();
        if (entityType === 'assetgroupprofile') {
          // reload only asset table
          getAssetListWithSize({
            siteId,
            assetGroupId: id,
            page: 0,
            pageSize: PAGE_SIZE,
            orderField: 'name',
            orderType: 'ASC',
          });
        } else {
          Router.pushRoute('cafm/admin/asset_group_profile', { asset_group_id: id });
        }
      });
    } else if (flag === 'maintenanceschedule') {
      addRemoveAssetToMaintenanceSchedule(params).then(() => {
        this.closeModal();
        if (entityType === 'scheduleprofile') {
          getSchedule(id);
        } else {
          Router.pushRoute('cafm/admin/schedule_profile', { schedule_id: id });
        }
      });
    }
  };

  render() {
    const { initialValues, handleSubmit, t, title, allAssetList, flag } = this.props;
    const { display } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show modal-template large-modal form-horizontal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{title}</h2>

          <form>
            <div className="row row-eq-height">
              <div className="col col-md-12">
                <AttachAssetToAssetGroupScheduleView
                  t={t}
                  args={{
                    allAssetList,
                  }}
                />
              </div>
            </div>
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleAttachAssetToAssetGroupSchedule)}
            >
              {t('Update')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    tokens: { accessToken },
    form,
    asset,
  } = state;
  return {
    accessToken,
    createForm: form,
    initialValues: props.initialValues,
    allAssetList: asset.all || [],
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAssetFullList,
      addRemoveAssetToAssetGroup,
      addRemoveAssetToMaintenanceSchedule,
      getAssetListWithSize,
      getSchedule,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'attachAssetToAssetGrupScheduleForm',
    enableReinitialize: true,
  })(AttachAssetToAssetGroupScheduleModal),
);
