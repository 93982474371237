import React from 'react';
import PropTypes from 'prop-types';

const DescriptionView = ({ label, description }) => (
  <div className="row form-horizontal">
    <div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{label}</label>

        <div className="col-sm-8 profile-values">
          <span dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    </div>
  </div>
);

DescriptionView.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default DescriptionView;
