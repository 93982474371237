import React from 'react';
import PropTypes from 'prop-types';

import Pagination from './Pagination';
import TableBody from './TableBody';
import TableHeader from './TableHeader';

// import { TableHeader, TableBody, Pagination } from './index';

const Table = ({
  headers,
  rows,
  intableMenu,
  onSort,
  count,
  page,
  maxpages,
  onPageClick,
  funcs,
  paginate,
  t,
  typeId,
  pageSize,
  useUuid,
  navParams,
  showIntable,
  onMonthAmountClick,
  navigateOnClick,
  taskNavParams,
  extended,
  iconOnClick,
  multiDeleteMode,
  multiArchiveMode,
  getSelectedValue,
  isCheckBoxOnEachRow,
  userId,
}) => (
  <div className="dataTables_wrapper p-sm">
    <div className="dataTables_wrapper no-footer">
      <div>
        <div className="dataTables_scroll">
          <TableHeader
            headers={headers}
            onSort={onSort}
            t={t}
            extended={extended}
            showIntable={showIntable}
            multiDeleteMode={multiDeleteMode || multiArchiveMode}
            isCheckBoxOnEachRow={isCheckBoxOnEachRow}
          />

          <TableBody
            headers={headers}
            rows={rows}
            intableMenu={intableMenu}
            funcs={funcs}
            typeId={typeId}
            t={t}
            extended={extended}
            useUuid={useUuid}
            navParams={navParams}
            showIntable={showIntable}
            onMonthAmountClick={onMonthAmountClick}
            taskNavParams={taskNavParams}
            navigateOnClick={navigateOnClick}
            iconOnClick={iconOnClick}
            multiDeleteMode={multiDeleteMode}
            multiArchiveMode={multiArchiveMode}
            getSelectedValue={getSelectedValue}
            isCheckBoxOnEachRow={isCheckBoxOnEachRow}
            userId={userId}
          />
        </div>

        {paginate && (
          <Pagination
            page={page}
            count={count}
            maxpages={maxpages}
            onPageClick={onPageClick}
            pageSize={pageSize}
            t={t}
          />
        )}
      </div>
    </div>
  </div>
);

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  intableMenu: PropTypes.arrayOf(PropTypes.shape()),
  navParams: PropTypes.shape(),
  onMonthAmountClick: PropTypes.func,
  navigateOnClick: PropTypes.func,
  useUuid: PropTypes.bool,
  t: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  onSort: PropTypes.func,
  page: PropTypes.number,
  count: PropTypes.number,
  maxpages: PropTypes.number,
  onPageClick: PropTypes.func,
  funcs: PropTypes.arrayOf(PropTypes.func),
  paginate: PropTypes.bool,
  showIntable: PropTypes.bool,
  typeId: PropTypes.string,
  extended: PropTypes.bool,
  taskNavParams: PropTypes.arrayOf(PropTypes.string),
  iconOnClick: PropTypes.func,
  getSelectedValue: PropTypes.func,
  multiDeleteMode: PropTypes.bool,
  multiArchiveMode: PropTypes.bool,
  isCheckBoxOnEachRow: PropTypes.bool,
  userId: PropTypes.number,
};

Table.defaultProps = {
  count: 0,
  funcs: [],
  intableMenu: [],
  paginate: true,
  page: 0,
  pageSize: 50,
  maxpages: 1,
  onPageClick: undefined,
  onSort: undefined,
  onMonthAmountClick: () => {},
  navigateOnClick: () => {},
  typeId: null,
  useUuid: false,
  extended: false,
  navParams: {},
  showIntable: true,
  taskNavParams: [],
  iconOnClick: () => {},
  getSelectedValue: () => {},
  multiDeleteMode: false,
  multiArchiveMode: false,
  isCheckBoxOnEachRow: false,
  userId: 0,
};

export default Table;
