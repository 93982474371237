import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';
import AddNewTaskModal from '../AddNewTaskModal';
import { createScheduleTask, updateScheduleTask } from '../../../actions/cafm/ScheduleTaskActions';
import { getTaskCategoryFullList } from '../../../actions/cafm/TaskCategoryActions';
// import { getTaskFullList } from '../../../actions/cafm/TaskActions';
import { showBodyScroll, hideBodyScroll } from '../../../lib/bodyScrollToggle';
import { getRepeatOptions, getPeriodOptions } from '../../../constants/options';
import { getFullUserListbyAccountId } from '../../../actions/UserActions';
import { ScheduleTaskEntryView } from '../../cafm/admin';
import commonFunction from '../../../lib/commonFunction';

class AddEditScheduleTaskModal extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    initialValues: PropTypes.shape().isRequired,
    createForm: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    taskCategories: PropTypes.arrayOf(PropTypes.shape()),
    tasks: PropTypes.arrayOf(PropTypes.shape()),
    internalCompanies: PropTypes.arrayOf(PropTypes.shape()),
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    createScheduleTask: PropTypes.func,
    updateScheduleTask: PropTypes.func,
    users: PropTypes.arrayOf(PropTypes.shape()),
    getFullUserListbyAccountId: PropTypes.func,
    title: PropTypes.string,
    maintenanceScheduleId: PropTypes.number,
    siteId: PropTypes.number,
  };

  static defaultProps = {
    maintenanceScheduleId: 0,
    siteId: 0,
    taskCategories: [],
    tasks: [],
    internalCompanies: [],
    createForm: {
      addEditScheduleTaskForm: {},
    },
    users: [],
    getFullUserListbyAccountId: undefined,
  };

  constructor(props) {
    super(props);
    const repeatOptions = getRepeatOptions(props.t);
    const periodOptions = getPeriodOptions(props.t);
    const { internalCompanies, initialValues, taskCategories } = props;
    const chosenCompany = _.find(internalCompanies, ['id', initialValues.company && initialValues.company.value]);
    const contacts = (chosenCompany && chosenCompany.InternalContact) || [];

    this.state = {
      display: false,
      // taskCategories: taskCategories || [],
      categoryId:
        initialValues && initialValues.Category && initialValues.Category.value && props.id > 0
          ? initialValues.Category.value
          : 0,
      // tasks: tasks || [],
      taskId:
        initialValues && initialValues.task && initialValues.task.value && props.id > 0 ? initialValues.task.value : 0,
      companies: internalCompanies || [],
      repeatEvery: '',
      repeatPeriod: '',
      contacts: contacts || [],
      repeatOptions,
      overrideRepeatFrequency: false,
      periodOptions,
      overrideLabourFrequency: false,
      labourRequired: '',
      labourPeriod: '',
      isLastTaskDisable: initialValues && initialValues.lastTask && initialValues.lastTask !== '',
      overrideTaskDescription: false,
      addNewTaskLibraryModal: {
        open: false,
        initialValues: {},
      },
    };
    props.getTaskCategoryFullList({ includeTaskLibrary: true });
    props.getFullUserListbyAccountId();
    // props.getTaskFullList({ siteId: props.siteId });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      hideBodyScroll();
      this.setState({
        display: true,
      });
    }

    const form = nextProps.createForm.addEditScheduleTaskForm;
    const prevForm = this.props.createForm.addEditScheduleTaskForm;
    if (_.isUndefined(nextProps.id) || _.isNull(nextProps.id) || nextProps.id === '' || nextProps.id == '0') {
      if (
        form &&
        form.values &&
        form.values.Category &&
        form.values.Category !== (prevForm && prevForm.values && prevForm.values.Category)
      ) {
        const { value } = form.values.Category;
        const chosenTaskCategory = _.find(nextProps.taskCategories, ['id', value]);
        this.setState({
          categoryId: value || 0,
        });
        if (chosenTaskCategory) {
          if (nextProps.tasks) {
            const taskList = _.filter(nextProps.tasks, ['taskCategoryId', chosenTaskCategory.id]);
            if (prevForm && prevForm.values && form && form.values) {
              if (
                form.values.Category &&
                prevForm.values.Category &&
                form.values.Category !== prevForm.values.Category
              ) {
                form.values.task = '';
              }
            }
            this.setState({
              tasks: taskList,
            });
          } else {
            this.setState({
              tasks: [],
              repeatEvery: undefined,
              repeatPeriod: undefined,
              labourRequired: undefined,
              labourPeriod: undefined,
              taskId: 0,
            });
          }
        } else {
          // form.values.task = '';
          this.setState({
            tasks: [],
            repeatEvery: undefined,
            repeatPeriod: undefined,
            labourRequired: undefined,
            labourPeriod: undefined,
            taskId: 0,
          });
        }
      } else if (form.values && !form.values.Category) {
        // if Category becomes null/cross/remove then clear Tasks dropdown as it not clears Task.
        // So selected value in task shows selected value of previous category if you select
        form.values.task = '';
        if (prevForm && prevForm.values) prevForm.values.task = '';
        this.setState({
          tasks: [],
          repeatEvery: undefined,
          repeatPeriod: undefined,
          labourRequired: undefined,
          labourPeriod: undefined,
          taskId: 0,
        });
      }
    }
    if (nextProps.id && nextProps.id > 0) {
      this.setState({
        tasks: nextProps.tasks || [],
      });
    }
    // // if (nextProps.id && nextProps.id > 0
    // //   && (form && form.values && form.values.Category && form.values.Category === (prevForm && prevForm.values && prevForm.values.Category))) {
    // //   const { value } = form.values.Category;
    // //   const chosenTaskCategory = _.find(nextProps.taskCategories, ['id', value]);
    // //   this.setState({
    // //     categoryId: value || 0,
    // //   });
    // //   if (chosenTaskCategory) {
    // //     if (nextProps.tasks) {
    // //       const taskList = _.filter(nextProps.tasks, ['taskCategoryId', chosenTaskCategory.id]);
    // //       this.setState({
    // //         tasks: taskList,
    // //       });
    // //     }
    // //   }
    // // }

    if (form.values && !_.isUndefined(form.values.task) && !_.isNull(form.values.task) && form.values.task !== '') {
      const chosenTask = form.values.task.info;
      this.setState({
        repeatEvery: chosenTask && chosenTask.repeatEvery,
        repeatPeriod: chosenTask && chosenTask.repeatPeriod,
        taskId: chosenTask && chosenTask.id,
        labourRequired: chosenTask && chosenTask.labourRequired,
        labourPeriod: chosenTask && chosenTask.labourPeriod,
      });
      if (
        prevForm &&
        prevForm.values &&
        (prevForm.values.task.value !== form.values.task.value || (!prevForm.values.task && form.values.task))
      ) {
        form.values.taskDescription = chosenTask && chosenTask.description;
      }
    } else {
      form.values.task = '';
      form.values.userAssignee = '';
      form.values.company = '';
      form.values.contact = '';
      form.values.taskDescription = '';
      this.setState({
        repeatEvery: undefined,
        repeatPeriod: undefined,
        labourRequired: undefined,
        labourPeriod: undefined,
        taskId: 0,
      });
      this.props.change('overrideTaskDescription', false);
    }
    let subcontract = null;
    if (prevForm && prevForm.values && form && form.values) {
      if (
        (prevForm.values.company && prevForm.values.company !== form.values.company) ||
        (!prevForm.values.company && form.values.company)
      ) {
        if (form.values.company) {
          const chosenCompany = _.find(this.props.internalCompanies, ['id', form.values.company.value]);
          if (chosenCompany && chosenCompany.InternalContact) {
            const contacts = chosenCompany.InternalContact;
            this.props.change('contact', null);
            this.setState({
              contacts,
            });
          }
        } else {
          this.props.change('contact', null);
          this.setState({
            contacts: [],
          });
        }
      }
    }

    if (form && form.values && (form.values.company || form.values.userAssignee)) {
      if (form.values.company && !form.values.userAssignee) subcontract = true;
      else if (!form.values.company && form.values.userAssignee) subcontract = false;
    }
    if (!subcontract) {
      this.props.change('notifySubcontractor', false);
    }
    let overrideRepeatFrequency = false;
    if (form.values && form.values.overrideRepeatFrequency) {
      overrideRepeatFrequency = true;
    }
    let overrideLabourFrequency = false;
    if (form.values && form.values.overrideLabourFrequency) {
      overrideLabourFrequency = true;
    }
    let overrideTaskDescription = false;
    if (form.values && form.values.overrideTaskDescription) {
      overrideTaskDescription = true;
    }
    this.setState({
      overrideRepeatFrequency,
      subcontract,
      overrideLabourFrequency,
      overrideTaskDescription,
    });
  }

  handleOnChangeCategory = (name) => {
    const form = this.props.createForm.addEditScheduleTaskForm.values;
    let chosenCategorieid = 0;
    if (form.Category !== undefined && form.Category !== '') {
      chosenCategorieid = form.Category.value;
      this.setState({
        categoryId: chosenCategorieid || 0,
      });
    }

    // this.props.getTaskFullList({ taskCategoryId: chosenCategorieid || 0 });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
    showBodyScroll();
    this.props.onModalClose();
  };

  handleTaskLibraryAddNew = () => {
    let chosenTaskCategoryId = 0;
    const form = this.props.createForm.addEditScheduleTaskForm.values;
    if (form.Category !== undefined) {
      chosenTaskCategoryId = form.Category.value;
    }
    if (chosenTaskCategoryId > 0) {
      this.setState({
        addNewTaskLibraryModal: {
          open: true,
          initialValues: {
            isTaskSimpleView: false,
            taskCategoryId: chosenTaskCategoryId,
          },
        },
      });
    }
  };

  handleTaskModalClose = () => {
    this.setState({
      display: false,
      addNewTaskLibraryModal: {
        open: false,
        initialValues: {
          siteId: null,
        },
      },
    });
  };

  handleAddUpdateScheduleTask = (item) => {
    const { id, siteId, maintenanceScheduleId, createScheduleTask, updateScheduleTask } = this.props;
    const {
      task,
      company, // supplierCompany
      contact, // supplierContact
      subcontract,
      active,
      firstTask,
      lastTask,
      overrideRepeatFrequency,
      notifySubcontractor,
      repeatEvery,
      repeatPeriod,
      userAssignee, // userAssignee
      overrideLabourFrequency,
      labourRequired,
      labourPeriod,
      overrideTaskDescription,
    } = item;

    const contentOnly = item && item.taskDescription ? item.taskDescription.replace(/<(?:.|\n)*?>/gm, '') : '';
    if (contentOnly !== undefined && contentOnly.trim() === '') {
      item.taskDescription = '';
    }
    const params = {
      maintenanceScheduleId,
      siteId,
      taskLibraryId: task && typeof task === 'object' ? task.value : null,
      supplierCompanyId: company && typeof company === 'object' ? company.value : null,
      supplierContactId: contact && typeof contact === 'object' ? contact.value : null,
      userAssigneeId: userAssignee && typeof userAssignee === 'object' ? userAssignee.value : null,
      subcontract,
      enabled: active,
      firstTaskDate: commonFunction.getDateWithValidFormat(firstTask),
      lastTaskDate: commonFunction.getDateWithValidFormat(lastTask),
      overrideRepeatFrequency,
      notifySubcontractor,
      repeatEvery: overrideRepeatFrequency ? repeatEvery : undefined,
      repeatPeriod: overrideRepeatFrequency ? repeatPeriod.value : undefined,
      overrideLabourFrequency,
      labourRequired: overrideLabourFrequency ? labourRequired : undefined,
      labourPeriod: overrideLabourFrequency ? labourPeriod.value : undefined,
      taskRepeatEvery: this.state.repeatEvery,
      taskRepeatPeriod: this.state.repeatPeriod,
      overrideTaskDescription,
      taskDescription: overrideTaskDescription ? item.taskDescription : undefined,
    };
    if (!_.isUndefined(id) && !_.isNull(id) && id !== '') {
      params.id = id;
      updateScheduleTask(params);
    } else {
      createScheduleTask(params);
    }
    this.closeModal();
  };

  render() {
    const { handleSubmit, t, users, title, initialValues, taskCategories, id } = this.props;
    const {
      display,
      companies,
      contacts,
      repeatEvery,
      repeatPeriod,
      subcontract,
      repeatOptions,
      overrideRepeatFrequency,
      periodOptions,
      overrideLabourFrequency,
      labourRequired,
      labourPeriod,
      categoryId,
      taskId,
      isLastTaskDisable,
      tasks,
      overrideTaskDescription,
      addNewTaskLibraryModal,
    } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                  ${!display && 'display-none'}`}
        // onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show modal-template form-horizontal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{title}</h2>

          <form>
            <ScheduleTaskEntryView
              t={t}
              args={{
                showCategory: !id || id <= 0,
                categoryList: taskCategories,
                onChangeCategory: this.handleOnChangeCategory,
                taskList: tasks,
                repeatEvery,
                repeatPeriod,
                overrideRepeatFrequency,
                repeatOptionList: repeatOptions,
                userList: users,
                subcontract,
                companyList: companies,
                contactList: contacts,
                showContacts: true,
                overrideLabourFrequency,
                periodOptionList: periodOptions,
                labourRequired,
                labourPeriod,
                categoryId,
                taskId,
                // showLastTaskDate: (id && id > 0 ? false : true),
                isLastTaskDisable,
                overrideTaskDescription,
                isNewMode: !id || id <= 0,
                onClickNewTask: this.handleTaskLibraryAddNew,
              }}
            />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleAddUpdateScheduleTask)}
            >
              {t('Save')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
        {addNewTaskLibraryModal.open && (
          <AddNewTaskModal
            open={addNewTaskLibraryModal.open}
            onModalClose={this.handleTaskModalClose}
            initialValues={addNewTaskLibraryModal.initialValues}
            t={t}
            isOpenFromScheduleTask
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    tokens: { accessToken },
    form,
    taskCategory: { all },
    task,
    user,
  } = state;
  return {
    accessToken,
    initialValues: props.initialValues,
    taskCategories: all,
    tasks: task.all,
    createForm: form,
    users: user.list,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTaskCategoryFullList,
      // getTaskFullList,
      createScheduleTask,
      updateScheduleTask,
      getFullUserListbyAccountId,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'addEditScheduleTaskForm',
    enableReinitialize: true,
  })(AddEditScheduleTaskModal),
);
