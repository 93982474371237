import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class ReduxFormSelect extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    input: PropTypes.shape().isRequired,
    onSelect: PropTypes.func,
    width: PropTypes.string,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.string,
    hasBlank: PropTypes.bool,
  };

  static defaultProps = {
    width: '100%',
    onSelect: () => {},
    disabled: false,
    defaultValue: undefined,
    hasBlank: true,
  };

  constructor(props) {
    super(props);

    if (props.defaultValue) {
      props.input.onChange(props.defaultValue);
    }

    this.state = {
      isShown: false,
    };
  }

  toggleOptions = () => {
    this.setState({ isShown: !this.state.isShown });
  };

  handleOnChange = (option) => {
    const { onChange } = this.props.input;
    this.setState({ isShown: false });
    onChange(option);
    setTimeout(() => {
      this.props.onSelect(option);
    }, 0);
  };

  handleOnMouseLeave = () => {
    this.setState({ isShown: false });
  };

  render() {
    const {
      placeholder,
      width,
      options,
      disabled,
      hasBlank,
      input: { value },
    } = this.props;
    const { isShown } = this.state;
    return (
      <div
        className={classNames('btn-group bootstrap-select form-control bs-select input-lg', { open: isShown })}
        title={value || placeholder}
        style={{ width }}
        onMouseLeave={this.handleOnMouseLeave}
      >
        <button
          className={classNames('btn dropdown-toggle btn-default btn-lg', { 'bs-placeholder': !value })}
          type="button"
          onClick={this.toggleOptions}
          disabled={disabled}
        >
          <span className="filter-option pull-left">{value || placeholder}</span>
          &nbsp;
          <span className="bs-caret">
            <span className="caret" />
          </span>
        </button>

        <div className="dropdown-menu open">
          <ul className="dropdown-menu inner" role="listbox" aria-expanded={isShown}>
            {hasBlank && (
              <li key={null} className={value === '' ? 'selected active' : ''}>
                <a
                  aria-selected={value === null}
                  onClick={() => {
                    this.handleOnChange('');
                  }}
                >
                  <span className="text" />
                </a>
              </li>
            )}
            {options.map((option) => (
              <li key={option} className={value === option ? 'selected active' : ''}>
                <a
                  aria-selected={value === option}
                  onClick={() => {
                    this.handleOnChange(option);
                  }}
                >
                  <span className="text">{option}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default ReduxFormSelect;
