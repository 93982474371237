import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

class ReduxFormMultiSelect extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    input: PropTypes.shape().isRequired,
    width: PropTypes.string,
    disabled: PropTypes.bool,
    disableCreate: PropTypes.bool,
    useSingleValue: PropTypes.bool,
    _value: PropTypes.string,
    label: PropTypes.string,
    savedOptions: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    width: '100%',
    disabled: false,
    disableCreate: false,
    useSingleValue: false,
    _value: 'value',
    label: 'label',
    savedOptions: [],
  };

  constructor(props) {
    super(props);
    const { options, input, savedOptions } = props;
    let newOptions = options;
    if (input.value) {
      newOptions = _.differenceWith(options, input.value, _.isEqual);
    } else if (savedOptions && Object.keys(savedOptions).length > 0) {
      newOptions = _.differenceWith(options, savedOptions, _.isEqual);
    }
    this.state = {
      isShown: false,
      options: newOptions,
      chosenOptions: props.input.value || savedOptions || [],
      text: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    const diffOptions = _.differenceWith(nextProps.options, this.props.options, _.isEqual);
    if (diffOptions.length !== 0) {
      this.setState({
        options: nextProps.options,
        chosenOptions: [],
      });
    }
  }

  toggleOptions = () => {
    this.setState({ isShown: !this.state.isShown });
  };

  handleOnChange = (clickedOption) => {
    const { input, label } = this.props;
    let { chosenOptions } = this.state;
    const { options } = this.state;
    if (this.props.useSingleValue) {
      chosenOptions.forEach((option) => {
        options.push(option);
      });
      chosenOptions = [];
    }
    chosenOptions.push(clickedOption);
    const newOptions = options.filter((option) => option[label] !== clickedOption[label]);
    this.setState({
      isShown: false,
      chosenOptions,
      options: newOptions,
    });
    input.onChange(chosenOptions);
  };

  handleOnMouseLeave = () => {
    const { isShown } = this.state;
    if (isShown) {
      this.setState({ isShown: false });
    }
  };

  handleInputChange = (e) => {
    const inputText = e.target.value;
    const { options, input, label, _value } = this.props;
    const { chosenOptions } = this.state;
    const newOptions = options.filter(
      (option) => option[label].indexOf(inputText) !== -1 && _.findIndex(chosenOptions, [label, option[label]]),
    );
    if (chosenOptions && chosenOptions.length === 0) {
      input.onChange([
        {
          [label]: inputText,
          [_value]: inputText,
        },
      ]);
    }
    this.setState({
      options: newOptions,
      text: inputText,
    });
  };

  handleKeyPress = (e) => {
    if (this.props.disableCreate) {
      return;
    }
    const code = e.charCode || e.keyCode;
    const { text, chosenOptions } = this.state;
    const { options, label, _value } = this.props;
    const newOptions = options.filter((option) => _.findIndex(chosenOptions, [label, option[label]]));
    if (code === 13) {
      chosenOptions.push({
        [label]: text,
        [_value]: text,
      });
      this.setState({
        chosenOptions,
        options: newOptions,
      });
    }
  };

  handleRemoveOption = (option, index, e) => {
    const { input } = this.props;
    e.stopPropagation();
    const { chosenOptions, options } = this.state;
    if (options.indexOf(option) === -1) {
      options.push(option);
    }
    chosenOptions.splice(index, 1);
    this.setState({
      chosenOptions,
      options,
    });
    input.onChange(chosenOptions);
  };

  render() {
    const { placeholder, width, disabled, useSingleValue, input, _value, label } = this.props;
    const { isShown, options, chosenOptions } = this.state;
    return (
      <div
        className={classNames('btn-group bootstrap-select form-control bs-select input-lg', {
          open: isShown,
          'multi-select-height': chosenOptions.length > 0 && !useSingleValue,
        })}
        title={input.value || placeholder}
        style={{ width }}
        onMouseLeave={this.handleOnMouseLeave}
      >
        <button
          className={classNames('btn dropdown-toggle btn-default btn-lg', { 'bs-placeholder': !input.value })}
          type="button"
          onClick={this.toggleOptions}
          disabled={disabled}
        >
          <div className="multi-select-data">
            {chosenOptions &&
              chosenOptions.map((option, index) => (
                <div
                  className={classNames({
                    'single-value-height': useSingleValue,
                  })}
                  key={option[_value]}
                  onClick={(e) => {
                    this.handleRemoveOption(option, index, e);
                  }}
                >
                  <div>{option[label]}</div>
                  <div>&nbsp;x</div>
                </div>
              ))}
            {chosenOptions && (chosenOptions.length === 0 || !useSingleValue) && (
              <input
                className="multi-select-input"
                placeholder={placeholder}
                type="text"
                onChange={this.handleInputChange}
                onKeyPress={this.handleKeyPress}
              />
            )}
          </div>
          <span className="bs-caret">
            <span className="caret" />
          </span>
        </button>

        <div className="dropdown-menu open">
          <ul className="dropdown-menu inner" role="listbox" aria-expanded={isShown}>
            {options.map((option) => (
              <li key={option[_value]} className={input.value === option[label] ? 'selected active' : ''}>
                <a
                  aria-selected={input.value === option[label]}
                  onClick={() => {
                    this.handleOnChange(option);
                  }}
                >
                  <span className="text">{option[label]}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default ReduxFormMultiSelect;
