import { legacy_createStore as createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';

import reducers from '../reducers';

let reduxStore = null;
let checkWindow = false;
let checkDevTools = false;
let checkDevMode = false;

if (typeof window !== 'undefined') {
  checkWindow = window && typeof window === 'object';
  checkDevTools = window && typeof window.devToolsExtension !== 'undefined';
  checkDevMode = process.env.NODE_ENV === 'development';
}
const useDevTools = checkWindow && checkDevTools && checkDevMode;
const devTools = useDevTools ? window.devToolsExtension() : (f) => f;

const create = (initialState) =>
  createStore(
    combineReducers({
      // Setup reducers
      ...reducers,
    }),
    initialState, // Hydrate the store with server-side data
    compose(applyMiddleware(thunkMiddleware), devTools),
  );

const initRedux = (initialState) => {
  if (!typeof window === 'undefined') {
    return create(initialState);
  }

  if (!reduxStore) {
    reduxStore = create(initialState);
  }

  return reduxStore;
};
export { reduxStore };
export default initRedux;
