import _ from 'lodash';
import { SHOW_ERROR_MODAL, HIDE_ERROR_MODAL, SHOW_SUCCESS_MODAL } from '../actions/types';

const initialValue = {
  isShown: false,
  errorList: null,
  title: 'Error',
};

const errors = (state = initialValue, action) => {
  switch (action.type) {
    case SHOW_ERROR_MODAL: {
      let checkAccessTokenError = false;
      let errorList = action.payload;
      if (_.isArray(errorList)) {
        errorList.forEach((item) => {
          if (item.param === 'accessToken') {
            checkAccessTokenError = true;
          }
        });
      } else {
        errorList = [];
      }
      const isShown = !checkAccessTokenError;
      return { ...state, isShown, errorList, title: 'Error' };
    }
    case HIDE_ERROR_MODAL:
      return { ...state, isShown: false, errorList: null };

    case SHOW_SUCCESS_MODAL: {
      let checkAccessTokenError = false;
      let errorList = action.payload;
      if (_.isArray(errorList)) {
        errorList.forEach((item) => {
          if (item.param === 'accessToken') {
            checkAccessTokenError = true;
          }
        });
      } else {
        errorList = [];
      }
      const isShown = !checkAccessTokenError;
      return { ...state, isShown, errorList, title: 'Success' };
    }

    default:
      return state;
  }
};

export default errors;
