import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { Table } from '../table';
import { SelectDropdown } from '../form/parts';

const ErrorModal = ({
  errorList,
  isShown,
  closeModal,
  tableData,
  tableScale,
  t,

  isDropdownToShow,
  selectionData,
  onSubmitClick,
  title,
  isShowOkCancel,
}) => (
  <div
    role="button"
    onKeyPress={() => {}}
    className={['swal2-container', 'swal2-center', 'swal2-shown', !isShown ? ' display-none' : ''].join(' ')}
    onClick={closeModal}
  >
    <div
      className={`swal2-modal swal2-show ${tableScale ? 'error-modal-large' : 'error-modal'}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={isDropdownToShow ? { height: `${650}px` } : null}
    >
      <h2 className="swal2-title">{title}</h2>

      <div className="swal2-content" style={{ display: 'block', maxHeight: '500px', overflowY: 'scroll' }}>
        <ul className="list-group">
          {errorList &&
            errorList.map((item, index) => (
              <li key={item.message} className="list-group-item">
                <span>{`${item.param ? `${item.param}: ` : ''}${item.message}`}</span>

                {item.entityList && item.selectionList && isDropdownToShow == true && selectionData[index].rows && (
                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {item.entityType === 'childLocation'
                        ? 'Reassign to Location'
                        : item.entityType === 'childTaskCompliance'
                        ? 'Reassign to Parent Compliance'
                        : item.entityType === 'complianceLinkedTasks'
                        ? 'Reassign to Compliance'
                        : 'Reassign To Task Category'}
                    </label>

                    <div className="col-sm-6">
                      <Field
                        name="id"
                        component={SelectDropdown}
                        options={selectionData[index].rows}
                        _optionLabel="name"
                        _optionValue="id"
                        placeholder={
                          item.entityType === 'childLocation'
                            ? 'Select Location'
                            : item.entityType === 'childTaskCompliance' || item.entityType === 'complianceLinkedTasks'
                            ? 'Select Compliance'
                            : 'Select Task Category'
                        }
                      />
                    </div>
                  </div>
                )}

                {item.entityList && (
                  <Table
                    headers={tableData[index].headers}
                    rows={tableData[index].rows}
                    showIntable={false}
                    t={t}
                    paginate={false}
                  />
                )}
              </li>
            ))}
        </ul>
      </div>

      {/* put existing Ok inside condition isDropdownToShow == false. as if simple error or error with list then just to show ok */}

      {((isDropdownToShow == true && selectionData[0].rows) || isShowOkCancel) && (
        <div className="swal2-buttonswrapper display-block">
          <button className="swal2-confirm swal2-styled btn-primary" onClick={onSubmitClick} type="submit">
            Ok
          </button>
          &nbsp;
          <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={closeModal}>
            Cancel
          </button>
        </div>
      )}

      {isDropdownToShow === false && isShowOkCancel === false && (
        <div className="swal2-buttonswrapper display-block">
          <button className="swal2-confirm swal2-styled btn-primary" onClick={closeModal}>
            Ok
          </button>
        </div>
      )}
    </div>
  </div>
);

ErrorModal.propTypes = {
  errorList: PropTypes.arrayOf(
    PropTypes.shape({
      param: PropTypes.string,
      message: PropTypes.string,
      entityList: PropTypes.arrayOf(PropTypes.shape()),
    }),
  ),
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      headers: PropTypes.arrayOf(PropTypes.shape()),
      rows: PropTypes.arrayOf(PropTypes.shape()),
    }),
  ),
  tableScale: PropTypes.bool.isRequired,
  isShown: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,

  onSubmitClick: PropTypes.func,
  isDropdownToShow: PropTypes.bool,
  selectionData: PropTypes.arrayOf(
    PropTypes.shape({
      rows: PropTypes.arrayOf(PropTypes.shape()),
    }),
  ),

  isShowOkCancel: PropTypes.bool,
};

ErrorModal.defaultProps = {
  errorList: null,
  tableData: [],
  selectionData: [],
  isDropdownToShow: false,
  isShowOkCancel: false,
};

export default ErrorModal;
