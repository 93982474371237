import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { SelectDropdown } from '../../form/parts';

const MoveAssetGroupLocationView = ({ t, args }) => (
  <div>
    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Parent Location')}</label>

      <div className="col-sm-8">
        <Field
          name="parentLocation"
          component={SelectDropdown}
          drpName="parentLocation"
          options={args.parentLocationList.map((data) => {
            return { name: data.name, id: data.id, isDisabled: data.disabled };
          })}
          _optionLabel="name"
          _optionValue="id"
          placeholder={t('Parent Location')}
        />
      </div>
    </div>

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Child Location')}</label>

      <div className="col-sm-8">
        <Field
          name="childLocation"
          component={SelectDropdown}
          drpName="childLocation"
          options={args.childLocationList.map((data) => {
            return { name: data.name, id: data.id, isDisabled: data.disabled };
          })}
          _optionLabel="name"
          _optionValue="id"
          placeholder={t('Child Location')}
        />
      </div>
    </div>

    {args.isAssetMove && (
      <div className="form-group">
        <label className="col-sm-4 control-label">{t('Asset Group')}</label>

        <div className="col-sm-8">
          <Field
            name="assetGroup"
            component={SelectDropdown}
            drpName="assetGroup"
            options={args.assetGroupList.map((data) => {
              return { name: data.name, id: data.id };
            })}
            _optionLabel="name"
            _optionValue="id"
            placeholder={t('Asset Group')}
          />
        </div>
      </div>
    )}
  </div>
);

const assetGroupLocationProps = PropTypes.shape({
  parentLocationList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  childLocationList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  assetGroupList: PropTypes.arrayOf(PropTypes.shape()),
  isAssetMove: PropTypes.bool,
}).isRequired;

MoveAssetGroupLocationView.propTypes = {
  args: assetGroupLocationProps,
  t: PropTypes.func.isRequired,
};

MoveAssetGroupLocationView.defaultProps = {
  args: {
    parentLocationList: [],
    childLocationList: [],
    assetGroupList: [],
    isAssetMove: false,
  },
};

export default MoveAssetGroupLocationView;
