import { LOAD_SITE, LOAD_SITES, LOAD_COUNT } from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  list: [],
};

const site = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_SITE: {
      const item = action.payload;
      let { list, count } = state;
      if (item.name !== null) {
        list = list.map((listItem) => (item.id === listItem.id ? item : listItem));
      } else {
        list = list.filter((listItem) => item.id !== listItem.id);
        count -= 1;
      }
      return { item, list, count };
    }
    case LOAD_SITES:
      return { ...state, list: action.payload };
    case LOAD_COUNT:
      return { ...state, count: action.payload };
    default:
      return state;
  }
};

export default site;
