import { LOAD_CUSTOMER_LIST } from '../../actions/types';

const INITIAL_STATE = {
  customerList: [],
};

const internalCompany = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_CUSTOMER_LIST:
      return { ...state, customerList: action.payload };

    default:
      return state;
  }
};

export default internalCompany;
