import {
  LOAD_SCHEDULE,
  LOAD_SCHEDULES,
  LOAD_ALL_SCHEDULES,
  LOAD_SCHEDULE_COUNT,
  LOAD_SCHEDULE_WITH_TASK,
  LOAD_SCHEDULETASK_COUNT_BY_MAINTENANCESCHEDULE,
  LOAD_SCHEDULE_DELETE_POPUP_ON,
  LOAD_SCHEDULE_DELETE_POPUP_OFF,
  REMOVE_SCHEDULE,
} from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  list: [],
  all: [],
  scheduleTaskCountByMS: null,
  schedulePopDelete: false,
};

const schedule = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_SCHEDULE: {
      const item = action.payload;
      let { list, count, all } = state;
      if (item.name !== null) {
        list = list.map((listItem) => (item.id === listItem.id ? item : listItem));
        all = all.map((listItem) => (item.id === listItem.id ? item : listItem));
      } else {
        list = list.filter((listItem) => item.id !== listItem.id);
        all = all.filter((listItem) => item.id !== listItem.id);
        count -= 1;
      }
      return {
        item,
        list,
        count,
        all,
      };
    }
    case LOAD_SCHEDULES:
      return { ...state, list: action.payload };
    case LOAD_ALL_SCHEDULES:
      return { ...state, all: action.payload };
    case LOAD_SCHEDULE_COUNT:
      return { ...state, count: action.payload };
    case LOAD_SCHEDULE_DELETE_POPUP_ON:
      return { ...state, schedulePopDelete: true };
    case LOAD_SCHEDULE_DELETE_POPUP_OFF:
      return { ...state, schedulePopDelete: false };

    case LOAD_SCHEDULE_WITH_TASK: {
      const actionItem = action.payload; // it will be array
      let { list, item, all } = state;
      if (actionItem && actionItem.length > 0) {
        actionItem.map((ai) => {
          if (ai.name !== null) {
            list = list && list.map((listItem) => (ai.id === listItem.id ? ai : listItem));
          } else {
            list = list.filter((listItem) => ai.id !== listItem.maintenanceScheduleId);
          }
        });
      }
      return {
        list,
        count: list && list.length,
        all,
        item: actionItem && actionItem.length > 0 && actionItem[0].name !== null ? actionItem[0] : item,
        schedulePopDelete: false,
      };
    }
    case LOAD_SCHEDULETASK_COUNT_BY_MAINTENANCESCHEDULE:
      return { ...state, scheduleTaskCountByMS: action.payload };
    case REMOVE_SCHEDULE:
      let { list, count, all } = state;
      list = list.filter((listItem) => action.payload !== listItem.id);
      all = all.filter((listItem) => action.payload !== listItem.id);
      count -= 1;
      return {
        ...state,
        list,
        count,
        all,
        schedulePopDelete: false,
      };
    default:
      return state;
  }
};

export default schedule;
