//1453/1446
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Router } from '../../../common/routes';
import { permissions } from '../../constants/permissions';
import { AddInternalCompanyModal, AddNewUserModal } from '.';
import { getInternalCompanyFullList } from '../../actions/cafm/InternalCompanyActions';
import { getSiteFullList } from '../../actions/cafm/SiteActions';
import { getCompanyContactList } from '../../actions/oms/InternalContactActions';
import { createAssetSurvey, updateAssetSurvey } from '../../actions/cafm/AssetSurveyAction';
import { getFullUserListbyAccountId, getCurrentUserPermissions } from '../../actions/UserActions';
import { AssetSurveyEntryView } from '../cafm/admin';

class AddAssetSurveyModal extends Component {
  static propTypes = {
    initialValues: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    createAssetSurvey: PropTypes.func.isRequired,
    updateAssetSurvey: PropTypes.func.isRequired,
    siteList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    internalCompanies: PropTypes.arrayOf(PropTypes.shape()),
    internalContact: PropTypes.arrayOf(PropTypes.shape()),
    createForm: PropTypes.shape(),
    getFullUserListbyAccountId: PropTypes.func,
    getCurrentUserPermissions: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    updateBuilding: undefined,
    siteList: [],
    internalCompanies: [],
    internalContact: [],
    createForm: {
      assetSurveyForm: {},
    },
    getFullUserListbyAccountId: undefined,
    getCurrentUserPermissions: undefined,
    users: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      isSiteChangeExecute: true,
      display: false,
      contacts: [],
      siteId: props.initialValues && props.initialValues.Site ? 1 : 0,
      hasFloorPlan: false,
      addInternalCompanyModal: {
        open: false,
        initialValues: {},
      },
      addNewUserModal: {
        open: false,
        initialValues: {},
      },
    };
    this.props.getInternalCompanyFullList();
    props.getSiteFullList();
    props.getFullUserListbyAccountId();
    props.getCurrentUserPermissions();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
    const { isSiteChangeExecute } = this.state;
    let chosenCategorieid = 0;
    const form = nextProps.createForm.assetSurveyForm;
    const prevForm = this.props.createForm.assetSurveyForm;
    // Site Profile > Add Planned Task (to execute asset/location dropdownbind)
    if (prevForm && prevForm.values && form && form.values) {
      if (
        isSiteChangeExecute &&
        form.values.Site == prevForm.values.Site &&
        nextProps.siteList &&
        Object.keys(nextProps.siteList).length > 0
      ) {
        this.handleOnChangeSite(form.values.Site);
      }
    }

    let isSubContract = null;
    if (prevForm && prevForm.values && form && form.values) {
      if (
        (prevForm.values.company && prevForm.values.company !== form.values.company) ||
        (!prevForm.values.company && form.values.company)
      ) {
        if (form.values.company) {
          const chosenCompany = _.find(this.props.internalCompanies, ['id', form.values.company.value]);
          if (chosenCompany && chosenCompany.InternalContact) {
            const contacts = chosenCompany.InternalContact;
            this.props.change('contact', null);
            this.setState({
              contacts,
            });
          }
        } else {
          this.props.change('contact', null);
          this.setState({
            contacts: [],
          });
        }
      }
    }

    if (form && form.values && (form.values.company || form.values.userAssignee)) {
      if (form.values.company && !form.values.userAssignee) isSubContract = true;
      else if (!form.values.company && form.values.userAssignee) isSubContract = false;
    }
    let hasFloorPlan = false;
    if (form.values && form.values.hasFloorPlan) {
      hasFloorPlan = true;
    }
    this.setState({
      hasFloorPlan,
      isSubContract,
    });
  }

  handleOnAddNewUser = () => {
    this.setState({
      addNewUserModal: {
        open: true,
      },
    });
  };

  handleOnAddNewInternalCompany = () => {
    this.setState({
      addInternalCompanyModal: {
        open: true,
      },
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      addInternalCompanyModal: {
        open: false,
        initialValues: {
          siteId: null,
        },
      },
      addNewUserModal: {
        open: false,
        initialValues: {
          siteId: null,
        },
      },
    });

    this.props.onModalClose();
  };

  onAddInternalCompanyCloseModal = () => {
    this.setState({
      display: false,
      addInternalCompanyModal: {
        open: false,
        initialValues: {
          siteId: null,
        },
      },
    });
  };

  onAddUserCloseModal = () => {
    this.setState({
      display: false,
      addNewUserModal: {
        open: false,
        initialValues: {
          siteId: null,
        },
      },
    });
  };

  handleOnChangeSite = (site) => {
    if (site && site.value) {
      const { isSiteChangeExecute } = this.state;
      const chosenSite = this.props.siteList.find((building) => building.id === site.value);
      const tmpSiteId = (chosenSite && chosenSite.id) || (site && site.value); // chosenSite.id
      this.setState({
        siteId: tmpSiteId,
        isSiteChangeExecute: false,
      });
    }
  };

  handleFormSubmit = (values) => {
    const {
      Site,
      estimateNoOfDay,
      startDate,
      estimateNoOfAsset,
      floor,
      hasFloorPlan,
      userAssignee,
      company,
      contact,
      adminEmail,
      notifyAdmin,
    } = values;

    const assetSurvey = {
      siteId: Site && Site.value,
      estimateNoOfDay,
      startDate,
      estimateNoOfAsset,
      floor,
      hasFloorPlan: hasFloorPlan ? hasFloorPlan : false,
      userAssigneeId: userAssignee && userAssignee.value,
      supplierCompanyId: company && company.value,
      supplierContactId: contact && contact.value,
      adminEmail,
      notifyAdmin,
    };
    if (this.props.id) {
      assetSurvey.id = this.props.id;
      this.props.updateAssetSurvey(assetSurvey).then(() => {
        this.closeModal();
      });
    } else {
      assetSurvey.status = 1; //scheduled
      this.props.createAssetSurvey(assetSurvey).then(() => {
        this.closeModal();
      });
    }
  };

  render() {
    const { handleSubmit, t, siteList, internalCompanies, internalContact, users, userPermissions } = this.props;
    const { display, addInternalCompanyModal, addNewUserModal } = this.state;
    const siteNames = siteList;

    const { siteId, hasFloorPlan, userAssigneeId, notifyAdmin, contacts, isSubContract } = this.state;

    const showContacts = contacts && contacts.length > 0;
    const canAddNewUser = userPermissions && userPermissions.permissions.includes(permissions.accounts.Edit);
    const canAddNewCompany = userPermissions && userPermissions.permissions.includes(permissions.jms.companies.Create);

    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown over-y-auto
                  ${!display && 'display-none'}`}
      >
        <div aria-modal="true" className="swal2-modal swal2-show profile-modal quick-addnew-ppm-modal form-horizontal">
          <div className="col-sm-12 title_with_filter">
            <h2 className="swal2-title text-left col-sm-offset-3 col-sm-9">{t('Asset Survey')}</h2>
          </div>
          <form>
            <AssetSurveyEntryView
              t={t}
              args={{
                siteList: siteNames,
                onChangeSite: this.handleOnChangeSite,
                siteId,
                hasFloorPlan,
                userList: users,
                onClickNewUser: this.handleOnAddNewUser,
                companyList: internalCompanies,
                onClickNewInternalCompany: this.handleOnAddNewInternalCompany,
                showContacts,
                contactList: contacts,
                canAddNewUser,
                canAddNewCompany,
                isSubContract,
              }}
            />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleFormSubmit)}
            >
              {t('Save')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>

        {addNewUserModal.open && (
          <AddNewUserModal
            open={addNewUserModal.open}
            onModalClose={this.onAddUserCloseModal}
            initialValues={addNewUserModal.initialValues}
            t={t}
          />
        )}

        {addInternalCompanyModal.open && (
          <AddInternalCompanyModal
            open={addInternalCompanyModal.open}
            onModalClose={this.onAddInternalCompanyCloseModal}
            initialValues={addInternalCompanyModal.initialValues}
            t={t}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    tokens: { accessToken },
    site: { list, count },
    internalCompany,
    form,
    omsInternalContact,
    user,
  } = state;

  return {
    siteList: list,
    initialValues: props.initialValues,
    internalCompanies: internalCompany.all,
    createForm: form,
    internalContact: omsInternalContact.customerContactList,
    users: user.list,
    userPermissions: user.currentuserpermissions,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createAssetSurvey,
      updateAssetSurvey,
      getInternalCompanyFullList,
      getCompanyContactList,
      getSiteFullList,
      getFullUserListbyAccountId,
      getCurrentUserPermissions,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'assetSurveyForm',
    enableReinitialize: true,
  })(AddAssetSurveyModal),
);
