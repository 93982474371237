import { LOAD_USER_REPORT, LOAD_USER_REPORT_CELL_DATA } from '../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  list: [],
  celldata: [],
};

const userReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_USER_REPORT:
      return { ...state, list: action.payload };

    case LOAD_USER_REPORT_CELL_DATA:
      return { ...state, celldata: action.payload };

    default:
      return state;
  }
};

export default userReportReducer;
