import { LOAD_MODEL, LOAD_MODELS, LOAD_MODEL_COUNT, SHOW_LOADER, HIDE_LOADER } from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/model';

const adjustModels = (model) => {
  if (!model.manufacturerId || !model.Manufacturer) {
    model.manufacturer = null;
  } else {
    model.manufacturer = model.Manufacturer.name;
  }
};

export const loadModel = (model) => ({
  type: LOAD_MODEL,
  payload: model,
});

export const loadModels = (models) => ({
  type: LOAD_MODELS,
  payload: models,
});

export const loadCount = (count) => ({
  type: LOAD_MODEL_COUNT,
  payload: count,
});

export const createModel = (model) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/create`, model)
    .then((response) => {
      dispatch(loadModel(response.data));
      Router.pushRoute('cafm/admin/model_profile', { model_id: response.data.uuid });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createModel, model);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateModel = (model, path) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .put(`${routePath}/update`, model)
    .then((response) => {
      const newModel = response.data;
      adjustModels(newModel);
      newModel.path = path;
      dispatch(loadModel(newModel));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateModel, model);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getModel = (uuid) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-item/${uuid}`)
    .then((response) => {
      const newModel = response.data;
      adjustModels(newModel);
      dispatch(loadModel(newModel));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getModel, uuid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteModel = (uuid, isNavNeed) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${uuid}`)
    .then(() => {
      const item = {
        uuid,
        name: null,
      };
      dispatch(loadModel(item));
      if (isNavNeed) {
        Router.pushRoute('cafm/admin/manufacturers');
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteModel, uuid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getModelList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      const newModels = response.data;
      newModels.forEach(adjustModels);
      dispatch(loadModels(newModels));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getModelList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getModelFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-full-list`, { params })
    .then((response) => {
      dispatch(loadModels(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getModelFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getModelListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list-with-size`, { params })
    .then((response) => {
      const newModels = response.data.rows;
      newModels.forEach(adjustModels);
      dispatch(loadModels(newModels));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getModelListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
