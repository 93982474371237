import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInput, FormCheckbox, SelectDropdown } from '../../form/parts';
import { getEnabledOptions, getTimePeriodOptions, getLabourTypeOptions } from '../../../constants/options';
import HelpdeskCategoryTopicPicture from '../../../containers/decorate/HelpdeskCategoryTopicPicture';

const ProblemTopicEntryView = ({ t, args }) => (
  <div>
    <Field label={t('Name *')} component={FormInput} name="name" type="text" placeholder={t('Enter name')} />

    <Field
      label={t('Description')}
      component={FormInput}
      name="description"
      type="text"
      placeholder={t('Enter description')}
    />

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Priority *')}</label>

      <div className="col-sm-8">
        <Field
          name="priorityId"
          component={SelectDropdown}
          drpName="priorityId"
          options={args.enumerationList}
          _optionLabel="label"
          _optionValue="value"
          placeholder={t('Enter Priority')}
        />
      </div>
    </div>

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Tracker *')}</label>

      <div className="col-sm-8">
        <Field
          name="trackerId"
          component={SelectDropdown}
          drpName="trackerId"
          options={args.trackerList}
          _optionLabel="label"
          _optionValue="value"
          placeholder={t('Enter tracker')}
        />
      </div>
    </div>

    <Field
      label={t('Acknowledge time *')}
      component={FormInput}
      name="acknowledgeTime"
      type="number"
      placeholder={t('Enter acknowledge time')}
    />

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Acknowledge period *')}</label>

      <div className="col-sm-8">
        <Field
          name="acknowledgePeriod"
          component={SelectDropdown}
          drpName="acknowledgePeriod"
          options={getTimePeriodOptions(t)}
          _noLabelValue
          placeholder={t('Enter acknowledge period')}
        />
      </div>
    </div>

    <Field
      label={t('Resolved time *')}
      component={FormInput}
      name="resolveTime"
      type="number"
      placeholder={t('Enter resolve time')}
    />

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Resolve period *')}</label>

      <div className="col-sm-8">
        <Field
          name="resolvePeriod"
          component={SelectDropdown}
          drpName="resolvePeriod"
          options={getTimePeriodOptions(t)}
          _noLabelValue
          placeholder={t('Enter resolve period')}
        />
      </div>
    </div>

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Labour type *')}</label>

      <div className="col-sm-8">
        <Field
          name="labourType"
          component={SelectDropdown}
          drpName="labourType"
          options={getLabourTypeOptions(t)}
          _noLabelValue
          placeholder={t('Labour type')}
        />
      </div>
    </div>

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Enabled')}</label>

      <div className="col-sm-8">
        <Field
          name="enabled"
          component={SelectDropdown}
          drpName="enabled"
          options={getEnabledOptions(t)}
          _noLabelValue
          placeholder={t('Enabled (optional)')}
        />
      </div>
    </div>

    {/* TODO: Uncomment with app release */}
    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Image')}</label>

      <div className="col-sm-8">
        <HelpdeskCategoryTopicPicture initialPictures={args.imageLarge ? [{ imageLarge: `${args.imageLarge}` }] : []} />
      </div>
    </div>

    <Field name="detailOnly" component={FormCheckbox} isShort={false} label={t('Detail only')} />
  </div>
);

const problemTopicProps = PropTypes.shape({
  enumerationList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  trackerList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  imageLarge: PropTypes.string,
  imageSmall: PropTypes.string,
}).isRequired;

ProblemTopicEntryView.propTypes = {
  args: problemTopicProps,
  t: PropTypes.func.isRequired,
};

ProblemTopicEntryView.defaultProps = {
  args: {
    enumerationList: [],
    trackerList: [],
    imageLarge: undefined,
    imageSmall: undefined,
  },
};

export default ProblemTopicEntryView;
