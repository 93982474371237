import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { CascadeSelect } from 'primereact/cascadeselect';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { FilterMatchMode } from 'primereact/api';
import { InputNumber } from 'primereact/inputnumber';
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { TreeTable } from 'primereact/treetable';

import _ from 'lodash';
import moment from 'moment';

import { Filter, SmartFilter } from '../../components/filter';
import { AddNewItemView, TitleView, AddInModalViewSmall } from '../../components/decorate';

import userSelectedPreference from '../../lib/userSelectedPreference';
import PAGE_SIZE from '../../constants/tabledata/page_size';
import IntableMenu from '../../components/table/IntableMenu';

const Options = [
  { label: 'Equal', value: '=' },
  { label: 'Less than or equal', value: '<=' },
  { label: 'Greater than or equal', value: '>=' },
  { label: 'Greater', value: '>' },
  { label: 'Less', value: '<' },
  { label: 'Like', value: 'like' },
];

export const SmartDataTable = ({
  list = [],
  count = 0,
  addNewItem = {},
  extended = false,
  budgetPeriodFilter = false,
  boolTypes = null,
  typeId = null,
  useUuid = false,
  funcs = [],
  extraParams = {},
  navParams = {},
  filterValues = {},
  filterData = {},
  orderField = 'id',
  taskNavParams = [],
  categoryGroupFilter = false,
  categoryGroups = [],
  navigateOnClick = () => {},
  showAddNew = true,
  showIntable = true,
  hideFilter = false,
  useSmartFilter = false,
  title = null,
  showQuickAddNew = false,
  quickAddNewItem = {},
  iconOnClick = () => {},
  multiDeleteMode = false,
  multiArchiveMode = false,
  handleDeleteMultiJob = () => {},
  handleArchiveMultiJob = () => {},
  showStartEndDate = false,
  startEndDate = {},
  checkBoxOnEachRowSetting = {},
  showSearchBar = false,
  tableId = '',
  headers,
  intableMenu,
  page: pageProps,
  params,
  paramsWithFilter: paramsWithFilterProps,
  filterParams: filterParamsProps,
  isMultiDelete,
  isMultiArchive,
  selectedIds,
  searchTerm,
  user,
  getListWithSize,
  getList,
  t,
  loading = false,
  isCheckBoxOnEachRow,
  url,
  paginate,
  store,
  advanceFilterData,
}) => {
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(1);
  const [first, setFirst] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filters, setFilters] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [filterParams, setFilterParams] = useState();
  const [paramsWithFilter, setParamsWithFilter] = useState({});
  const [page, setPage] = useState();
  const [numberCondition, setNumberCondition] = useState('=');
  const [filterEnable, setFilterEnable] = useState(false);
  const [formattedList, setFormattedList] = useState([]);
  const [showRollBack, setShowRollBack] = useState(false);
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  const [isFilterAvailable, setIsFilterAvailable] = useState(false);

  useEffect(() => {
    const defaultParams = {
      page: 0,
      pageSize: PAGE_SIZE,
      orderField: orderField,
      orderType: 'ASC',
    };
    const tempPagingData = userSelectedPreference.getSpecificTablePagingData({
      currentPageUrl: typeof window !== 'undefined' ? window.location.pathname : '',
      userId: user && user.id,
      tableId,
    });
    let tempMultiSelData;
    if (multiDeleteMode) {
      tempMultiSelData = userSelectedPreference.getSpecificTableMultiSelectionData({
        currentPageUrl: typeof window !== 'undefined' ? window.location.pathname : '',
        userId: user && user.id,
        tableId,
      });
    }
    if (tempPagingData && tempPagingData.pagingInfo) {
      defaultParams.page = tempPagingData.pagingInfo.page;
      defaultParams.pageSize =
        tempPagingData.pagingInfo.pageSize == undefined ? defaultParams.pageSize : tempPagingData.pagingInfo.pageSize;
      defaultParams.orderField = tempPagingData.pagingInfo.orderField;
      defaultParams.orderType = tempPagingData.pagingInfo.orderType;
      if (tempPagingData.pagingInfo.searchTerm && tempPagingData.pagingInfo.searchTerm !== '') {
        defaultParams.searchTerm = tempPagingData.pagingInfo.searchTerm;
      }
    }
    const tempParams = {
      ...defaultParams,
      ...extraParams,
      ...(tempPagingData && tempPagingData.filterParams),
    };

    setSortField(orderField);
    const tempFilterParam = tempPagingData && tempPagingData.filterParams ? tempPagingData.filterParams : null;
    setFilterParams(tempFilterParam);
    setFilterEnable(tempFilterParam !== null && Object.keys(tempFilterParam).length !== 0);
    setShowAdvancedFilter(tempFilterParam !== null && Object.keys(tempFilterParam).length !== 0);
    setParamsWithFilter(tempParams);
    setPage(
      tempPagingData && tempPagingData.pagingInfo && tempPagingData.pagingInfo.page
        ? tempPagingData.pagingInfo.page
        : 0,
    );
    initFilter(tempFilterParam);
    if (filterData != undefined && Object.keys(filterData).length > 0) {
      setIsFilterAvailable(true);
    }
  }, []);

  const initFilter = (tempFilterParam) => {
    const filterType = {};
    Object.values(filterData).forEach((f) => {
      if (tempFilterParam && tempFilterParam?.filterField && tempFilterParam?.filterField == f.value) {
        if (f.type === 'date') {
          filterType[f.headerName] = {
            value: { value: tempFilterParam.filterValue, condition: tempFilterParam.fieldComparisonType },
            matchMode: FilterMatchMode.EQUALS,
          };
        } else if (f.type === 'string' && f.hasOwnProperty('array')) {
          const { name, id } = filterValues[f.array].find((e) => e.id === tempFilterParam.filterValue);
          filterType[f.headerName] = { value: { name, id }, matchMode: FilterMatchMode.EQUALS };
        } else {
          filterType[f.headerName] = { value: tempFilterParam.filterValue, matchMode: FilterMatchMode.EQUALS };
        }
      } else {
        if (f.hasOwnProperty('headerName')) {
          filterType[f.headerName] = { value: '', matchMode: FilterMatchMode.EQUALS };
        }
      }
    });
    setFilters(filterType);
  };

  useEffect(() => {
    if (Object.keys(paramsWithFilter).length !== 0) {
      getListWithSize(paramsWithFilter, t);
    }
  }, [paramsWithFilter]);

  useEffect(() => {
    const modifiedList = list.map((a) => {
      Object.keys(a).forEach(function (key) {
        if (a[key] === null) {
          a[key] = '-';
        }
      });
      return a;
    });
    setFormattedList(
      modifiedList.map((e, key) => {
        return {
          key: '' + key,
          data: { ...e },
          children: e.children,
        };
      }),
    );
  }, [list]);

  const onSort = (data) => {
    setSortField(data.sortField);
    setSortOrder(data.sortOrder);

    setParamsWithFilter({
      ...paramsWithFilter,
      orderField: data.sortField,
      orderType: data.sortOrder === 1 ? 'ASC' : 'DESC',
    });
  };

  const Menu = (data) => {
    return (
      <IntableMenu
        firstOrLastRow={data.data.id === 0 || data.data.id === list.length - 1}
        id={useUuid ? data.data.uuid : data.data.id}
        typeId={data.data.typeId || typeId}
        intableMenu={data.data.intableMenu ? data.data.intableMenu : intableMenu}
        funcs={data.data.intableFuncs ? data.data.intableFuncs : funcs}
        navParams={navParams}
        taskParam={taskNavParams[data.data.id] || ''}
        t={t}
        row={data.data}
      />
    );
  };

  const onFilterDebounced = useCallback(
    _.debounce((e) => {
      onFilter(e);
    }, 1000),
    [],
  );

  const onFilter = (e) => {
    let currentUrl = null;
    if (!url || url === '' || url == undefined) {
      currentUrl = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    } else {
      currentUrl = url;
    }
    if (e.target.value != '' && e.target.value != null) {
      const filterConditions = Object.values(filterData).find((i) => i.headerName === e.target.name);
      setPage(0);
      const tempFilterParam = {
        fieldComparisonType:
          typeof e.target.value === 'number'
            ? numberCondition
            : filterConditions.type == 'string'
            ? '='
            : filterConditions.type,
        filterField: filterConditions.value,
        filterValue:
          typeof e.target.value === 'string' || typeof e.target.value === 'number'
            ? e.target.value
            : e.target.value.name,
      };
      setFilterParams(tempFilterParam);
      setParamsWithFilter({
        ...extraParams,
        ...paramsWithFilter,
        ...tempFilterParam,
        page: 0,
      });
      userSelectedPreference.setUserSelectedPreference({
        currentPageUrl: currentUrl.asPath,
        userId: user && user.id,
        tableId,
        tablePagingPreference: {
          pagingInfo: { ...paramsWithFilter, ...tempFilterParam, page: 0 },
          filterParams: tempFilterParam,
        },
      });
    } else {
      setFilterParams({});
      const params = { ...paramsWithFilter };
      delete params.fieldComparisonType;
      delete params.filterField;
      delete params.filterValue;
      userSelectedPreference.setUserSelectedPreference({
        currentPageUrl: currentUrl.asPath,
        userId: user && user.id,
        tableId,
        tablePagingPreference: {
          pagingInfo: params,
          filterParams: {},
        },
      });
      setParamsWithFilter(params);
    }
  };

  const onFilterByDate = (e, headerItem) => {
    let currentUrl = null;
    if (!url || url === '' || url == undefined) {
      currentUrl = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    } else {
      currentUrl = url;
    }
    const tempFilterParam = {
      fieldComparisonType: e.condition,
      filterField: headerItem.value,
      filterValue: moment(e.value).format('YYYY-MM-DD'),
    };
    setFilterParams(tempFilterParam);
    setParamsWithFilter({
      ...paramsWithFilter,
      ...tempFilterParam,
      page: 0,
    });
    userSelectedPreference.setUserSelectedPreference({
      currentPageUrl: currentUrl.asPath,
      userId: user && user.id,
      tableId,
      tablePagingPreference: {
        pagingInfo: { ...paramsWithFilter, ...tempFilterParam, page: 0 },
        filterParams: tempFilterParam,
      },
    });
  };

  const onFilterByOptions = (value, headerName) => {
    let currentUrl = null;
    if (!url || url === '' || url == undefined) {
      currentUrl = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    } else {
      currentUrl = url;
    }
    const filterConditions = Object.values(filterData).find((i) => i.headerName === headerName);
    setPage(0);
    const tempFilterParam = {
      fieldComparisonType: '=',
      filterField: filterConditions.value,
      filterValue: value.id,
    };
    setFilterParams(tempFilterParam);
    setParamsWithFilter({
      ...paramsWithFilter,
      ...tempFilterParam,
      page: 0,
    });
    userSelectedPreference.setUserSelectedPreference({
      currentPageUrl: currentUrl.asPath,
      userId: user && user.id,
      tableId,
      tablePagingPreference: {
        pagingInfo: { ...paramsWithFilter, ...tempFilterParam, page: 0 },
        filterParams: tempFilterParam,
      },
    });
  };

  const clearFilter = () => {
    let currentUrl;
    if (!url || url === '' || url == undefined) {
      currentUrl = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    } else {
      currentUrl = url;
    }
    setFilterParams({});

    const params = { ...paramsWithFilter };
    delete params.fieldComparisonType;
    delete params.filterField;
    delete params.filterValue;
    userSelectedPreference.setUserSelectedPreference({
      currentPageUrl: currentUrl.asPath,
      userId: user && user.id,
      tableId,
      tablePagingPreference: {
        pagingInfo: params,
        filterParams: {},
      },
    });
    setParamsWithFilter(params);
  };

  const template = {
    layout: 'CurrentPageReport PrevPageLink PageLinks NextPageLink',
    PrevPageLink: (options) => {
      return (
        <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
          <span className="p-3">Previous</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
          <span className="p-3">Next</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page && options.view.startPage !== 0) ||
        (options.view.endPage === options.page && options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { 'p-disabled': true });
        return (
          <span className={className} style={{ userSelect: 'none' }}>
            ...
          </span>
        );
      }
      return (
        <button type="button" className={options.className} onClick={options.onClick}>
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };

  const FilterInput = (options) => {
    const headerItem = Object.values(filterData).find((e) => e.headerName == options.field);
    if (headerItem.type === 'like') {
      return (
        <AutoComplete
          value={options.value}
          onChange={(e) => {
            clearFilter();
            options.filterCallback(e.value);
            onFilterDebounced(e);
          }}
          className="p-column-filter"
          name={options.field}
          completeMethod={autoComplete}
          suggestions={suggestions}
          field="name"
        />
      );
    } else if (headerItem.type === 'number') {
      return (
        <>
          <Dropdown
            optionLabel="label"
            value={numberCondition}
            options={Options}
            onChange={(e) => setNumberCondition(e.value)}
            className="p-column-filter"
          />
          <InputNumber
            className="mt-2 p-column-filter"
            value={options.value}
            name={options.field}
            onValueChange={(e) => {
              clearFilter();
              options.filterCallback(e.value);
              onFilter(e);
            }}
          />
        </>
      );
    } else if (headerItem.type === 'string' && headerItem.hasOwnProperty('array')) {
      return (
        <CascadeSelect
          optionLabel="name"
          optionGroupLabel="name"
          optionGroupChildren={['nested']}
          value={options.value}
          options={getDropDownOptions(headerItem)}
          onChange={(e) => {
            clearFilter();
            options.filterCallback(e.value.name);
            onFilterByOptions(e.value, options.field);
          }}
          name={options.field}
          placeholder="Select Options"
        />
      );
    } else if (headerItem.type === 'date') {
      return (
        <>
          <Dropdown
            optionLabel="label"
            value={options.value?.condition}
            options={Options}
            onChange={(e) => options.filterCallback({ ...options.value, condition: e.value })}
            className="p-column-filter"
          />
          <Calendar
            className="mt-2"
            value={new Date(options.value?.value)}
            dateFormat={'dd/mm/yy'}
            onChange={(e) => {
              clearFilter();
              options.filterCallback({ ...options.value, value: e.value });
              onFilterByDate({ ...options.value, value: e.value }, headerItem);
            }}
          />
        </>
      );
    } else {
      return (
        <InputText
          value={options.value == null ? '' : options.value}
          name={options.field}
          onChange={(e) => {
            clearFilter();
            options.filterCallback(e.target.value);
            onFilterDebounced(e);
          }}
        />
      );
    }
  };

  const ImageTemplate = (rowData) => {
    if (rowData.hasOwnProperty('SitePictures') && rowData.SitePictures.length !== 0) {
      const primaryImage = rowData.SitePictures.find((e) => e.isDefault === true);
      return <img src={`${primaryImage.url}`} alt={rowData.name} height="45px" className="list-image cursor-pointer" />;
    } else {
      return <></>;
    }
  };

  const autoComplete = (event) => {
    const headerItem = Object.values(filterData).find((e) => e.headerName == event.originalEvent.target.name);
    const filterArray = filterValues[headerItem.array];
    if (filterArray !== undefined) {
      const formattedArray = filterArray
        .map((e) => ({ name: e.name, id: e.id }))
        .filter((e) => e.name.toLowerCase().indexOf(event.originalEvent.target.value.toLowerCase()) > -1);
      setSuggestions(formattedArray);
    } else {
      setSuggestions([]);
    }
  };

  const getDropDownOptions = (data) => {
    const filterArray = filterValues[data.array];
    const formattedArray = filterArray.map((i) => {
      return {
        name: i.name,
        id: i.id,
        ...(data.hasOwnProperty('nestedArray') && { nested: i[data.nestedArray] }),
      };
    });
    return formattedArray;
  };

  const onPageChange = (event) => {
    setParamsWithFilter({
      ...paramsWithFilter,
      page: event?.page,
    });
    setFirst(event.first);
  };

  const onIncludeAll = (bool) => {
    setSelectedItems([]);
    setShowRollBack(bool);
    setParamsWithFilter({
      ...paramsWithFilter,
      includeAll: bool,
      page: 0,
    });
  };

  const onAdvanceFilter = (e) => {
    let currentUrl = null;
    if (!url || url === '' || url == undefined) {
      currentUrl = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    } else {
      currentUrl = url;
    }
    if (Object.keys(e).length > 0) {
      setPage(0);
      const tempFilterParam = {
        fieldComparisonType: e.fieldComparisonType,
        filterField: e.filterField,
        filterValue: e.filterValue,
      };
      setFilterParams(tempFilterParam);
      setParamsWithFilter({
        ...extraParams,
        ...paramsWithFilter,
        ...tempFilterParam,
        page: 0,
      });
      userSelectedPreference.setUserSelectedPreference({
        currentPageUrl: currentUrl.asPath,
        userId: user && user.id,
        tableId,
        tablePagingPreference: {
          pagingInfo: { ...paramsWithFilter, ...tempFilterParam, page: 0 },
          filterParams: tempFilterParam,
        },
      });
    } else {
      setFilterParams({});
      const params = { ...paramsWithFilter };
      delete params.fieldComparisonType;
      delete params.filterField;
      delete params.filterValue;
      userSelectedPreference.setUserSelectedPreference({
        currentPageUrl: currentUrl.asPath,
        userId: user && user.id,
        tableId,
        tablePagingPreference: {
          pagingInfo: params,
          filterParams: {},
        },
      });
      setParamsWithFilter(params);
    }
  };

  return (
    <div className="col-lg-12">
      <div className="row row-title white-bg p-sm">
        <div className={`${title ? 'smart-header-title dis_block' : 'smart-header-no-title dis_block'}`}>
          {title && <TitleView title={title} t={t} />}
          {showAddNew &&
            (addNewItem.isModal ? (
              <AddInModalViewSmall openModal={addNewItem.openModal} title={addNewItem.title} />
            ) : (
              <AddNewItemView route={addNewItem.route} params={addNewItem.params} label={addNewItem.label} t={t} />
            ))}
          {showQuickAddNew && (
            <div className="wrap-for-max-width pull-right margin_right_20">
              <div className="text-right">
                <button type="button" className="btn oms-btn new" onClick={quickAddNewItem.openModal}>
                  {quickAddNewItem.title}
                </button>
              </div>
            </div>
          )}
          {showStartEndDate && (
            <div>
              <div className="wrap-for-max-width pull-right margin_right_20">
                <div className="text-right">
                  <h3 className="text-right lineHeight">
                    End Date:
                    {startEndDate.endDate}
                  </h3>
                </div>
              </div>
              <div className="wrap-for-max-width pull-right margin_right_20">
                <div className="text-right">
                  <h3 className="text-right lineHeight">
                    Start Date:
                    {startEndDate.startDate}
                  </h3>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="card p-4 flex flex-row justify-content-between">
        {advanceFilterData && Object.keys(advanceFilterData).length != 0 && (
          <div className="">
            <Button
              type="button"
              icon={`pi ${showAdvancedFilter ? 'pi-filter-slash' : 'pi-filter'}`}
              label={`Advanced Filter`}
              className={`filter-toggle`}
              onClick={() => {
                setShowAdvancedFilter(!showAdvancedFilter);
              }}
            />
          </div>
        )}
        <div className="flex flex-row flex-wrap justify-content-end">
          {multiDeleteMode && (
            <Button
              type="button"
              icon="fas fa-1p4x fa-trash"
              label="Delete"
              className={`${selectedItems.length === 0 && 'p-disabled'} filter-toggle mr-4`}
              onClick={() => {
                handleDeleteMultiJob(
                  selectedItems.map((i) => i.id),
                  paramsWithFilter,
                );
              }}
            />
          )}
          {multiArchiveMode && (
            <Button
              type="button"
              label={`${showRollBack ? 'Exclude archived' : 'Include all'}`}
              className="filter-toggle mr-4"
              onClick={() => {
                onIncludeAll(!showRollBack);
              }}
            />
          )}
          {showRollBack && (
            <Button
              type="button"
              icon="fas fa-1p4x fa-undo"
              label="Rollback Job"
              className={`${selectedItems.length === 0 && 'p-disabled'} filter-toggle mr-4`}
              onClick={() => {
                handleArchiveMultiJob(
                  selectedItems.map((i) => i.id),
                  paramsWithFilter,
                );
              }}
            />
          )}
          {isFilterAvailable && (
            <Button
              type="button"
              icon={`pi ${filterEnable ? 'pi-filter-slash' : 'pi-filter'}`}
              label={`${filterEnable ? 'Disable Filter' : 'Enable Filter'}`}
              className={`${filterEnable ? 'enabled' : 'disabled'} filter-toggle`}
              onClick={() => {
                if (filterEnable) {
                  clearFilter();
                }
                setFilterEnable(!filterEnable);
              }}
            />
          )}
        </div>
      </div>
      {showAdvancedFilter && (
        <SmartFilter
          onFilter={onAdvanceFilter}
          filterFields={advanceFilterData}
          filterValues={filterValues}
          boolTypes={boolTypes}
          budgetPeriodFilter={budgetPeriodFilter}
          categoryGroupFilter={categoryGroupFilter}
          categoryGroups={categoryGroups}
          store={store}
          t={t}
        />
      )}
      <TreeTable
        rows={paramsWithFilter.pageSize}
        totalRecords={count - 1}
        value={formattedList}
        responsiveLayout="scroll"
        loading={loading}
        onSort={onSort}
        sortField={sortField}
        sortOrder={sortOrder}
        paginatorTemplate={template}
        onPage={onPageChange}
        first={first}
        size="large"
        lazy
        paginator={paginate}
        stripedRows
        {...(filterEnable && { filterDisplay: 'row', filters })}
        globalFilterFields={['name']}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        paginatorClassName="justify-content-between"
        selection={selectedItems}
        onSelectionChange={(e) => setSelectedItems(e.value)}
        {...(showRollBack && { showSelectionElement: (e) => (e?.archived === 0 ? '' : true) })}
        // onDoubleClick={(e) => (useUuid ? navigateOnClick(e.node.data.uuid) : navigateOnClick(e.node.data.id))}
      >
        {(multiDeleteMode || multiArchiveMode) && (
          <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} exportable={false}></Column>
        )}

        {headers.map((h, key) => {
          if (h.hasOwnProperty('type') && h.type == 'image') {
            return (
              <Column
                key={h.name}
                field={h.name}
                header={h.label}
                sortable={h.order === 'DISABLED' ? false : true}
                style={{ width: h.width }}
                body={ImageTemplate}
              />
            );
          } else {
            return (
              <Column
                {...(key === 0 ? { expander: true } : {})}
                key={h.name}
                field={h.name}
                header={h.label}
                sortable={h.order === 'DISABLED' ? false : true}
                style={{ width: h.width }}
                filter={
                  !isFilterAvailable &&
                  !filterEnable &&
                  Object.values(filterData).findIndex((i) => i.headerName == h.name) === -1
                    ? false
                    : true
                }
                {...(filterEnable && { filterElement: FilterInput })}
                showFilterMenu={false}
                onFilterClear={clearFilter}
              />
            );
          }
        })}
        <Column header="Actions" className="actionColumn" body={Menu} style={{ width: '7%' }} />
      </TreeTable>
      <div style={{ height: '15px' }}></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    user: user.currentuser,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SmartDataTable);

SmartDataTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape()),
  headers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  intableMenu: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  addNewItem: PropTypes.shape(),
  count: PropTypes.number,
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
  }).isRequired,
  getListWithSize: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  filterData: PropTypes.shape(),
  filterValues: PropTypes.shape(),
  boolTypes: PropTypes.string,
  extended: PropTypes.bool,
  budgetPeriodFilter: PropTypes.bool,
  typeId: PropTypes.string,
  funcs: PropTypes.arrayOf(PropTypes.func),
  useUuid: PropTypes.bool,
  navigateOnClick: PropTypes.func,
  extraParams: PropTypes.shape(),
  taskNavParams: PropTypes.arrayOf(PropTypes.string),
  categoryGroupFilter: PropTypes.bool,
  categoryGroups: PropTypes.arrayOf(PropTypes.shape()),
  navParams: PropTypes.shape(),
  orderField: PropTypes.string,
  showAddNew: PropTypes.bool,
  showIntable: PropTypes.bool,
  hideFilter: PropTypes.bool,
  useSmartFilter: PropTypes.bool,
  title: PropTypes.string,
  t: PropTypes.func.isRequired,
  showQuickAddNew: PropTypes.bool,
  quickAddNewItem: PropTypes.shape(),
  iconOnClick: PropTypes.func,
  multiDeleteMode: PropTypes.bool,
  multiArchiveMode: PropTypes.bool,
  handleDeleteMultiJob: PropTypes.func,
  handleArchiveMultiJob: PropTypes.func,
  showStartEndDate: PropTypes.bool,
  startEndDate: PropTypes.shape(),
  checkBoxOnEachRowSetting: PropTypes.shape(),
  showSearchBar: PropTypes.bool,
  loading: PropTypes.bool,
  tableId: PropTypes.string,
};
