import React from 'react';
import PropTypes from 'prop-types';
import FormLabel from './FormLabel';

const FormInput = ({ input, label, name, placeholder, isShort, isDisabled, type, addclass }) => (
  <div className="form-group">
    {label && <FormLabel isShort={isShort} label={label} name={name} />}
    <div className={addclass === null ? (isShort ? 'col-sm-6' : 'col-sm-8') : addclass}>
      <input
        {...input}
        placeholder={placeholder}
        className="form-control input-lg"
        type={type} // Added by Rohan Dave on 27/03/2019(OMSCAFM-685) changed from "text"
        disabled={isDisabled}
        name={name}
        autoComplete="off"
      />
    </div>
  </div>
);

FormInput.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  isShort: PropTypes.bool,
  isDisabled: PropTypes.bool,
  type: PropTypes.string,
  addclass: PropTypes.string,
};

FormInput.defaultProps = {
  name: '',
  isShort: false,
  isDisabled: false,
  type: 'text',
  addclass: null,
};

export default FormInput;
