import {
  LOAD_PROBLEM_CATEGORY,
  LOAD_PROBLEM_CATEGORIES,
  LOAD_ALL_PROBLEM_CATEGORIES,
  LOAD_PROBLEM_CATEGORIES_COUNT,
  REMOVE_PROBLEM_CATEGORY,
  CREATE_PROBLEM_CATEGORY,
} from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  all: [],
  list: [],
};

const problemCategory = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_PROBLEM_CATEGORY: {
      const { all, count } = state;

      return {
        ...state,
        item: action.payload,
        all: [...all, action.payload],
        count: count + 1,
      };
    }

    case LOAD_PROBLEM_CATEGORY: {
      const item = action.payload;
      let { list, all, count } = state;
      list = list.map((listItem) => (item.id === listItem.id ? item : listItem));
      all = all.map((listItem) => (item.id === listItem.id ? item : listItem));

      return {
        item,
        list,
        all,
        count,
      };
    }

    case REMOVE_PROBLEM_CATEGORY: {
      const id = action.payload;
      let { list, all, count } = state;

      list = list.filter((listItem) => {
        const remove = id !== listItem.id;
        if (remove) {
          count--;
        }
        return remove;
      });

      all = all.filter((listItem) => id !== listItem.id);

      return {
        item: null,
        list,
        all,
        count,
      };
    }
    case LOAD_PROBLEM_CATEGORIES:
      return { ...state, list: action.payload };

    case LOAD_ALL_PROBLEM_CATEGORIES:
      return { ...state, all: action.payload };

    case LOAD_PROBLEM_CATEGORIES_COUNT:
      return { ...state, count: action.payload };

    default:
      return state;
  }
};

export default problemCategory;
