import { LOAD_TASK, LOAD_TASKS, LOAD_ALL_TASKS, LOAD_TASK_COUNT, SHOW_LOADER, HIDE_LOADER } from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/taskLibrary';

const adjustTasks = (task) => {
  task.regulatory = task.isRegulatory ? 'yes' : 'no';
  task.sfg20 = task.majorSfg20 ? `${task.majorSfg20}-${task.minorSfg20}` : undefined;
  task.repeat = `${task.repeatEvery} ${task.repeatPeriod}`;
  task.labour = task.labourPeriod ? `${task.labourRequired} ${task.labourPeriod}` : undefined;
  task.taskComplianceName = task.TaskCompliance && task.TaskCompliance.name;
};

export const loadTask = (task) => ({
  type: LOAD_TASK,
  payload: task,
});

export const loadTasks = (tasks) => ({
  type: LOAD_TASKS,
  payload: tasks,
});

export const loadAllTasks = (tasks) => ({
  type: LOAD_ALL_TASKS,
  payload: tasks,
});

export const loadCount = (count) => ({
  type: LOAD_TASK_COUNT,
  payload: count,
});

export const createTask = (task) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/create`, task)
    .then((response) => {
      dispatch(loadTask(response.data));
      Router.pushRoute('cafm/admin/task_profile', { task_id: response.data.id });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createTask, task);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const quickCreateTask = (task) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .post(`${routePath}/create`, task)
    .then((response) => {
      dispatch(loadTask(response.data));
      // Router.pushRoute('cafm/admin/task_profile', { task_id: response.data.id });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, quickCreateTask, task);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateTask = (task) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .put(`${routePath}/update`, task)
    .then((response) => {
      const newTask = response.data;
      adjustTasks(newTask);
      dispatch(loadTask(newTask));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateTask, task);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getTask = (id) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-item/${id}`)
    .then((response) => {
      const newTask = response.data;
      adjustTasks(newTask);
      dispatch(loadTask(newTask));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getTask, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteTask = (id, path) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${id}`)
    .then(() => {
      const item = {
        id,
        name: null,
      };
      dispatch(loadTask(item));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, deleteTask, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getTaskList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      const newTasks = response.data;
      newTasks.forEach(adjustTasks);
      dispatch(loadTasks(newTasks));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getTaskList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getTaskFullList = (params) => (dispatch) => {
  // condition added as in addplannedtask in old if you select Category then it show Task of that category
  // but if you clear Category then still it shows task of old
  if ((params.taskCategoryId && params.taskCategoryId > 0) || (params.siteId && params.siteId > 0)) {
    dispatch({ type: SHOW_LOADER });

    api
      .get(`${routePath}/get-full-list`, { params })
      .then((response) => {
        dispatch(loadAllTasks(response.data));
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response.data));
        handleErrors(error, dispatch, getTaskFullList, params);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  } else {
    dispatch(loadAllTasks([]));
  }
};

export const getTaskListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list-with-size`, { params })
    .then((response) => {
      const newTasks = response.data.rows;
      newTasks.forEach(adjustTasks);
      dispatch(loadTasks(newTasks));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getTaskListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
