import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import { FormInput, SelectDropdown } from '../form/parts';

import SimpleModal from './SimpleModal';
import {
  createTaskCompliance,
  updateTaskCompliance,
  getTaskComplianceFullList,
} from '../../actions/cafm/TaskComplianceActions';

class AddEditTaskComplianceModal extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    createTaskCompliance: PropTypes.func.isRequired,
    updateTaskCompliance: PropTypes.func.isRequired,
    getTaskComplianceFullList: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    createForm: PropTypes.shape(),
    initialValues: PropTypes.shape().isRequired,
    taskCompliancesList: PropTypes.arrayOf(PropTypes.shape()),
    onModalClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    createForm: {
      addEditTaskComplianceForm: {},
    },
    taskCompliancesList: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      display: false,
      notifyUserOnChildComplianceParentRemove: {
        id: null,
        name: null,
        open: false,
        tempValues: {},
      },
      isSuccess: true,
    };

    if (props.id) {
      props.getTaskComplianceFullList({ isOnlyParent: true, notInIds: props.id });
    } else {
      props.getTaskComplianceFullList({ isOnlyParent: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    this.props.onModalClose();
  };

  handleFormSubmit = (values) => {
    const { initialValues } = this.props;
    const { name, parentCompliance } = values;
    const params = {
      name,
      parentId: (parentCompliance && parentCompliance.value) || null,
    };
    params.isChildUpdate = initialValues.isChildUpdate || undefined;
    params.urlPath = initialValues.urlPath || undefined;
    if (initialValues.mode == 'new' && initialValues.parentId && initialValues.parentId > 0) {
      params.parentId = initialValues.parentId;
    }
    if (this.props.id) {
      params.id = this.props.id;
      this.props.updateTaskCompliance(params).then(() => {
        this.closeModal();
      });
    } else {
      this.props.createTaskCompliance(params).then(() => {
        this.closeModal();
      });
    }
  };

  editTaskComplianceExecute = () => {
    const { tempValues, id } = this.state.notifyUserOnChildComplianceParentRemove;
    const params = {
      name: tempValues.name,
      parentId: (tempValues.parentCompliance && tempValues.parentCompliance.value) || null,
    };
    params.id = id;
    params.isChildUpdate = tempValues.isChildUpdate;
    params.urlPath = tempValues.urlPath;
    this.props.updateTaskCompliance(params).then(() => {
      this.closeModal();
    });
  };

  handleModalsClose = () => {
    this.setState({
      notifyUserOnChildComplianceParentRemove: {
        open: false,
        id: null,
        name: null,
        tempValues: {},
      },
    });
  };

  render() {
    const { handleSubmit, t, initialValues, title, taskCompliancesList } = this.props;
    const { display, notifyUserOnChildComplianceParentRemove } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
              ${!display && 'display-none'}`}
      >
        <div aria-modal="true" className="swal2-modal swal2-show modal-template form-horizontal">
          <h2 className="swal2-title">{title}</h2>

          <form>
            <Field label={t('Name *')} component={FormInput} name="name" type="text" placeholder={t('Name')} />

            {initialValues.mode == 'edit' && (
              <div className="form-group">
                <label className="col-sm-4 control-label">{t('Parent')}</label>

                <div className="col-sm-8">
                  <Field
                    name="parentCompliance"
                    drpName="parentCompliance"
                    component={SelectDropdown}
                    options={taskCompliancesList}
                    _optionLabel="name"
                    _optionValue="id"
                    _noLabelValue={false}
                    placeholder={t('Select Parent Compliance')}
                  />
                </div>
              </div>
            )}
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleFormSubmit)}
            >
              {t('Save')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>

          {notifyUserOnChildComplianceParentRemove.open && (
            <SimpleModal
              open={notifyUserOnChildComplianceParentRemove.open}
              id={notifyUserOnChildComplianceParentRemove.id}
              onModalClose={this.handleModalsClose}
              header={t('Remove Parent')}
              text={`${t('Are you sure you want to remove parent from')} ${
                notifyUserOnChildComplianceParentRemove.name
              }?`}
              handleOk={this.editTaskComplianceExecute}
              t={t}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ tokens: { accessToken }, form, taskCompliance }) => ({
  accessToken,
  taskCompliance: taskCompliance.item,
  createForm: form,
  taskCompliancesList: taskCompliance.onlyParent || [],
  childItem: taskCompliance.childItem,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createTaskCompliance,
      updateTaskCompliance,
      getTaskComplianceFullList,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'addEditTaskComplianceForm',
  })(AddEditTaskComplianceModal),
);
