import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import { FormInput, FormCheckbox } from '../form/parts';

import { createJobStatus, updateJobStatus } from '../../actions/cafm/JobStatusActions';

class AddEditJobStatusModal extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    createJobStatus: PropTypes.func.isRequired,
    updateJobStatus: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    createForm: PropTypes.shape(),
    initialValues: PropTypes.shape().isRequired,
    onModalClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    createForm: {
      addEditJobStatusForm: {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
    const form = nextProps.createForm.addEditJobStatusForm;
    if (form.values && nextProps.statusData) {
      if (nextProps.statusData.colorCode === form.values.colorCode && form.submitSucceeded) {
        this.props.onModalClose();
      }
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    this.props.onModalClose();
  };

  handleFormSubmit = (values) => {
    const { colorCode, name, isComplete, isDefault, isActive } = values;

    const jobStatus = {
      colorCode,
      name,
      isComplete,
      isDefault,
      isActive,
    };
    if (this.props.id) {
      jobStatus.id = this.props.id;
      this.props.updateJobStatus(jobStatus).then(() => this.closeModal());
    } else {
      this.props.createJobStatus(jobStatus).then(() => this.closeModal());
    }
  };

  render() {
    const { handleSubmit, t, initialValues, title } = this.props;
    const { display } = this.state;

    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                        ${!display && 'display-none'}`}
      >
        <div aria-modal="true" className="swal2-modal swal2-show modal-template form-horizontal">
          <h2 className="swal2-title">{title}</h2>

          <form>
            {/* <Field
                            label={t('Name *')}
                            component={FormInput}
                            name="name"
                            type="text"
                            placeholder={t('Name')}
                        />                        */}
            <Field
              label={t('Color Code *')}
              component={FormInput}
              name="colorCode"
              type="color"
              placeholder={t('Color Code')}
            />
            <Field name="isComplete" component={FormCheckbox} label={t('Complete')} />

            <Field name="isDefault" component={FormCheckbox} label={t('Is Default')} />
            <Field name="isActive" component={FormCheckbox} label={t('Is Active')} />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleFormSubmit)}
            >
              {t('Save')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    tokens: { accessToken },
    form,
  } = state;

  return {
    accessToken,
    createForm: form,
    initialValues: props.initialValues,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createJobStatus,
      updateJobStatus,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'addEditJobStatusForm',
  })(AddEditJobStatusModal),
);
