import React from 'react';
import PropTypes from 'prop-types';

const FormCheckbox = ({ input, label, name, isShort, disabled, tooltipText }) => (
  <div className="form-group">
    {tooltipText && tooltipText !== '' ? (
      <label title={tooltipText} className={`${isShort ? 'col-sm-3' : 'col-sm-4'} control-label`} htmlFor={name}>
        {label}
      </label>
    ) : (
      <label className={`${isShort ? 'col-sm-3' : 'col-sm-4'} control-label`} htmlFor={name}>
        {label}
      </label>
    )}

    <div className={`${isShort ? 'col-sm-7' : 'col-sm-7'}`}>
      {tooltipText && tooltipText !== '' ? (
        <input
          {...input}
          className="form-control"
          type="checkbox"
          name={name}
          checked={input.value}
          disabled={disabled}
          title={tooltipText}
        />
      ) : (
        <input
          {...input}
          className="form-control"
          type="checkbox"
          name={name}
          checked={input.value}
          disabled={disabled}
        />
      )}
    </div>
  </div>
);

FormCheckbox.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  isShort: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltipText: PropTypes.string,
};

FormCheckbox.defaultProps = {
  isShort: false,
  disabled: false,
  tooltipText: '',
};

export default FormCheckbox;
