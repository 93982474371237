import _ from 'lodash';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { showBodyScroll } from '../../../lib/bodyScrollToggle';
import { CustomFileUploader } from '../../../containers/decorate';
import { addMaintenanceScheduleAttachment } from '../../../actions/cafm/AttachmentActions';

class AddAttachmentModal extends Component {
  static defaultProps = {
    msId: 0,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  closeModal = () => {
    const { onModalClose } = this.props;
    showBodyScroll();
    onModalClose();
  };

  handleFormSubmit = () => {
    const {
      msId,
      initialValues: { pictures },
    } = this.props;

    const payload = {
      maintenanceScheduleId: msId,
      attachmentList: pictures.length > 0 && pictures[0].type !== 'new',
    };
    this.props.addMaintenanceScheduleAttachment(payload, this.closeModal);
  };

  render() {
    const { t, title } = this.props;
    return (
      <div tabIndex={0} role="button" className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow`}>
        <div aria-modal="true" className="swal2-modal swal2-show modal-template form-horizontal">
          <h2 className="swal2-title">{title}</h2>

          <CustomFileUploader maxNumberOfFiles={30} t={this.props.t} />

          <div className="swal2-buttonswrapper display-block">
            <button type="submit" className="swal2-confirm swal2-styled btn-primary" onClick={this.handleFormSubmit}>
              {t('Save')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    tokens: { accessToken },
  } = state;

  return {
    accessToken,
    initialValues: props.initialValues,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addMaintenanceScheduleAttachment,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddAttachmentModal);
