import React from 'react';
import PropTypes from 'prop-types';

const FilterInput = ({ input, label, name, meta: { touched, error }, selectedValue }) => (
  <div className="navbar-form">
    <div className="input-group add-on search basic-search-form">
      {selectedValue && selectedValue !== '' && (
        <input
          {...input}
          className="form-control input-lg ignore-change filter-input"
          placeholder={label}
          name={name}
          type="text"
          value={selectedValue}
        />
      )}
      {(!selectedValue || selectedValue === '') && (
        <input
          {...input}
          className="form-control input-lg ignore-change filter-input"
          placeholder={label}
          name={name}
          type="text"
        />
      )}
    </div>
  </div>
);

FilterInput.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
};
FilterInput.defaultProps = {
  name: null,
  label: null,
};

export default FilterInput;
