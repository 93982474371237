import { LOAD_SITE, LOAD_SITES, LOAD_COUNT, SHOW_LOADER, HIDE_LOADER } from '../types';

import { api } from '../../lib/api';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';
import commonFunction from '../../lib/commonFunction';

const routePath = '/v2/building';

const adjustSites = (building) => {
  building.startDate = commonFunction.displayDateWithValidFormat(building.startDate);
  building.endDate = commonFunction.displayDateWithValidFormat(building.endDate);
  building.createdAt = commonFunction.displayDateWithValidFormat(building.createdAt);
};

export const loadSite = (site) => ({
  type: LOAD_SITE,
  payload: site,
});

export const loadSites = (sites) => ({
  type: LOAD_SITES,
  payload: sites,
});

export const loadCount = (count) => ({
  type: LOAD_COUNT,
  payload: count,
});

export const getSiteFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-full-list`, { params })
    .then((response) => {
      const buildings = response.data;
      buildings.forEach(adjustSites);
      dispatch(loadSites(buildings));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getSiteFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
