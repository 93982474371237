import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInput, SelectDropdown, FormCheckbox } from '../../form/parts';

const LocationEntryView = ({ t, args }) => (
  <div>
    <Field label={t('Name *')} component={FormInput} name="name" type="text" placeholder={t('Name')} />

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Floor')}</label>

      <div className="col-sm-8">
        <Field
          name="type"
          component={SelectDropdown}
          options={args.floorList}
          _optionLabel="label"
          _optionValue="value"
          placeholder={t('Select Floor')}
        />
      </div>
    </div>

    {/* if child location added from location profile > add new then location of profile will be parent */}
    {args.isParentDropdownShow && (
      <div className="form-group">
        <label className="col-sm-4 control-label">{t('Parent Location')}</label>

        <div className="col-sm-8">
          <Field
            name="parent"
            component={SelectDropdown}
            options={
              args.parentLocationList &&
              args.parentLocationList.map((data) => {
                return { name: data.name, id: data.id, isDisabled: data.disabled };
              })
            }
            _optionLabel="name"
            _optionValue="id"
            placeholder={t('Select Parent Location')}
          />
        </div>
      </div>
    )}

    {args.mode === 'edit' && <Field name="disabled" component={FormCheckbox} label={t('Disabled')} />}
  </div>
);

const locationProps = PropTypes.shape({
  floorList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mode: PropTypes.string,
  parentLocationList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isParentDropdownShow: PropTypes.bool,
}).isRequired;

LocationEntryView.propTypes = {
  args: locationProps,
  t: PropTypes.func.isRequired,
};

LocationEntryView.defaultProps = {
  args: {
    floorList: [],
    parentLocationList: [],
    mode: '',
    isParentDropdownShow: true,
  },
};

export default LocationEntryView;
