import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UppyFileUploader from '../form/parts/UppyFileUploader';

class PictureEdit extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    initialPictures: PropTypes.arrayOf(PropTypes.shape()),
    pictures: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    removed: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    setPictures: PropTypes.func.isRequired,
    setRemoved: PropTypes.func.isRequired,
    showDefault: PropTypes.bool,
  };

  static defaultProps = {
    showDefault: true,
  };

  createNew(isDefault = true) {
    return { type: 'new', isDefault };
  }

  constructor(...data) {
    super(...data);

    const { setPictures, setRemoved, initialPictures } = this.props;

    let pictures;

    if (!initialPictures || initialPictures.length == 0) {
      pictures = [this.createNew()];
    } else {
      pictures = initialPictures.map((obj) => ({
        type: 'preview',
        data: obj,
        isDefault: obj.isDefault,
      }));
    }

    setPictures(pictures);
    setRemoved([]);
  }

  addPicture(e) {
    e.preventDefault();

    const { setPictures, pictures } = this.props;
    const newPictures = [...pictures, this.createNew(false)];

    setPictures(newPictures);
  }

  onAdd(index, accepted) {
    if (!accepted) {
      return;
    }

    // mutable copy
    const { setPictures } = this.props;
    const pictures = [...this.props.pictures];
    const { isDefault } = pictures[index];
    let newParams = { ...accepted };
    delete accepted.uppyObject;
    const picture = {
      type: 'file',
      data: accepted,
      isDefault,
      uppyObject: newParams.uppyObject,
    };

    pictures[index] = picture;

    setPictures(pictures);
  }

  onRemove(index) {
    let { removed, pictures } = this.props;

    const { setPictures, setRemoved } = this.props;

    const picture = pictures[index];

    pictures = pictures.filter((item, key) => key != index);

    if (pictures.length === 0) {
      pictures = [this.createNew()];
    }

    if (picture.isDefault) {
      pictures[0].isDefault = true;
    }

    if (picture.type === 'preview') {
      removed = [...removed, picture];
    }

    setPictures(pictures);
    setRemoved(removed);
  }

  onDefault(index) {
    const { setPictures } = this.props;
    const pictures = this.props.pictures.map((picture, key) => {
      picture.isDefault = key === index;
      return picture;
    });

    setPictures(pictures);
  }

  renderUppyFileUpload(picture, index) {
    const { t, showDefault } = this.props;
    const attrs = {};

    if (picture.type === 'file') {
      attrs.accepted = picture.data;
      attrs.removeButton = false;
    } else if (picture.type === 'preview') {
      attrs.preview = picture.data.url;
    } else if (picture.type === 'new' && index === 0) {
      attrs.removeButton = false;
    }

    if (showDefault) {
      attrs.onDefault = (index) => this.onDefault(index);
    }

    return (
      <UppyFileUploader
        t={t}
        key={index}
        index={index}
        name={`picture-dropzone-${index}`}
        isDefault={picture.isDefault}
        onAdd={(index, accepted) => this.onAdd(index, accepted)}
        onRemove={(index) => this.onRemove(index)}
        {...attrs}
      />
    );
  }

  render() {
    const { pictures } = this.props;

    return (
      <div className="asset-picture-edit">
        {pictures && pictures.map((picture, index) => this.renderUppyFileUpload(picture, index))}
        {pictures && pictures.length < 10 && (
          <a href="#" className="btn btn-primary" onClick={(e) => this.addPicture(e)}>
            Add
          </a>
        )}
      </div>
    );
  }
}

export default PictureEdit;
