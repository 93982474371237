export const getDropDownPTEvent = () => {
  return {
    checkboxIcon: {
      onClick: (e) => {
        try {
          e.stopPropagation();
          e.target.parentNode.click();
        } catch (error) {
          console.log('error', error);
        }
      },
    },
    headerCheckbox: {
      onClick: (e) => {
        try {
          e.stopPropagation();
          e?.target?.parentNode && e.target.parentNode.click();
        } catch (error) {
          console.log('error', error);
        }
      },
    },
  };
};
