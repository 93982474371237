import React from 'react';
import PropTypes from 'prop-types';

import TitleView from './TitleView';

const EditAndDeleteView = ({
  onEdit,
  onDelete,
  onGoBack,
  onQuickAddPPM,
  onReactiveIssue,
  onGoBackCAFMDashboard,
  onGoBackAllSites,
  title,
  t,
  onExtendContractDate,
  onAttachAsset,
  onSubmit,
  submitBtnText,
}) => (
  <div className={`${title ? 'smart-header-title' : 'smart-header-no-title'}`}>
    {title && <TitleView title={title} t={t} />}

    <div className="profile-buttons">
      {onAttachAsset && (
        <button className="btn oms-btn new margin_right_20" onClick={onAttachAsset}>
          {t('Attach Asset')}
        </button>
      )}
      {onQuickAddPPM && (
        <button className="btn oms-btn new margin_right_20" onClick={onQuickAddPPM}>
          {t('Add Planned Task')}
        </button>
      )}
      {onReactiveIssue && (
        <button className="btn oms-btn new margin_right_20" onClick={onReactiveIssue}>
          {t('Add Reactive Issue')}
        </button>
      )}
      {onExtendContractDate && (
        <button className="btn oms-btn new margin_right_20" onClick={onExtendContractDate}>
          {t('Extend Contract Date')}
        </button>
      )}
      {/* //Added by Rohan Dave on 03/07/2018(OMSCAFM-594) */}
      {onGoBackCAFMDashboard && (
        <button className="filter-button" onClick={onGoBackCAFMDashboard}>
          {t('Dashboard')}
        </button>
      )}
      {/* //Added by Rohan Dave on 03/07/2018(OMSCAFM-594) */}
      {onGoBack && (
        <button className="filter-button" onClick={onGoBack}>
          {t('Go back')}
        </button>
      )}
      {onEdit && (
        <button className="filter-button" onClick={onEdit}>
          {t('Edit')}
        </button>
      )}
      {onDelete && (
        <button className="filter-button" onClick={onDelete}>
          {t('Delete')}
        </button>
      )}
      {onSubmit && (
        <button className="btn btn-success std-btn" onClick={onSubmit}>
          {t(submitBtnText)}
        </button>
      )}
    </div>
  </div>
);

EditAndDeleteView.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onQuickAddPPM: PropTypes.func,
  onReactiveIssue: PropTypes.func,
  title: PropTypes.string,
  t: PropTypes.func.isRequired,
};

EditAndDeleteView.defaultProps = {
  title: null,
};

export default EditAndDeleteView;
