import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';

import { updateNotification } from '../../../actions/cafm/NotificationsActions';
import { showBodyScroll, hideBodyScroll } from '../../../lib/bodyScrollToggle';
import { FormInput } from '../../form/parts';

class EditNotificationModal extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    initialValues: PropTypes.shape().isRequired,
    createForm: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    updateNotification: PropTypes.func.isRequired,
  };

  static defaultProps = {
    createForm: {
      editNotificationForm: {},
    },
  };

  constructor(props) {
    super(props);
    const { initialValues } = props;
    this.state = {
      display: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      hideBodyScroll();
      this.setState({
        display: true,
      });
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    showBodyScroll();
    this.props.onModalClose();
  };

  handleUpdateNotification = (notification) => {
    const { firstName, lastName, email, siteId } = notification;
    const { id } = this.props;

    const newnotification = {
      id,
      firstName,
      lastName,
      email,
      siteId,
    };
    this.props.updateNotification(newnotification);
    this.closeModal();
  };

  render() {
    const { handleSubmit, t } = this.props;
    const { display } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show modal-template form-horizontal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="swal2-title">{t('Update Notification')}</h2>

          <form>
            <Field
              label={t('First Name *')}
              component={FormInput}
              name="firstName"
              type="text"
              placeholder={t('First Name')}
            />

            <Field
              label={t('Last Name *')}
              component={FormInput}
              name="lastName"
              type="text"
              placeholder={t('Last Name')}
            />

            <Field label={t('Email *')} component={FormInput} name="email" type="text" placeholder={t('Email')} />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleUpdateNotification)}
            >
              {t('Update')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues,
  createForm: state.form,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateNotification,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'editNotificationForm',
    enableReinitialize: true,
  })(EditNotificationModal),
);
