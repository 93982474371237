import { LOAD_THRESHOLD, LOAD_THRESHOLDS, LOAD_COUNT } from '../actions/types';

const INITIAL_STATE = {
  item: null,
  list: [],
};

const threshold = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_THRESHOLD:
      return { ...state, item: action.payload };
    case LOAD_THRESHOLDS:
      return { ...state, list: action.payload };
    case LOAD_COUNT:
      return { ...state, count: action.payload };
    default:
      return state;
  }
};

export default threshold;
