import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInput, SelectDropdown, InputWithoutLabel, FormCheckbox, FormQuill } from '../../form/parts';

const TaskLibraryEntryView = ({ t, args }) => (
  <div>
    <Field label={t('Name *')} component={FormInput} name="name" type="text" placeholder={t('Name')} />

    {args.isTaskCategoryShow && (
      <div className="form-group">
        <label className="col-sm-4 control-label">{t('Task Category *')}</label>

        <div className="col-sm-6">
          <Field
            name="category"
            component={SelectDropdown}
            drpName="category"
            options={args.taskCategoryList}
            placeholder={t('Choose task category')}
          />
        </div>
        <div className="col-sm-2">
          <button
            type="button"
            className="swal2-confirm swal2-styled margin_0 modal-button"
            onClick={args.onClickAddNewTaskCategory}
          >
            {t('Add New')}
          </button>
        </div>
      </div>
    )}

    <Field
      label={t('Repeat every *')}
      component={FormInput}
      name="repeatEvery"
      type="text"
      placeholder={t('Repeat every')}
      isDisabled={args.isCustomRepeatPeriod}
    />

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Repeat period *')}</label>

      <div className="col-sm-8">
        <Field
          name="repeatPeriod"
          component={SelectDropdown}
          drpName="repeatPeriod"
          options={args.repeatOptionList}
          _noLabelValue
          placeholder={t('Choose Repeat period')}
          onSelect={args.handleRepeatPeriod}
        />
      </div>
    </div>

    {args.isCustomRepeatPeriod && (
      <div className="form-group">
        <label className="col-sm-4 control-label">{t('Select custom date')}</label>
        <div className="col-sm-8 customRepearDateSelectMain">
          {args.customDateList.map((e, i) => {
            let isMach = args.customRepeatList.filter((ele) => ele.id == e.id);
            isMach = isMach.length > 0 ? true : false;
            return (
              <div
                className="customRepearDateSelect"
                style={{
                  backgroundColor: isMach ? 'rgb(26, 115, 232)' : 'lightgray',
                  color: isMach ? 'white' : 'black',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                onClick={() => args.handleCustomRepeatList({ id: e.id, val: e.val })}
              >
                <span>{e.val}</span>
              </div>
            );
          })}
        </div>
      </div>
    )}

    {args.isAPTAddNewTask && args.isTaskSimpleView === 'false' && (
      <div>
        <Field
          label={t('Labour Required')}
          component={FormInput}
          name="labourRequired"
          type="text"
          placeholder={t('Labour Required')}
        />

        <div className="form-group">
          <label className="col-sm-4 control-label">{t('Labour Period')}</label>

          <div className="col-sm-8">
            <Field
              name="labourPeriod"
              drpName="labourPeriod"
              component={SelectDropdown}
              options={args.periodOptionList}
              _noLabelValue
              placeholder={t('Labour Period')}
            />
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-4 control-label">{t('Compliance')}</label>

          <div className="col-sm-8">
            <Field
              name="compliance"
              drpName="compliance"
              component={SelectDropdown}
              options={args.taskCompliancesList}
              _optionLabel="name"
              _optionValue="id"
              _noLabelValue={false}
              placeholder={t('Select Compliance')}
            />
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-4 control-label">{t('Sfg20 title')}</label>

          <div className="col-sm-8">
            <div className="row">
              <Field
                component={InputWithoutLabel}
                name="majorSfg20"
                type="text"
                divClassName="col-sm-2"
                parse={(value) => (value !== '' ? value : null)}
              />
              <span className="col-sm-1" style={{ paddingTop: 14 }}>
                {' - '}
              </span>
              <Field
                component={InputWithoutLabel}
                name="minorSfg20"
                type="text"
                divClassName="col-sm-2"
                parse={(value) => (value !== '' ? value : null)}
              />
            </div>
          </div>
        </div>
      </div>
    )}

    {!args.isAPTAddNewTask && (
      <div>
        <Field
          label={t('Labour Required')}
          component={FormInput}
          name="labourRequired"
          type="text"
          placeholder={t('Labour Required')}
        />

        <div className="form-group">
          <label className="col-sm-4 control-label">{t('Labour Period')}</label>

          <div className="col-sm-8">
            <Field
              name="labourPeriod"
              drpName="labourPeriod"
              component={SelectDropdown}
              options={args.periodOptionList}
              _noLabelValue
              placeholder={t('Labour Period')}
            />
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-4 control-label">{t('Compliance')}</label>

          <div className="col-sm-8">
            <Field
              name="compliance"
              drpName="compliance"
              component={SelectDropdown}
              options={args.taskCompliancesList}
              _optionLabel="name"
              _optionValue="id"
              _noLabelValue={false}
              placeholder={t('Select Compliance')}
            />
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-4 control-label">{t('SFG20 Reference')}</label>

          <div className="col-sm-8">
            <div className="row">
              <Field
                component={InputWithoutLabel}
                name="majorSfg20"
                type="text"
                divClassName="col-sm-2"
                parse={(value) => (value !== '' ? value : null)}
              />
              <span className="col-sm-1" style={{ paddingTop: 14 }}>
                {' - '}
              </span>
              <Field
                component={InputWithoutLabel}
                name="minorSfg20"
                type="text"
                divClassName="col-sm-2"
                parse={(value) => (value !== '' ? value : null)}
              />
            </div>
          </div>
        </div>
      </div>
    )}

    <Field name="isRegulatory" component={FormCheckbox} label={t('Regulatory')} />

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Description *')}</label>

      <div className="col-sm-8 description-container" style={{ minHeight: 250 }}>
        <Field
          name="description"
          component={FormQuill}
          bounds=".description-container"
          placeholder="Enter a description of the task to be carried out"
        />
      </div>
    </div>
  </div>
);

const taskLibraryProps = PropTypes.shape({
  repeatOptionList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  periodOptionList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  taskCategoryList: PropTypes.arrayOf(PropTypes.shape()),
  isTaskCategoryShow: PropTypes.bool,
  onClickAddNewTaskCategory: PropTypes.func,
  isAPTAddNewTask: PropTypes.bool,
  isTaskSimpleView: PropTypes.string,
  taskCompliancesList: PropTypes.arrayOf(PropTypes.shape()),
}).isRequired;

TaskLibraryEntryView.propTypes = {
  args: taskLibraryProps,
  t: PropTypes.func.isRequired,
};

TaskLibraryEntryView.defaultProps = {
  args: {
    repeatOptionList: [],
    periodOptionList: [],
    taskCategoryList: [],
    isTaskCategoryShow: false,
    onClickAddNewTaskCategory: undefined,
    isAPTAddNewTask: false,
    isTaskSimpleView: 'true',
    taskCompliancesList: [],
  },
};

export default TaskLibraryEntryView;
