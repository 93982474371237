import React from 'react';
import PropTypes from 'prop-types';

const AddInModalViewSmall = ({ openModal, title, disabled }) => (
  <div className="wrap-for-max-width pull-right">
    <div className="text-right">
      <button className="btn oms-btn-small new" onClick={openModal} disabled={disabled}>
        {title}
      </button>
    </div>
  </div>
);

AddInModalViewSmall.propTypes = {
  openModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default AddInModalViewSmall;
