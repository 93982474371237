import {
  LOAD_MANUFACTURER,
  LOAD_MANUFACTURERS,
  LOAD_ALL_MANUFACTURERS,
  LOAD_MANUFACTURER_COUNT,
} from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  all: [],
  list: [],
};

const manufacturer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_MANUFACTURER: {
      const item = action.payload;
      let { list, all, count } = state;
      if (item.name !== null) {
        list = list.map((listItem) => (item.uuid === listItem.uuid ? item : listItem));
        all = all.map((listItem) => (item.uuid === listItem.uuid ? item : listItem));
      } else {
        list = list.filter((listItem) => item.uuid !== listItem.uuid);
        all = all.filter((listItem) => item.uuid !== listItem.uuid);
        count -= 1;
      }
      return {
        item,
        list,
        all,
        count,
      };
    }
    case LOAD_MANUFACTURERS:
      return { ...state, list: action.payload };
    case LOAD_ALL_MANUFACTURERS:
      return { ...state, all: action.payload };
    case LOAD_MANUFACTURER_COUNT:
      return { ...state, count: action.payload };
    default:
      return state;
  }
};

export default manufacturer;
