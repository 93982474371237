import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reduxForm, Field, reset } from 'redux-form';
import FilterButton from './FilterButton';
import DropFilterButton from './DropFilterButton';
import FilterInput from '../input/FilterInput';
import MonthPicker from '../picker/MonthPicker';
import { SelectDropdown, FormDatePicker } from '../form/parts';

import { COMPARISON, AMOUNT_TYPES, TASK_TYPES } from '../../constants/filterdata';
import userSelectedPreference from '../../lib/userSelectedPreference';

class Filter extends React.Component {
  static propTypes = {
    onFilter: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    filterFields: PropTypes.shape().isRequired,
    formWrapper: PropTypes.shape().isRequired,
    store: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    budgetPeriodFilter: PropTypes.bool,
    categoryGroupFilter: PropTypes.bool,
    categoryGroups: PropTypes.arrayOf(PropTypes.shape()),
    boolTypes: PropTypes.string,
  };

  static defaultProps = {
    boolTypes: null,
    budgetPeriodFilter: false,
    categoryGroupFilter: false,
    categoryGroups: [],
  };

  constructor(props) {
    super(props);
    const boolOptions = this.renderBoolOptions();
    const boolValues = this.renderBoolValues();
    let stateParams = {
      chosenField: null,
      chosenComparison: 'equal',
      chosenBool: boolOptions[0] || null,
      boolOptions,
      boolValues,
      strDate: null,
      value: null,
      startDate: null,
      endDate: null,
    };
    const { user } = props;
    const tempFilterData = userSelectedPreference.getSpecificTableFilterData({
      currentPageUrl: typeof window !== 'undefined' ? window.location.pathname : '',
      userId: user && user.id,
    });
    if (tempFilterData && tempFilterData.filterFieldInfo) {
      stateParams = tempFilterData.filterFieldInfo;
    }
    this.state = stateParams;
  }

  onFieldSelectChange = (option) => {
    if (option.hasOwnProperty('label')) {
      option = option.value;
    } else {
      option = undefined;
    }
    let { strDate } = this.state;

    if (option !== 'startDate' || option !== 'endDate') {
      strDate = null;
    }

    this.setState({
      value: null,
      chosenField: option,
      strDate,
    });
  };

  onComparisonSelectChange = (option) => {
    if (option.hasOwnProperty('label')) {
      option = option.value;
    } else {
      option = undefined;
    }
    this.setState({ chosenComparison: option });
  };

  onBoolSelectChange = (option) => {
    if (option.hasOwnProperty('label')) {
      option = option.value;
    } else {
      option = undefined;
    }
    this.setState({ chosenBool: option });
  };

  handleFilter = (filterData) => {
    const { chosenField, chosenComparison, chosenBool, strDate, boolValues, startDate, endDate, boolOptions } =
      this.state;
    // const { categoryGroups } = this.props;
    let { url, user } = this.props;
    if (!url || url === '') {
      url = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    }
    let filterParams = {};
    let filterValue = null;
    if (!chosenField) {
      const params = {
        startDate,
        endDate,
      };
      filterParams = _.pickBy(params, (property) => property);
    } else {
      const { type, value } = this.props.filterFields[chosenField];
      switch (type) {
        case 'date':
          filterValue = strDate;
          break;
        case 'boolean':
          filterValue = boolValues[chosenBool];
          break;
        default:
          filterValue = filterData.value;
      }
      filterParams = {
        filterField: value,
        fieldComparisonType: COMPARISON[chosenComparison],
        filterValue,
        startDate,
        endDate,
      };
      filterParams = _.pickBy(filterParams, (property) => property);
      this.setState({ value: filterData.value });
    }
    if (filterData.group) {
      // const categoryGroup = _.find(categoryGroups, group => filterData.group === group.name);
      filterParams.budgetCategoryGroupUUID = filterData.group.value; // categoryGroup.uuid;
    }
    userSelectedPreference.setUserSelectedPreference({
      currentPageUrl: url.asPath,
      userId: user && user.id,
      tableFilterPreference: {
        filterFieldInfo: {
          chosenField,
          strDate,
          chosenBool,
          boolOptions,
          boolValues,
          startDate,
          endDate,
          chosenComparison,
          value: filterValue,
        },
      },
    });
    this.props.onFilter(filterParams);
  };

  handleDropFilter = () => {
    this.setState({
      chosenField: null,
      chosenComparison: 'equal',
      chosenBool: this.state.boolOptions[0] || null,
      startDate: null,
      endDate: null,
    });
    let { url, user } = this.props;
    if (!url || url === '') {
      url = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    }
    userSelectedPreference.setUserSelectedPreference({
      currentPageUrl: url.asPath,
      userId: user && user.id,
      isFilterResetOnDropFilter: true,
    });

    this.props.store.dispatch(reset('filterForm'));
    this.props.onFilter({});
  };

  handleDate = (strDate) => {
    this.setState({ strDate });
  };

  handleBudgetStart = (startDate) => {
    this.setState({ startDate });
  };

  handleBudgetEnd = (endDate) => {
    this.setState({ endDate });
  };

  renderBoolOptions = () => {
    const { boolTypes } = this.props;
    switch (boolTypes) {
      case null:
        return [];
      case 'amount':
        return Object.keys(AMOUNT_TYPES);
      case 'task':
        return Object.keys(TASK_TYPES);
      default:
        return [];
    }
  };

  renderBoolValues = () => {
    const { boolTypes } = this.props;
    switch (boolTypes) {
      case null:
        return [];
      case 'amount':
        return AMOUNT_TYPES;
      case 'task':
        return TASK_TYPES;
      default:
        return [];
    }
  };

  render() {
    const { handleSubmit, filterFields, budgetPeriodFilter, categoryGroupFilter, categoryGroups, formWrapper, t } =
      this.props;
    const { chosenField, chosenComparison, chosenBool, value, boolOptions, startDate, endDate } = this.state;
    const { type } = chosenField ? filterFields[chosenField] : {};
    const showComparisonSelect = chosenField && type !== 'string' && type !== 'boolean';
    const showBoolSelect = chosenField && type === 'boolean';
    const showFilterInput = chosenField && type !== 'date' && type !== 'boolean';
    const showMonthPicker = chosenField && type === 'date';
    const categoryGroupNames = categoryGroups;
    const isCategoryGroupChosen =
      formWrapper.filterForm && formWrapper.filterForm.values && formWrapper.filterForm.values.group;

    return (
      <form onSubmit={handleSubmit(this.handleFilter)}>
        <div className="filter-wrapper">
          <Field
            name="filterFields"
            component={SelectDropdown}
            drpName="filterFields"
            options={Object.keys(filterFields)}
            _noLabelValue
            placeholder={t('Choose a field')}
            onChange={this.onFieldSelectChange}
            width="180px"
            defaultValue={{
              label: chosenField,
              value: chosenField,
            }}
          />

          {showBoolSelect && (
            <div>
              <Field
                name="boolOptions"
                component={SelectDropdown}
                drpName="boolOptions"
                options={boolOptions}
                _noLabelValue
                placeholder={t('Select value')}
                onChange={this.onBoolSelectChange}
                width="210px"
                isClearable={false}
                defaultValue={{
                  label: chosenBool,
                  value: chosenBool,
                }}
              />
            </div>
          )}

          {showComparisonSelect && (
            <div>
              <Field
                name="comparisonOptions"
                component={SelectDropdown}
                drpName="comparisonOptions"
                options={Object.keys(COMPARISON)}
                _noLabelValue
                placeholder={t('operation')}
                onChange={this.onComparisonSelectChange}
                width="210px"
                isClearable={false}
                defaultValue={{
                  label: chosenComparison,
                  value: chosenComparison,
                }}
              />
            </div>
          )}

          {!showComparisonSelect && !showBoolSelect && <div className="comparison-gap" />}

          {(!chosenField || showBoolSelect) && <div className="value-gap" />}

          {showFilterInput && (
            <Field label={t('Enter value')} component={FilterInput} name="value" type="text" selectedValue={value} />
          )}

          {showMonthPicker && (
            <div className="navbar-form">
              <Field
                name="firstTask"
                component={FormDatePicker}
                placeholder={t('Select Date')}
                onChangeExternal={this.handleDate}
              />
            </div>
          )}

          <FilterButton
            width="90px"
            onSubmit={handleSubmit(this.handleFilter)}
            disabled={!chosenField && !startDate && !endDate && !isCategoryGroupChosen}
            t={t}
          />

          <DropFilterButton width="120px" onDrop={this.handleDropFilter} t={t} />
        </div>

        {budgetPeriodFilter && (
          <div className="filter-wrapper">
            <MonthPicker changeDate={this.handleBudgetStart} placeholder={t('Budget period from')} />

            <MonthPicker changeDate={this.handleBudgetEnd} placeholder={t('Budget period to')} />
          </div>
        )}

        {categoryGroupFilter && (
          <div className="filter-wrapper">
            <Field
              name="group"
              component={SelectDropdown}
              drpName="group"
              options={categoryGroupNames}
              _optionLabel="name"
              _optionValue="uuid"
              isOptionFullInfoRequired
              placeholder={this.props.t('Category group')}
              width="180px"
            />
          </div>
        )}
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  const { form, user } = state;

  return {
    formWrapper: form,
    user: user.currentuser,
  };
};

// export default reduxForm({
//   form: 'filterForm',
// })(Filter);

export default connect(
  mapStateToProps,
  null,
)(
  reduxForm({
    form: 'filterForm',
  })(Filter),
);
