import { LOAD_CUSTOMER_CONTACT_LIST } from '../../actions/types';

const INITIAL_STATE = {
  customerContactList: [],
};

const internalContact = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_CUSTOMER_CONTACT_LIST:
      return { ...state, customerContactList: action.payload };

    default:
      return state;
  }
};

export default internalContact;
