import { LOAD_LOGS, SHOW_LOADER, HIDE_LOADER, LOAD_EMAIL_DELIVERY } from '../types';
import { api } from '../../lib/api';
import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/audit/log';
const webHookPath = '/v2/postmarkWebhook';

export const loadLogs = (logs) => ({
  type: LOAD_LOGS,
  payload: logs,
});

export const loadEmailDelivery = (logs) => ({
  type: LOAD_EMAIL_DELIVERY,
  payload: logs,
});

export const getAuditLogList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      const logData = response.data;
      dispatch(loadLogs(logData));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getAuditLogList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getEmailDeliveryReport = (id) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${webHookPath}/get-email-status/${id}/log`)
    .then((response) => {
      dispatch(loadEmailDelivery(response.data?.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getAuditLogList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
