import {
  SHOW_LOADER,
  HIDE_LOADER,
  LOAD_ACCOUNT_FULL_DATA,
  LOAD_ACCOUNT_SUBSCRIPTION_DATA,
  LOAD_ACCOUNT_SUBSCRIPTION_PRODUCT_LIST,
  LOAD_ACCOUNT_BILLINGS,
  LOAD_ACCOUNT_BILLINGS_COUNT,
  LOAD_ACCOUNT_ACTIVE_PAYMENTMETHOD_DATA,
  LOAD_ENTITLED_ACCOUNT_APPLICATION_FEATURE_LIST,
} from '../types';

import { api } from '../../lib/api';
import { baseUrl, apiURL } from '../../../common/config';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';
import commonFunction from '../../lib/commonFunction';

const adjustBillings = (billings) => {
  billings.invoiceAt = commonFunction.displayDateWithValidFormat(billings.invoiceAt);
  billings.createdAt = commonFunction.displayDateWithValidFormat(billings.createdAt, false);
};

export const loadAccountFullData = (accountFullData) => ({
  type: LOAD_ACCOUNT_FULL_DATA,
  payload: accountFullData,
});

export const loadAccountSubscriptionData = (accountSubscriptionData) => ({
  type: LOAD_ACCOUNT_SUBSCRIPTION_DATA,
  payload: accountSubscriptionData,
});

export const loadAccountSubscriptionProductList = (accountSubscriptionProductList) => ({
  type: LOAD_ACCOUNT_SUBSCRIPTION_PRODUCT_LIST,
  payload: accountSubscriptionProductList,
});

export const loadAccountBillings = (accountBillings) => ({
  type: LOAD_ACCOUNT_BILLINGS,
  payload: accountBillings,
});

export const loadAccountBillingsCount = (count) => ({
  type: LOAD_ACCOUNT_BILLINGS_COUNT,
  payload: count,
});

export const loadAccountActivePaymentMethodData = (activePaymentMethodData) => ({
  type: LOAD_ACCOUNT_ACTIVE_PAYMENTMETHOD_DATA,
  payload: activePaymentMethodData,
});

export const loadEntitledAccountApplicationFeature = (entitledFeatureList) => ({
  type: LOAD_ENTITLED_ACCOUNT_APPLICATION_FEATURE_LIST,
  payload: entitledFeatureList,
});

export const loadAccountFullDetail = (accountUUID) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`/v2/account/${accountUUID}`)
    .then((response) => {
      dispatch(loadAccountFullData(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, loadAccountFullDetail, accountUUID);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const loadSubscriptionDetail = (accountUUID) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`/v2/account/${accountUUID}/subscription/entitlement-detail`)
    .then((response) => {
      dispatch(loadAccountSubscriptionData(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, loadSubscriptionDetail, accountUUID);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const loadSubscriptionProductList = (accountUUID) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`/v2/account/${accountUUID}/subscription/application-detail`)
    .then((response) => {
      dispatch(loadAccountSubscriptionProductList(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, loadSubscriptionDetail, accountUUID);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAccountBillingList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get('/v2/account/billing/get-list', { params })
    .then((response) => {
      const newSchedules = response.data;
      dispatch(loadAccountBillings(newSchedules));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getAccountBillingList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAccountBillingListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get('/v2/account/billing/get-list-with-size', { params })
    .then((response) => {
      const newSchedules = response.data.rows;
      newSchedules.forEach(adjustBillings);
      dispatch(loadAccountBillings(newSchedules));
      dispatch(loadAccountBillingsCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getAccountBillingListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getActivePaymentMethod = (accountUUID) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`/v2/account/${accountUUID}/billing/payment-method/active`)
    .then((response) => {
      dispatch(loadAccountActivePaymentMethodData(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getActivePaymentMethod, accountUUID);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateBillingPaymentMethod = (accountUUID) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .put(`/v2/account/${accountUUID}/billing/payment-method`)
    .then((response) => {
      if (response.data) {
        window.location.href = response.data.redirect_url;
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateBillingPaymentMethod, accountUUID);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const handleGoCardlessRedirectFlow = (accountUUID, redirectFlowId, replacePrevious) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`/v2/account/${accountUUID}/${redirectFlowId}/card-less-flow/${replacePrevious}`)
    .then((response) => {
      window.location = `${baseUrl}/account/${accountUUID}`;
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, loadAccountFullDetail, accountUUID);
      window.location = `${baseUrl}/account/${accountUUID}`;
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const AddBillingPaymentMethod = (accountUUID) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .put(`/v2/account/${accountUUID}/billing/payment-method`)
    .then((response) => {
      if (response.data) {
        window.location.href = response.data.redirect_url;
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateBillingPaymentMethod, accountUUID);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const UpdateAccount = (account) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .put(`/v2/account/${account.uuid}`, account)
    .then((response) => {
      if (response.data) {
        dispatch(loadAccountFullData(response.data));
        window.location.href = window.location.href;
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, UpdateAccount, account.uuid);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getEntitledAccountApplicationFeature = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`/v2/account/${params.accountUUID}/application/${params.applicationId}/entitled-feature`)
    .then((response) => {
      dispatch(loadEntitledAccountApplicationFeature(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getEntitledAccountApplicationFeature, params.accountUUID);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const doCancelSubscription = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  const apiUrl = `/v2/account/${params.accountUUID}/cancel/subscription/${params.applicationId}`;
  api
    .put(apiUrl, params)
    .then((response) => {
      if (response.data) {
        window.location = response.data.redirect_url;
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, doCancelSubscription, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
