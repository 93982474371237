import { LOAD_ALL_ENUMERATIONS, LOAD_ACTIVE_ENUMERATIONS } from '../../actions/types';

const INITIAL_STATE = {
  all: [],
  activeList: [],
};

const enumeration = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_ALL_ENUMERATIONS:
      return { ...state, all: action.payload };

    case LOAD_ACTIVE_ENUMERATIONS:
      return { ...state, activeList: action.payload };

    default:
      return state;
  }
};

export default enumeration;
