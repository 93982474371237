import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import PictureEdit from '../../components/decorate/PictureEdit';

import { setPictures, setRemoved } from '../../actions/cafm/AttachmentActions';

export class AssetPicturesEdit extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    initialPictures: PropTypes.arrayOf(PropTypes.shape()),
    pictures: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    removed: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    setPictures: PropTypes.func.isRequired,
    setRemoved: PropTypes.func.isRequired,
  };

  static defaultProps = {
    initialPictures: [],
  };

  render() {
    return <PictureEdit {...this.props} showDefault={false} />;
  }
}

const mapStateToProps = ({ attachmentUpload: { pictures, removed } }) => ({
  pictures,
  removed,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPictures,
      setRemoved,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AssetPicturesEdit);
