import { LOAD_ACCESS_TOKEN, LOAD_REFRESH_TOKEN } from '../actions/types';

const INITIAL_STATE = {
  accessToken: null,
  refreshToken: null,
};

const user = (state = INITIAL_STATE, action) => {
  // maybe token?
  switch (action.type) {
    case LOAD_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload };
    case LOAD_REFRESH_TOKEN:
      return { ...state, refreshToken: action.payload };
    default:
      return state;
  }
};

export default user;
