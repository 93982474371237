import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import { FormInput } from '../form/parts';

import { createLookupvalue, updateLookupvalue } from '../../actions/cafm/LookupvaluesAction';

class AddNewLookupValue extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    createLookupvalue: PropTypes.func.isRequired,
    updateLookupvalue: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    createForm: PropTypes.shape(),
    initialValues: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    createForm: {
      addNewLookupValueForm: {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
    const form = nextProps.createForm.addNewLookupValueForm;
    if (form.values && nextProps.lookupValue) {
      if (nextProps.lookupValue.lookupvalue == form.values.lookupvalue && form.submitSucceeded) {
        nextProps.createForm.addNewAssetForm.values.condition = {
          label: nextProps.lookupValue.lookupvalue,
          value: nextProps.lookupValue.lookupvaluesid,
        };
        this.props.onModalClose();
      }
    }
  }

  closeModal = () => {
    this.props.onModalClose();
  };

  handleFormSubmit = (values) => {
    const { lookupname, lookupvalue, orderbyid } = values;
    const LookupValues = {
      lookupname,
      lookupvalue,
      orderbyid,
    };
    if (this.props.lookupvaluesid) {
      LookupValues.lookupvaluesid = this.props.lookupvaluesid;
      this.props.updateLookupvalue(LookupValues);
    } else {
      this.props.createLookupvalue(LookupValues);
    }
  };

  render() {
    const { handleSubmit, t, initialValues, title } = this.props;
    const { display } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
              ${!display && 'display-none'}`}
      >
        <div aria-modal="true" className="swal2-modal swal2-show modal-template form-horizontal">
          <h2 className="swal2-title">{title}</h2>

          <form>
            <Field label={t('Name *')} component={FormInput} name="lookupvalue" type="text" placeholder={t('Name')} />

            <Field label={t('Order *')} component={FormInput} name="orderbyid" type="text" placeholder={t('Order')} />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleFormSubmit)}
            >
              {t('Save')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ tokens: { accessToken }, lookupvalues: { item }, form }) => ({
  accessToken,
  lookupValue: item,
  createForm: form,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createLookupvalue,
      updateLookupvalue,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'addNewLookupValueForm',
  })(AddNewLookupValue),
);
