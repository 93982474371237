import React, { Component, useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';
import _, { isArray } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import { Badge } from 'primereact/badge';
import { DateRangePicker } from 'react-date-range';
import { getDropDownPTEvent } from '../../helpers/dropdownHelper';

/**
 * Custom DropDown Component
 * @param {*} param0
 * @returns
 */
const CustomDropDown = ({ setCustomFilter, filterData, oldSelectedList }) => {
  const [state, setState] = useState(null);
  const [selectedItemsLabel, setSelectedItemsLabel] = useState(filterData.name);

  useEffect(() => {
    if (filterData.options && oldSelectedList.length > 0) {
      const selected = filterData.options.filter((e) => oldSelectedList[0].filterValue.includes(e.id));
      setStateValue(selected, true);
    }
  }, []);

  const setStateValue = (selectedItems, isLoad = false) => {
    setState(selectedItems);
    setSelectedItemsLabel(getSelectedCountString(filterData.name, selectedItems || []));
    !isLoad &&
      setCustomFilter({
        fieldComparisonType: 'in',
        filterField: filterData.filterField,
        filterValue: selectedItems?.length > 0 ? selectedItems.map((e) => e.id) : [],
        removeFilter: !selectedItems || selectedItems?.length == 0 ? true : false,
      });
  };

  return (
    <div className="justify-content-start mr-1 mt-1">
      <MultiSelect
        onChange={(e) => {
          setStateValue(e.value);
        }}
        filter
        value={state}
        options={filterData.options}
        optionLabel={filterData.optionLabel}
        placeholder={filterData.placeholder}
        className="w-full h-4rem mr-2 customFilterMultiselect"
        maxSelectedLabels={0}
        selectedItemsLabel={selectedItemsLabel}
        showClear
        pt={getDropDownPTEvent()}
      />
    </div>
  );
};

/**
 * Custom Multi Header DropDown Component
 * @param {*} param0
 * @returns
 */
const CustomMultiHeaderDropDown = ({ setCustomFilter, filterData, oldSelectedList }) => {
  const [state, setState] = useState(null);
  const [selectedItemsLabel, setSelectedItemsLabel] = useState(filterData.name);

  useEffect(() => {
    if (filterData.options && oldSelectedList.length > 0) {
      const selected = oldSelectedList[0].filterValue;
      setStateValue(selected, true);
    }
  }, []);

  const setStateValue = (selectedItems, isLoad = false) => {
    setState(selectedItems);
    setSelectedItemsLabel(getSelectedCountString(filterData.name, selectedItems || [], 0));
    !isLoad &&
      setCustomFilter({
        fieldComparisonType: 'in',
        filterField: filterData.filterField,
        filterValue: selectedItems?.length > 0 ? selectedItems : [],
        removeFilter: !selectedItems || selectedItems?.length == 0 ? true : false,
      });
  };
  const groupedItemTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.label}</div>
      </div>
    );
  };
  return (
    <div className="justify-content-start mr-1 mt-1">
      <MultiSelect
        onChange={(e) => setStateValue(e.value)}
        filter
        value={state}
        options={filterData.options}
        optionLabel={filterData.optionLabel}
        placeholder={filterData.placeholder}
        className="w-full h-4rem mr-2 customFilterMultiselect"
        maxSelectedLabels={0}
        selectedItemsLabel={selectedItemsLabel}
        showClear
        optionGroupLabel="label"
        optionGroupChildren="items"
        optionGroupTemplate={groupedItemTemplate}
        pt={getDropDownPTEvent()}
      />
    </div>
  );
};

/**
 * Custom TextBox Component
 * @param {*} param0
 * @returns
 */
const CustomTextBox = ({ setCustomFilter, filterData, oldSelectedList }) => {
  const [state, setState] = useState(null);
  useEffect(() => {
    oldSelectedList.length > 0 && setState(oldSelectedList[0].filterValue);
  }, []);

  return (
    <div className="justify-content-start mr-1 mt-1">
      <span className="p-input-icon-right">
        <i className="pi pi-search" />
        <InputText
          placeholder={filterData.placeholder}
          value={state || ''}
          onChange={(e) => {
            setState(e.target.value);
            setCustomFilter({
              fieldComparisonType: 'like',
              filterField: filterData.filterField,
              filterValue: e.target.value,
              removeFilter: !e.target.value,
            });
          }}
          className="customFilterTextBox"
        />
      </span>
    </div>
  );
};

/**
 * Custom Range Calender Component
 */
class CustomRangeCalender extends Component {
  constructor(props) {
    super(props);
    this.date = new Date();
    this.defaultState = {
      selectionRange: [{ startDate: this.date, endDate: this.date, key: 'selection' }],
      visible: false,
      btnTxt: this.props.filterData.placeholder,
    };

    this.state = { ...this.defaultState };
  }

  setFilter = (isLoad = false) => {
    const { startDate, endDate } = this.state.selectionRange[0];
    let btnTxt = this.props.filterData.placeholder;
    let filterValue = null,
      fieldComparisonType = '=';
    if (moment(startDate).isSame(moment(endDate))) {
      btnTxt += `:${moment(startDate).format('DD/MM/YYYY')}`;
      fieldComparisonType = '=';
      filterValue = moment(startDate).format('YYYY-MM-DD');
    } else {
      btnTxt += `:${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`;
      fieldComparisonType = 'between';
      filterValue = [moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD')];
    }

    !isLoad &&
      this.props.setCustomFilter({
        filterField: this.props.filterData.filterField,
        fieldComparisonType,
        filterValue,
        removeFilter: false,
      });
    this.setState({ visible: false, btnTxt });
  };

  componentDidMount() {
    const { oldSelectedList } = this.props;
    if (oldSelectedList.length > 0) {
      let dateVals = {};
      if (_.isArray(oldSelectedList[0].filterValue)) {
        dateVals = {
          startDate: new Date(oldSelectedList[0].filterValue[0]),
          endDate: new Date(oldSelectedList[0].filterValue[1]),
        };
      } else {
        dateVals = {
          startDate: new Date(oldSelectedList[0].filterValue),
          endDate: new Date(oldSelectedList[0].filterValue),
        };
      }
      this.setState({ selectionRange: [{ ...dateVals, key: 'selection' }] }, () => {
        this.setFilter(true);
      });
    }
  }

  render() {
    const { filterData } = this.props;
    const footerContent = (
      <div className="">
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => this.setState({ visible: false })}
          className="p-button-text h-3rem"
        />
        <Button label="Yes" icon="pi pi-check" className="h-3rem" onClick={() => this.setFilter()} autoFocus />
      </div>
    );
    return (
      <div className="card flex justify-content-center mt-1">
        <div
          className="flex justify-content-center align-items-center mr-1"
          style={{ border: 'solid .5px gray', borderRadius: 5 }}
        >
          <Button
            label={this.state.btnTxt}
            className=" w-auto"
            severity="secondary"
            outlined
            onClick={() => this.setState({ visible: true })}
            style={{ border: 'none' }}
          />
          {this.state.btnTxt != this.props.filterData.placeholder && (
            <div
              className="flex align-items-center"
              style={{ width: 24, height: '100%' }}
              onClick={() => {
                this.setState({ ...this.defaultState });
                this.props.setCustomFilter({
                  filterField: this.props.filterData.filterField,
                  fieldComparisonType: '=',
                  filterValue: null,
                  removeFilter: true,
                });
              }}
            >
              <i className="fas fa-times ml-2"></i>
            </div>
          )}
        </div>
        <Dialog
          header={'Select ' + filterData.placeholder}
          visible={this.state.visible}
          style={{ width: 'auto', height: '448px' }}
          onHide={() => this.setState({ visible: false })}
          footer={footerContent}
        >
          <DateRangePicker
            ranges={this.state.selectionRange}
            onChange={(ranges) => this.setState({ selectionRange: [ranges.selection] })}
          />
        </Dialog>
      </div>
    );
  }
}

const getSelectedCountString = (baseString, selectedList, count = 1) => {
  if (selectedList.length > 0) {
    return (
      <span>
        {baseString}: {selectedList[0]?.name} &nbsp;
        {selectedList.length > count && (
          <Badge value={'+' + (selectedList.length - count)} style={{ fontSize: '12px' }} />
        )}
      </span>
    );
  } else {
    return <span>{baseString}</span>;
  }
};

/**
 * MAIN Component
 */
class CustomFilter extends Component {
  constructor(props) {
    super(props);
    this.defaultState = {
      selectedFilterList: [],
      loading: false,
      selectedItemsLabel: 'Filters',
    };

    this.state = { ...this.defaultState };
  }
  componentDidMount() {
    const { filters } = this.props.paramsWithFilter;

    this.setState({ ...this.getResetStateObj(filters) });
  }

  getResetStateObj = (filters = []) => {
    let oldFilterList = [];
    if (filters.length > 0) {
      oldFilterList = filters.map((e) => e.filterField);
    }
    const selectedList = this.props.customFilterList.filter(
      (e) => e?.isDefault || oldFilterList.includes(e.filterField),
    );
    return {
      selectedFilterList: selectedList,
      selectedItemsLabel: getSelectedCountString(this.defaultState.selectedItemsLabel, selectedList),
    };
  };

  resetFilter = _.debounce(() => {
    this.setState(
      {
        ...this.defaultState,
        loading: true,
        ...this.getResetStateObj(),
      },
      () => {
        this.props.setCustomFilter({ removeAll: true });
        this.props.executeResetFns();
        this.setState({ loading: false });
      },
    );
  }, 400);

  setFilterList = (e) => {
    const currentFilters = [...this.state.selectedFilterList];

    this.setState({
      selectedFilterList: [...e.value],
      selectedItemsLabel: getSelectedCountString(this.defaultState.selectedItemsLabel, e.value),
    });
    if (e.value && currentFilters) {
      if (e.value.length === 0) {
        this.props.setCustomFilter({ removeAll: true });
      } else if (e.value.length > 0 && currentFilters.length > 0) {
        const onlyremoved = currentFilters.filter((e1) => !e.value.some((e2) => e1.filterField === e2.filterField));
        for (let index = 0; index < onlyremoved.length; index++) {
          const ele = onlyremoved[index];
          this.props.setCustomFilter({
            fieldComparisonType: null,
            filterField: ele.filterField,
            filterValue: null,
            removeFilter: true,
          });
        }
      }
    }
  };

  scfDebounced = _.debounce((e) => this.props.setCustomFilter(e), 400);

  render() {
    const {
      t,
      customFilterList,
      paramsWithFilter: { filters },
      customFilterValues,
    } = this.props;
    const { selectedFilterList, loading, selectedItemsLabel } = this.state;

    return (
      <div className="flex flex-row flex-wrap " id="customFilter">
        {!loading &&
          selectedFilterList.length > 0 &&
          selectedFilterList.map((e, i) => {
            const oldSelectedList = filters.filter((ele) => ele.filterField === e.filterField);

            if (e.type === 'defaultSearch') {
              return (
                <CustomTextBox
                  key={e.filterField}
                  t={t}
                  setCustomFilter={this.scfDebounced}
                  filterData={e}
                  oldSelectedList={oldSelectedList}
                />
              );
            } else if (e.type === 'multiDropdown') {
              const filterValue =
                e.options && isArray(e.options) && e.options.length > 0
                  ? e.options
                  : customFilterValues?.[e.filterField] || [];

              return (
                filterValue.length > 0 && (
                  <CustomDropDown
                    key={e.filterField}
                    t={t}
                    setCustomFilter={this.scfDebounced}
                    filterData={{ ...e, options: filterValue }}
                    oldSelectedList={oldSelectedList}
                  />
                )
              );
            } else if (e.type === 'date') {
              return (
                <CustomRangeCalender
                  key={e.filterField}
                  setCustomFilter={this.scfDebounced}
                  filterData={e}
                  oldSelectedList={oldSelectedList}
                />
              );
            } else if (e.type === 'multiHeadingDropdown') {
              const filterValue = customFilterValues?.[e.filterField] || [];
              return (
                filterValue.length > 0 && (
                  <CustomMultiHeaderDropDown
                    key={e.filterField}
                    t={t}
                    setCustomFilter={this.scfDebounced}
                    filterData={{ ...e, options: filterValue }}
                    oldSelectedList={oldSelectedList}
                  />
                )
              );
            }
          })}

        <MultiSelect
          onChange={(e) => {
            this.setFilterList(e);
          }}
          value={this.state.selectedFilterList}
          options={customFilterList}
          optionLabel="name"
          placeholder="Filters"
          className="w-min h-4rem customFilterMultiselect mr-1  mt-1"
          maxSelectedLabels={0}
          selectedItemsLabel={selectedItemsLabel}
          pt={getDropDownPTEvent()}
        />
        <Button
          icon="pi pi-times"
          type="button"
          label="Reset"
          severity="secondary"
          raised
          className="mr-1 h-3rem align-self-center"
          onClick={this.resetFilter}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { form, user } = state;
  return {
    formWrapper: form,
    user: user.currentuser,
  };
};

export default memo(connect(mapStateToProps, null)(CustomFilter));
