import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

// import { CreateAssetForm } from '../form/cafm';
import { quickCreateAsset } from '../../actions/cafm/AssetActions';

import AddLookupValueModal from './AddLookupValueModal';

import { getLocationFullList } from '../../actions/cafm/LocationActions';
import { getManufacturerFullList } from '../../actions/cafm/ManufacturerActions';
import { getConditionOptions } from '../../constants/options';

import { getLookupvalueList } from '../../actions/cafm/LookupvaluesAction';
import AssetEntryView from '../cafm/admin/AssetEntryView';
import commonFunction from '../../lib/commonFunction';

class AddNewAssetModal extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    quickCreateAsset: PropTypes.func.isRequired,
    getLocationFullList: PropTypes.func.isRequired,
    getManufacturerFullList: PropTypes.func.isRequired,
    locations: PropTypes.arrayOf(PropTypes.shape()),
    manufacturers: PropTypes.arrayOf(PropTypes.shape()),
    handleSubmit: PropTypes.func.isRequired,
    createForm: PropTypes.shape(),
    open: PropTypes.bool.isRequired,
    conditionlist: PropTypes.arrayOf(PropTypes.shape()),
    t: PropTypes.func.isRequired,
    getLookupvalueList: PropTypes.func.isRequired,
  };

  static defaultProps = {
    locations: [],
    manufacturers: [],
    createForm: {
      addNewAssetForm: {},
    },
    conditionlist: [],
  };

  constructor(props) {
    super(props);
    const conditionOptions = getConditionOptions(props.t);

    const locationNames = props.locations;
    const conditionNames = props.conditionlist;
    const manufacturerNames = props.manufacturers;
    const { url } = props;

    this.state = {
      locationNames,
      manufacturerNames,
      modelNames: [],
      conditionOptions,
      isAssetSimpleView: 'true',
      addNewConditionModal: {
        open: false,
        initialValues: {},
      },
      conditionNames,
    };

    props.getLocationFullList({ siteId: this.props.initialValues.siteId });
    props.getManufacturerFullList();
    props.getLookupvalueList({ lookupname: 'Condition' });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }

    const locationNames = nextProps.locations;
    const conditionNames = nextProps.conditionlist;
    const manufacturerNames = nextProps.manufacturers;
    let modelNames = [];
    const form = nextProps.createForm.addNewAssetForm;
    if (form.values && form.values.manufacturer && form.values.manufacturer[0]) {
      const { value } = form.values.manufacturer[0];
      const chosenManufacturer = _.find(nextProps.manufacturers, ['id', value]);
      if (chosenManufacturer && chosenManufacturer.Model) {
        modelNames = chosenManufacturer.Model;
      }
    } else if (form.values && form.values.manufacturer && Object.keys(form.values.manufacturer).length <= 0) {
      // if manufacturer becomes null/cross/remove then clear model dropdown as it not clears model.
      // So selected value in model shows selected value of previous manufacturer if you select
      form.values.model = [];
      this.setState({
        modelNames: [],
      });
    }

    if (form.values && nextProps.asset) {
      if (nextProps.asset.name == form.values.name && nextProps.createForm.addNewAssetForm.submitSucceeded) {
        nextProps.createForm.quickAddNewPPMForm.values.asset = {
          label: nextProps.asset.name,
          value: nextProps.asset.id,
        };
        this.props.onModalClose();
      }
    }

    this.setState({
      locationNames,
      manufacturerNames,
      modelNames,
      conditionNames,
    });
  }

  handleFormSubmit = (values) => {
    const { name, description, quantity, location, model, manufacturer, installDate, condition, siteId } = values;
    const manufacturerLabel = manufacturer && manufacturer[0] && manufacturer[0].label;
    const manufacturerValue = manufacturer && manufacturer[0] && manufacturer[0].value;
    const modelLabel = model && model[0] && model[0].label;
    const modelValue = model && model[0] && model[0].value;
    const assetManufacturer = _.find(this.props.manufacturers, ['id', manufacturerValue]);
    const assetModel =
      assetManufacturer && assetManufacturer.Model ? _.find(assetManufacturer.Model, ['id', modelValue]) : null;

    const newAsset = {
      name,
      description,
      quantity,
      locationId: location && typeof location === 'object' ? location.value : undefined, // assetLocation ? assetLocation.id : undefined,
      installDate: commonFunction.getDateWithValidFormat(installDate),
      conditionId: condition && typeof condition === 'object' ? condition.value : undefined, // conditionId ? conditionId.lookupvaluesid : undefined,
      siteId,
    };
    if (assetModel && assetModel.uuid) {
      newAsset.modelUUID = assetModel.uuid;
    } else if (assetManufacturer) {
      newAsset.modelCreator = {
        modelName: modelLabel,
        manufacturerUUID: assetManufacturer.uuid,
      };
    } else if (modelLabel && manufacturerLabel) {
      newAsset.modelCreator = {
        modelName: modelLabel,
        manufacturerName: manufacturerLabel,
      };
    }
    this.props.quickCreateAsset(newAsset);
  };

  closeModal = () => {
    this.props.onModalClose();
  };

  handleAddNewCondition = () => {
    this.setState({
      addNewConditionModal: {
        open: true,
        initialValues: { lookupname: 'Condition' },
        title: 'Add new Condition',
      },
    });
  };

  closeConditionModal = () => {
    this.setState({
      display: false,
      addNewConditionModal: {
        open: false,
        initialValues: {},
      },
    });
  };

  handleAssetChangeSimpleandAdvanced = (value) => {
    this.setState({
      isAssetSimpleView: value,
    });
  };

  render() {
    const { handleSubmit, t, createForm, initialValues } = this.props;
    const {
      locationNames,
      manufacturerNames,
      modelNames,
      display,
      conditionOptions,
      isAssetSimpleView,
      addNewConditionModal,
      conditionNames,
    } = this.state;
    const showModels =
      modelNames.length > 0 ||
      (createForm.addNewAssetForm &&
        createForm.addNewAssetForm.values &&
        createForm.addNewAssetForm.values.manufacturer &&
        createForm.addNewAssetForm.values.manufacturer.length > 0) ||
      false;

    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                  ${!display && 'display-none'}`}
        // onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show quick-addnew-asset-modal form-horizontal"
          // onClick={(e) => {
          //   e.stopPropagation();
          // }}
        >
          <div className="col-sm-12 title_with_filter">
            <h2 className="swal2-title text-left col-sm-offset-3 col-sm-9">
              {t('Add New Asset')}
              <div className="switch_field">
                <input
                  type="radio"
                  name="AssetSimpleandAdvanced"
                  checked={isAssetSimpleView === 'false'}
                  onChange={(e) => this.handleAssetChangeSimpleandAdvanced(e.target.value)}
                  id="RBAssetAdvanced"
                  value="false"
                />{' '}
                <label className="radio_label" htmlFor="RBAssetAdvanced">
                  {t('Advanced')}
                </label>
                <input
                  type="radio"
                  name="AssetSimpleandAdvanced"
                  checked={isAssetSimpleView === 'true'}
                  onChange={(e) => this.handleAssetChangeSimpleandAdvanced(e.target.value)}
                  id="RBAssetSimple"
                  value="true"
                />{' '}
                <label className="radio_label" htmlFor="RBAssetSimple">
                  {t('Simple')}
                </label>
              </div>
            </h2>
          </div>

          <form>
            <AssetEntryView
              t={t}
              args={{
                isAPTAddNewTask: true,
                isAssetSimpleView,
                conditionList: conditionNames,
                onClickNewCondition: this.handleAddNewCondition,
                locationList: locationNames,
                manufacturerList: manufacturerNames,
                modelList: modelNames,
                showModels,
              }}
            />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleFormSubmit)}
            >
              {t('Save')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
        {
          addNewConditionModal.open && (
            <AddLookupValueModal
              open={addNewConditionModal.open}
              onModalClose={this.closeConditionModal}
              initialValues={addNewConditionModal.initialValues}
              t={t}
              title={addNewConditionModal.title}
            />
          )
          // <AddNewConditionModal
          //   open={addNewConditionModal.open}
          //   onModalClose={this.closeConditionModal}
          //   initialValues={addNewConditionModal.initialValues}
          //   t={t}
          // />
        }
      </div>
    );
  }
}

const mapStateToProps = ({ tokens: { accessToken }, asset: { item }, location, manufacturer, form, lookupvalues }) => ({
  accessToken,
  asset: item,
  locations: location.parent,
  manufacturers: manufacturer.all,
  createForm: form,
  conditionlist: lookupvalues.list,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      quickCreateAsset,
      getLocationFullList,
      getManufacturerFullList,
      getLookupvalueList,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'addNewAssetForm',
  })(AddNewAssetModal),
);
