export const applicationFeature = {
  ALP: {
    TIMEOFF: 1,
    SETTINGS: 2,
    EMPLOYEE: 3,
    TEAM: 4,
  },
  JMS: {
    QUOTE: 5,
    JOB: 6,
    PURCHASEORDER: 7,
    COMPANIES: 8,
    JOBVISIT: 9,
    JOBVISITTIMELOG: 10,
    INVOICE: 19,
    ADVANCEDINVOICE: 21,
  },
  CAFM: {
    SITEDASHBOARD: 11,
    SITEADMIN: 12,
    PPMCALENDAR: 13,
    HELPDESK: 14,
    ADVANCEDTICKETING: 15,
    DOCUMENTS: 16,
    INDUCTION: 17,
    COMPANIES: 18,
    PERMITS: 22,
    RISK: 23,
  },
  ADDON: {
    SYSCOM: 20,
  },
};
