import axios from 'axios';

import { Router } from '../../common/routes';

import { LOAD_ACCESS_TOKEN, LOAD_REFRESH_TOKEN } from './types';

import { refreshURL } from '../../common/config';
import initRedux from '../lib/initRedux';
import { api } from '../lib/api';
import { showErrorModal } from './ErrorActions';

const handleRefreshError = (dispatch) => {
  axios
    .post('/logout', {}, { headers: { 'Content-Type': 'application/json' } })
    .then(() => {
      Router.pushRoute('auth');
    })
    .catch(() => {
      const errorList = [
        {
          message: 'logout error',
        },
      ];
      dispatch(showErrorModal(errorList));
    });
};

export const loadAccessToken = (token) => ({
  type: LOAD_ACCESS_TOKEN,
  payload: token,
});

export const loadRefreshToken = (token) => ({
  type: LOAD_REFRESH_TOKEN,
  payload: token,
});

export const getTokenRefreshed = (query, params) => {
  const redux = initRedux();
  const { refreshToken } = redux.getState().tokens;

  return (dispatch) => {
    axios
      .post(refreshURL, { refreshToken })
      .then((response) => {
        const {
          data: { accessToken },
        } = response;

        api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

        dispatch(loadAccessToken(accessToken));
        dispatch(query(...params));
        axios.post('/save_tokens', { accessToken, refreshToken }, { headers: { 'Content-Type': 'application/json' } });
      })
      .catch(() => {
        handleRefreshError(dispatch);
      });
  };
};

export const handleErrors = (error, dispatch, query, ...params) => {
  let errorList = [];
  if (!error || !error.response) {
    errorList = [
      {
        message: 'cannot read error response',
      },
    ];
    dispatch(showErrorModal(errorList));
  } else if (error.response.status > 499) {
    errorList = [
      {
        message: 'cannot get information from server',
      },
    ];
    dispatch(showErrorModal(errorList));
  } else if (!error.response.data || !error.response.data[0] || !error.response.data[0].message) {
    errorList = [
      {
        message: 'cannot read error message',
      },
    ];
    dispatch(showErrorModal(errorList));
  } else {
    const { message } = error.response.data[0];
    if (message === 'Access token is expired') {
      dispatch(getTokenRefreshed(query, params));
    }
  }
};
