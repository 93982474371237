import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { SimpleModal } from '../../components/modals';
import { SmartTable } from '../../containers/decorate';
import { HEADERS_SCHEDULE_ATTACHMENT } from '../../constants/tabledata/cafm/maintenanceScheduleAttachments';
import { FILTER_SCHEDULE_ATTACHMENTS } from '../../constants/filter/cafm';
import { AddAttachmentModal } from '../modals/cafm';

import {
  getMaintenanceScheduleAttachmentListWithSize,
  getMaintenaneScheduleAttachmentList,
  downloadAttachment,
  deleteAttachment,
} from '../../actions/cafm/AttachmentActions';

class AttachmentView extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    t: PropTypes.func,
    url: PropTypes.shape(),
    store: PropTypes.shape({
      dispatch: PropTypes.func.isRequired,
    }).isRequired,
    rowId: PropTypes.number.isRequired, // it will be scheduleId, siteId (if site attachment), assetId (if asset attachment)
    entityType: PropTypes.string.isRequired, // it will be schedule, site (if site attachment), asset (if asset attachment)
    attachmentsList: PropTypes.arrayOf(PropTypes.shape()),
    count: PropTypes.number,
    getMaintenaneScheduleAttachmentList: PropTypes.func,
    getMaintenanceScheduleAttachmentListWithSize: PropTypes.func,
    downloadAttachment: PropTypes.func,
    deleteAttachment: PropTypes.func,
  };

  static defaultProps = {
    attachmentsList: [],
    count: 0,
    getMaintenaneScheduleAttachmentList: undefined,
    getMaintenanceScheduleAttachmentListWithSize: undefined,
    downloadAttachment: undefined,
    deleteAttachment: undefined,
  };

  constructor(props) {
    super(props);

    const { rowId, entityType } = props;
    let tempHeaders = [];
    let tempFiltersInfo = {};
    if (entityType === 'maintenanceschedule') {
      tempHeaders = HEADERS_SCHEDULE_ATTACHMENT;
      tempFiltersInfo = FILTER_SCHEDULE_ATTACHMENTS;
    }
    this.state = {
      headers: tempHeaders,
      extraParams: {
        maintenanceScheduleId: rowId,
      },
      tempFiltersInfo,
      deleteAttachmentModal: {
        id: null,
        name: null,
        open: false,
      },
      addAttachmentModal: {
        open: false,
        initialValues: {},
        title: '',
      },
    };
  }

  handleDownloadAttachment = (id) => {
    const { entityType } = this.props;
    this.props.downloadAttachment(id, entityType);
  };

  handleDeleteAttachment = (id) => {
    const { entityType } = this.props;
    const chosenAttachment = this.props.attachmentsList.find((data) => data.id === id);
    this.setState({
      deleteAttachmentModal: {
        id,
        name: chosenAttachment.name,
        open: true,
      },
    });
  };

  deleteAttachmentExecute = () => {
    const { entityType } = this.props;
    const { id } = this.state.deleteAttachmentModal;
    this.props.deleteAttachment(id, entityType);
  };

  handleModalsClose = () => {
    this.setState({
      deleteAttachmentModal: {
        id: null,
        open: false,
      },
      addAttachmentModal: {
        open: false,
        initialValues: {},
        title: '',
      },
    });
  };

  handleAddNewAttachment = () => {
    this.setState({
      addAttachmentModal: {
        open: true,
        initialValues: {
          pictures: this.props.pictures || [],
        },
        title: 'Add New Attachment',
      },
    });
  };

  render() {
    const { attachmentsList, count, store, url, t, rowId, entityType } = this.props;
    const { headers, extraParams, tempFiltersInfo, deleteAttachmentModal, addAttachmentModal } = this.state;
    let tempList = [];
    if (entityType === 'maintenanceschedule') {
      extraParams.maintenanceScheduleId = rowId;
      tempList = attachmentsList;
    }
    let funcs = [this.handleDownloadAttachment];
    let intableMenu = [
      {
        type: 'download',
        title: 'Download attachment',
        funcNumber: 0,
      },
    ];
    intableMenu = [
      ...intableMenu,
      {
        type: 'remove',
        title: 'Remove attachment',
        funcNumber: intableMenu.length,
      },
    ];
    funcs = [...funcs, this.handleDeleteAttachment];

    const filterValues = {
      attachmentsList,
    };
    return (
      <div className="container-fluid no-padding-h default-color zero-side-paddings">
        <SmartTable
          orderField="friendlyName"
          list={tempList}
          headers={headers}
          intableMenu={intableMenu}
          addNewItem={{
            isModal: true,
            openModal: this.handleAddNewAttachment,
            params: {},
            title: 'Add New Attachment',
          }}
          store={store}
          count={count}
          getList={this.props.getMaintenaneScheduleAttachmentList}
          getListWithSize={this.props.getMaintenanceScheduleAttachmentListWithSize}
          filterData={tempFiltersInfo}
          extraParams={extraParams}
          funcs={funcs}
          typeId="id"
          useSmartFilter
          filterValues={filterValues}
          t={t}
        />

        {addAttachmentModal.open && (
          <AddAttachmentModal
            open={addAttachmentModal.open}
            onModalClose={this.handleModalsClose}
            initialValues={addAttachmentModal.initialValues}
            msId={rowId ? parseInt(rowId) : 0}
            t={t}
            title={addAttachmentModal.title}
          />
        )}

        {deleteAttachmentModal.open && (
          <SimpleModal
            open={deleteAttachmentModal.open}
            id={deleteAttachmentModal.id}
            onModalClose={this.handleModalsClose}
            header={t('Delete attachment')}
            text={`${t('Are you sure you want to delete attachment')}?`}
            handleOk={this.deleteAttachmentExecute}
            t={t}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    tokens: { accessToken },
    attachmentUpload: { list, count, pictures },
  } = state;

  return {
    accessToken,
    attachmentsList: list || [],
    pictures: pictures || [],
    count: count || 0,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMaintenaneScheduleAttachmentList,
      getMaintenanceScheduleAttachmentListWithSize,
      downloadAttachment,
      deleteAttachment,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'attachmentTableForm',
  })(AttachmentView),
);
