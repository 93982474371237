import React from 'react';
import PropTypes from 'prop-types';

const FormLabel = ({ label, name, isShort, isLong, tooltipText }) => (
  <label
    title={tooltipText}
    className={`${isShort ? 'col-sm-3' : isLong ? 'col-sm-6' : 'col-sm-4'} control-label`}
    htmlFor={name}
  >
    {label}
  </label>
);

FormLabel.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  isShort: PropTypes.bool,
  isLong: PropTypes.bool,
  tooltipText: PropTypes.string,
};

FormLabel.defaultProps = {
  isShort: false,
  isLong: false,
  tooltipText: '',
};

export default FormLabel;
