import React from 'react';
import PropTypes from 'prop-types';

const DropFilterButton = ({ width, onDrop, t }) => (
  <button className="filter-button" type="button" onClick={onDrop} style={{ width }}>
    {t('Drop Filter')}
  </button>
);

DropFilterButton.propTypes = {
  width: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

DropFilterButton.defaultProps = {
  width: '100%',
};

export default DropFilterButton;
