import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TitleView from './TitleView';

const ButtonsView = ({ buttonsArray, title, t }) => (
  <div className={`${title ? 'smart-header-title' : 'smart-header-no-title'}`}>
    {title && <TitleView title={title} t={t} />}

    <div className="profile-buttons">
      {buttonsArray.map((button) => (
        <button
          key={button.text}
          className={classNames('filter-button', { 'long-button': button.isLong })}
          onClick={button.onClick}
        >
          {button.text}
        </button>
      ))}
    </div>
  </div>
);

ButtonsView.propTypes = {
  buttonsArray: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      text: PropTypes.string.isRequired,
      isLong: PropTypes.bool,
    }),
  ).isRequired,
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
};

ButtonsView.defaultProps = {
  title: null,
};

export default ButtonsView;
