import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { updateBuildingBudget } from '../../actions/bms/BuildingBudgetActions';
import { FormInput } from '../form/parts';
import { RangeMonthPicker } from '../picker';

class EditBuildingBudgetModal extends Component {
  static propTypes = {
    uuid: PropTypes.string.isRequired,
    initialValues: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    updateBuildingBudget: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      display: false,
      toggleClose: false,
      startDate: props.initialValues.startDate,
      endDate: props.initialValues.endDate,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
  }

  changeDates = (startDate, endDate) => {
    this.setState({ startDate, endDate });
  };

  closeModal = () => {
    this.setState({
      display: false,
    });
    this.props.onModalClose();
  };

  handlePickerClose = () => {
    this.setState({
      toggleClose: !this.state.toggleClose,
    });
  };

  handleUpdateBuildingBudget = (buildingBudget) => {
    const { uuid } = this.props;
    const { startDate, endDate } = this.state;
    const newBuildingBudget = {
      uuid,
      amount: buildingBudget.amount,
      startDate,
      endDate,
    };
    this.props.updateBuildingBudget(newBuildingBudget);
    this.closeModal();
  };

  render() {
    const { handleSubmit, t } = this.props;
    const { display, startDate, endDate, toggleClose } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
                  ${!display && 'display-none'}`}
        onClick={this.closeModal}
      >
        <div
          aria-modal="true"
          className="swal2-modal swal2-show modal-overflow profile-modal edit-building-budget-modal form-horizontal"
          onClick={(e) => {
            e.stopPropagation();
            this.handlePickerClose();
          }}
        >
          <h2 className="swal2-title">{t('Update building budget')}</h2>

          <form>
            <div className="form-group">
              <label className="col-sm-2 control-label">{t('Date range')}</label>

              <div className="col-sm-10">
                <RangeMonthPicker
                  changeDates={this.changeDates}
                  placeholderStart={t('Pick start date')}
                  placeholderEnd={t('Pick end date')}
                  toggleClose={toggleClose}
                  initialValues={{
                    startDate,
                    endDate,
                  }}
                />
              </div>
            </div>

            <Field
              label={t('Amount')}
              component={FormInput}
              isShort
              name="amount"
              type="text"
              placeholder={t('Enter amount')}
            />
          </form>

          <div className="swal2-buttonswrapper display-block">
            <button
              type="submit"
              className="swal2-confirm swal2-styled btn-primary"
              onClick={handleSubmit(this.handleUpdateBuildingBudget)}
            >
              {t('Update')}
            </button>
            &nbsp;
            <button type="button" className="swal2-confirm swal2-styled btn-default" onClick={this.closeModal}>
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateBuildingBudget,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'editBuildingBudgetForm',
    enableReinitialize: true,
  })(EditBuildingBudgetModal),
);
