import {
  LOAD_TASK_CATEGORY,
  LOAD_TASK_CATEGORIES,
  LOAD_ALL_TASK_CATEGORIES,
  LOAD_TASK_CATEGORY_COUNT,
} from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  list: [],
  all: [],
};

const taskCategory = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_TASK_CATEGORY: {
      const item = action.payload;
      let { list, all, count } = state;
      if (item.name !== null) {
        if (all.find((element) => element.id === item.id) === undefined) {
          all.push(item);
        }
        if (list.find((element) => element.id === item.id) === undefined) {
          list.push(item);
        }

        list = list.map((listItem) => (item.uuid === listItem.uuid ? item : listItem));
        all = all.map((listItem) => (item.uuid === listItem.uuid ? item : listItem));
        if (item.path) {
          list = list.filter((listItem) => {
            const check = !listItem.siteId || +item.path === listItem.siteId;
            if (!check) {
              count -= 1;
            }
            return check;
          });
        }
      } else {
        list = list.filter((listItem) => item.uuid !== listItem.uuid);
        all = all.filter((listItem) => item.uuid !== listItem.uuid);
        count -= 1;
      }
      return {
        item,
        list,
        count,
        all,
      };
    }
    case LOAD_TASK_CATEGORIES:
      return { ...state, list: action.payload };
    case LOAD_ALL_TASK_CATEGORIES:
      return { ...state, all: action.payload };
    case LOAD_TASK_CATEGORY_COUNT:
      return { ...state, count: action.payload };
    default:
      return state;
  }
};

export default taskCategory;
