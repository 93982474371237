import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import classNames from 'classnames';

const ProfileDataView = ({ label, value, isInForm, showAsCurrency }) => (
  <div className="form-group">
    <label className={classNames('col-sm-4 control-label', { 'form-data-view': isInForm })}>{label}</label>

    <div className={classNames('col-sm-8 profile-values', { 'in-form-value': isInForm })}>
      <span>{showAsCurrency ? numeral(value).format() : value}</span>
    </div>
  </div>
);

ProfileDataView.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  isInForm: PropTypes.bool,
  showAsCurrency: PropTypes.bool,
};

ProfileDataView.defaultProps = {
  value: '',
  isInForm: false,
  showAsCurrency: false,
};

export default ProfileDataView;
