import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { SelectDropdown } from '../parts';

const ApproverForm = ({ t, approverUsersList, approver }) => (
  <div className="form-group">
    <label className="col-sm-3 control-label">{t('Task Comment Approver')}</label>
    <div className="col-sm-8">
      <Field
        name="approver"
        component={SelectDropdown}
        drpName="approver"
        options={approverUsersList.map((item) => ({
          name: item.fullName,
          approverId: item.id,
        }))}
        isMulti
        _optionLabel="name"
        _optionValue="approverId"
        placeholder={t('Task Comment Approver')}
        defaultValue={approver}
      />
    </div>
  </div>
);

ApproverForm.propTypes = {
  t: PropTypes.func.isRequired,
  approverUsersList: PropTypes.arrayOf(PropTypes.shape()),
  approver: PropTypes.arrayOf(PropTypes.shape()),
};

export default ApproverForm;
