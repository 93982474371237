import React from 'react';
import PropTypes from 'prop-types';

import { Router } from '../../../common/routes';

class IntableMenu extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]).isRequired,
    t: PropTypes.func.isRequired,
    typeId: PropTypes.string,
    intableMenu: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    funcs: PropTypes.arrayOf(PropTypes.func),
    firstOrLastRow: PropTypes.bool.isRequired,
    navParams: PropTypes.shape().isRequired,
    taskParam: PropTypes.string.isRequired,
    row: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    typeId: null,
    funcs: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  setItemIcon = (type) => {
    switch (type) {
      case 'profile':
        return 'fa-folder-open';
      case 'view':
        return 'fa-folder-open';
      case 'duplicate':
        return 'fa-copy';
      case 'tasks':
        return 'fa-tasks';
      case 'edit':
        return 'fa-edit';
      case 'remove budget':
        return 'fa-times';
      case 'remove task budget':
        return 'fa-times';
      case 'remove user':
        return 'fa-times';
      case 'remove':
        return 'fa-times';
      case 'ban':
        return 'fa-ban';
      case 'home':
        return 'fa-home';
      case 'grant-access': //1453/1446
        return 'fa-unlock';
      case 'start-survey': //1453/1446
        return 'fa-tasks';
      case 'download': //1453/1446
        return 'fa fa-cloud-download';
      case 'move': //1453/1446
        return 'fa fa-arrows-alt';
      case 'clone': //1453/1446
        return 'fa fa-clone';
      case 'attach':
        return 'fa fa-plus-circle';
      case 'review-survey':
        return 'fa fa-server';
      case 'job-time-log':
        return 'fa fa-clock';

      default:
        return 'fa-folder-open';
    }
  };

  handleMenuEnter = () => {
    this.setState({ isOpen: true });
  };

  handleMenuLeave = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { id, typeId, intableMenu, funcs, firstOrLastRow, navParams, taskParam, t, row } = this.props;
    if (intableMenu.length === 0) return;
    return (
      <div className="intable-slide-menu" onMouseEnter={this.handleMenuEnter} onMouseLeave={this.handleMenuLeave}>
        <li className={`list-group-item${this.state.isOpen ? ' open' : ''}`}>
          <span className="show-menu">
            <span className="btn-menu" />
          </span>

          <ul className="list-group-submenu">
            {intableMenu.map((item) => {
              if (!firstOrLastRow && item.type === 'remove budget') {
                return null;
              }
              if (item.hasOwnProperty('condition')) {
                if (row[item.condition.field] != item.condition.value) {
                  return null;
                }
              }

              const routeParams = {
                [typeId]: id,
                ...item.params,
                ...navParams,
              };

              return (
                <li key={item.title} className="list-group-submenu-item">
                  <button
                    title={t(`${item.title}`)}
                    onClick={() => {
                      if (item.type === 'tasks') {
                        taskParam
                          ? Router.pushRoute(item.route, { [item.param]: taskParam })
                          : Router.pushRoute('add_building_budget', routeParams);
                      }
                      item.type === 'profile' && Router.pushRoute(item.route, routeParams);
                      item.funcNumber !== undefined && funcs[item.funcNumber](id);
                    }}
                    className="btn wrap-btn-round"
                  >
                    <i className={`fas fa-1p5x ${this.setItemIcon(item.type)}`} />
                  </button>
                </li>
              );
            })}
          </ul>
        </li>
      </div>
    );
  }
}

export default IntableMenu;
