import React from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { getDropDownPTEvent } from '../../helpers/dropdownHelper';

export default function AddMoreColumnOptionsButton({
  multipleColumns = [],
  setMultipleColumns,
  headers = [],
  columnMoreoptions = [],
}) {
  return (
    <MultiSelect
      value={multipleColumns}
      onChange={(e) => setMultipleColumns(e.value)}
      options={[...headers, ...columnMoreoptions]}
      optionLabel="label"
      placeholder="Select Columns"
      className="w-full md:w-15rem h-4rem mr-2"
      id="multiSelectColumn"
      maxSelectedLabels={0}
      selectedItemsLabel={'Add More Columns'}
      pt={getDropDownPTEvent()}
    />
  );
}
