import {
  LOAD_ASSET_GROUP,
  LOAD_ASSET_GROUPS,
  LOAD_ALL_ASSET_GROUPS,
  LOAD_ASSET_GROUP_COUNT,
} from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  list: [],
  all: [],
};

const assetGroup = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_ASSET_GROUP: {
      const item = action.payload;
      let { list, count, all } = state;
      if (item.name !== null) {
        if (all.find((element) => element.id === item.id) === undefined) {
          all.push(item);
        }
        if (list.find((element) => element.id === item.id) === undefined) {
          list.push(item);
        }

        list = list.map((listItem) => (item.id === listItem.id ? item : listItem));
        all = all.map((listItem) => (item.id === listItem.id ? item : listItem));
      } else {
        list = list.filter((listItem) => item.id !== listItem.id);
        all = all.filter((listItem) => item.id !== listItem.id);
        count -= 1;
      }
      return {
        ...state,
        item,
        list,
        count,
        all,
      };
    }
    case LOAD_ASSET_GROUPS:
      return { ...state, list: action.payload };
    case LOAD_ALL_ASSET_GROUPS:
      return { ...state, all: action.payload };
    case LOAD_ASSET_GROUP_COUNT:
      return { ...state, count: action.payload };
    default:
      return state;
  }
};

export default assetGroup;
