import moment from 'moment';
import { LOAD_CALENDAR } from '../../actions/types';

const INITIAL_STATE = {
  listData: [],
  selectedUser: [],
  startDate: moment().format('YYYY-MM-DD HH:mm:ss'),
  endDate: moment().format('YYYY-MM-DD HH:mm:ss'),
};

const calendarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_CALENDAR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default calendarReducer;
