import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export class LoaderContainer extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
    };

    this.timeoutPointer = null;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading) {
      this.timeoutPointer = setTimeout(() => {
        this.setState({ showLoader: true });
      }, 500);
    } else {
      clearTimeout(this.timeoutPointer);
      this.setState({ showLoader: false });
    }
  }

  render() {
    const { showLoader } = this.state;
    return (
      <div
        className={['swal2-container', 'swal2-center', 'swal2-shown', !showLoader ? 'display-none' : ''].join(' ')}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <i className="fas fa-spinner fa-spin fa-3x fa-fw" style={{ color: '#185ec4' }} />

        <span className="sr-only">Loading...</span>
      </div>
    );
  }
}

const mapStateToProps = ({ isLoading }) => ({ isLoading });

export default connect(mapStateToProps)(LoaderContainer);
