import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import { SelectDropdown } from '../../form/parts';

import { updateAccount } from '../../../actions/cafm/AccountActions';

class EditTaskApprovalModal extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    createForm: PropTypes.shape(),
    initialValues: PropTypes.shape().isRequired,
    updateAccount: PropTypes.func.isRequired,
  };

  static defaultProps = {
    createForm: {
      editTaskApprovalform: {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        display: true,
      });
    }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
    this.props.onModalClose();
  };

  handleFormSubmit = (values) => {
    const { taskApprovalRequired } = values;
    const accountValues = {
      taskApprovalRequired: taskApprovalRequired.value, // taskApprovalRequired === 'Yes' ? 1 : 0,
    };
    this.props.updateAccount(accountValues);
    this.props.onModalClose();
  };

  render() {
    const { handleSubmit, t, title } = this.props;
    const { display } = this.state;
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyPress={() => {}}
        className={`swal2-container swal2-center swal2-fade swal2-shown modal-overflow
              ${!display && 'display-none'}`}
      >
        <div aria-modal="true" className="swal2-modal swal2-show modal-template form-horizontal">
          <h2 className="swal2-title">{title}</h2>

          <form>
            <div className="form-group">
              <label className="col-sm-7 control-label">{t('Notification Required?')}</label>
              <div className="col-sm-3">
                <Field
                  name="taskApprovalRequired"
                  drpName="taskApprovalRequired"
                  component={SelectDropdown}
                  options={[
                    { label: 'Yes', value: '1' },
                    { label: 'No', value: '0' },
                  ]}
                  placeholder={t('Notification Required?')}
                  isClearable={false}
                  _optionLabel="label"
                  _optionValue="value"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-8">{t('if yes, all reactive helpdesk issues will required approval.')}</label>
            </div>
            <div className="swal2-buttonswrapper display-block">
              <button
                type="submit"
                className="swal2-confirm swal2-styled btn-primary"
                id="btnSubmit"
                onClick={handleSubmit(this.handleFormSubmit)}
              >
                {t('Update')}
              </button>
              &nbsp;
              <button
                type="button"
                className="swal2-confirm swal2-styled btn-default"
                id="btnClose"
                // onClick={this.closeModal}
                onClick={(e) => this.closeModal()}
              >
                {t('Cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ tokens, form, account }) => ({
  accessToken: tokens && tokens.accessToken,
  createForm: form,
  account: account && account.item,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateAccount,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'editTaskApprovalform',
  })(EditTaskApprovalModal),
);
