import { LOAD_ACTIVITY, LOAD_ACTIVITY_ITEM } from '../../actions/types';

const INITIAL_STATE = {
  list: null,
  item: {},
};

const ActivityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_ACTIVITY:
      return { ...state, list: action.payload };

    case LOAD_ACTIVITY_ITEM:
      return { ...state, item: action.payload };
    default:
      return state;
  }
};

export default ActivityReducer;
