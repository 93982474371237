import { LOAD_THRESHOLD, LOAD_THRESHOLDS, LOAD_COUNT, SHOW_LOADER, HIDE_LOADER } from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/categoryThreshold';

export const loadThreshold = (threshold) => ({
  type: LOAD_THRESHOLD,
  payload: threshold,
});

export const loadThresholds = (thresholds) => ({
  type: LOAD_THRESHOLDS,
  payload: thresholds,
});

export const loadCount = (count) => ({
  type: LOAD_COUNT,
  payload: count,
});

export const createThreshold = (threshold) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/create`, threshold)
    .then((response) => {
      Router.pushRoute('threshold_profile', { thuuid: response.data.uuid });
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createThreshold, threshold);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getThreshold = (id) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-item/${id}`)
    .then((response) => {
      dispatch(loadThreshold(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getThreshold, id);
    });
};

export const updateThreshold = (threshold) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .put(`${routePath}/update`, threshold)
    .then(() => {
      dispatch(getThreshold(threshold.uuid));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateThreshold, threshold);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteThreshold =
  ({ uuid, buildingBudgetCategoryUUID }) =>
  (dispatch) => {
    dispatch({ type: SHOW_LOADER });

    api
      .delete(`${routePath}/delete/${uuid}`)
      .then(() => {
        dispatch(loadThreshold(null));
        Router.pushRoute('building_budget_category_profile', { bbcuuid: buildingBudgetCategoryUUID });
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response.data));
        handleErrors(error, dispatch, deleteThreshold, { uuid, buildingBudgetCategoryUUID });
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const getThresholdList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params })
    .then((response) => {
      dispatch(loadThresholds(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getThresholdList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getThresholdListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list-with-size`, { params })
    .then((response) => {
      dispatch(loadThresholds(response.data.rows));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getThresholdListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const addUserToSendList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/add-to-send-list`, params)
    .then(() => {
      dispatch(getThreshold(params.thresholdUUID));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, addUserToSendList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const removeUserFromSendList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`${routePath}/remove-from-send-list`, params)
    .then(() => {
      dispatch(getThreshold(params.thresholdUUID));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, removeUserFromSendList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
